import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import testimonials from "./Partner/testimonialsData";
import "./SeeAllTestimonial.css";
import { Pagination } from "@mui/material";
import { useState } from "react";

const splitArray = (arr, rows) => {
  const itemsPerRow = rows;
  return arr.reduce((acc, val, ind) => {
     const currentRow = Math.floor(ind / itemsPerRow);
     if(!acc[currentRow]){
        acc[currentRow] = [val];
     }else{
        acc[currentRow].push(val);
     };
    //  console.log(acc,testimonials)
     return acc;
  }, []);
};

const testimonialsPages = splitArray(testimonials, 10)

const SeeAllTestimonial = () => {

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value);
  };
  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const color = ["#febfbd", "#fdddaa", "#b1e8ca"];
  return (
    <div className="p-2">
      <div
      style={{paddingTop:"35px"}}
        className="page-heading-h1"
      >
        Success Stories
      </div>
      <div
        className="normalText details-box"
      >
        Read the {" "}
        <a
          href="https://www.iitmaa.org/f/mock-interview-12582"
          className="underline text-blue-600 hover:text-blue-800"
        >
          Blog Article
        </a>{" "}
        on the role of Fyzen Career Solutions Pvt Ltd (owner of interwiu.com) in
        the success of below students by conducting Mock Interviews (with
        Feedback Sessions) before the Campus Placements (2021-22) at IIT Madras.
        <br /> (published by <b> IIT Madras Alumni Association</b>)
      </div>
      {testimonialsPages[page-1].map((testimonial, index) => {
        return (
          <div className="my-5 mx-auto">
            <div
              className="text-black rounded-lg p-3 mx-auto main-box"
              style={{
                maxWidth: "1100px",
                backgroundColor: color[index % 3],
              }}
              key={index}
            >
              <div className="col-span-1 my-auto">
                <div
                  className="rounded-3xl m-3 mx-auto overflow-hidden  img-box"
                  style={{ height: "169px", width: "169px" }}
                >
                  <img
                    src={require("../../images/testimonial-image/" +
                      testimonial.name +
                      ".jpg")}
                    className="object-cover"
                    alt="Person"
                  />
                </div>
                <div className="text-center">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{ fontSize: "1.2rem" }}
                    sx={{ fontWeight: "bold" }}
                  >
                    {testimonial.name}
                  </Typography>
                  {/* <p>
                            Place at {testimonial.company} as {testimonial.position}
                          </p> */}
                </div>
              </div>
              <div className="col-span-3 my-auto">
                <Typography
                  variant="body1"
                  sx={{ m: 0, fontSize: "18px" }}
                  gutterBottom
                >
                  {testimonial.degree} <b>IIT Madras</b>, 2022
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "18px" }}
                  gutterBottom
                >
                  Placed at <b>{testimonial.placedIn}</b> as{" "}
                  <b>{testimonial.position}</b>
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    textAlign: "justify",
                    lineHeight: 1.2,
                    fontSize: 16,
                    mt: 2,
                  }}
                >
                  <i class="fa-solid fa-quote-left text-green-800"></i>{" "}
                  {testimonial.comment}{" "}
                  <i class="fa-solid fa-quote-right text-green-800"></i>
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
      <Pagination count={Math.ceil(testimonials.length/10)} page={page} onChange={handleChange} style={{margin:"30px", display:"flex",justifyContent:"center"}} color="primary" />
    </div>
  );
};

export default SeeAllTestimonial;
