import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
// import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import { Typography } from "@mui/material";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const StudentsFAQS = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question: "What is Interwiu.com?",
      answer:
        "interwiu.com is an online interactive platform which connects the students (as interviewees) with the professionals from top MNCs (as interviewers) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews with integral Feedback Sessions. The interviewers and mentors serving at the platform are experienced professionals from various big MNCs like Microsoft, Amazon, Google, Goldman Sachs, McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture, Deloitte, etc.",
    },
    {
      question: "How do I schedule a Mock Interview?",
      answer:
        "Browse interviewers, apply filters (profile, duration, company of interviewer, suitable date and time) to refine your search, and send a Mock Interview Request to each of the best suiting interviewers by picking a date and time-slot as per as per your own and interviewer’s availability",
    },
    {
      question: "When do I pay for a Mock Interview (with Feedback Session)?",
      answer:
        "Once your mock interview request is accepted by an interviewer, you have to pay using the associated payment gateway to confirm a mock interview (with feedback session)",
    },
    {
      question: "How and when do I get the meeting link for a mock interview?",
      answer:
        "Once you pay for a mock interview and thereby confirm it, you are automatically provided with a meeting link (Google Meet) by interwiu.com, which is embedded in the [Join Meeting] button provided on the card of each such confirmed mock interview. The same meeting link is provided to your interviewer as well",
    },
    {
      question:
        "How is the structure of a Mock Interview with Feedback Session?",
      answer:
        "The mock interviews are structured exactly like real placement/internship interviews in every context such as kind/type, level, and number of questions asked; inclusion of stress rounds and HR questions; environment; etc. The feedback is provided by your interviewer during/after the mock interview on every possible aspect like your incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. Interviewers also suggest better ways of answering certain questions",
    },
    {
      question:
        "What is the duration of a Mock Interview with Feedback Session?",
      answer:
        "The mock interviews are structured exactly like real placement/internship interviews in every context such as kind/type, level, and number of questions asked; inclusion of stress rounds and HR questions; environment; etc. The feedback is provided by your interviewer during/after the mock interview on every possible aspect like your incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. Interviewers also suggest better ways of answering certain questions",
    },
    {
      question:
        "How is the structure of a Mock Interview with Feedback Session?",
      answer:
        "The duration can be 30, 45, 60, 75, or 90 minutes, depending on the interviewer. You can choose interviewers as per your preference of duration. Also, the duration can be extended based on your requirements and interviewer’s  availability",
    },
    {
      question: "Why do I need to create an account or a profile?",
      answer:
        "Your short profile is needed to match and connect you with the most relevant and best suiting interviewers. Your profile also helps your prospective interviewers to know about you before the mock interview, which ensures the credibility of mock interviews",
    },
    {
      question:
        "If I appear for multiple mock interviews, one after another with some intermittent gaps, will those sessions be arranged with the same interviewer or different interviewers?",
      answer:
        "After a mock interview is done, it’s completely your choice whether to pick the same interviewer or different interviewers for the subsequent mock interviews",
    },
    {
      question: "How do I find a relevant interviewer?",
      answer:
        "Once you have browsed the interviewers, apply the filters to refine your search, and thus find the interviewers best suiting your requirements/preferences ",
    },
    {
      question: "How can I reschedule a confirmed Mock Interview?",
      answer:
        "You can go to the [Reschedule a Mock Interview] option, pick a new slot as per the interviewer’s availability, and book another slot with the same interviewer",
    },
    {
      question: "How can I cancel a confirmed Mock Interview?",
      answer:
        "You can go to the [Cancel a Mock Interview] option to cancel a confirmed mock interview. The refund will be governed by our refund policy",
    },
    {
      question:
        "How and when can I report an issue about an upcoming or a completed mock interview?",
      answer:
        "You can report an issue about any upcoming or completed mock interview by using the [Report an Issue] option only upto 24 hours after the respective mock interview slot",
    },
    {
      question: "How can I check the profiles of interviewers?",
      answer:
        "You can visit any interviewer’s profile with a single click. You can also visit his/her LinkedIn profile using the link (if available) on his/her interwiu.com profile",
    },
    {
      question: "How and when do I receive my feedback from my interviewer?",
      answer:
        "You receive a detailed feedback from your interviewer in a verbal form during and/or in the end of your each mock interview",
    },
    {
      question:
        "What can I expect from a Mock Interview with Feedback Session?",
      answer:
        "You can expect to feel the heat of actual placement/internship interviews in the mock interviews, which are constituted with all the relevant questions in a well structured format. The feedback provided with each mock interview session will help you to understand and rectify your mistakes, realize what went right/wrong, prepare better, improve the performance in subsequent mock interviews, and thus ultimately enhance the odds of cracking the real placement/internship interviews in a highly competitive environment",
    },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <Typography sx={{}} variant="h3">
            FAQs for Students
          </Typography>

          <Typography>Got a question? We're here to answer!</Typography>

          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
export default StudentsFAQS;
