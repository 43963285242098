import React, { useState, useEffect, useContext } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import $ from "jquery";
import "../Dashboard/ProfileForm.css";
import toast from "react-hot-toast";
import { reduce_image_file_size, toastStyle } from "../../utility/helper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Navbarlogedin from "./Navbarlogedin";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from "@mui/material";
import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import FileBase from "react-file-base64";
import { Oval } from "react-loader-spinner";
import Radio from "@mui/material/Radio";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { Divider } from "@mui/material";
import { TextField, Menu, MenuItem, ButtonBase } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa0,
  faCancel,
  faCheck,
  faClose,
  faCross,
  faCut,
  faInfo,
  faPen,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { HOME_ROUTE } from "../../constants/routes";

const ProfileForm = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const { user, setUser } = useContext(UserContext);
  const [userData, setUserData] = useState({});
  const [branch, setBranch] = useState("");
  const [photo, setPhoto] = useState(null);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [checked, setChecked] = useState();
  const [profiledata, setProfileData] = useState("");
  const [newImage, setnewImage] = useState(null);
  const [newResume, setnewResume] = useState("");
  const [imageSize, setimageSize] = useState(false);
  const [resumeSize, setresumeSize] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [errs, setErrs] = useState({});
  const [pfpImage, setpfpImage] = useState(null);
  const [editInstituteDetails, setEditInstituteDetails] = useState(true);
  const [prevTeammates, setPrevTeammates] = useState([]);
  const [viewAdmins, setViewAdmins] = useState(false);
  const [verified, setVerified] = useState([]);
  const [state2, setState2] = React.useState({
    aCheckbox1: user.aCheckbox1,
    aCheckbox2: user.aCheckbox2,
  });
  const [hasTeammates, setHasTeammates] = useState(0);
  const [foundAdmin, setFoundAdmin] = useState("")

  const [stats, setStats] = useState({
    added: 0,
    completed: 0,
    upcoming: 0,
    registered: 0,
    feedback: { q1: 0, q2: 0, q3: 0, q4: 0 },
  });

  // console.log(user);
  const [fieldError, setFieldError] = useState({
    Pos: false,
    program: false,
    ibranch: false,
  });
  const [teammates, setTeammates] = useState([
    {
      tFname: "",
      tTitle: "",
      tLname: "",
      tEmail: "",
      tPos: "",
      tWhatsapp: "",
      tCheckbox1: false,
      tCheckbox2: false,
    },
  ]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  useEffect(() => {
    let x = [];
    axios
      .get(`${BASE}/send/admin/added/${user.id}`)
      .then((res) => {
        x.push(res.data.length);
        axios
          .get(`${BASE}/send/admin/registered/${user.id}`)
          .then((response) => {
            x.push(response.data.ans.length);
            axios
              .get(`${BASE}/send/admin/getCompletedInterviews/${user.id}`)
              .then((res) => {
                x.push(res.data.requests.length);
                axios
                  .get(`${BASE}/send/admin/getUpcomingInterviews/${user.id}`)
                  .then((res) => {
                    x.push(res.data.requests.length);
                    setStats({
                      added: x[0],
                      registered: x[1],
                      completed: x[2],
                      upcoming: x[3],
                    });

                    axios
                      .get(`${BASE}/send/admin/getFeedback/${user.id}`)
                      .then((response) => {
                        let y = [0, 0, 0, 0];
                        for (
                          let i = 0;
                          i < response.data?.intReviews.length;
                          i++
                        ) {
                          console.log(
                            response.data.intReviews[i].scores,
                            "-------------"
                          );
                          y[0] =
                            y[0] +
                            parseInt(response.data.intReviews[i].scores.q1);
                          y[1] =
                            y[1] +
                            parseInt(response.data.intReviews[i].scores.q2);
                          y[2] =
                            y[2] +
                            parseInt(response.data.intReviews[i].scores.q3);
                          y[3] =
                            y[3] +
                            parseInt(response.data.intReviews[i].scores.q4);
                        }
                        y[0] =
                          Math.round(
                            (y[0] / response.data?.intReviews.length) * 100
                          ) / 100;
                        y[1] =
                          Math.round(
                            (y[3] / response.data?.intReviews.length) * 100
                          ) / 100;
                        y[2] =
                          Math.round(
                            (y[2] / response.data?.intReviews.length) * 100
                          ) / 100;
                        y[3] =
                          Math.round(
                            (y[1] / response.data?.intReviews.length) * 100
                          ) / 100;
                        setStats({
                          added: x[0],
                          registered: x[1],
                          completed: x[2],
                          upcoming: x[3],
                          feedback: { q1: y[0], q2: y[1], q3: y[2], q4: y[3] },
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(user);
    // console.log(userData);
    // setUser((user) => ({...user, [name] : value}))
    setUserData((userData) => ({ ...userData, [name]: value }));
  };
  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${BASE}/send/student/112964740830785165733`)
        .then((response) => {
          setProfileData(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
        });
    }
    fetchData();
  }, []);
  const [admin, setAdmin] = useState({});
  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${BASE}/send/admin/getAdmin/${user.id}`)
        .then((response) => {
          setAdmin(response.data);
          if(response.data.pemail.split('@')[1] !== "gmail.com"){
            navigate("/", {state : {from : location}, replace : true});
            toast.error("Please login using a valid gmail account", {...toastStyle.error});
          }
          setLoaded(true);
          setHasTeammates(response.data.teamMates.length)
          // console.log(response.data.teamMates.length)
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
        });
    }
    fetchData();
  }, []);
  useEffect(() => {
    setUserData({
      ...user,
      ["aEmail"]: user.aEmail ? user.aEmail : user.iemail,
    });
    // console.log(user, "--------1312213123---------123123123123");
    // setnewImage(user.iLogo);
    setChecked(user.iPaymentPref);
    if (user.aFname) {
      // setEditInstituteDetails(true);
    }
  }, [user]);
  // console.log(checked);
  useEffect(() => {
    axios.get(`${BASE}/admin/get/instituteEmail/${user.pemail}`).then((res) => {
      if (!res.data || res.data.primaryAdmin == user.id) {
        if (user.teamMates !== {}) {
          user?.teamMates?.forEach((element, index) => {
            axios
              .get(`${BASE}/admin/get/adminTeammates/${element.tEmail}`)
              .then((res) => {
                setVerified((prev) => {
                  prev[index] = res.data;
                  return prev;
                });
                setTeammates(user.teamMates);
                setPrevTeammates(user.teamMates);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
        setUserData((prev) => {
          if (!prev["aEmail"]) {
            prev["aEmail"] = prev.iemail;
          }
          return prev;
        });
      } else {
        //checkingmatching with institute email
        axios
          .delete(`${BASE}/admin/delete/adminEmail/${user.pemail}`)
          .then((res) => {
            if (localStorage.getItem("isAAuthenticated"))
              localStorage.removeItem("isAAuthenticated");
            setUser({});
            navigate(HOME_ROUTE);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        toast.error(
          "This is an institute email address. Please use your personal Gmail to login."
        );
      }
    });

    console.log(teammates);
  }, [user]);
  const handleChangeRadio = (event) => {
    console.log(checked);
    setChecked(event.target.value);
  };
  const handlePrefChange = (e) => {
    const profile = e.target.value;
    if (profile)
      setUserData((userData) =>
        userData.profiles.indexOf(profile) === -1
          ? { ...userData, profiles: [profile] }
          : userData
      );
  };
  const handleChangeCheckBox2 = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });
  };
  const handleProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      profiles: userData.profiles.filter((item) => item !== label),
    }));
  // Handle Change for Targeted Companies
  const handleChangeTargetedCompanies = (index, profile, value) => {
    // document.querySelectorAll(".temail")[index].style.borderColor = "none";
    let tarComObj = [...teammates];
    tarComObj[index][profile] = value;

    console.log(tarComObj);
    setTeammates(tarComObj);
  };
  const handleBranchChange = () => {
    console.log(branch);
    if (branch) {
      setUserData((userData) =>
        userData?.ibranches?.indexOf(branch) === -1
          ? { ...userData, ibranches: [...userData.ibranches, branch] }
          : userData
      );
      setBranch("");
    }
    console.log(userData);
  };
  const handleBranchDelete = (label) => {
    console.log(label);
    setUserData((userData) => ({
      ...userData,
      ibranches: userData.ibranches.filter((item) => item !== label),
    }));
  };
  useEffect(() => {
    console.log("admin id", admin?.id);
    console.log("user id", user?.id);
  }, [admin]);

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    if (!userData.aTitle) {
      const box = document.getElementById("atitle");
      box.style.borderColor = "#FF0000";
      setErrs({ ...errs, title: true });
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else setErrs({ ...errs, title: false });

    if (!userData.aFname) {
      const box = document.getElementById("afname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("afname").style.borderColor = "#E5E7EB";

    if (!userData.aLname) {
      const box = document.getElementById("alname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("alname").style.borderColor = "#E5E7EB";

    if (!userData.aPos) {
      const box = document.getElementById("apos");
      box.style.borderColor = "#FF0000";
      // window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("apos").style.borderColor = "#E5E7EB";

    if (!userData.amobNumber) {
      const box = document.getElementById("amobNumber");
      box.style.borderColor = "#FF0000";
      // window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("amobNumber").style.borderColor = "#E5E7EB";

    if (!userData.ibranches || userData.ibranches.length === 0) {
      const box = document.getElementById("ibranches");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      alert("Please select atleast one branch");
      return box.focus();
    } else document.getElementById("ibranches").style.borderColor = "#E5E7EB";


    if (userData.aEmail) {
      if (userData.aEmail.split("@")[1] !== "gmail.com") {
        const box = document.getElementById("aemail");
        box.style.borderColor = "#FF0000";
        alert(
          "Please enter a valid gmail address (no other email address is allowed)"
        );
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else {
        document.getElementById("aemail").style.borderColor = "#E5E7EB";
      }
    } else {
      document.getElementById("aemail").style.borderColor = "#E5E7EB";
      // $("#aemail:before").css("content","This is incorrect gmail address");
    }

    if (
      !userData.aMobNumber &&
      isValidPhoneNumber(String(userData.aMobNumber))
    ) {
      const box = document.getElementById("amobNumber");
      box.parentElement.style.borderColor = "#FF0000";

      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("amobNumber").style.borderColor = "#e5e7eb";

    if (!userData.iname) {
      setFieldError({ ...fieldError, institute: true });
      const box = document.getElementById("iname");
      box.style.borderColor = "#FF0000";
      // window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else {
      document.getElementById("iname").style.borderColor = "#E5E7EB";
    }

    if (!userData.iemail) {
      const box = document.getElementById("iemail");
      box.style.borderColor = "#FF0000";
      return box.focus();
    } else document.getElementById("iemail").style.borderColor = "#E5E7EB";

    if (!userData.imobNumber) {
      const box = document.getElementById("imobNumber");
      box.style.borderColor = "#FF0000";
      return box.focus();
    } else document.getElementById("imobNumber").style.borderColor = "#E5E7EB";

    const institute = await axios.get(`${BASE}/admin/get/instituteEmail/${userData.aEmail}`)
    console.log(institute);

    if (institute.data) {
      if ((userData.iemail === userData.aEmail) && !user.isAdmin) {
        const box = document.getElementById("iemail");
        box.style.borderColor = "#FF0000";
        alert("Institute email and Admin email cannot be same");
        return box.focus();
      } else document.getElementById("imobNumber").style.borderColor = "#E5E7EB";
    }



    if (teammates.length !== 0) {
      console.log(teammates);
      let check = false;
      teammates.forEach((teammate, index) => {
        if (teammate.tEmail && index - prevTeammates.length >= 0) {
          if (teammate.tEmail.split("@")[1] !== "gmail.com") {
            // console.log(document.querySelectorAll(".temail")[ index - prevTeammates.length],"---21-3-123-231--1232-1231-21-231-");
            const box =
              document.querySelectorAll(".temail")[
              index - prevTeammates.length
              ];
            box.style.borderColor = "#FF0000";
            alert(
              "Please enter a valid gmail address of other admins (no other email address is allowed)"
            );
            // window.scrollTo(0, box.offsetTop);
            check = true;
            return box.focus();
            // return;
          } else {
            document.querySelectorAll(".temail")[
              index - prevTeammates.length
            ].style.borderColor = "#E5E7EB";
          }
        } else if (index - prevTeammates.length >= 0)
          document.querySelectorAll(".temail")[
            index - prevTeammates.length
          ].style.borderColor = "#E5E7EB";

        if (
          (!teammate.tWhatsapp ||
            !isValidPhoneNumber(String(teammate.tWhatsapp))) &&
          index - prevTeammates.length >= 0
        ) {
          const box =
            document.querySelectorAll(".twhatsapp")[
            index - prevTeammates.length
            ];
          box.parentElement.style.borderColor = "#FF0000";

          alert("Please enter a valid phone number");
          // window.scrollTo(0, box.offsetTop);
          check = true;
          return box.focus();
          // return ;
        } else if (index - prevTeammates.length >= 0) {
          document.querySelectorAll(".twhatsapp")[
            index - prevTeammates.length
          ].style.borderColor = "#E5E7EB";
        }
      });

      if (check) {
        return;
      }
    }

    // setUpdateDetails(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const fd = new FormData();
      fd.append("hi", "hello");
      fd.append("studentinfo", userData);
      fd.append("comp", teammates);
      userData.teamMates = teammates;
      // if (imageSize) {
      //   const box = document.getElementById("image");
      //   return window.scrollTo(0, box.offsetTop);
      // }
      // if (resumeSize) {
      //   const box = document.getElementById("resume");
      //   return window.scrollTo(0, box.offsetTop);
      // }
      if (newImage) {
        userData.iLogo = newImage;
      }
      // if (newResume) {
      //   userData.resume = newResume;
      // }
      console.log(userData);
      console.log(newImage);
      // let newTeammates=[...teammates,...prevTeammates]
      for (let i = 0; i < teammates.length; i++) {
        if(teammates[i].tEmail === userData.pemail){
          alert("You can not add yourself");
          document.querySelectorAll(".temail")[
            i - prevTeammates.length
          ].style.borderColor = "#FF0000";
          return document
            .querySelectorAll(".temail")
          [i - prevTeammates.length].focus();
        }
        for (let j = i + 1; j < teammates.length; j++) {
          if (teammates[i].tEmail === teammates[j].tEmail) {
            // if (document.querySelectorAll(".temail")[j - prevTeammates.length])
            document.querySelectorAll(".temail")[
              j - prevTeammates.length
            ].style.borderColor = "#FF0000";
            if (verified[i] && verified[i] === true) {
              alert("This admin is already registered");
            } else {
              alert("This admin has been already added");
            }
            // window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin`;
            // window.scrollTo(0,document.querySelectorAll(".temail")[j-prevTeammates.length])
            return document
              .querySelectorAll(".temail")
            [j - prevTeammates.length].focus();
          }
        }
      }

      // axios
      //   .get(`${BASE}/admin/get/instituteEmail/${userData.iemail}`)
      //   .then((res) => {
      //     if (!res.data) {
      // const filteredTeammates = teammates.filter(tMate => !prevTeammates.includes(tMate));
      axios
        .patch(
          `${BASE}/send/adminUpdate/${user.id}`,
          { userData, state2, teammates, checked, prevTeammates },
          config
        )
        .then((res) => {
          const foundAdmins = res?.data?.foundAdmins;
          console.log(foundAdmins);
          if(foundAdmins && foundAdmins.length>0){
            // for (let i = 0; i < foundAdmins.length; i++) {
              for (let j = 0; j < teammates.length; j++) {
                if (foundAdmins.includes(teammates[j].tEmail)) {
                  // if (document.querySelectorAll(".temail")[j - prevTeammates.length])
                  alert("This admin is already registered under an institute");
                  document.querySelectorAll(".foundAdminMessage")[j - prevTeammates.length].innerHTML = teammates[j].tEmail + " is already registered as an admin at interwiu.com. Please try with a different Gmail address of this person";
                  return document.querySelectorAll(".foundAdminMessage")[j - prevTeammates.length].style.display = "";
                  // return document.querySelectorAll(".temail")[
                  //   j - prevTeammates.length
                  // ].style.borderColor = "#FF0000";
                  // window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin`;
                  // window.scrollTo(0,document.querySelectorAll(".temail")[j-prevTeammates.length])
                  // return document
                    // .querySelectorAll(".temail")
                  // [j - prevTeammates.length].focus();
                }
              }
            // }
          }
          else {
            if (!user?.aVerified) {
            setUser({...userData});
            toast.success(
              /* `${data.message}` */ "Profile verfied successfully",
              {
                ...toastStyle.success,
              }
            );
          } else {
            setUser({...userData});
            toast.success(
              /* `${data.message}` */ "Profile updated successfully",
              {
                ...toastStyle.success,
              }
            );
          }
          setHasTeammates(teammates.length)
          console.log(teammates.length)
          setUpdateDetails(false);
          if (!user?.aVerified || !user?.imobNumber) {
            setTimeout(() => {
              navigate("/admin/welcome");
              // window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/welcome`;
            }, 1000);
          }
          }
        })
        .catch((err) => {
          // if (err.msg) {
          //   toast.error(`${err.msg}`, {
          //     ...toastStyle.error,
          //   });
          // }
          // console.log(err);
        });
      //   }
      //   else{
      //     toast.error("This Institute Email is already registered as an Institute Email", {
      //       ...toastStyle.error,
      //     });
      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    } catch (error) {
      toast.error(`${error.message}`, {
        ...toastStyle.error,
      });
    }
  };
  if (updateDetails) {
    return (
      <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
        <Oval
          height={120}
          width={120}
          color="#306bc9"
          wrapperStyle={{ padding: "50%, 50%" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#90caf9"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Edit Profile</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      {loaded ? (
        <div className="profile-form py-1">
          {!user?.aVerified ?
            <>
            <h1 className="text-2xl font-medium text-center py-4 text-blue-500 pt-5 s-mobile-head-edit-profile">
              {user.isAdmin ? "You're just a step away from registering your Institute at interwiu.com" : "You're just a step away from accessing the Institute Account of " + user.iname + " at interwiu.com"}
            </h1>
            <h1
              className="text-2xl font-medium text-center py-4 leading-3 text-black-500 s-mobile-subhead-edit-profile max-[600px]:mb-[1re]"
              style={{ color: "rgb(9,90,84)" }}
            >
              {user.isAdmin ? "Tell us about your institute!" : "Verify your account!"}
            </h1>
            </> :
            <div style={{margin : "30px 0"}}></div>
          }
          <form onSubmit={handleSubmit}>
            {/* Profile Form Container */}

            <div className="profile-form-cont">
              {/* Col-70 */}

              <div className="profile-form-text">
                {/* First Name and Last Name */}
                <div className="max-[600px]:text-right">
                  {(userData?.isAdmin && userData?.aVerified) && (
                    <Button
                      variant="contained"
                      style={{ width: "50px" }}
                      color="warning"
                      onClick={() => {
                        if(!editInstituteDetails){
                          setUserData({
                            ...userData,
                            iname : user.iname,
                            iemail : user.iemail,
                            imobNumber : user.imobNumber,
                            iLogo : user.iLogo,
                            iWebsite : user.iWebsite,
                            iLinkedIn : user.iLinkedIn
                          });
                        }
                        setEditInstituteDetails(!editInstituteDetails);
                      }}
                      className="h-[40px] max-[600px]:h-[30px]"
                    >
                      {editInstituteDetails ? "Edit" : "Cancel"}
                      <FontAwesomeIcon
                        size={2}
                        icon={editInstituteDetails ? faPen : ""}
                      />
                    </Button>
                  )}
                </div>

                {/* mobile profile photo  */}
                <div className="profile-photo border rounded-md p-2 s-resume-950-1 mt-[1rem]">
                  <div className="element">
                    <label htmlFor="instituteName">
                    Logo of your Institute
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <img src={user.iLogo} className="upload" alt="Upload" />
                  {(userData.isAdmin === true && (!editInstituteDetails || !userData?.aVerified)) && (
                    <div className="filebase">
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={async ({ base64, size }) => {
                          if (parseInt(size) < 500) {
                            setimageSize(false);
                            setnewImage(await reduce_image_file_size(base64));
                            console.log(await reduce_image_file_size(base64));
                          } else {
                            setimageSize(true);
                          }
                        }}
                      />
                    </div>
                  )}
                  {imageSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 500 KB
                    </p>
                  )}
                </div>


                <div className="name cust-row flex">
                  <div className="element">
                    <label htmlFor="instituteName">
                      Full Name of your Institute
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="iname"
                      type="text"
                      name="iname"
                      className="border-2 rounded"
                      value={userData?.iname}
                      onChange={handleChange}
                      placeholder="Institute Name"
                      style={{ height: "56px" }}
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                    />
                  </div>
                </div>

                {/* Email and Mobile Number */}

                <div className="email cust-row flex max-[600px]:flex-col">
                  <div className="element">
                    <label htmlFor="email">
                      Email address of Institute
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="iemail"
                      id="iemail"
                      // disabled
                      className="border-2 rounded"
                      value={userData.iemail}
                      onChange={handleChange}
                      placeholder="Email"
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                      style={{ height: "56px" }}
                    />
                    {/* {admin && admin !== {} && admin.id !== user.id && ( */}
                      {/* <p style={{fontSize : "12px", fontStyle: "italic"}}>
                        Details of Institute added by
                        &nbsp;
                        {admin.aFname + " " + admin.aLname}
                      </p>
                    )} */}
                  </div>
                  
                  <div className="element">
                    <label htmlFor="number">
                      Phone number of Institute
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    {/* <div className="border-2 rounded px-3" id="amobNum">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        name="imobNumber"
                        id="imobNumber"
                        value={
                          userData.imobNumber
                            ? String(userData.imobNumber)
                            : "+91"
                        }
                        disabled={userData.isAdmin === false}
                        placeholder="Phone No."
                        
                        onChange={(number) =>
                          setUserData((userData) => ({
                            ...userData,
                            imobNumber: number.target.value,
                          }))
                        }
                        error={
                          userData.imobNumber
                            ? isValidPhoneNumber(String(userData.imobNumber))
                              ? undefined
                              : "Invalid phone number"
                            : "Ph"
                        }
                      />
                    </div> */}

                    <div className="border-2 rounded" id="imobNum">
                      <input
                        // international
                        // countryCallingCodeEditable={false}
                        // defaultCountry="IN"
                        className="s-mobile-text-14"
                        id="imobNumber"
                        name="imobNumber"
                        value={userData.imobNumber}
                        disabled={
                          userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                        }
                        placeholder="Phone No."
                        onChange={(number) =>
                          setUserData((userData) => ({
                            ...userData,
                            imobNumber: number.target.value,
                          }))
                        }
                        error={
                          userData.imobNumber
                            ? isValidPhoneNumber(String(userData.imobNumber))
                              ? undefined
                              : "Invalid phone number"
                            : "Ph"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-3 w-[100%] p-2 border rounded-md text-left s-resume-950-1">
                  <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize:'20px'
                    }}
                  >
                    Social Links
                  </Typography>
                  <div className="my-3">
                    <label htmlFor="iWebsite"
                    className="s-mobile-card-text-15-950">
                      <i className="fa fa-globe"></i> Website of your Institute
                    </label>
                    <input
                      type="text"
                      name="iWebsite"
                      id="iWebsite"
                      value={userData.iWebsite}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="Website URL"
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="iLinkedIn"
                     className="s-mobile-card-text-15-950">
                      <i className="fa-brands fa-linkedin"></i> URL of the LinkedIn page of your Institute
                    </label>
                    <input
                      type="text"
                      name="iLinkedIn"
                      id="iLinkedIn"
                      value={userData.iLinkedIn}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="LinkedIn URL"
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                    />
                  </div>
                </div>
                <div className="profilepref cust-row flex">
                  <div className="element">
                  {admin && admin !== {} && admin.id !== user.id && (
                      <p style={{fontSize : "12px", fontStyle: "italic"}}>
                        <sup style={{color : "red", paddingBottom : "10px"}}>#</sup>
                        Details of Institute added by
                        &nbsp;
                        {admin.aFname + " " + admin.aLname}
                      </p>
                    )}
                    <label htmlFor="ibranches">
                      Branches/Departments in your Institute
                    </label>
                    <div className="flex gap-5 max-[600px]:flex-col max-[600px]:gap-[0.25rem]">
                      <div className="interviewerProfileFormPrevCompInput flex-col gap-[0.5rem]">
                        <Select
                          multiple
                          displayEmpty
                          id="departments"
                          sx={{ width: 1 }}
                          value={[]}
                          renderValue={() => "Select Branches/Departments"}
                          onChange={(e) => {
                            if (e.target.value) {
                              console.log(userData?.ibranches);
                              setUserData((userData) =>
                                userData?.ibranches.indexOf(e.target.value) ===
                                  -1
                                  ? {
                                    ...userData,
                                    ibranches: [
                                      ...userData.ibranches,
                                      e.target.value[0],
                                    ],
                                  }
                                  : userData
                              );
                            }
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue=""
                        >
                          <MenuItem hidden disabled value="">
                            Select
                          </MenuItem>
                          <MenuItem
                            value="Aerospace Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Aerospace Engineering"
                              ) !== -1
                            }
                          >
                            Aerospace Engineering
                          </MenuItem>
                          <MenuItem
                            value="Applied Mechanics"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Applied Mechanics"
                              ) !== -1
                            }
                          >
                            Applied Mechanics
                          </MenuItem>
                          <MenuItem
                            value="Biomedical Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Biomedical Engineering"
                              ) !== -1
                            }
                          >
                            Biomedical Engineering
                          </MenuItem>
                          <MenuItem
                            value="Bioscience and Bioengineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Bioscience and Bioengineering"
                              ) !== -1
                            }
                          >
                            Bioscience and Bioengineering
                          </MenuItem>
                          <MenuItem
                            value="Biotechnology"
                            disabled={
                              userData.ibranches?.indexOf("Biotechnology") !== -1
                            }
                          >
                            Biotechnology
                          </MenuItem>
                          <MenuItem
                            value="Chemical Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Chemical Engineering"
                              ) !== -1
                            }
                          >
                            Chemical Engineering
                          </MenuItem>
                          <MenuItem
                            value="Chemistry"
                            disabled={
                              userData.ibranches?.indexOf("Chemistry") !== -1
                            }
                          >
                            Chemistry
                          </MenuItem>
                          <MenuItem
                            value="Civil Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Civil Engineering"
                              ) !== -1
                            }
                          >
                            Civil Engineering
                          </MenuItem>
                          <MenuItem
                            value="Computer Science and Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Computer Science and Engineering"
                              ) !== -1
                            }
                          >
                            Computer Science and Engineering
                          </MenuItem>
                          <MenuItem
                            value="Economics"
                            disabled={
                              userData.ibranches?.indexOf("Economics") !== -1
                            }
                          >
                            Economics
                          </MenuItem>
                          <MenuItem
                            value="Electrical Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Electrical Engineering"
                              ) !== -1
                            }
                          >
                            Electrical Engineering
                          </MenuItem>
                          <MenuItem
                            value="Electrical and Electronics Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Electrical and Electronics Engineering"
                              ) !== -1
                            }
                          >
                            Electrical and Electronics Engineering
                          </MenuItem>
                          <MenuItem
                            value="Electronics & Communication Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Electronics & Communication Engineering"
                              ) !== -1
                            }
                          >
                            Electronics & Communication Engineering
                          </MenuItem>
                          <MenuItem
                            value="Engineering Design"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Engineering Design"
                              ) !== -1
                            }
                          >
                            Engineering Design
                          </MenuItem>
                          <MenuItem
                            value="Humanities and Social Sciences"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Humanities and Social Sciences"
                              ) !== -1
                            }
                          >
                            Humanities and Social Sciences
                          </MenuItem>
                          <MenuItem
                            value="Management/Business Studies"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Management/Business Studies"
                              ) !== -1
                            }
                          >
                            Management/Business Studies
                          </MenuItem>
                          <MenuItem
                            value="Mathematics/Mathematics and Computing"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Mathematics/Mathematics and Computing"
                              ) !== -1
                            }
                          >
                            Mathematics/Mathematics and Computing
                          </MenuItem>
                          <MenuItem
                            value="Mechanical Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Mechanical Engineering"
                              ) !== -1
                            }
                          >
                            Mechanical Engineering
                          </MenuItem>
                          <MenuItem
                            value="Metallurgical and Materials Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Metallurgical and Materials Engineering"
                              ) !== -1
                            }
                          >
                            Metallurgical and Materials Engineering
                          </MenuItem>
                          <MenuItem
                            value="Mining Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Mining Engineering"
                              ) !== -1
                            }
                          >
                            Mining Engineering
                          </MenuItem>
                          <MenuItem
                            value="Ocean Engineering"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Ocean Engineering"
                              ) !== -1
                            }
                          >
                            Ocean Engineering
                          </MenuItem>
                          <MenuItem
                            value="Physics/Engineering Physics"
                            disabled={
                              userData.ibranches?.indexOf(
                                "Physics/Engineering Physics"
                              ) !== -1
                            }
                          >
                            Physics/Engineering Physics
                          </MenuItem>
                        </Select>

                        <div className="branchInput items-center max-[600px]:flex-row gap-[0.5rem]">
                          {/* <OverlayTrigger
                            className="max-[600px]:order-[3]"
                            placement="top"
                            overlay={
                              <Tooltip className="tooltip">
                                Enter any other branch/department <br /> from
                                here, if it has not been covered <br />
                                in the above drop-down menu
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px]"
                              icon={faInfo}
                            />
                          </OverlayTrigger> */}



                          <input
                            type="text"
                            name="ibranches"
                            id="ibranches"
                            className="border-2 h-[56px] rounded max-[600px]:order-[1] max-[600px]:h-[40px] s-mobile-text-14"
                            value={branch}
                            onChange={(e) => setBranch(e.target.value)}
                            placeholder="Enter any other Branch/Dept"
                            autoComplete="nope"
                            style={{
                              paddingLeft: "10px",
                              maxWidth: "355px",
                              display: "block",
                            }}
                          />
                          {/* <Autocomplete
                          disablePortal
                          freeSolo
                          placeholder="Enter a Branch/Department"
                          name="ibranches"
                          id="ibranches"
                          options={["AE", "EE", "ME", "CSE"]}
                          fullWidth={true}
                          value={branch}
                          // onChange={(e) => setBranch(e.target.value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // value={branch}
                              onChange={(e) => setBranch(e.target.value)}
                              sx={{
                                height: "56px",
                                maxWidth: "355px",
                                display: "block",
                              }}
                            />
                          )}
                        /> */}

                          <Button
                            variant="contained"
                            onClick={() => {
                              handleBranchChange();
                            }}
                            style={{minWidth:'revert'}}
                            className="h-[50px] max-[600px]:order-[2] max-[600px]:h-[40px] max-[600px]:w-[40px] max-[600px]:min-w-[auto] "
                          >
                            <AddRoundedIcon />
                          </Button>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className=" max-[600px]:order-[3]"
                          >
                            <div className="tt ml-auto">
                              <InfoOutlined
                                sx={{
                                  color: "rgb(83, 81, 81)",
                                  fontSize: "20px",
                                }}
                              />
                              <span
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "3px 5px",
                                  top: "-57px",
                                  textAlign: "left",
                                }}
                                className="tttext"
                              >
                                Enter any other branch / department from
                                here, if it has not been covered in the above drop-down menu
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile-pref-list rounded p-2">
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {userData.isAdmin === false &&
                            userData.ibranches?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          {userData.isAdmin === true &&
                            userData.ibranches?.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() => {
                                  handleBranchDelete(value);
                                }}
                              />
                            ))}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1
                    className="text-2xl font-medium text-center py-4 leading-3 text-black-500 s-mobile-subhead-edit-profile max-[600px]:mb-[1re]"
                    style={{ color: "rgb(9,90,84)", marginLeft: "0" }}
                  >
                    Personal Details of the Institute Admin
                  </h1>
                  <div></div>
                </div>
                <div className="name cust-row flex max-[600px]:flex-col" style={{ alignItems: 'flex-start' }}>
                  <div className="element" style={{ width: "auto" }}>
                    <label htmlFor="title" className="max-[600px]:w-[100%] max-[600px]:inline-block">
                      Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      name="aTitle"
                      id="atitle"
                      // error={errs.title}
                      value={userData.aTitle || ""}
                      onChange={handleChange}
                      autoWidth
                      className="h-[56px] max-[600px]:h-[40px] max-[600px]:w-[100%]"
                      sx={{ padding: "0px" }}
                    >
                      <MenuItem value="Mr">Mr.</MenuItem>
                      <MenuItem value="Ms">Ms.</MenuItem>
                      <MenuItem value="Prof">Prof.</MenuItem>
                      <MenuItem value="Dr">Dr.</MenuItem>
                    </Select>
                  </div>
                  <div className="element">
                    <label htmlFor="firstname">
                      Your First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="afname"
                      type="text"
                      name="aFname"
                      className="border-2 rounded"
                      value={userData.aFname}
                      onChange={handleChange}
                      placeholder="First Name"
                      style={{ height: "56px" }}
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="lastname">
                      Your Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="alname"
                      type="text"
                      name="aLname"
                      className="border-2 rounded"
                      value={userData.aLname}
                      onChange={handleChange}
                      placeholder="Last Name"
                      style={{ height: "56px" }}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="branch cust-row flex">
                    <div className="element w-50">
                      <label htmlFor="Pos">
                        Position/Designation
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        id="apos"
                        type="text"
                        name="aPos"
                        className="border-2 rounded h-[56px] max-[600px]:h-[40px]"
                        placeholder="Your Current Position/Designation"
                        displayEmpty
                        error={fieldError.Pos}
                        value={userData.aPos}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldError({ ...fieldError, Pos: false });
                        }}
                        autoComplete="nope"
                      />
                    </div>
                  </div>
                </div>
                <div className="email cust-row flex max-[600px]:flex-col">
                  <div className="element">
                    <label htmlFor="aEmail">
                      Your Gmail<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="aEmail"
                      id="aemail"
                      className="border-2 rounded"
                      value={userData.aEmail}
                      placeholder="Email"
                      onChange={handleChange}
                      style={{ height: "56px" }}
                      disabled={true}
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="amobNumber">
                      Your WhatsApp Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="border-2 rounded px-3" id="amobNum">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        name="amobNumber"
                        id="amobNumber"
                        value={
                          userData.amobNumber
                            ? String(userData.amobNumber)
                            : "+91"
                        }
                        onChange={(number) =>
                          setUserData((userData) => ({
                            ...userData,
                            amobNumber: number,
                          }))
                        }
                        error={
                          userData.amobNumber
                            ? isValidPhoneNumber(String(userData.amobNumber))
                              ? undefined
                              : "Invalid phone number"
                            : "Ph"
                        }
                      />
                    </div>
                    {/* <div className="border-2 rounded px-3" id="mobNum"></div> */}
                  </div>
                </div>

                {/* <FormControl component="fieldset" variant="standard"> */}
                {/* <FormGroup> */}
                {/* {(checked === 1 ||
                      checked === 3 ||
                      checked === "1" ||
                      checked === "3") && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state2.aCheckbox1}
                            onChange={handleChangeCheckBox2}
                            name="aCheckbox1"
                          />
                        }
                        sx={{ marginTop: "20px" }}
                        label=" I will complete the payment process using the credentials of my Institute’s Bank Account or any other Bank Account (via any available mode of payment)"
                      />
                    )} */}
                {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={state2.aCheckbox2}
                          onChange={handleChangeCheckBox2}
                          name="aCheckbox2"
                        />
                      }
                      sx={{ marginTop: "20px" }}
                      label="I will have the viewing rights for data of students, mock interviews, feedback by interviewers, etc. at interwiu.com"
                    /> */}
                {/* </FormGroup> */}
                {/* </FormControl> */}

                <Divider sx={{ marginTop: "20px" }}></Divider>

                {/* Rendering teammates that are already present */}
                {viewAdmins && (
                  <>
                    <div className="mt-5 mb-5">
                      <h1
                        className="text-2xl font-medium text-left py-4 leading-3 text-black-500 s-mobile-subhead-edit-profile max-[600px]:mb-[1re]"
                        style={{ color: "rgb(9,90,84)", marginLeft: "0" }}
                      >
                        Other Admins
                      </h1>
                      {prevTeammates.map((res, index) => {
                        return (
                          <div className="py-1 mb-2 s-mobile-text-14">
                            <p className="py-1">
                              <span style={{ fontWeight: "bold" }}>• Name</span>{" "}
                              : {res.tFname} {res.tLname}{" "}
                              <span
                                style={{
                                  color: `${verified[index] ? "green" : "red"}`,
                                  whiteSpace:'nowrap'
                                }}
                              >
                                {verified[index] ? (
                                  <>
                                    (<FontAwesomeIcon icon={faCheck} />{" "}
                                    Verified)
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </p>
                            <p style={{wordBreak:'break-all'}} className="py-1">
                              <span style={{ fontWeight: "bold" }}>
                                &nbsp;&nbsp;Email
                              </span>{" "}
                              : {res.tEmail}
                            </p>
                            <p className="py-1">
                              <span style={{ fontWeight: "bold" }}>
                                &nbsp;&nbsp;Mobile No.
                              </span>{" "}
                              : {res.tWhatsapp}
                            </p>
                            <p className="py-1">
                              <span style={{ fontWeight: "bold" }}>
                                &nbsp;&nbsp;Position/Designation
                              </span>{" "}
                              : {res.tPos}
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    <Divider sx={{ marginTop: "20px" }}></Divider>
                  </>
                )}

                {/* Add Teammates */}
                <div
                  style={{
                    display: "flex",
                  }}
                  className="pt-8 items-center max-[600px]:items-start max-[600px]:flex-col"
                >
                  <h1
                    className="text-2xl font-medium text-center py-4 leading-3 text-black-500 s-mobile-subhead-edit-profile max-[600px]:mb-[1re]"
                    style={{ color: "rgb(9,90,84)", marginLeft: "0" }}
                  >
                    Add other Admins
                  </h1>
                  <div className="pl-1 max-[600px]:text-[15px]">
                    (The admins whom you add here, can add further admins from
                    their end)
                  </div>
                </div>
                <div className="w-full gap-2 text-lg mt-2 flex">
                  <div className="element">
                    <div>
                      {teammates?.map(
                        (item, index) =>
                          index >= prevTeammates.length && (
                            <div
                              key={index}
                              className="relative rounded border p-2 my-2"
                            >
                              {teammates?.length > 0 ? (
                                <div className="absolute top-1 right-1">
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => {
                                      setTeammates(
                                        teammates?.filter(function (sItem) {
                                          return sItem !== item;
                                        })
                                      );
                                      document.querySelectorAll(".foundAdminMessage").forEach(element => {
                                        element.style.display = "none"
                                      })
                                    }}
                                  >
                                    <CancelIcon fontSize="medium" />
                                  </IconButton>
                                </div>
                              ) : null}
                              {/* {teammates?.length > 0 && <> */}
                              {/* {index >= user.teamMates.length && <> */}
                              <div className="name cust-row flex max-[600px]:flex-col" style={{ alignItems: 'flex-start' }}>
                                <div
                                  className="element"
                                  style={{ width: "auto" }}
                                >
                                  <label htmlFor="title" className="max-[600px]:w-[100%] max-[600px]:inline-block">
                                    Title<span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Select
                                    name="tTitle"
                                    id="ttitle"
                                    // error={errs.title}
                                    value={item.tTitle || ""}
                                    onChange={(e) => {
                                      console.log(item);

                                      handleChangeTargetedCompanies(
                                        index,
                                        "tTitle",
                                        e.target.value
                                      );
                                    }}
                                    autoWidth
                                    className="h-[56px] max-[600px]:h-[40px] max-[600px]:w-[100%]"
                                    sx={{ padding: "0px" }}
                                    required
                                    disabled={index < prevTeammates.length}
                                  >
                                    <MenuItem value="Mr">Mr.</MenuItem>
                                    <MenuItem value="Ms">Ms.</MenuItem>
                                    <MenuItem value="Prof">Prof.</MenuItem>
                                    <MenuItem value="Dr">Dr.</MenuItem>
                                  </Select>
                                </div>
                                <div className="element">
                                  <label htmlFor="tFname">
                                    First Name of Admin
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    id="tfname"
                                    type="text"
                                    name="tFname"
                                    className="border-2 rounded"
                                    value={item.tFname}
                                    required
                                    onChange={(e) => {
                                      handleChangeTargetedCompanies(
                                        index,
                                        "tFname",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="First Name"
                                    style={{ height: "56px" }}
                                    disabled={index < prevTeammates.length}
                                  />
                                </div>
                                <div className="element">
                                  <label htmlFor="tLname">
                                    Last Name of Admin
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                  </label>
                                  <input
                                    id="tlname"
                                    type="text"
                                    name="tLname"
                                    className="border-2 rounded"
                                    value={item.tLname}
                                    onChange={(e) => {
                                      handleChangeTargetedCompanies(
                                        index,
                                        "tLname",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="Last Name"
                                    style={{ height: "56px" }}
                                    disabled={index < prevTeammates.length}
                                  />
                                </div>
                              </div>
                              <div className="email cust-row flex max-[600px]:flex-col">
                                <div className="element">
                                  <label htmlFor="tPos">
                                    Position/Designation of Admin
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="tPos"
                                    id="tpos"
                                    className="border-2 rounded"
                                    value={item.tPos}
                                    onChange={(e) => {
                                      handleChangeTargetedCompanies(
                                        index,
                                        "tPos",
                                        e.target.value
                                      );
                                    }}
                                    placeholder="Position/Designation"
                                    style={{ height: "56px" }}
                                    disabled={index < prevTeammates.length}
                                  />
                                </div>
                              </div>
                              <div className="email cust-row flex max-[600px]:flex-col">
                                <div className="element">
                                  <label htmlFor="tEmail">
                                    Gmail address of Admin
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    name="tEmail"
                                    className="border-2 rounded temail"
                                    value={item.tEmail}
                                    required
                                    placeholder="Email"
                                    onChange={(e) => {
                                      handleChangeTargetedCompanies(
                                        index,
                                        "tEmail",
                                        e.target.value
                                      );
                                      document.querySelectorAll(".temail").forEach(element => {
                                        element.style.borderColor = "#E5E7EB"
                                      })
                                      document.querySelectorAll(".foundAdminMessage").forEach(element => {
                                        element.style.display = "none"
                                      })
                                    }}
                                    style={{ height: "56px" }}
                                    disabled={index < prevTeammates.length}
                                  />
                                </div>
                                <div className="element">
                                  <label htmlFor="tWhatsapp">
                                    WhatsApp Number of Admin
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {/* <input
                                type="text"
                                name="tWhatsapp"
                                id="tWhatsapp"
                                className="border-2 rounded"
                                value={item.tWhatsapp}
                                required
                                onChange={(e) => {
                                  handleChangeTargetedCompanies(
                                    index,
                                    "tWhatsapp",
                                    e.target.value
                                  );
                                }}
                                placeholder="WhatsApp No."
                                style={{ height: "56px" }}
                                disabled={index < user.teamMates.length}
                              /> */}
                                  <div className="border-2 rounded px-3" id="">
                                    <PhoneInput
                                      international
                                      countryCallingCodeEditable={false}
                                      defaultCountry="IN"
                                      name="tWhatsapp"
                                      className="twhatsapp"
                                      placeholder="WhatsApp No."
                                      required
                                      value={
                                        item.tWhatsapp
                                          ? String(item.tWhatsapp)
                                          : "+91"
                                      }
                                      onChange={(e) => {
                                        handleChangeTargetedCompanies(
                                          index,
                                          "tWhatsapp",
                                          e
                                        );
                                      }}
                                      error={
                                        item.tWhatsapp
                                          ? isValidPhoneNumber(
                                            String(item.tWhatsapp)
                                          )
                                            ? undefined
                                            : "Invalid phone number"
                                          : "Ph"
                                      }
                                      disabled={index < prevTeammates.length}
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="foundAdminMessage" style={{color : "red", fontSize : "12px", display : "none"}}></p>
                              {/* </>} */}
                              {/* </>} */}

                              {/* <FormControl component="fieldset" variant="standard"> */}
                              {/* <FormGroup> */}
                              {/* {(checked === 1 ||
                                checked === 3 ||
                                checked === "1" ||
                                checked === "3") && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={item.tCheckbox1}
                                      onChange={(e) => {
                                        handleChangeTargetedCompanies(
                                          index,
                                          "tCheckbox1",
                                          e.target.checked
                                        );
                                      }}
                                      name="tCheckbox1"
                                    />
                                  }
                                  sx={{ marginTop: "20px" }}
                                  label={`This teammate will complete the payment process using the credentials of my Institute's Bank Account or any other Bank Account (via any available mode of payment)`}
                                />
                              )} */}
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item.tCheckbox2}
                                    onChange={(e) => {
                                      handleChangeTargetedCompanies(
                                        index,
                                        "tCheckbox2",
                                        e.target.checked
                                      );
                                    }}
                                    name="tCheckbox2"
                                  />
                                }
                                sx={{ marginTop: "20px" }}
                                label="{{Name}} will have viewing rights for data of students, mock interviews, feedback by interviewers, etc. at interwiu.com"
                              /> */}
                              {/* </FormGroup> */}
                              {/* </FormControl> */}
                            </div>
                          )
                      )}
                      <div className="flex gap-[0.5rem]" style={{alignItems:'stretch'}}>
                        {/* <OverlayTrigger
                          className="s-dekstop-card"
                          placement="top"
                          overlay={
                            <Tooltip className="tooltip">
                              The admins whom you add here, <br /> can add
                              further admins from their end
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                            icon={faInfo}
                          />
                        </OverlayTrigger> */}
                        <Button
                          variant="contained"
                          style={{
                            fontFamily:'"Ubuntu", sans-serif',
                            fontSize:'18px',
                            fontWeight:'normal',
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setTeammates([
                              ...teammates,
                              {
                                tFname: "",
                                tLname: "",
                                tEmail: "",
                                tPos: "",
                                tWhatsapp: "",
                                tCheckbox1: false,
                                tCheckbox2: false,
                              },
                            ]);
                          }}
                        >
                          {teammates?.length === 0
                            ? `Add an Admin`
                            : " Add another Admin"}
                        </Button>

                        {(hasTeammates > 0) && (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                console.log(teammates);
                                setViewAdmins(!viewAdmins);
                              }}
                              style={{
                                backgroundColor: "orange",
                                color: "white",
                                borderRadius: "5px",
                                padding: "5px 10px",
                                border: "1px solid orange",
                                boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)",
                              }}
                            >
                              {viewAdmins
                                ? "Hide Other Admins"
                                : "View Other Admins"}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Col-30 */}

              <div className="profile-form-profile max-[950px]:flex max-[950px]:flex-col-reverse" id="image">
                {/* Profile Photo */}

                <div className="profile-photo border rounded-md p-2 s-resume-950">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Logo of your Institute
                  </Typography>
                  <img src={user.iLogo} className="upload" alt="Upload" />
                  {(userData.isAdmin === true && (!editInstituteDetails || !userData?.aVerified)) && (
                    <div className="filebase">
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={async ({ base64, size }) => {
                          if (parseInt(size) < 500) {
                            setimageSize(false);
                            setnewImage(await reduce_image_file_size(base64));
                            console.log(await reduce_image_file_size(base64));
                          } else {
                            setimageSize(true);
                          }
                        }}
                      />
                    </div>
                  )}
                  {imageSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 500 KB
                    </p>
                  )}
                </div>
                {/* Profile Resume */}
                <div className="my-3 p-2 border rounded-md text-left s-resume-950">
                  <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize:'20px'
                    }}
                  >
                    Social Links
                  </Typography>
                  <div className="my-3">
                    <label htmlFor="iWebsite"
                    className="s-mobile-card-text-15-950">
                      <i className="fa fa-globe"></i> Website of your Institute
                    </label>
                    <input
                      type="text"
                      name="iWebsite"
                      id="iWebsite"
                      value={userData.iWebsite}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="Website URL"
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="iLinkedIn"
                     className="s-mobile-card-text-15-950">
                      <i className="fa-brands fa-linkedin"></i> URL of the LinkedIn page of your Institute
                    </label>
                    <input
                      type="text"
                      name="iLinkedIn"
                      id="iLinkedIn"
                      value={userData.iLinkedIn}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="LinkedIn URL"
                      disabled={
                        userData.isAdmin === false || (editInstituteDetails && userData?.aVerified)
                      }
                    />
                  </div>
                </div>
                {/* <div className="text-[14px] my-3 p-2 border rounded-md text-left">
                  <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize:'20px'
                    }}
                  >
                    Statistics
                  </Typography>
                  <l>
                    <li className="mt-[10px]">
                      Total Number of Students added = {stats.added}
                    </li>
                    <li className="mt-[10px]">
                      Number of Students Registered = {stats.registered}
                    </li>
                    <li className="mt-[10px]">
                      Number of Completed Interviews = {stats.completed}
                    </li>
                    <li className="mt-[10px]">
                      Number of Upcoming Mock Interviews = {stats.upcoming}
                    </li>
                    <li className="mt-[10px]">
                      Average Score of Students as per the <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Feedback provided by
                      Interviewers
                      <ul className="ml-[20px]">
                        <li className="mt-[10px]">
                          i. Knowledge, skillset, and experience in the desired
                          roles = {stats?.feedback?.q1}
                        </li>
                        <li className="mt-[10px]">
                          ii. Approach and ability to solve a problem ={" "}
                          {stats?.feedback?.q2}
                        </li>
                        <li className="mt-[10px]">
                          iii. Way of Introduction = {stats?.feedback?.q3}
                        </li>
                        <li className="mt-[10px]">
                          iv. Body language and communication skills ={" "}
                          {stats?.feedback?.q4}
                        </li>
                      </ul>
                    </li>
                  </l>
                </div> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="px-2"
            >
              <div></div>
              <button
                type="submit"
                // onClick={(e) => handleSubmit(e)}
                onClick={() => {
                  console.log(userData);
                  console.log(checked);
                  console.log(state2);
                  console.log(teammates);
                }}
                className="cust-btn create-btn"
              >
                {!user?.imobNumber
                  ? "Create an Account"
                  : !user?.aVerified
                    ? "Verify account"
                    : "Update Details"}
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className=" max-[600px]:order-[3]"
              >
                <div className="tt ml-auto">
                  <InfoOutlined
                    sx={{
                      color: "rgb(83, 81, 81)",
                      fontSize: "20px",
                    }}
                  />
                  <span
                    style={{
                      background: "black",
                      color: "white",
                      padding: "3px 5px",
                      top: "-57px",
                      textAlign: "left",
                    }}
                    className="tttext"
                  >
                    By clicking on{" "}
                    {user?.imobNumber
                      ? ["[Update Details]"]
                      : ["[Create Account]"]}{" "}
                    button, you agree to our Terms and Conditions and Privacy
                    Policy
                  </span>
                </div>
              </div>
              {/* <div className="tooltip">
                <InfoOutlined
                  sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                />
                <span className="tooltiptext">

                </span>
              </div> */}
            </div>
          </form>
        </div>
      ) : (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
};

export default ProfileForm;