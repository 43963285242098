import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { CircularProgressbar } from "react-circular-progressbar";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import "react-circular-progressbar/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import Searchable from "react-searchable-dropdown";
import { Helmet } from "react-helmet";
import axios from "axios";
import Navbarlogedin from "./Navbarlogedin";
import CompletedInterview from "./cards/CompletedInterview";
import { BASE } from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import "./Dashboard.css";
import { COMPANY_NAME } from "../../constants/variables";
import {
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
} from "../../constants/routes.js";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { downloadPDF, isIIT, programValues } from "../../utility/helper";
import LZString from "lz-string";
import { Oval } from "react-loader-spinner";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const Dashboard = () => {
  // const { user, setUser } = useContext(UserContext);
  const [list, setList] = useState([
    "Data Science",
    "Banking and Finance",
    "Consulting",
  ]);

  const [couponCodeExists, setCouponCodeExists] = useState(true)
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [profiledata, setProfileData] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const [loadedCREData, setLoadedCREData] = useState(false);
  const [couponRows, setCouponRows] = useState([])
  const [couponTableData, setCouponTableData] = useState([]);
  const [couponTableDataCRF, setCouponTableDataCRF] = useState([])
  const [creCrfLinkingData, setCreCrfLinkingData] = useState([]);


  // console.log(user,"89")
  function filterCouponIndividual(coupon) {
    return coupon.substring(0, 2) != "CRE";
  }
  //  async function handleCRECouponUser(){
  //   const getCREcouponUsergotReferred= await fetch(`${BASE}/coupon/get-cre-coupons-of-individual/${user.email}`,{
  //     method:"GET",
  //     headers:{
  //       "Content-type": "application/json; charset=UTF-8",
  //     }
  //   }).then((t)=>t.json());
  //   const getCREcouponUserhasReferred= await fetch(`${BASE}/coupon/get-cre-coupons-of-individual-has-referred/${user.id}`,{
  //     method:"GET",
  //     headers:{
  //       "Content-type": "application/json; charset=UTF-8",
  //     }
  //   }).then((t)=>t.json());
  //   // console.log(getCREcouponUserhasReferred)

  //   let dataarr=[]
  //   let dataarr1=[]

  //   // console.log(getCREcouponUsergotReferred.data.length,"100")
  //   for (let i=0;i<getCREcouponUsergotReferred.data.length;i++){
  //     let newObj={"couponCode":getCREcouponUsergotReferred.data[i]["creCoupon"],"used":getCREcouponUsergotReferred.data[i]["used"],status:getCREcouponUsergotReferred.data[i]["used"]?("Inactive"):("active")}
  //     // console.log(newObj,"104")
  //     dataarr.push(newObj)
  //     // setCouponTableData([...couponTableData,newObj])
  //   }

  //   for (let j=0;j<getCREcouponUserhasReferred.data.length;j++){
  //     let newObj={"couponCode":getCREcouponUserhasReferred.data[j]["crfCoupon"],"used":getCREcouponUserhasReferred.data[j]["used"],"status":getCREcouponUserhasReferred.data[j]["used"]?("-"):("Inactive"),"name":getCREcouponUserhasReferred.data[j]["referrerName"]}
  //     dataarr1.push(newObj)
  //   }
  //   // setCouponTableData(dataarr)
  //   // console.log(dataarr,dataarr1) // saving CE coupons
  //   if (!loadedCREData){
  //     setCreCrfLinkingData(getCREcouponUserhasReferred.data)
  //     setLoadedCREData(true);
  //     setCouponTableData(dataarr)
  //     setCouponTableDataCRF(dataarr1)
  //   }
  //  }

  //  handleCRECouponUser();
  //  console.log(creCrfLinkingData,"129")
  //  console.log(couponTableData,"110")

  function Inactive({ coupon }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [friend, setFriend] = useState(null)
    const handleClick = async (event) => {
      setAnchorEl(event.currentTarget)

    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
      if (open) {
        axios.get(`${BASE}/coupon/get-friend-data-crf/?crf=${coupon}&cacheBuster=${Date.now()}`)
          .then((res) => {
            setFriend(res.data)
            console.log(res)
          })
          .catch(err => console.log("Friend data not found"))
      }
    }, [open])

    return (
      <span>Inactive <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}

        >
          <Typography sx={{ p: 2, fontSize: "12px", width: "250px" }}>
            {!friend ? "Loading..." : `It will be activated immediately after ${friend.fullName} 
        uses the associated referral coupon code to book a mock interview`}
          </Typography>
        </Popover></span>
    )
  }

  const getCouponData = () => {
    axios.get(`${BASE}/coupon/individual-coupon-read/${user.id}`)
      .then((res) => {
        setLoaded(true)
        console.log(res.data)

        setCouponTableData(res.data.map(c => ({
          couponCode: c.id,
          activity: c.active,
          status: {
            used: Boolean(c.used),
            expired: new Date(c.expiresOn) < new Date()
          }
        })))

      }).catch(err => console.log(err))
  }



  useEffect(() => {
    getCouponData()
    // axios.get(`${BASE}/coupon/individual-coupon-read/${user.id}`)
    //   .then(response => {
    //     let mycoupondata=response.data.data;
    //     let couponDis=[]
    //     mycoupondata.reverse();
    //     for (let i=0;i<mycoupondata.length;i++){
    //       let couponType=mycoupondata[i]["id"].substring(0,3);
    //       if(couponType=="CRE" || couponType=="CRF"){
    //       }else{
    //         let newObj={"couponCode":mycoupondata[i]["id"],"used":mycoupondata[i]["used"],"status":mycoupondata[i]["used"]?("Already used"):("Yet to be Used")}
    //         couponDis.push(newObj)
    //       }
    //     }
    //     setCouponRows(couponDis);
    //     // console.log(response.data.data,"mycoupon")
    //     setLoaded(true)
    //   })
    //   .catch(error => {
    //     // console.log(error);
    //   });

  }, [couponTableData.length === 0]);

  // console.log(couponTableData,"162 ",couponRows,couponTableDataCRF)
  // Profile Completion Percentageaffetc
  const [percent, setPercent] = useState(10);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | My Profile</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <div className="interviews">
        <div className="w-full p-2 mb-2 border-2 rounded flex justify-center">
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // textAlign: "center",
              display: "inline",
              color: "#1265a8",
              fontWeight: "bold",
              margin: "0 auto",
            }}
          >
            My Profile
          </Typography>
        </div>
        {loaded ? (
          <div className="grid mx-2 profilechange max-[700px]:mx-0">
            <div className="m-2 overflow-hidden max-[700px]:mx-0">
              <div className="border rounded mb-2">
                <div className="h-32 opacity-80 s-mobile-overflow-h" style={{ position: 'relative' }}>
                  <img src={require("../../images/cover2.webp")} alt="Cover" />
                  <div className="s-mobile-card" style={{ position: 'absolute', bottom: '-50px', left: '0', right: '0', justifyContent: 'center' }}>
                    <div className="" style={{ width: '43%', position: 'realtive' }}>
                      <img
                        src={user.photo}
                        style={{ width: '100%', height: '100%' }}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />

                    </div>
                  </div>

                </div>
                <div className="relative py-2 s-mobile-flex-dir-col s-desktop-card" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }} id="profile-bio">
                  <div className="" style={{ transform: 'translate(14%,-50%)', width: '20%' }}>
                    <div className="" style={{ width: '100%' }}>
                      <img
                        src={user.photo}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    {/* <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user.program] +
                          ", " +
                          (user.branch ? user.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                      />
                      <Chip
                        label={user.institute ? user.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2"
                      />
                    </div> */}
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user?.program] +
                          ", " +
                          (user?.year)
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 "
                      />
                      <Chip
                        label={
                          (user?.branch ? user?.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {
                        (user.specialization !== undefined && user.specialization !== "") && (
                          <Chip
                            label={

                              ("Specialization: " + user?.specialization)
                            }
                            sx={{ fontSize: "16px" }}
                            className="mt-2 s-mobile-changing-chip-property"
                          />
                        )
                      }

                      <Chip
                        label={user?.institute ? user?.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {/* <Chip
                      label={"B.Tech, IIT Kharagpur"}
                      sx={{ fontSize: "20px" }}
                    /> */}
                    </div>
                    {/* <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    B.Tech, IIT Kharagpur
                  </Typography> */}

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.linkedInURL
                                  ? user.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user.githubURL ? user.githubURL : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user.twitterURL
                                  ? user.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className="relative">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.iLogo !== "" ? <img
                          src={user.iLogo}
                          alt={user.institute}
                        /> : (
                        user.institute && isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user.institute.split(" ").join("_") +
                            "_Logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ))}
                    </div>
                    <div
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/s/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>

                <div className="s-mobile-card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginTop: '17%', postion: 'relative' }} id="profile-bio">
                  <div style={{ position: 'absolute', top: '43vw', right: '0.4rem', transform: 'scale(0.7)' }} className="relative s-mobile-card">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.iLogo !== "" ? <img
                          src={user.iLogo}
                          alt={user.institute}
                        /> :
                        (user.institute && isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user.institute.split(" ").join("_") +
                            "_Logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ))}
                    </div>

                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    {/* <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user.program] +
                          ", " +
                          (user.branch ? user.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                      />
                      <Chip
                        label={user.institute ? user.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2"
                      />
                    </div> */}
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user?.program] +
                          ", " +
                          (user?.year)
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      <Chip
                        label={
                          (user?.branch ? user?.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {
                        (user.specialization !== undefined && user.specialization !== "") && (
                          <Chip
                            label={

                              ("Specialization: " + user?.specialization)
                            }
                            sx={{ fontSize: "16px" }}
                            className="mt-2 s-mobile-changing-chip-property"
                          />
                        )
                      }

                      <Chip
                        label={user?.institute ? user?.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {/* <Chip
                      label={"B.Tech, IIT Kharagpur"}
                      sx={{ fontSize: "20px" }}
                    /> */}
                    </div>

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.linkedInURL
                                  ? user.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user.githubURL ? user.githubURL : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user.twitterURL
                                  ? user.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative s-desktop-card">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.institute && isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user.institute.split(" ").join("_") +
                            "_Logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      )}
                    </div>
                    <div style={{ display: 'flex' }}
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/s/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                  <div className="s-mobile-card" style={{ justifyContent: 'space-around', width: '100%', marginBottom: '10px', alignItems: 'center' }}>
                    <Button
                      onClick={() => downloadPDF(user.resume)}
                      variant="contained"
                    >
                      <i className="fa-solid fa-link"></i> Resume
                    </Button>
                    <div style={{ display: 'flex' }}
                      className="text-center border rounded-md p-1 text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/s/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border rounded p-2 s-mobile-profile-card change-main">
                <div className="border rounded p-2 text-justify change-find-an">
                  <p className="text-sm font-medium">
                    Are you looking to appear for a Mock Interview of your choice
                    and obtain Personalized Feedback on your performance in
                    realtime?
                  </p>
                  <div className="flex justify-center">
                    <Button
                      variant="contained"
                      sx={{ textAlign: "center", fontSize: 12, mt: 1 }}
                      onClick={() => {
                        navigate(STUDENT_FIND_AN_INTERVIEWER_ROUTE);
                      }}
                    >
                      Find an Interviewer
                    </Button>
                  </div>
                </div>
                {/* <div className="border rounded drop-shadow-lg mx-0 flex p-1 py-2">
                  <div
                    style={{ maxWidth: '150px' }}
                    className="mt-4 mx-auto"
                  >
                    <CircularProgressbar value={percent} text={`${percent}%`} />
                  </div>
                  <div className="text-center pt-2 pb-4">
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      className="max-[500px]:text-[16px] font-16-500"
                    >
                      Profile Completion
                    </Typography>
                    <p
                      className="max-[500px]:text-[14px]">
                      In order to complete your profile, please provide us with
                      the required information.
                    </p>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 8,
                        backgroundColor: "#00a86b",
                        "&:hover": {
                          backgroundColor: "#00a80b",
                        },
                      }}
                      onClick={() => {
                        navigate(STUDENT_PROFILE_FORM_ROUTE);
                      }}
                      className="font-12-500"
                    >
                      Update Your Profile
                    </Button>
                  </div>
                </div> */}
              </div>
              <div className="rounded border p-2 my-2">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                  }}
                  className="font-16-500"
                >
                  Description
                </Typography>
                <p className="font-14-500">
                  I'm a Student of {programValues[user.program]} ({user.branch})
                  at {user.institute}. I'm looking for Mock Interviews.
                </p>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                  }}
                  className="font-16-500 s-desktop-card s-desktop-card-remove-margin-any-view-resume"
                >
                  Resume
                </Typography>
                <Button
                  onClick={() => downloadPDF(user.resume)}
                  variant="contained"
                  className="s-desktop-card s-desktop-card-remove-margin-any-view"
                >
                  <i className="fa-solid fa-link"></i> Resume
                </Button>
                {
                  (user?.coreprofiles.length!=0 || user?.profiles.length!=0) && <>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="s-mobile-profile-subHeading"

                    >
                      {`Profile Preference(s)`}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}>
                      {user?.coreprofiles.map((item) => (
                        <Chip label={item} variant="outlined" />
                      ))}
                      {user?.profiles.map((item) => (
                        <Chip label={item} variant="outlined" />
                      ))}
                      {/* <Chip label="Software" variant="outlined" />
                <Chip label="Data Analysis" variant="outlined" /> */}
                    </div></>
                }

                {user.project && (
                  <>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="font-16-500"
                    >
                      {programValues[user.program]}&nbsp;Project Title
                    </Typography>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {user.project}
                      {/* <Chip label= variant="outlined" /> */}
                      {/* <Chip label="Software" variant="outlined" />
                  <Chip label="Data Analysis" variant="outlined" /> */}
                    </div>
                  </>
                )}
                {
                  (user.appearingFor.one || user.appearingFor.two) && (
                    <>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                        className="font-16-500"
                      >
                        Appearing For
                      </Typography>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}>
                        {user.appearingFor.one && <Chip label={`Campus Placements`} variant="outlined" />}
                        {user.appearingFor.two && <Chip label={`Internship Interviews`} variant="outlined" />}
                      </div>
                    </>
                  )
                }
                {user.companies && user.companies.length!=0 && user.companies[0]?.company !== "" && (
                  <>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="font-16-500"

                    >
                      Targeted Companies
                    </Typography>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 40fr', whiteSpace: 'pre-wrap' }}>
                      {user.companies?.map((item) => (
                        <>
                          <div style={{ marginRight: '5px' }}>• </div>
                          <div style={{ marginBottom: '0.5rem' }}>
                            {item?.company !== "" && (
                              <>
                                <span style={{ fontWeight: '500', fontSize: '14px' }}>Company </span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.company}</span> <br />
                              </>
                            )}
                            {item?.company !== "" && (
                              <>
                                <span style={{ fontWeight: '500', fontSize: '14px' }}>Profile</span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.profile}</span> <br />
                              </>
                            )}
                            {item?.company !== "" && (
                              <>
                                <span style={{ fontWeight: '500', fontSize: '14px' }}>Job Description</span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.textJD}</span><br />
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="tt ml-auto">
                        <InfoOutlined
                          sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                        />
                        <span style={{ background: 'black', color: 'white', padding: '3px 5px' }} className="tttext">
                          By keeping an account at interwiu.com, you agree to
                          our Terms and Conditions and Privacy Policy
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {couponTableData.length !== 0 ? (<>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                  className="font-16-500"

                >
                  Coupon Codes
                </Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="padding-5-500">Coupon Code</StyledTableCell>
                        <StyledTableCell className="padding-5-500" align="center">Activity</StyledTableCell>
                        <StyledTableCell className="padding-5-500" align="center">Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {couponTableData.map((row, i) => (
                        <StyledTableRow key={i} id={i}>
                          <StyledTableCell className="padding-5-500" component="th" scope="row" sx={{
                            color: row.activity ? "#000" : "GrayText",
                            textDecoration: row.status.used || row.status.expired ? "line-through" : "none"
                          }}>
                            {row.couponCode}
                          </StyledTableCell>
                          <StyledTableCell className="padding-5-500" align="center">{row.status.used || row.status.expired ? "-" : row.activity ? "Active" : <Inactive coupon={row.couponCode} />}</StyledTableCell>
                          <StyledTableCell className="padding-5-500" align="center">{row.status.expired ? "Expired" : (row.status.used ? "Already used" : "Yet to be used")}</StyledTableCell>
                        </StyledTableRow>
                      ))}

                    </TableBody>
                  </Table>
                </TableContainer></>) : null}
              {/* <div className="rounded border p-2 my-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Past Mock Interviews
              </Typography>
              <div style={{ margin: "auto 20px" }}>
                <CompletedInterview />
                <CompletedInterview />
              </div>
            </div> */}
            </div>
            <div className="border rounded p-2 m-2 s-desktop-profile-card">
              {/* <div className="border rounded mb-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Feeds
              </Typography>
              <Carousel>
                <div>
                  <img src={require("../../images/join-now.jpg")} />
                </div>
                <div>
                  <img src={require("../../images/car-1.jpg")} />
                </div>
                <div>
                  <img src={require("../../images/car-2.jpg")} />
                </div>
                <div>
                  <img src={require("../../images/car-3.jpg")} />
                </div>
              </Carousel>
              <div className="border rounded drop-shadow-lg my-2">
                <img
                  src={require("../../images/join-now.jpg")}
                  alt="Join Now"
                />
              </div>
            </div> */}
              <div className="border rounded p-1 py-2 text-justify" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p className="text-sm font-medium">
                  Are you looking to appear for a Mock Interview of your choice
                  and obtain Personalized Feedback on your performance in
                  realtime?
                </p>
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    sx={{ textAlign: "center", fontSize: 12, mt: 1 }}
                    onClick={() => {
                      navigate(STUDENT_FIND_AN_INTERVIEWER_ROUTE);
                    }}
                  >
                    Find an Interviewer
                  </Button>
                </div>
              </div>
              <div className="border rounded drop-shadow-lg my-2 mx-0">
                <div
                  className="mt-4 mx-auto"
                  style={{ height: "200px", width: "200px" }}
                >
                  <CircularProgressbar value={percent} text={`${percent}%`} />
                </div>
                <div className="text-center pt-2 pb-4">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Profile Completion
                  </Typography>
                  <p>
                    In order to complete your profile, please provide us with the required information.
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 8,
                      backgroundColor: "#00a86b",
                      "&:hover": {
                        backgroundColor: "#00a80b",
                      },
                    }}
                    onClick={() => {
                      navigate(STUDENT_PROFILE_FORM_ROUTE);
                    }}
                  >
                    Update Your Profile
                  </Button>
                </div>
              </div>

              {/* <Typography
              variant="h6"
              component="h6"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Top Interviewers
            </Typography>
            <div>
              <div
                className="border rounded flex justify-between my-2 p-1"
                id="interviewer-card"
              >
                <div className="flex">
                  <div className="mr-1">
                    <img
                      src={require("../../images/photo.png")}
                      alt="Profile"
                      className="w-12 rounded-full"
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-blue-500 leading-5">
                      John Doe
                    </p>
                    <p className="text-gray-500 leading-5">ML Engineer, IBM</p>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ width: 80, fontSize: 10, padding: "4px" }}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
              <div
                className="border rounded flex justify-between my-2 p-1"
                id="interviewer-card"
              >
                <div className="flex">
                  <div className="mr-1">
                    <img
                      src={require("../../images/photo.png")}
                      alt="Profile"
                      className="w-12 rounded-full"
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-blue-500 leading-5">
                      John Doe
                    </p>
                    <p className="text-gray-500 leading-5">ML Engineer, IBM</p>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ width: 80, fontSize: 10, padding: "4px" }}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
              <div
                className="border rounded flex justify-between my-2 p-1"
                id="interviewer-card"
              >
                <div className="flex">
                  <div className="mr-1">
                    <img
                      src={require("../../images/photo.png")}
                      alt="Profile"
                      className="w-12 rounded-full"
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-blue-500 leading-5">
                      John Doe
                    </p>
                    <p className="text-gray-500 leading-5">ML Engineer, IBM</p>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ width: 80, fontSize: 10, padding: "4px" }}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
              <div
                className="border rounded flex justify-between my-2 p-1"
                id="interviewer-card"
              >
                <div className="flex">
                  <div className="mr-1">
                    <img
                      src={require("../../images/photo.png")}
                      alt="Profile"
                      className="w-12 rounded-full"
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-blue-500 leading-5">
                      John Doe
                    </p>
                    <p className="text-gray-500 leading-5">ML Engineer, IBM</p>
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ width: 80, fontSize: 10, padding: "4px" }}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        ) : (
          <div className="fullPageLoader">
            <Oval
              height={120}
              width={120}
              color="#306bc9"
              wrapperStyle={{ padding: "50%, 50%" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#90caf9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;