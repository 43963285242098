import React, { useState, useRef } from 'react';
import { Button, Popover, Badge, Typography, ListItem, List, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Notifications = ({ notifications, isOpen, setIsOpen}) => {
  
  
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setIsOpen(notifications?.length > 0);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        
        ref={anchorRef}
        onClick={handleOpen}
        color="inherit"
        sx={{
          aspectRatio: 1,
          // borderRadius: "50%",
          // p: 0,
          // m: 0
        }}
      >
        <Badge sx={{zoom: 0.8, p: 0, m:0, maxWidth: "inherit",}} badgeContent={notifications?.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
            height: "200px",
            width: "400px"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            width: "40%"
          }
        }}
      >
        {/* Your notifications content */}
        <List sx={{
            zoom: 0.7
          }}>
        { notifications.map((notification, index) => (
          <ListItem key={index} className='' divider sx={{
            p: "5px",
            pl: "8px"
          }}>
            <Typography>{notification.message}</Typography>
          </ListItem>
        ))}
        </List>
      </Popover>
    </>
  );
};

export default Notifications;
