import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import "./CompletedInterviews.css";
import DatePicker from "react-date-picker";
import Navbarlogedin from "./Navbarlogedin";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import CompletedInterview from "./cards/CompletedInterview";
import UserContext from "../../contexts/UserContext";
import { BASE } from "../../constants/endpoints";
import axios from "axios";
import moment from "moment-timezone";
import {
  compareReverse,
  compareSlots,
  getCompanyLogo,
  getCountForUpcoming,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import { Oval } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";

const changeLink = () => {
  const links = document.querySelectorAll(".nav-item a p");
  links.forEach((link) => {
    if (link.classList.contains("active")) {
      link.classList.remove("active");
    }
    if (
      link.innerHTML === 'Completed Interviews <div className="notify"></div>'
    ) {
      link.classList.add("active");
    }
  });
};

const CompletedInterviews = () => {
  const { user } = useContext(UserContext);
  const [completedRequest, setcompletedRequest] = useState([]);
  const [CompletedInterViewers, setCompletedInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    changeLink();
  }, []);
  const params = {
    reqStatus: "ReqPaid",
    userRole: "Student",
    completed: "Completed",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response.data, "-----------");
          setcompletedRequest(response.data.Requests);
          let temp_s = response.data.Requests
            .filter((result, index) => moment().tz("Asia/Kolkata").diff(moment(result.date + " " + t24HDecimalTo12HFormat(result.toTime), "DD/MM/YYYY hh:mm a"), "seconds") > 0)
          setcompletedRequest(temp_s);
          setCompletedInterViewers(response.data.SentInterViewers);
          console.log(response.data.Requests);
          setLoaded(true);
          console.log('temp_s',temp_s);

          let ints = response.data.Requests;

          ints?.map((int) => {
            axios.get(`${BASE}/send/student/completedInterviewRequestById/${int.reqId}`)
              .then((response) => {
                console.log(response.data);
              }).catch((err) => {
                console.log(err);
              })
          })

        });
    }
    fetchData();
  }, []);
  // var prevScrollpos = window.pageYOffset;
  // window.onscroll = function () {
  //   var currentScrollPos = window.pageYOffset;
  //   if (currentScrollPos >= 104) {
  //     document.querySelector("#head").style.position = "sticky";
  //     document.querySelector("#head").style.top = "0";
  //   } else {
  //     document.querySelector("#head").style.position = "unset";
  //     document.querySelector("#head").style.top = "0";
  //   }
  //   prevScrollpos = currentScrollPos;
  // };
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (currentScrollPos >= 104) {
      if (document.querySelector("#head")) {
        document.querySelector("#head").style.position = "sticky";
        document.querySelector("#head").style.top = "0";
      }
    } else {
      if (document.querySelector("#head")) {
        document.querySelector("#head").style.position = "unset";
        document.querySelector("#head").style.top = "0";
      }
    }
    prevScrollpos = currentScrollPos;
  };
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, completedRequest]);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Completed Interview</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <div className="completed-interview interviews">
        <div className="inteviews-container"></div>
        <div className="flex justify-center items-center mb-4" id="head">
          <h2 className="interview-head text-2xl font-medium heading1">
            Completed Interviews (
            {/* {completedRequest
              ? page * 10 -
                9 +
                " - " +
                (completedRequest?.length > page * 10
                  ? page * 10
                  : completedRequest?.length)
              : null}
            ) out of ({completedRequest ? completedRequest?.length : 0}) */}
            {completedRequest.length
              ? page * 10 -
              9 +
              " - " +
              (completedRequest.length > page * 10
                ? page * 10
                : completedRequest.length)
              : null}
            ) out of ({completedRequest ? completedRequest?.length : 0})
          </h2>
        </div>
        <div className="interview-cont">
          <div className="flex justify-between mx-8">
            <div>
              Showing the results for {date ? date.toDateString() : "all days"}
            </div>
            {/*
            <div className="flex gap-2">
              <label htmlFor="date">Filter by Date: </label>
              <DatePicker
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                value={date}
              />
            </div>
            */}
          </div>
          {loaded ? (
            <div className="interviews-list">
              {completedRequest?.slice(pageNo[0], pageNo[1]).map(
                (result, index) =>
                  moment().tz("Asia/Kolkata").diff(moment(result.date + " " + t24HDecimalTo12HFormat(result.toTime), "DD/MM/YYYY hh:mm a"), "seconds") > 0 && (
                    <CompletedInterview
                      user={user}
                      name={
                        CompletedInterViewers[pageNo[0] + index]?.fname +
                        " " +
                        CompletedInterViewers[pageNo[0] + index]?.lname
                      }
                      desg={
                        CompletedInterViewers[pageNo[0] + index]?.designation
                      }
                      price={completedRequest[pageNo[0] + index]?.price}
                      profile={completedRequest[pageNo[0] + index]?.profile}
                      subprofile={completedRequest[pageNo[0] + index]?.subprofile}
                      slotStart={completedRequest[pageNo[0] + index]?.fromTime}
                      slotEnd={completedRequest[pageNo[0] + index]?.toTime}
                      status={completedRequest[pageNo[0] + index]?.reqStatus}
                      id={CompletedInterViewers[pageNo[0] + index]?.id}
                      date={completedRequest[pageNo[0] + index]?.date}
                      image={
                        CompletedInterViewers[pageNo[0] + index]?.isDisabled ===
                          true || CompletedInterViewers?.isDelete === true
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                          : CompletedInterViewers[pageNo[0] + index]?.photo
                      }
                      logo={getCompanyLogo(
                        CompletedInterViewers[pageNo[0] + index]?.currCompany
                      )}
                      mockId={completedRequest[pageNo[0] + index]?.mockId}
                      reqId={completedRequest[pageNo[0] + index]?.reqId}
                      isUnavailable={
                        CompletedInterViewers[pageNo[0] + index]?.isDisabled ===
                        true || CompletedInterViewers.isDelete === true
                      }
                      sId={completedRequest[pageNo[0] + index]?.studId}
                      isDisable={
                        CompletedInterViewers[pageNo[0] + index]?.isDisabled ===
                        true
                      }
                      isDelete={
                        CompletedInterViewers[pageNo[0] + index]?.isDelete ===
                        true
                      }
                      company={
                        CompletedInterViewers[pageNo[0] + index]?.currCompany
                      }
                    />
                  )
              )}
              {/* Pagination */}
              <div className="flex flex-row justify-center mt-4 my-2">
                <Pagination
                  count={Math.ceil(completedRequest?.length / 10)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </div>
          ) : (
            <div className="fullPageLoader">
              <Oval
                height={120}
                width={120}
                color="#306bc9"
                wrapperStyle={{ padding: "50%, 50%" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#90caf9"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompletedInterviews;
