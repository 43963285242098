import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import SuccessStories from "./SuccessStories";
import "./Home.css";
import Features from "./Features";
import Professional from "./TwoColWithButton";
import Pricing from "./Pricing";
import Hero from "./TwoColumnWithInput";
import FAQS from "./FAQs";
import Partners from "./Partner/index.jsx";
import Working from "./HowitWorks";
import GetStarted from "./GetStarted";
import { Helmet } from "react-helmet";
import UserContext from "../../contexts/UserContext";
import { COMPANY_NAME } from "../../constants/variables";
import PlanComponent from "./PlanComponent"
import { BASE } from "../../constants/endpoints.js";
import axios from "axios";
const Home = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post(`${BASE}/inactiveUsers`, {user})
    .catch((err) => {
      console.log(err)
    })
  }, [location]);

  return (
    <div id="home-page">
      <Helmet>
        <title>{COMPANY_NAME}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className="" id="home">
        <Hero />
      </div>
      {!user?.role ? (
        <div id="getStarted">
          <GetStarted />
        </div>
      ) : null}
      <div id="howItWorks">
        <Working />
      </div>
      <div id="features">
        <Features />
      </div>
      <div id="profiles">
        <Professional />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="plans">
        <PlanComponent/>
      </div>
      <div id="partners">
        <Partners />
      </div>
      <div id="testimonials">
        <SuccessStories />
        {/* <Testimonial /> */}
      </div>
      <div id="faqs">
        <FAQS />
      </div>
    </div>
  );
};

export default Home;
