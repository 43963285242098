import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import RateAndReview from "../RateAndReview";
import {
  GET_REQUEST_INTERVIEWS,
  GET_STUDENT_INFO,
  GET_INETRVIEWER_INFO,
  BASE,
} from "../../../constants/endpoints";
import "./StudentEnd.css";
import TextLoading from "./TextLoading";

const StudentEnd = () => {
  const { mock_id: ROOM_ID } = useParams();
  const [interviewerDetails, setInterviewerDetails] = useState();
  const [studentDetails, setStudentDetails] = useState();
  const [interviewInfo, setInterviewInfo] = useState();
  const [rated, setRated] = useState(false)


  const handleVisibility = () => {
    console.log(document.visibilityState)
  }
  useEffect(() => {
    // console.log(document.visibilityState)
    document.addEventListener("visibilitychange", handleVisibility)
    axios.get(`${BASE}/send/getRating/${ROOM_ID}`)
    .then((res) => {
      if(res.data) {
        setRated(true)
      }
    })
    .catch((err) => {
      setRated(false)
      console.log(err)
    })
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility)
    }
  }, [document.visibilityState])

  useEffect(() => {
    // getting data about the interview and make link
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
      .then((res) => {
        setInterviewInfo(res.data);
        let student_id = res.data.studId;
        let interviewer_id = res.data.interviewerId;

        axios
          .get(`${GET_STUDENT_INFO}/${student_id}`)
          .then((res) => {
            setStudentDetails(res.data);
          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
            console.log(err);
          });

        axios
          .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
          .then((res) => {
            setInterviewerDetails(res.data);
          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
          });
      })
      .catch((err) => {
        alert("Invalid Link");
      });
  }, []);

  return (
    <div className="studentend">
      <p style={{ margin: "20px 0" }}>
        Hope you had a Great Experience with the Mock Interview!
      </p>
      {!rated && <p style={{ margin: "20px 0" }}>
        Please take a moment to rate and review your mock interview experience
        with{" "}
        {interviewerDetails?interviewerDetails?.fname +
          " " +
          interviewerDetails?.lname +
          ", " +
          interviewerDetails?.designation +
          ", " +
          interviewerDetails?.currCompany:<TextLoading text="loading..."/>}
      </p>}
      <div style={{display: "flex", justifyContent:"center"}}>
        <RateAndReview mock_id={ROOM_ID} rated={rated} setRated={setRated} page="end"/>
      </div>
      <p style={{ margin: "40px 0" }}>
        If this mock interview session is yet to be completed, you can
        &nbsp;{studentDetails?.id? <Link
          to={
            "/valid/vc/" +
            ROOM_ID +
            "/" +
            studentDetails?.id +
            "/" +
            studentDetails?.fname
          }
          // to={
          //   "/valid/vc/" +
          //   ROOM_ID +
          //   "?id=" +
          //   studentDetails?.id +
          //   "&name=" +
          //   studentDetails?.fname
          // }
          style={{ color: "blue", textDecoration: "underline" }}
        >
        Re-Join
        </Link>:<TextLoading text="Re-Join"/>}
        &nbsp;it
      </p>
    </div>
  );
};

export default StudentEnd;
