import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import "./StudentsHIW.css";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const AdminsHIW = () => {
  const cards = [
    {
      imageSrc:
        "https://img.freepik.com/free-vector/illustration-avatar-social-network-concept_53876-28529.jpg?w=900&t=st=1687590442~exp=1687591042~hmac=ea9df21c70a41d03e3ed4fe1fb0a471cf0af9b56c66f1a05f0405155bbfbb6b4",
      title: "Add all Admins",
      description:
        "Once you have created the institute account at interwiu.com, please add all other relevant co-admins, and if you have been added as a co-admin by another admin, please verify your account after logging-in the very first time.",
    },

    {
      imageSrc:
        "https://img.freepik.com/free-vector/flat-cartoon-character-customer-50-percent-discount-with-coupon_1150-34877.jpg?w=740&t=st=1687589423~exp=1687590023~hmac=1ca440bbaf3fcd14e4a3ff1e7a21d251fddb9437963694efc71d516e8085c415",
      title: "Add Students and Generate Coupon Codes",
      description:
        "Add the data of the students of your institute (as a .csv file), choose the number of mock interviews per student and payment preference, and save the details. Then pay (depending on your payment preference) and generate the coupon codes for all the added students.",
    },

    {
      imageSrc:
        "https://img.freepik.com/free-vector/reviews-concept-landing-page_52683-18599.jpg?w=740&t=st=1687589956~exp=1687590556~hmac=f47dcab6a47a502f4fc31cda6c015d98c764b0f045efc4596452335aec7e1de4",
      title: "View the Mock Interviews and Feedback/Comments",
      description:
        "Check all the Interviewers registered at interwiu.com, the mock interviews that the students of your institute are booking with them, and how good or bad the students of your institute are performing in those mock interviews. Also, check what your students are saying about their mock interview experience.",
    },
  ];

  return (
    <Container>
      <SingleColumn style={{ paddingTop: "0" }}>
        <HeadingInfoContainer>
          <HeadingTitle style={{fontSize:"2.3rem"}}>For Admins</HeadingTitle>
        </HeadingInfoContainer>

        <Content style={{ marginTop: "0" }}>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1} style={{ marginTop: "0" }}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title className={`${i==2?"title-padding-mobile":""}`}>{card.title}</Title>
                <Description className="normalText">{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
export default AdminsHIW;
