const data = [
  {
    institute: [
      "IIT Bhilai",
      "IIT BHU",
      "IIT Bhubaneswar",
      "IIT Bombay",
      "IIT Delhi",
      "IIT (ISM) Dhanbad",
      "IIT Dharwad",
      "IIT Gandhinagar",
      "IIT Goa",
      "IIT Guwahati",
      "IIT Hyderabad",
      "IIT Indore",
      "IIT Jammu",
      "IIT Jodhpur",
      "IIT Kanpur",
      "IIT Kharagpur",
      "IIT Madras",
      "IIT Mandi",
      "IIT Palakkad",
      "IIT Patna",
      "IIT Roorkee",
      "IIT Ropar",
      "IIT Tirupati",
    ],
  },
  {
    "IIT Bhilai": [
      "Computer Science and Engineering",
      "Chemistry",
      "Electrical Engineering",
      "Mathematics",
      "Mechanical Engineering",
      "Physics",
    ],
    "IIT BHU": [
      "Biochemical Engineering",
      "Biomedical Engineering",
      "Ceremic Engineering and Technology",
      "Chemical Engineering",
      "Chemistry",
      "Civil Enginnering",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Electronics Engineering",
      "Humanistic Studies",
      "Materials Science and Technology",
      "Mathematics Science",
      "Mechanical Engineering",
      "Metallurigical Engineering",
      "Mining Enginnering",
      "Pharmaceutical Engineering and Technology",
      "Physics",
    ],

    "IIT Bhubaneswar": [
      "Biosciences",
      "Chemistry",
      "Civil Enginnering (School of Infrastructure)",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Electronics & Communication Engineering",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurigical & Materials Engineering",
      "Physics",
      "School of Earth, Ocean and Climate Sciences",
      "School of Humanities, Social Sciences & Management",
    ],

    "IIT Bombay": [
      "Aerospace Engineering",
      "Biosciences and Bioengineering",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science & Engineering",
      "Earth Sciences",
      "Electrical Engineering",
      "Energy Science and Engineering",
      "Environmental Science and Engineering",
      "Humanities & Social Sciences",
      "IDC School of Design (IDC SoD)",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgical Engineering & Materials Science",
      "Physics",
      "Shailesh J. Mehta School of Management (SJMSoM)",
    ],

    "IIT Delhi": [
      "Applied Mechanics",
      "Biochemical Engineering and Biotechnology",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Design",
      "Electrical Engineering",
      "Energy Science and Engineering",
      "Humanities and Social Sciences",
      "Management Studies",
      "Materials Science and Engineering",
      "Mathematics",
      "Mechanical Engineering",
      "Physics",
      "Textile and Fibre Engineering",
    ],

    "IIT (ISM) Dhanbad": [
      "Applied Geology",
      "Applied Geophysics",
      "Chemical Engineering",
      "Chemistry and Chemical Biology",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Electronics Engineering",
      "Environmental Science & Engineering",
      "Fuel, Minerals and Metallurgical Engineering",
      "Humanities and Social Sciences",
      "Management Studies",
      "Mathematics and Computing",
      "Mechanical Engineering",
      "Mining Engineering",
      "Petroleum Engineering",
      "Physics",
    ],
    "IIT Dharwad": [
      "Bioscience and Bioengineering",
      "Chemistry",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics",
      "Mechanical, Materials and Aerospace Engineering",
      "Physics",
    ],
    "IIT Gandhinagar": [
      "Biological Engineering",
      "Biomedical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Chemical Engineering",
      "Cognitive Science",
      "Computer Science and Engineering",
      "Earth Sciences",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Materials Engineering",
      "Mathematics",
      "Mechanical Engineering",
      "Physics",
    ],
    "IIT Goa": [
      "Chemical and Materials Engineering",
      "Chemistry",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics and Computing (MnC)/Mathematics",
      "Mechanical Engineering",
      "Physics Sciences",
      "School of Interdisciplinary Life Sciences (SILS)",
    ],
    "IIT Guwahati": [
      "Biosciences & Bioengineering",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Design",
      "Electronics & Electrical Engineering",
      "Humanities and Social Sciences",
      "Jyoti and Bhupat Mehta School of Health Science and Technology",
      "Mathematics",
      "Mechanical Engineering",
      "Mehta Family School of Data Science and Artificial Intelligence",
      "Physics",
      "School of Business",
      "School of Energy Science and Engineering",
    ],
    "IIT Hyderabad": [
      "Artificial Intelligence",
      "Biomedical Engineering",
      "Biotechnology",
      "Chemical Engineering",
      "Chemistry",
      "Climate Change",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Design",
      "Electrical Engineering",
      "Engineering Science",
      "Entrepreneurship and Management",
      "Heritage Science and Technology",
      "Liberal Arts",
      "Materials Science and Metallurgical Engineering",
      "Mathematics",
      "Mechanical & Aerospace Engineering",
      "Physics",
    ],
    "IIT Indore": [
      "Astronomy, Astrophysics and Space Engineering",
      "Biosciences and Biomedical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgy Engineering and Materials Science",
      "Physics",
    ],
    "IIT Jammu": [
      "Biosciences and Bioengineering",
      "Civil Engineering",
      "Chemistry",
      "Chemical Engineering",
      "Computer Science Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics",
      "Materials Engineering",
      "Mechanical Engineering",
      "Physics",
    ],
    "IIT Kanpur": [
      "Aerospace Engineering",
      "Biological Sciences & Bioengineering",
      "Chemistry",
      "Chemical Engineering",
      "Civil Engineering",
      "Computer Science & Engineering",
      "Design",
      "Earth Sciences",
      "Economic Sciences",
      "Electrical Engineering",
      "Humanities & Social Sciences",
      "Industrial & Management Engineering",
      "Materials Science & Engineering",
      "Mathematics & Statistics",
      "Mechanical Engineering",
      "Physics",
      "Sustainable Energy Engineering",
    ],
    "IIT Kharagpur": [
      "Agricultural and Food Engineering",
      "Bio Science",
      "Biotechnology",
      "Centre for Computational and Data Sciences",
      "Centre of Excellence in Advanced Manufacturing Technology",
      "Centre of Excellence in Artificial Intelligence (AI)",
      "Chemistry",
      "Chemical Engineering",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Electronics and Electrical Communication Engineering",
      "Energy Science and Engineering",
      "Environmental Science and Engineering",
      "Geology and Geophysics",
      "Humanities and Social Sciences",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgical and Materials Engineering",
      "Mining Engineering",
      "Ocean Engg and Naval Architecture",
      "Physics",
      "Rubber Technology",
      "Vinod Gupta School of Management",
    ],
    "IIT Madras": [
      "Aerospace Engineering",
      "Applied Mechanics",
      "Biotechnology",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Engineering Design",
      "Humanities and Social Sciences",
      "Management Studies",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgical and Materials Engineering",
      "Ocean Engineering",
      "Physics",
    ],
    "IIT Mandi": [
      "Bio-Engineering",
      "Biotechnology",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Data Science and Engineering",
      "Electrical Engineering",
      "Engineering Physics",
      "Humanities and Social Sciences",
      "Materials Engineering ",
      "Mathematics",
      "Mechanical Engineering",
      "Physics",
    ],
    "IIT Jodhpur": [
      "Bioscience and Bioengineering",
      "Chemical Engineering",
      "Chemistry",
      "Civil and Infrastructure Engineering",
      "Computer Science & Engineering",
      "Electrical Engineering",
      "Humanities & Social Sciences",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgical & Materials Engineering",
      "Physics",
      "School of Management & Entrepreneurship",
      "School of Artificial Intelligence and Data Science",
    ],
    "IIT Palakkad": [
      "Biological Sciences and Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Data Science",
      "Electrical Engineering",
      "Environmental Sciences and Sustainable Engineering Center",
      "Humanities and Social Sciences",
      "Mathematics",
      "Mechanical Engineering",
      "Physics",
    ],
    "IIT Patna": [
      "Chemistry",
      "Computer Science and Engineering",
      "Electrical Engineering",
      "Mechanical Engineering",
      "Civil and Environmental Engineering",
      "Chemical and Biochemical Engineering",
      "Humanities and Social Sciences",
      "Mathematics",
      "Metallurgical and Materials Engineering",
      "Physics",
    ],
    "IIT Roorkee": [
      "Applied Mathematics and Scientific Computing",
      "Biosciences and Bioengineering",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science and Engineering",
      "Design",
      "Earthquake Engineering",
      "Earth Sciences",
      "Electrical Engineering",
      "Electronics and Communication Engineering",
      "Humanities and Social Sciences",
      "Hydrology",
      "Hydro and Renewable Energy",
      "Management Studies",
      "Mathematics",
      "Mechanical and Industrial Engineering",
      "Mehta Family School of Data Science and Artificial Intelligence",
      "Metallurgical and Materials Engineering",
      "Paper Technology",
      "Polymer and Process Engineering",
      "Physics",
      "Water Resources Development and Management",
    ],
    "IIT Ropar": [
      "BioMedical Engineering",
      "Chemical Engineering",
      "Chemistry",
      "Civil Engineering",
      "Computer Science & Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics",
      "Mechanical Engineering",
      "Metallurgical and Materials Engineering",
      "Physics",
    ],
    "IIT Tirupati": [
      "Chemical Engineering",
      "Chemistry",
      "Civil & Environmental Engineering",
      "Computer Science & Engineering",
      "Electrical Engineering",
      "Humanities and Social Sciences",
      "Mathematics & Statistics",
      "Mechanical Engineering",
      "Physics",
    ],
  },
];

export default data;
