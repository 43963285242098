import React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import moment from "moment"; // Getting day from date and modifying date format
import { Calendar } from "react-multi-date-picker";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Draggable from "react-draggable";
import Paper, { PaperProps } from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { AiOutlineCloseCircle, AiTwotoneCalendar } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { InfoOutlined } from "@mui/icons-material";

const TimePreference = ({ timeRef, gnrcSchdule, cstmSchdule }) => {


  const FAQSContainer = tw.dl` max-w-4xl relative`;
  const FAQ = tw.div`cursor-pointer mt-5 px-8  sm: rounded-lg text-gray-800 hover:text-gray-900 bg-[#F8F8F8] transition duration-300`;
  const Question = tw.dt`flex justify-between items-center`;
  const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
  const QuestionToggleIcon = motion(styled.span`
    ${tw`ml-2 transition duration-300`}
    svg {
      ${tw`w-6 h-6`}
    }
  `);
  const Answer = motion(tw.dd`text-sm sm:text-base leading-relaxed`);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);

    // console.log(activeQuestionIndex);
  };

  const [datePickerMobile, setDatePickerMobile] = useState(false)
  function PaperComponent(props) {
    return (
      <>
      {
        window.innerWidth>=600 ? (
          <Draggable
          className="s-desktop-card"
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
        ):(
          <Paper {...props} />
        )
      }
      </>
    );
  }
  // ADD TIME MODAL
  // ------- Edit Slot ---------------
  const [isItEditing, setIsItEditing] = useState(false); // "isItEditing" keeps track of whether we are editing the already entered schedule for both generic and exception schedule
  const [oldTimeWhileEditingASlot, setOldTimeWhileEditingASlot] = useState({
    // Data that will be prefilled while editing any slot for
    hrs: "12",
    min: "00",
    merd: "AM",
    start: 0,
    end: 0,
    slots: 0,
  });
  const [
    disableDaysWhileEditingGenericSlot,
    setDisableDaysWhileEditingGenericSlot,
  ] = useState({
    // To disable days that cannot be edit because they don't follow the edit rules
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  // ---- Model Settings for Generi Schedule Popup ----
  const [openGenericSchedulePopup, setOpenGenericSchedulePopup] =
    useState(false);
  const [checkedDaysGenericSchedule, setCheckedDaysGenericSchedule] = useState({
    // Store checked days while adding generic schedule
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  const [allDaysCheckedGenericSchedule, setAllDaysCheckedGenericSchedule] =
    useState(false); // To check and uncheck Select All
  useEffect(() => {
    // Handle Select All
    checkedDaysGenericSchedule.mon === true &&
      checkedDaysGenericSchedule.tue === true &&
      checkedDaysGenericSchedule.wed === true &&
      checkedDaysGenericSchedule.thu === true &&
      checkedDaysGenericSchedule.fri === true &&
      checkedDaysGenericSchedule.sat === true &&
      checkedDaysGenericSchedule.sun === true
      ? setAllDaysCheckedGenericSchedule(true)
      : setAllDaysCheckedGenericSchedule(false);
  }, [checkedDaysGenericSchedule]);
  const [time, setTime] = useState({
    // Stores the from time datas from popup
    hrs: "12",
    min: "00",
    merd: "AM",
  });
  const [slots, setSlots] = useState(0); // No of slot that are selected through popup
  const handleSlotsChange = (event) => {
    // Handling slot change event
    setSlots(parseInt(event.target.value));
  };
  const handleOpenGenericSchedulePopup = () => {
    setOpenGenericSchedulePopup(true);
  };
  const handleCloseGenericSchedulePopup = () => {
    setOpenGenericSchedulePopup(false);
    setIsItEditing(false);
    setDisableDaysWhileEditingGenericSlot({
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    });
    setCheckedDaysGenericSchedule({
      ...checkedDaysGenericSchedule,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    });
    setAllDaysCheckedGenericSchedule(false);
    setTime({
      hrs: "12",
      min: "00",
      merd: "AM",
    });
    setSlots(0);
  };

  // --- Handling Popup for "Clear All" Button while entering generic schedule ---
  const [openClearGenericSchedulePopup, setOpenClearGenericSchedulePopup] =
    useState(false);
  const handleClearGenericSchedulePopupOpen = () => {
    setOpenClearGenericSchedulePopup(true);
  };
  const handleClearGenericSchedulePopupClose = () => {
    setOpenClearGenericSchedulePopup(false);
  };
  // ---------- END -------------
  // --- Handling Popup for "Clear All" Button while entering generic schedule ---
  const [openClearCustSchedulePopup, setOpenClearCustSchedulePopup] =
    useState(false);
  const handleClearCustSchedulePopupOpen = () => {
    setOpenClearCustSchedulePopup(true);
  };
  const handleClearCustSchedulePopupClose = () => {
    setOpenClearCustSchedulePopup(false);
  };
  // ---------- END -------------

  // "ADD TIME PREFERENCE"

  // ------------ Initializing Days Array -------------------
  // Generic Array
  /* {
    mon:[{init: [0, 1], final: ['12:00 AM', '12:30 AM]}, {init: [2, 3], final: ['01:00 AM', '01:30 AM]}]
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  }*/
  const [boolGnrcSchd, setBoolGnrcSchd] = useState({
    // These arrays will contain 48 boolean item that represents the status of any perticular
    // mon: [false * 48]
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });

  const [slotwiseGenericSchd, setSlotwiseGenericSchd] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });
  // Exceptional Array
  /*  
    - "selectedDateBoolArr" store boolean array for currently selected date in calender
      e.g. [false * 48]
    - "selectedDateSlotsArr" stores slots array for currently selected date in calender
      init: [startSlotID, endSlotId], final: [startTime, endTime]
      [{init: [0, 1], final: ['12:00 AM', '12:30 AM]}, {init: [2, 3], final: ['01:00 AM', '01:30 AM]}]
    - "excpDatesBoolArr" stores (custom dates and their respective schedule) and (exception dates) in boolean format
      e.g. [{date: "31-09-2022", slots: [false * 3, true * 5, false * 40]}, {date: "31-09-2022", slots: [false * 48]}]
    - "excpDatesSlotsArr" stores custom dates and their respective slots in both slotId and Time format and holiday dates
      e.g. [{date: "31-09-2022", slots: [{init: [0, 2], final: [12:00 AM, 01:30 AM]}, {init: [33, 36], final: [04:30 PM, 6:00 PM]}]}, {date: "31-09-2022", slots: [empty]}]
    - ""
  */
  const [selectedDateBoolArr, setSelectedDateBoolArr] = useState([]);
  const [selectedDateSlotsArr, setSelectedDateSlotsArr] = useState([]);
  const [excpDatesBoolArr, setExcpDatesBoolArr] = useState([]);
  const [excpDatesSlotsArr, setExcpDatesSlotsArr] = useState([]);
  const [finalExcpDatesBoolArr, setFinalExcpDatesBoolArr] = useState([]);
  const [finalExcpDatesSlotsArr, setFinalExcpDatesSlotsArr] = useState([]);
  // ---------------------------------------------------------
  // Assigning boolean(initially false) array of 48 item to boolGnrcSchd(object that contain slot status of all days in boolean)
  const initGnrcSchdBool = () => {
    let mon = new Array(48);
    let tue = new Array(48);
    let wed = new Array(48);
    let thu = new Array(48);
    let fri = new Array(48);
    let sat = new Array(48);
    let sun = new Array(48);
    for (let i = 0; i < 48; i++) {
      mon[i] = false;
      tue[i] = false;
      wed[i] = false;
      thu[i] = false;
      fri[i] = false;
      sat[i] = false;
      sun[i] = false;
    }
    setBoolGnrcSchd({ ...boolGnrcSchd, mon, tue, wed, thu, fri, sat, sun });
  };

  useEffect(() => {
    initGnrcSchdBool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("gnrcSchdule");
    // console.log(JSON.stringify(gnrcSchdule));
    if (Object.keys(gnrcSchdule)?.length > 0) {
      let boolGnrcSchd = {};
      Object.keys(gnrcSchdule).forEach(
        (day) => (boolGnrcSchd[day] = t12ToBoolArray(gnrcSchdule[day]))
      );
      // console.log("boolGnrcSchd");
      // console.log(JSON.stringify(boolGnrcSchd));
      setBoolGnrcSchd(boolGnrcSchd);
    }
  }, [gnrcSchdule]);

  useEffect(() => {
    setTimeout(() => {
      let copyExcpDatesBoolArr = [];
      // // console.log(
      //   "initilazing excpDatesBoolArr (input): ",
      //   JSON.stringify(cstmSchdule)
      // );
      // console.log("typeof cstmSchedule in setTimeout: ", typeof cstmSchdule);
      // console.log(
      //   "typeof cstmSchedule in setTimeout: ",
      //   Array.isArray(cstmSchdule)
      // );
      cstmSchdule.forEach((schedule) => {
        // console.log("pushing: ", schedule.date);
        copyExcpDatesBoolArr.push({
          date: schedule.date,
          slots: t12ToBoolArray(schedule.slots),
        });
      });
      // console.log(
      //   "initilazing excpDatesBoolArr (output): ",
      //   JSON.stringify(copyExcpDatesBoolArr)
      // );
      setExcpDatesBoolArr(copyExcpDatesBoolArr);
    }, 0);
  }, [cstmSchdule]);
  // ----------------------------------------------------------------

  /* 
    Converting Slots form Boolean array to Readable 12hours Time format(also slotId format)
    [false, true, true, false * 43, true, true] ->
    [{init: [1, 2], final: ["12:30 AM", "01:00 AM"]}, {init: [47, 48], final: ["11:30 PM", "11:59 PM"]}] 
  */
  const convrtBoolTo12HTime = (dayBoolArr) => {
    // Filtering out slot index which is true e.g. [ 1, 2, 4, 5, 6, 10, 12]
    let copyDayBoolArr = [];
    dayBoolArr.forEach((item, index) => {
      if (item === true) {
        copyDayBoolArr.push(index);
      }
    });

    // Creating the Start and End Point of slotIds e.g. [[1,2], [4, 6], [10], [12]] and storing it in "daySlots"
    let daySlots = [];
    daySlots = copyDayBoolArr.reduce((r, n) => {
      const lastSubArray = r[r?.length - 1];
      if (!lastSubArray || lastSubArray[lastSubArray?.length - 1] !== n - 1) {
        r.push([]);
      }
      r[r?.length - 1].push(n);
      return r;
    }, []);

    // Creating Meaningfull 12-H Time Slots from slotIds [1, 2] -> ["12:30 AM", "01:00 AM"] and storing it in "t12HSlotsArr"
    let t12HSlotsArr = [];
    daySlots.forEach((item) => {
      let start = 0;
      let end = 0;
      // Start
      start = moment(
        Math.floor(item[0] / 2) + (item[0] % 2 === 0 ? ":00" : ":30"),
        "H:mm"
      ).format("hh:mm A");
      if (item?.length !== 1) {
        // End
        end =
          item[item?.length - 1] === 47
            ? "23:59"
            : Math.floor((item[item?.length - 1] + 1) / 2) +
            ((item[item?.length - 1] + 1) % 2 === 0 ? ":00" : ":30");
        end = moment(end, "H:mm").format("hh:mm A");
        t12HSlotsArr.push({
          init: [item[0], item[item?.length - 1]],
          final: [start, end],
        });
      } else {
        // End
        end =
          item[0] === 47
            ? "11:59 PM"
            : moment(
              Math.floor((item[0] + 1) / 2) +
              ((item[0] + 1) % 2 === 0 ? ":00" : ":30"),
              "H:mm"
            ).format("hh:mm A");
        t12HSlotsArr.push({
          init: [item[0], item[0]],
          final: [start, end],
        });
      }
    });
    return t12HSlotsArr;
  };
  // -------------------------------------------------------------------------

  /* 
    - 12 Hours to Boolean Array 
    - E.g. [{init: [1, 2], final: ["12:30 AM", "01:00 AM"]}, {init: [47, 48], final: ["11:30 PM", "11:59 PM"]}] -> [false, true, false, false * 43, false, true]
  */
  const t12ToBoolArray = (array) => {
    let result = new Array(48).fill(false);
    array.map((item) => {
      for (let i = item.init[0]; i <= item.init[1]; i++) {
        result[i] = true;
      }
    });
    return result;
  };

  /* 
    - "calculateNOSlots" takes hours, minutes and meridiem and calculates the no of slots
  */
  const calculateNoSlots = (hours, minutes, merd) => {
    let noOfSlots = hours * 2; // slotId ranges from 0 to 47 as there are 48 slot in a day and it will be according to "from time"
    if (merd === "AM") {
      if (hours === "12") {
        noOfSlots = 0;
      }
    } else {
      noOfSlots += 24;
      if (hours === "12") {
        noOfSlots = 24;
      }
    }
    minutes === "00" ? (noOfSlots += 0) : (noOfSlots += 1);
    return noOfSlots;
  };

  /* 
    - Add (and Combine them if required) additional time slots to existing boolean Array(set Slots to true)(modifies existing Array)
      concatSlots(dayArray, hours, minutes, merd, slot) => {return BooleanArray}
      e.g. (daysBoolArray[mon], 12, 30, AM, 4) -> [false, true * 4, false * 43]
    - Takes boolean array of slots for a given day, starting time of a session in 12 hour format and no of slots and returns final boolean 
      array of total no of slots
  */
  const concatSlots = (day, hours, minutes, merd, slot) => {
    // Calculating slot no
    let slotId = calculateNoSlots(hours, minutes, merd);
    // Adding Slots in Day Array
    let getArr = [...day];
    if (slotId + slot > 48) {
      for (let j = slotId; j < 48; j++) {
        getArr[j] = true;
      }
    } else {
      for (let j = slotId; j < slotId + slot; j++) {
        getArr[j] = true;
      }
    }
    return getArr;
  };
  // ---------------------------------------------
  /* 
    - Creating or Updating Days Boolean Array(boolGnrcSchd) for generic schedule
      syntax: addNewGenericTimeSlots(selectedHours, selectedMinutes, selectedMeridiem, selectedSlot, selectedDay) 
      addNewGenericTimeSlots(hours, minutes, merd, slot, selectedDays) =>{setBoolGnrcSchd(
        mon: boolGnrcSchd.mon,
        tue: boolGnrcSchd.tue,
        wed: boolGnrcSchd.wed,
        thu: boolGnrcSchd.thu,
        fri: boolGnrcSchd.fri,
        sat: boolGnrcSchd.sat,
        sun: boolGnrcSchd.sun,
      )}
    - It will add/update new slots to the existing generic schedule and if their is no modification in any of the day then it will remain the 
      same
  */
  const addNewGenericTimeSlots = (hours, minutes, merd, slot, selectedDays) => {
    // console.log(hours, minutes, merd, slot, selectedDays);
    let copyBoolGnrcSchd = {
      // Making copy of original Days Boolean Array(boolGnrcSchd)
      mon: [...boolGnrcSchd.mon],
      tue: [...boolGnrcSchd.tue],
      wed: [...boolGnrcSchd.wed],
      thu: [...boolGnrcSchd.thu],
      fri: [...boolGnrcSchd.fri],
      sat: [...boolGnrcSchd.sat],
      sun: [...boolGnrcSchd.sun],
    };
    // Converting from time to Slots No
    let slotId = calculateNoSlots(hours, minutes, merd);
    let m_DayIndex = 0;
    for (let aDay in copyBoolGnrcSchd) {
      if (selectedDays[aDay]) {
        // Creating or Updating a Day Boolean Array
        copyBoolGnrcSchd[aDay] = concatSlots(
          copyBoolGnrcSchd[aDay],
          hours,
          minutes,
          merd,
          slot
        );
        if (slotId + slot > 48) {
          // If Slots are exceeding current limit then the remaining will get added to next day
          // console.log(slotId, slot);
          let nextDay = m_DayIndex < 6 ? m_DayIndex + 1 : 0;
          copyBoolGnrcSchd[Object.keys(boolGnrcSchd)[nextDay]] = concatSlots(
            copyBoolGnrcSchd[Object.keys(boolGnrcSchd)[nextDay]],
            "12",
            "00",
            "AM",
            slotId + slot - 48
          );
        }
      }
      m_DayIndex++;
    }
    setBoolGnrcSchd(copyBoolGnrcSchd);
  };
  // ---------------------------------------------------------------

  // Enable "SAVE" button if all the requirement has been satisfied
  const [isAddTimeBtnDisabled, setIsAddTimeBtnDisabled] = useState(true); // Disabling "SAVE" button in generic slot popup
  useEffect(() => {
    if (
      slots !== 0 &&
      (checkedDaysGenericSchedule.mon ||
        checkedDaysGenericSchedule.tue ||
        checkedDaysGenericSchedule.wed ||
        checkedDaysGenericSchedule.thu ||
        checkedDaysGenericSchedule.fri ||
        checkedDaysGenericSchedule.sat ||
        checkedDaysGenericSchedule.sun ||
        allDaysCheckedGenericSchedule)
    ) {
      setIsAddTimeBtnDisabled(false);
    } else {
      setIsAddTimeBtnDisabled(true);
    }
  }, [slots, checkedDaysGenericSchedule]);

  // Creating or Updating Days Array(slotwiseGenericSchd)
  useEffect(() => {
    let days = {
      mon: [...slotwiseGenericSchd.mon],
      tue: [...slotwiseGenericSchd.tue],
      wed: [...slotwiseGenericSchd.wed],
      thu: [...slotwiseGenericSchd.thu],
      fri: [...slotwiseGenericSchd.fri],
      sat: [...slotwiseGenericSchd.sat],
      sun: [...slotwiseGenericSchd.sun],
    };
    for (let day in days) {
      days[day] = convrtBoolTo12HTime(boolGnrcSchd[day]);
    }
    setSlotwiseGenericSchd(days);
    checkCustSchdValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boolGnrcSchd]);
  // -----------------------------------------

  /* 
    - DisabledDaysWhileEditing: It keeps tracks of the days which can not be edited while modifying a given day
      It will disable the days checkbox which cannot be edited and used in the generic schedule popup
      disabledDaysWhileEditing(from time, to time) => {setDisplay(checked_Array)} 
  */
  const disabledDaysWhileEditing = (start, end) => {
    let checked = {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    };
    for (let day in slotwiseGenericSchd) {
      slotwiseGenericSchd[day]?.forEach((item) => {
        if (item.init[0] === start && item.init[1] === end) {
          checked[day] = false;
        }
      });
    }
    setDisableDaysWhileEditingGenericSlot(checked);
  };
  // ------------------------------------------------------------------

  /* 
    - Editing Slots in Generic Schedule: Adding, editing and removing the slots in generic schedule
      editGenericTimeSlots = (selectedDays, start, end, hours, minutes) => {return setBoolGnrcSchd(Days_Array)} 
    - It takes list of days starting time, and no of slots and adds these details in the boolean array which automatically get reflected in 
      generic slot array by useEffect hook
  */
  const editGenericTimeSlots = (
    selectedDays,
    start,
    end,
    hours,
    minutes,
    merd,
    slot
  ) => {
    let copyBoolGnrcSchd = {
      mon: [...boolGnrcSchd.mon],
      tue: [...boolGnrcSchd.tue],
      wed: [...boolGnrcSchd.wed],
      thu: [...boolGnrcSchd.thu],
      fri: [...boolGnrcSchd.fri],
      sat: [...boolGnrcSchd.sat],
      sun: [...boolGnrcSchd.sun],
    };
    for (let aDay in selectedDays) {
      if (selectedDays[aDay]) {
        for (let a = start; a <= end; a++) {
          copyBoolGnrcSchd[aDay][a] = false;
        }
        // console.log("edited custom schedule... ", copyBoolGnrcSchd[aDay]);
        // Calculating slot no
        let slotId = calculateNoSlots(hours, minutes, merd);
        let m_DayIndex = 0;
        for (let anoElem in copyBoolGnrcSchd) {
          if (selectedDays[anoElem]) {
            // Updating a Day Boolean Array
            copyBoolGnrcSchd[anoElem] = concatSlots(
              copyBoolGnrcSchd[anoElem],
              hours,
              minutes,
              merd,
              slot
            );
            // console.log(hours, minutes, merd, slot);
            // If Slots are exceeding current limit then it will get added to next day
            let nextDay = m_DayIndex < 6 ? m_DayIndex + 1 : 0;
            copyBoolGnrcSchd[Object.keys(copyBoolGnrcSchd)[nextDay]] =
              concatSlots(
                copyBoolGnrcSchd[Object.keys(copyBoolGnrcSchd)[nextDay]],
                "12",
                "00",
                "AM",
                slotId + slot - 48
              );
          }
          m_DayIndex++;
        }
      }
    }
    setBoolGnrcSchd(copyBoolGnrcSchd);
  };
  // ------------------------------------------------------

  /* 
    - Deleting a Slot in Generic Schedule: It'll delete a particular slot of any day in generic schedule
      syntax: deleteSlots(selectedDayArr, startSlotId, endSlotId) => {setBoolGnrcSchd({ ...boolGnrcSchd, [day]: dys })}
      Takes a day name, startSlotId and endSlotId as argument and removes that specified slot/slots(in the chip) from the boolean generic schedule array
      selectedDay - (mon/tue/wed/thu/fri/sat/sun)
  */
  const deleteSlots = (selectedDay, startSlotId, endSlotId) => {
    let selectedDayBoolArr = [...boolGnrcSchd[selectedDay]];
    for (let a = startSlotId; a <= endSlotId; a++) {
      selectedDayBoolArr[a] = false;
    }
    setBoolGnrcSchd({ ...boolGnrcSchd, [selectedDay]: selectedDayBoolArr });
  };
  // ---------------------------------------

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("interwiu.com"));
    const newLocalData = {
      ...localData,
      gnrcSchdule: {
        ...slotwiseGenericSchd,
      },
    };
    localStorage.setItem("interwiu.com", JSON.stringify(newLocalData));
  }, [slotwiseGenericSchd]);
  // END

  // EDIT AVAILABILITY AND ADD EXCEPTION / CUSTOM SCHEDULE

  const [calenderDate, setCalenderDate] = useState(new Date()); // "calenderDate" stores the date selected in the calender.

  // ------- 48 item array -------
  let dayBoolArray = new Array(48);
  for (let i = 0; i < 48; i++) {
    dayBoolArray[i] = false;
  }
  useEffect(() => {
    // Assigning 48 item boolean array to current boolean array
    setSelectedDateBoolArr(dayBoolArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ----------------------------

  /* 
    - Converting Current Boolean Array (selectedDateBoolArr) to Readable Array(selectedDateSlotsArr)
    - Update "excpDatesSlotsArr" whenever "excepDatesBoolArr" is changed
  */
  useEffect(() => {
    // If there is change in excpDatesBoolArr then this will run
    // console.log("excpDatesBoolArr: ", excpDatesBoolArr);
    let arrayBool = [];
    excpDatesBoolArr.forEach((obj) => {
      let result = convrtBoolTo12HTime(obj.slots);
      arrayBool.push({ date: obj.date, slots: result });
    });
    // console.log("excpDatesSlotsArr: ", arrayBool);
    displayButtons();
    setExcpDatesSlotsArr(arrayBool);
    // ------------------------
    // let arrayBool = [];
    // Object.keys(excpDatesBoolArr).forEach((date) => {
    //   let result = convrtBoolTo12HTime(excpDatesBoolArr[date]);
    //   arrayBool.push({ date, slots: result });
    // });
    // console.log("excpDatesBoolArr: ", excpDatesBoolArr);
    // displayButtons();
    // setExcpDatesSlotsArr(arrayBool);
  }, [excpDatesBoolArr]);

  useEffect(() => {
    // // It'll update the final custom schedule variable in "Create an Account as an Interviewer"
    const localData = JSON.parse(localStorage.getItem("interwiu.com"));
    const newLocalData = {
      ...localData,
      cstmSchdule: {
        ...excpDatesSlotsArr,
      },
    };
    localStorage.setItem("interwiu.com", JSON.stringify(newLocalData));
  }, [excpDatesSlotsArr]);

  // -----------
  // useEffect(() => {
  //   // console.log("finalExcpDatesSlotsArr: ", finalExcpDatesBoolArr);
  // }, [finalExcpDatesSlotsArr]);
  // -------------------------------

  /* 
    - If there is any change in "selectedDateBoolArr" then it'll update the current 12-H Time and slotId format array(selectedDateSlotsArr)
  */
  useEffect(() => {
    // console.log("changed");
    // console.log("selectedDateBoolArr: ", selectedDateBoolArr);
    let result = convrtBoolTo12HTime(selectedDateBoolArr);
    // console.log("result: ", result);
    setSelectedDateSlotsArr(result);
  }, [selectedDateBoolArr]);
  // -------------------------------

  /* 
    - Getting custom/generic schedule for the Current Boolean Array(selectedDateBoolArr) and Current 12-H Time Slots(selectedDateSlotsArr)
      for the selected date in calender
      syntax: getCustomOrGnrcSchd(currentlySelectedDateInCalender)
  */
  const getCustomOrGnrcSchd = (m_selectedDate) => {
    const currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    const currentDay = moment(m_selectedDate).format("ddd").toLowerCase();
    let check = false;
    excpDatesBoolArr?.forEach((item) => {
      if (item.date === currentDate) {
        // console.log("reached here 1");
        // console.log(item.slots);
        setSelectedDateBoolArr([...item.slots]);
        check = true;
      }
    });
    if (!check) {
      setSelectedDateBoolArr([...boolGnrcSchd[currentDay]]); // Call by Value
      // setSelectedDateSlotsArr([...slotwiseGenericSchd[currentDay]]);
    }
  };
  useEffect(() => {
    // updating current boolean array(selectedDateBoolArr) and readable array(selectedDateSlotsArr)
    getCustomOrGnrcSchd(calenderDate);
    displayButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderDate, boolGnrcSchd]);
  // --------------------------------
  // useEffect(() => {
  //   getCustomOrGnrcSchd(calenderDate);
  //   // console.log("excpDatesBoolArr changed");
  // }, [excpDatesBoolArr]);
  useEffect(() => {
    // Logging current array
    // console.log("selectedDateSlotsArr: ", selectedDateSlotsArr);
    // console.log("selectedDateBoolArr: ", selectedDateBoolArr);
  }, [selectedDateSlotsArr]);

  // "resetToGenrcSchd" will reset the selected date schedule to generic day schedule
  const resetToGenrcSchd = (m_selectedDate) => {
    const currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    const currentDay = moment(m_selectedDate).format("ddd").toLowerCase();
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    copyExcpDatesBoolArr?.forEach((item, index) => {
      if (item.date === currentDate) {
        copyExcpDatesBoolArr.splice(index, 1); // Removing this exception
      }
    });
    // console.log("copyExcpDatesBoolArr", copyExcpDatesBoolArr);
    setExcpDatesBoolArr(copyExcpDatesBoolArr);
    setSelectedDateBoolArr([...boolGnrcSchd[currentDay]]);
    // setSelectedDateSlotsArr(slotwiseGenericSchd[currentDay]);
  };
  // ------------------

  /* 
    - "checkArrayEquality" check if two array are equal or not
  */
  const checkArrayEquality = (firstArray, secondArray) => {
    // All array will be having length 48
    if (firstArray?.length !== 48 || secondArray?.length !== 48) {
      return false;
    }
    let index = 0;
    while (index < 48) {
      if (firstArray[index] !== secondArray[index]) {
        return false;
      }
      index++;
    }
    return true;
  };

  /* 
    - "checkCustSchdValidity" checks if "excpDatesBoolArr" is valid or not. 
    - It'll check that the slot assigned to that date isn't same in generic schedule for that respective day.
  */
  // const checkCustSchdValidity = (firstArray, secondArray) => {
  //   let copyExcpDatesBoolArr = [...excpDatesBoolArr];
  //   copyExcpDatesBoolArr.forEach((item, index) => {
  //     const day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
  //     const dayArray = boolGnrcSchd[day];
  //     // console.log(dayArray);
  //     if (checkArrayEquality(item.slots, dayArray)) {
  //       copyExcpDatesBoolArr.splice(index, 1);
  //     }
  //   });
  //   console.log(copyExcpDatesBoolArr);
  //   setExcpDatesBoolArr(copyExcpDatesBoolArr);
  // };
  const checkCustSchdValidity = () => {
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    copyExcpDatesBoolArr.forEach((item, index) => {
      const day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
      const dayArray = boolGnrcSchd[day];
      // console.log(dayArray);
      if (checkArrayEquality(item.slots, dayArray)) {
        copyExcpDatesBoolArr.splice(index, 1);
      }
    });
    // console.log(copyExcpDatesBoolArr);
    setExcpDatesBoolArr(copyExcpDatesBoolArr);
  };
  /* 
    - "checkPopupOpen" will check when to open popup of save and discard changes in edit availability.
  */
  const checkPopupOpen = () => {
    let check = true;
    if (finalExcpDatesSlotsArr?.length !== excpDatesSlotsArr?.length) {
      check = false;
    }
    if (check) {
      for (let i = 0; i < finalExcpDatesSlotsArr?.length; i++) {
        // console.log("finalExcpDatesSlotsArr[i]: ", finalExcpDatesSlotsArr[i]);
        if (
          JSON.stringify(finalExcpDatesSlotsArr[i]) !==
          JSON.stringify(excpDatesSlotsArr[i])
        ) {
          check = false;
        }
      }
    }
    // console.log("checkFunc: ", check);
    return check;
  };

  /*   
    - Add Slots in Edit Availability : This will add new slot to excpDatesSlotsArr list
      syntax: addNewCustomSlot(selectedDate, SelectedHours, SelectedMinutes, SelectedMeridiem, SelectedSlots)
  */
  const addNewCustomSlot = (m_selectedDate, hours, minutes, merd, numSlots) => {
    // Coping the Array and calculating slotId
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    let slotId = calculateNoSlots(hours, minutes, merd);
    // Today or Current Date
    const currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    let currentDateArray = concatSlots(
      selectedDateBoolArr,
      hours,
      minutes,
      merd,
      numSlots
    );
    let currentDateCheck = false;
    // Next Date
    const nextDate = moment(currentDate, "DD/MM/YYYY")
      .add(1, "days")
      .format("DD/MM/YYYY");
    let nextDateArray = concatSlots(
      boolGnrcSchd[moment(nextDate, "DD/MM/YYYY").format("ddd").toLowerCase()],
      "12",
      "00",
      "AM",
      slotId + numSlots - 48
    );
    let nextDateCheck = false;
    // checking if slots exceeds the current limit or not
    let exceedToNextDate = slotId + numSlots > 48;
    if (exceedToNextDate) {
      copyExcpDatesBoolArr?.forEach((obj) => {
        if (obj.date === currentDate) {
          // Updating the current date array
          obj.slots = [...currentDateArray];
          currentDateCheck = true;
        }
        if (obj.date === nextDate) {
          // Updating the next date array
          obj.slots = concatSlots(
            obj.slots,
            "12",
            "00",
            "AM",
            slotId + numSlots - 48
          );
          nextDateCheck = true;
        }
      });
    } else {
      copyExcpDatesBoolArr?.forEach((obj) => {
        if (obj.date === currentDate) {
          obj.slots = [...currentDateArray];
          currentDateCheck = true;
        }
      });
    }
    if (!currentDateCheck) {
      // Pushing this date if it's not already there
      copyExcpDatesBoolArr.push({
        date: currentDate,
        slots: [...currentDateArray],
      });
    }
    if (!nextDateCheck && exceedToNextDate) {
      // Pushing this date if it's not already there
      copyExcpDatesBoolArr.push({
        date: nextDate,
        slots: [...nextDateArray],
      });
    }
    /*  
    - Checking if new custom schedule and generic schedule are same or not and if they are then we will remove this date from exception list and return but if not then we'll add or update the list
     */
    copyExcpDatesBoolArr = [
      ...copyExcpDatesBoolArr.filter((item) => {
        let day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
        let dayArray = boolGnrcSchd[day];
        // console.log("checking: ", item.date);
        if (!checkArrayEquality(item.slots, dayArray)) {
          return item;
        }
      }),
    ];
    // copyExcpDatesBoolArr?.forEach((item, index) => {
    //   let day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
    //   let dayArray = boolGnrcSchd[day];
    //   // console.log(dayArray);
    //   console.log("checking: ", item.date);
    //   if (checkArrayEquality(item.slots, dayArray)) {
    //     console.log("removing: ", item.date);
    //     copyExcpDatesBoolArr.splice(index, 1);
    //   }
    // });
    // console.log("copyExcpDatesBoolArr: ", copyExcpDatesBoolArr);
    setSelectedDateBoolArr([...currentDateArray]);
    // setSelectedDateSlotsArr(convrtBoolTo12HTime(result));
    setExcpDatesBoolArr([...copyExcpDatesBoolArr]);
  };
  // -------------------------------------

  /*  
    - Editing Slots in Edit Availability
      editCustomSlot(currentlySelectedDate, fromTime, toTime, SelectedHours, SelectedMinutes, SelectedMeridiem, SelectedSlots)
    - It takes selected date in calender, startSlotId, endSlotId, 12-H time format, no of slots and modifies boolean array of that exception date
  */
  const editCustomSlot = (
    m_selectedDate,
    start,
    end,
    hours,
    minutes,
    merd,
    numSlots
  ) => {
    // Coping the Array and calculating slotId
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    let slotId = calculateNoSlots(hours, minutes, merd);
    // Today or Current Date
    let currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    let copySelectedDateBoolArr = [...selectedDateBoolArr]; // Coping the selected date bool array
    for (let a = start; a <= end; a++) {
      // Removing slots from start to end in current date array
      copySelectedDateBoolArr[a] = false;
    }
    let currentDateArray = concatSlots(
      copySelectedDateBoolArr,
      hours,
      minutes,
      merd,
      numSlots
    );
    let currentDateCheck = false;
    // Next Date
    let nextDate = moment(currentDate, "DD/MM/YYYY")
      .add(1, "days")
      .format("DD/MM/YYYY");
    let nextDateArray = concatSlots(
      boolGnrcSchd[moment(nextDate, "DD/MM/YYYY").format("ddd").toLowerCase()],
      "12",
      "00",
      "AM",
      slotId + numSlots - 48
    );
    let nextDateCheck = false;
    // checking if slots exceeds the current limit or not
    let exceedToNextDate = slotId + numSlots > 48;
    if (exceedToNextDate) {
      copyExcpDatesBoolArr?.forEach((obj) => {
        if (obj.date === currentDate) {
          // Updating the current date array
          obj.slots = [...currentDateArray];
          currentDateCheck = true;
        }
        if (obj.date === nextDate) {
          // Updating the next date array
          obj.slots = concatSlots(
            obj.slots,
            "12",
            "00",
            "AM",
            slotId + numSlots - 48
          );
          nextDateCheck = true;
        }
      });
    } else {
      copyExcpDatesBoolArr?.forEach((obj) => {
        if (obj.date === currentDate) {
          obj.slots = [...currentDateArray];
          currentDateCheck = true;
        }
      });
    }
    if (!currentDateCheck) {
      // Pushing this date if it's not already there
      copyExcpDatesBoolArr.push({
        date: currentDate,
        slots: [...currentDateArray],
      });
    }
    if (!nextDateCheck && exceedToNextDate) {
      // Pushing this date if it's not already there
      copyExcpDatesBoolArr.push({
        date: nextDate,
        slots: [...nextDateArray],
      });
    }
    /*  
      - Checking if new custom schedule and generic schedule are same or not and if they are then we will remove this date from exception list and return
    */
    copyExcpDatesBoolArr = [
      ...copyExcpDatesBoolArr.filter((item) => {
        let day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
        let dayArray = boolGnrcSchd[day];
        // console.log("checking: ", item.date);
        if (!checkArrayEquality(item.slots, dayArray)) {
          return item;
        }
      }),
    ];
    // console.log("copyExcpDatesBoolArr: ", copyExcpDatesBoolArr);
    // Updating this schedule
    setSelectedDateBoolArr([...currentDateArray]);
    setExcpDatesBoolArr([...copyExcpDatesBoolArr]);
  };
  // -------------------------------------

  /*  
    - Deleting Slots in Edit Availability
      syntax: deleteCustomSlot(selectedDate, startSlotId, endSlotId) 
    - It'll takes selected date in calender, startSlotId and endSlotId and deletes them from custom schedule
  */
  const deleteCustomSlot = (m_selectedDate, start, end) => {
    const currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    let copySelectedDateBoolArr = [...selectedDateBoolArr];
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    for (let a = start; a <= end; a++) {
      copySelectedDateBoolArr[a] = false;
    }
    let check = false;
    copyExcpDatesBoolArr?.forEach((obj) => {
      if (obj.date === currentDate) {
        obj.slots = [...copySelectedDateBoolArr];
        check = true;
      }
    });
    if (!check) {
      copyExcpDatesBoolArr.push({
        date: currentDate,
        slots: [...copySelectedDateBoolArr],
      });
    }
    /*  
      - Checking if new custom schedule and generic schedule are same or not and if they are then we will remove this date from exception list and return
    */
    copyExcpDatesBoolArr?.forEach((item, index) => {
      const day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
      const dayArray = boolGnrcSchd[day];
      if (checkArrayEquality(item.slots, dayArray)) {
        // console.log("removing: ", item.date);
        copyExcpDatesBoolArr.splice(index, 1);
      }
    });
    setSelectedDateBoolArr(copySelectedDateBoolArr);
    setExcpDatesBoolArr(copyExcpDatesBoolArr);
  };
  // --------------------------------------

  /*
    - Add a date as an Exception
      excpDateForGnrcSchedule(selectedDate) 
    - It'll take currently selected date (the day of that date for which generic schedule was already specified), remove all the slots that were previously specified and adds this date to exception list with false * 48 in boolean list which automatically updates empty slot array in 12-H time format array
    - e.g. {date: 31-09-2022, slots: [false * 48]}
  */
  const excpDateForGnrcSchedule = (m_selectedDate) => {
    const currentDate = moment(m_selectedDate).format("DD/MM/YYYY");
    let copySelectedDateBoolArr = [...selectedDateBoolArr];
    let copyExcpDatesBoolArr = [...excpDatesBoolArr];
    for (let i = 0; i < 48; i++) {
      copySelectedDateBoolArr[i] = false;
    }
    let check = false;
    copyExcpDatesBoolArr?.forEach((obj) => {
      if (obj.date === currentDate) {
        obj.slots = [...copySelectedDateBoolArr];
        check = true;
      }
    });
    if (!check) {
      copyExcpDatesBoolArr.push({
        date: currentDate,
        slots: [...copySelectedDateBoolArr],
      });
    }
    /*  
      - Checking if new custom schedule and generic schedule are same or not and if they are then we will remove this date from exception list and return
    */
    copyExcpDatesBoolArr?.forEach((item, index) => {
      const day = moment(item.date, "DD/MM/YYYY").format("ddd").toLowerCase();
      const dayArray = boolGnrcSchd[day];
      if (checkArrayEquality(item.slots, dayArray)) {
        copyExcpDatesBoolArr.splice(index, 1);
      }
    });
    setSelectedDateBoolArr(copySelectedDateBoolArr);
    setExcpDatesBoolArr(copyExcpDatesBoolArr);
  };
  // ------------------------------------

  /* 
    - Display "Reset to Default" and "Add More Time" Button in Custom Schedule Popup
  */
  const [customSchdButtons, setCustomSchdButtons] = useState(false);
  const displayButtons = () => {
    const currentDate = moment(calenderDate).format("DD/MM/YYYY");
    let check = false;
    excpDatesBoolArr?.forEach((obj) => {
      if (obj.date === currentDate) {
        check = true;
      }
    });
    setCustomSchdButtons(check);
  };

  /* 
    - Date Color of Calender
      - Green color for the dates which has only generic schedule.
      - Yellow color for the dates which have modified schedule from generic but is not holiday.
      - Red color for the dates which were part of generic schedule but later declared as holiday by interviewer.
      - No color for dates for which no schedule(generic/custom) was specified.
  */
  const [green, setGreen] = useState([]);
  const [yellow, setYellow] = useState([]);
  const [red, setRed] = useState([]);

  // Creating Date Array starting from today Date till the end of current month
  function dateRange(startDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (
      currentDate <=
      new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
    ) {
      dateArray.push(new Date(currentDate));
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    return dateArray;
    // const dateArray = [];
    // let currentDate = new Date(startDate);
    // console.log("currentDate: ", currentDate);
    // console.log(
    //   "year:",
    //   startDate.getFullYear(),
    //   " month: ",
    //   startDate.getMonth() + 1,
    //   " date: ",
    //   moment(startDate).daysInMonth()
    // );
    // let lastDate = new Date(
    //   startDate.getFullYear(),
    //   startDate.getMonth(),
    //   moment(startDate).daysInMonth()
    // );
    // console.log("lastDate: ", lastDate);
    // while (currentDate <= lastDate) {
    //   dateArray.push(new Date(currentDate));
    //   currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    // }
    // console.log("dateArray: ", dateArray);
    // return dateArray;
  }

  const handleDateChange = () => {
    let nowDate = new Date();
    if (calenderDate.getMonth() !== new Date().getMonth()) {
      // console.log("true");
      nowDate = new Date(
        calenderDate.getFullYear(),
        calenderDate.getMonth(),
        1
      );
    } else {
      // console.log("false");
      nowDate = new Date();
    }
    // console.log("nowDate: ", nowDate);
    let dates = dateRange(nowDate);
    // Setting green array
    let newGreen = [];
    dates.forEach((item) => {
      // console.log("Array: ", slotwiseGenericSchd[moment(item).format("ddd").toLowerCase()]);
      if (
        slotwiseGenericSchd[moment(item).format("ddd").toLowerCase()]?.length !==
        0
      ) {
        newGreen.push(item.getDate());
      }
    });
    setGreen(newGreen);
    // Setting yellow and red array
    let newYellow = [];
    let newRed = [];
    excpDatesSlotsArr?.forEach((item) => {
      if (parseInt(item.date.slice(3, 5)) === nowDate.getMonth() + 1) {
        // console.log("true");
        if (item.slots?.length !== 0) {
          // console.log("non empty");
          newYellow.push(parseInt(item.date.slice(0, 2)));
        } else {
          // console.log("empty");
          newRed.push(parseInt(item.date.slice(0, 2)));
        }
      }
      // console.log(parseInt(item.date.slice(3, 5)) === nowDate.getMonth() + 1);
    });
    setYellow(newYellow);
    setRed(newRed);
    // console.log("nowDate: ", dates);
  };

  useEffect(() => {
    handleDateChange();
  }, [calenderDate, slotwiseGenericSchd, excpDatesBoolArr, excpDatesSlotsArr]);
  useEffect(() => {
    // console.log("green: ", green, " yellow: ", yellow, " red: ", red);
  }, [green, yellow, red]);
  // --------------------------------

  // Add Time Modal in Edit Availability in Custom Schedule
  const [openAddTimePopupInCustomSchd, setOpenAddTimePopupInCustomSchd] =
    useState(false);
  const handleAddTimePopupInCustomSchdOpen = () =>
    setOpenAddTimePopupInCustomSchd(true);
  const handleAddTimePopupInCustomSchdClose = () => {
    setOpenAddTimePopupInCustomSchd(false);
    setIsItEditing(false);
    setTime({
      hrs: "12",
      min: "00",
      merd: "AM",
    });
    setSlots(0);
  };
  const [oldTimeWhileEditingCustomSlot, setOldTimeWhileEditingCustomSlot] =
    useState({
      array: {},
      hrs: "12",
      min: "00",
      merd: "AM",
      start: 0,
      end: 0,
      index: 0,
    });

  /* 
    - Displaying slots on Popup in following format:
      (a) Selected Slots are
          Current Day: 11:00 PM - 11:59 PM
          Next Day: 12:00 AM - 01:00 AM
      (b) Selected Slots are
          11:00 PM - 11:30 PM
  */
  const getTimeSlots = (begin, number) => {
    let corr = moment(begin, "hh:mm a").format("hh:mm A");
    let end = moment(corr, "hh:mm A")
      .add(30 * number, "minutes")
      .format("hh:mm A");
    if (end.includes("12:00 AM")) {
      return (
        <>
          <div
            className="flex justify-center text-md rounded-full bg-green-500 p-2 text-white"
            style={{ width: "200px" }}
          >
            <p>{corr} - 11:59 PM</p>
          </div>
        </>
      );
    } else if (corr.includes("PM") && end.includes("AM")) {
      if (!openAddTimePopupInCustomSchd) {
        return (
          <>
            <div className="text-md">
              Given Day:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>{corr} - 11:59 PM</p>
              </div>
            </div>
            <div className="text-md">
              Next Day:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>12:00 AM - {end}</p>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="text-md">
              {moment(calenderDate, "DD/MM/YYYY").format("Do MMM YYYY")}:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>{corr} - 11:59 PM</p>
              </div>
            </div>
            <div className="text-md">
              {moment(calenderDate, "DD/MM/YYYY")
                .add(1, "days")
                .format("Do MMM YYYY")}
              :{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>12:00 AM - {end}</p>
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <div
          className="flex justify-center text-md rounded-full bg-green-500 p-2 text-white"
          style={{ width: "200px" }}
        >
          <p>
            {corr} - {end}
          </p>
        </div>
      );
    }
  };
  // --------------------------
  return (
    <div className=" max-[600px]:m-[10px] m-5 p-2 rounded border-2 border-yellow-700">
      <div className="flex justify-between">
        <Typography
          variant=""
          component="h5"
          sx={{ fontWeight: "500", color: "darkgreen" }}
          className="text-[20px] max-[600px]:text-[18px]"
        >
          Time Preferences for Mock Interviews
          <span style={{ color: "red" }}>*</span>{" "}
          <span
            ref={timeRef}
            style={{ display: "none", fontSize: "15px", color: "red" }}
          >
            Please add your time preferences!
          </span>
        </Typography>
        {slotwiseGenericSchd.mon?.length !== 0 ||
          slotwiseGenericSchd.tue?.length !== 0 ||
          slotwiseGenericSchd.wed?.length !== 0 ||
          slotwiseGenericSchd.thu?.length !== 0 ||
          slotwiseGenericSchd.fri?.length !== 0 ||
          slotwiseGenericSchd.sat?.length !== 0 ||
          slotwiseGenericSchd.sun?.length !== 0 ||
          excpDatesBoolArr?.length !== 0 ? (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={handleClearGenericSchedulePopupOpen}
              style={{ whiteSpace: 'nowrap', minWidth: '125px' }}
            >
              <i className="fa-solid fa-trash-can mr-2"></i> Clear All
            </Button>
            <Dialog
              open={openClearGenericSchedulePopup}
              onClose={handleClearGenericSchedulePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure about removing all the entries?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={(e) => {
                    setSlotwiseGenericSchd({
                      mon: [],
                      tue: [],
                      wed: [],
                      thu: [],
                      fri: [],
                      sat: [],
                      sun: [],
                    });
                    initGnrcSchdBool();
                    setExcpDatesBoolArr([]);
                    setExcpDatesSlotsArr([]);
                    setFinalExcpDatesBoolArr([]);
                    handleClearGenericSchedulePopupClose();
                  }}
                >
                  YES
                </Button>
                <Button onClick={handleClearGenericSchedulePopupClose}>
                  NO
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </div>

      <div className="relative element rounded-md border border-gray-500 p-2 my-2">
        <Typography
          variant="h6"
          sx={{
            color: "green",
            paddingBottom: '10px',
            fontSize: '18px'
          }}
        >
          Day-wise Time Preferences
        </Typography>

        <FAQSContainer className="mobile-hedder">
          {Object.keys(slotwiseGenericSchd).map((item, index) => (
            <FAQ
              key={index}
              onClick={() => {
                toggleQuestion(index);
              }}
              className="group"
              style={{
                backgroundColor: "hsl(225, 6%, 93%)",
                color: `${slotwiseGenericSchd[item]?.length === 0 ? "grey" : 'black'}`,
                fontWeight: "300",
                marginTop: `${index == 0 ? "0px" : "0.8rem"}`,
                border: `${slotwiseGenericSchd[item]?.length === 0 ? "" : '1px grey solid'}`,
                fontSize: '1rem'
              }}
            >
              <Question>
                <QuestionText>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    {item === "mon"
                      ? "Monday"
                      : item === "tue"
                        ? "Tuesday"
                        : item === "wed"
                          ? "Wednesday"
                          : item === "thu"
                            ? "Thursday"
                            : item === "fri"
                              ? "Friday"
                              : item === "sat"
                                ? "Saturday"
                                : item === "sun"
                                  ? "Sunday"
                                  : null}
                  </Typography>
                </QuestionText>
                <QuestionToggleIcon
                  variants={{
                    collapsed: { rotate: 0 },
                    open: { rotate: -180 },
                  }}
                  initial="collapsed"
                  animate={
                    activeQuestionIndex === index ? "open" : "collapsed"
                  }
                  transition={{
                    duration: 0.02,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <KeyboardArrowDownIcon />
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "0px" },
                  collapsed: {
                    opacity: 0,
                    height: 0,
                    marginTop: "0px",
                    display: "none",
                  },
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              // dangerouslySetInnerHTML={{ __html: faq.answer }}
              >
                <div className="text-center">
                  {slotwiseGenericSchd[item]?.length !== 0 ? (
                    slotwiseGenericSchd[item].map((arr) => (
                      <div
                        className="relative flex justify-between text-md rounded-full border bg-green-500 p-2 mb-2 mx-auto text-white"
                        style={{ width: "220px" }}
                      >
                        <p className="mx-auto">
                          {arr.final[0]} - {arr.final[1]}
                        </p>
                        <div
                          className="mx-1 cursor-pointer text-black hover:text-blue-600"
                          onClick={() => {
                            setIsItEditing(true);
                            handleOpenGenericSchedulePopup();
                            setCheckedDaysGenericSchedule({
                              ...checkedDaysGenericSchedule,
                              [item]: true,
                            });
                            disabledDaysWhileEditing(arr.init[0], arr.init[1]);
                            setTime({
                              hrs: arr.final[0].slice(0, 2),
                              min: arr.final[0].slice(3, 5),
                              merd: arr.final[0].slice(6, 8),
                            });
                            setSlots(arr.init[1] - arr.init[0] + 1);
                            setOldTimeWhileEditingASlot({
                              hrs: arr.final[0].slice(0, 2),
                              min: arr.final[0].slice(3, 5),
                              merd: arr.final[0].slice(6, 8),
                              start: arr.init[0],
                              end: arr.init[1],
                            });
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </div>
                        <div
                          className="mx-1 cursor-pointer text-black hover:text-blue-600"
                          onClick={() => {
                            deleteSlots(item, arr.init[0], arr.init[1]);
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ paddingBottom: '10px', color: 'black', fontWeight: '400' }}>No Specific Preferences</p>
                  )}
                </div>
              </Answer>
            </FAQ>
          ))}
        </FAQSContainer>

        <div className="grid grid-cols-4 gap-2 max-[1000px]:flex flex-wrap s-desktop-card">
          {/* Days */}
          {Object.keys(slotwiseGenericSchd).map((item) => (
            <div>
              <Typography
                variant="h6"
                component="h6"
                style={{
                  margin: "10px",
                  fontFamily: "'Ubuntu', sans-serif",
                  textAlign: "center",
                }}
              >
                {item === "mon"
                  ? "Monday"
                  : item === "tue"
                    ? "Tuesday"
                    : item === "wed"
                      ? "Wednesday"
                      : item === "thu"
                        ? "Thursday"
                        : item === "fri"
                          ? "Friday"
                          : item === "sat"
                            ? "Saturday"
                            : item === "sun"
                              ? "Sunday"
                              : null}
              </Typography>
              <div className="text-center">
                {slotwiseGenericSchd[item]?.length !== 0 ? (
                  slotwiseGenericSchd[item].map((arr) => (
                    <div
                      className="relative flex justify-between text-md rounded-full border bg-green-500 p-2 mb-2 mx-auto text-white"
                      style={{ width: "220px" }}
                    >
                      <p className="mx-auto">
                        {arr.final[0]} - {arr.final[1]}
                      </p>
                      <div
                        className="mx-1 cursor-pointer text-black hover:text-blue-600"
                        onClick={() => {
                          setIsItEditing(true);
                          handleOpenGenericSchedulePopup();
                          setCheckedDaysGenericSchedule({
                            ...checkedDaysGenericSchedule,
                            [item]: true,
                          });
                          disabledDaysWhileEditing(arr.init[0], arr.init[1]);
                          setTime({
                            hrs: arr.final[0].slice(0, 2),
                            min: arr.final[0].slice(3, 5),
                            merd: arr.final[0].slice(6, 8),
                          });
                          setSlots(arr.init[1] - arr.init[0] + 1);
                          setOldTimeWhileEditingASlot({
                            hrs: arr.final[0].slice(0, 2),
                            min: arr.final[0].slice(3, 5),
                            merd: arr.final[0].slice(6, 8),
                            start: arr.init[0],
                            end: arr.init[1],
                          });
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </div>
                      <div
                        className="mx-1 cursor-pointer text-black hover:text-blue-600"
                        onClick={() => {
                          deleteSlots(item, arr.init[0], arr.init[1]);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Specific Preferences</p>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Generic Schedule add and update popup */}
        {/* <Draggable> */}
        <Dialog
          open={openGenericSchedulePopup}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        // sx={{
        //   height: "200vh",
        //   width: "200vw",
        // }}
        // onClose={handleCloseGenericSchedulePopup}
        >
          <Box
            sx={{
              maxWidth: 540,
              bgcolor: "background.paper",
              overflow: "hidden",
              boxShadow: 24,
            }}
          >
            <DialogTitle
              style={{ cursor: "move", padding: "0" }}
              id="draggable-dialog-title"
            >
              <div className="p-2 bg-blue-500 flex justify-between cursor-move">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    ml: 1.5,
                  }}
                  className="s-mobile-main-h"
                >
                  {isItEditing
                    ? "Edit Time Preferences"
                    : "Add Time Preferences"}
                </Typography>
                <div
                  onClick={() => {
                    handleCloseGenericSchedulePopup();
                  }}
                >
                  <IconButton aria-label="delete" size="small">
                    <CancelIcon
                      fontSize="medium"
                      sx={{ color: "white", "&:hover": { color: "red" } }}
                    />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <div className="flex flex-row p-2 px-5 max-[600px]:flex-col">
              <div className="col-span-1" style={{ width: '100%' }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                >
                  Select Day(s)
                </Typography>
                <div style={{ display: 'grid',gridAutoFlow: 'column', gridTemplateRows: `auto auto auto auto ${window.innerWidth>600?'auto auto auto  auto':''}` }}>
                  {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                    (item) => (
                      <div className="max-[600px]:h-[1.5rem]">
                        <FormControlLabel
                          disabled={disableDaysWhileEditingGenericSlot[item]}
                          control={
                            <Checkbox
                              id={item}
                              name={item}
                              value={item}
                              sx={{ py: 0.5 }}
                              checked={checkedDaysGenericSchedule[item]}
                              onChange={() => {
                                setCheckedDaysGenericSchedule({
                                  ...checkedDaysGenericSchedule,
                                  [item]: !checkedDaysGenericSchedule[item],
                                });
                              }}
                            />
                          }
                          label={
                            item === "mon"
                              ? "Monday"
                              : item === "tue"
                                ? "Tuesday"
                                : item === "wed"
                                  ? "Wednesday"
                                  : item === "thu"
                                    ? "Thursday"
                                    : item === "fri"
                                      ? "Friday"
                                      : item === "sat"
                                        ? "Saturday"
                                        : item === "sun"
                                          ? "Sunday"
                                          : null
                          }
                        />
                      </div>
                    )
                  )}

                  <div className="mt-1 text-red-800 italic max-[600px]:h-[1.5rem]">
                    <FormControlLabel
                      disabled={isItEditing}
                      className="max-[600px]:h-[1.5rem]"
                      control={
                        <Checkbox
                          id="all"
                          name="all"
                          value="all"
                          sx={{ py: 0.5 }}
                          checked={allDaysCheckedGenericSchedule}
                          onChange={() => {
                            setCheckedDaysGenericSchedule({
                              ...checkedDaysGenericSchedule,
                              mon: !allDaysCheckedGenericSchedule,
                              tue: !allDaysCheckedGenericSchedule,
                              wed: !allDaysCheckedGenericSchedule,
                              thu: !allDaysCheckedGenericSchedule,
                              fri: !allDaysCheckedGenericSchedule,
                              sat: !allDaysCheckedGenericSchedule,
                              sun: !allDaysCheckedGenericSchedule,
                            });
                            setAllDaysCheckedGenericSchedule(
                              !allDaysCheckedGenericSchedule
                            );
                          }}
                        />
                      }
                      label={
                        allDaysCheckedGenericSchedule
                          ? "Unselect All"
                          : "Select All"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="my-4 max-[600px]:mb-0  col-span-2 s-mobile-text-14">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                >
                  Select Time Preferences
                </Typography>
                <div className="my-4 max-[600px]:my-0 flex gap-3 s-mobile-text-14 s-margin-top-0-7">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="w-[114px] s-mobile-text-14"
                  >
                    Available From
                  </Typography>
                  <div className="flex" id="time-picker">
                    <select
                      className="text-center"
                      name="hours"
                      id="hours time-picker"
                      value={time.hrs}
                      onChange={(e) => {
                        setTime({ ...time, hrs: e.target.value });
                      }}
                    >
                      <option value="12">12</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                    <p className="mx-1">:</p>
                    <select
                      className="text-center"
                      name="min"
                      id="min time-picker"
                      value={time.min}
                      onChange={(e) => {
                        setTime({ ...time, min: e.target.value });
                      }}
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                    <p className="mx-1"> </p>
                    <select
                      className="text-center"
                      name="merd"
                      id="merd time-picker"
                      value={time.merd}
                      onChange={(e) => {
                        setTime({ ...time, merd: e.target.value });
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="my-4 max-[600px]:my-0 flex gap-3 s-mobile-text-14  s-margin-top-0-7" id="time-picker">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="w-[114px] s-mobile-text-14"
                  >
                    No. of Mock Interviews
                  </Typography>
                  <select
                    
                    name="slots"
                    id="slots-select"
                    className="text-center"

                    value={slots}
                    onChange={handleSlotsChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                  {/* <Tooltip
                    title={`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip> */}
                  <div className="s-mobile-transform-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="tt ml-auto">
                      <InfoOutlined
                        sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                      />
                      <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-55px',right:`${window.innerWidth<600?'-80px':'0px'}` }} className="tttext">
                        {`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                      </span>
                    </div>
                  </div>

                </div>
                <div className="border-2 border-blue-500 rounded-lg my-4">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      ml: 2,
                    }}
                   
                  >
                    Selected Time Preferences
                  </Typography>
                  {!isAddTimeBtnDisabled ? (
                    <div className="my-2 ml-4">
                      {getTimeSlots(
                        time.hrs + ":" + time.min + " " + time.merd,
                        slots
                      )}
                    </div>
                  ) : (
                    <p className="ml-4">No Time Preferences Selected</p>
                  )}
                </div>
                <Button
                  variant="contained"
                  disabled={isAddTimeBtnDisabled}
                  sx={{ position: "relative", left: "35%" }}
                  onClick={() => {
                    if (isItEditing !== true) {
                      addNewGenericTimeSlots(
                        time.hrs,
                        time.min,
                        time.merd,
                        slots,
                        checkedDaysGenericSchedule
                      );
                    } else {
                      editGenericTimeSlots(
                        checkedDaysGenericSchedule,
                        oldTimeWhileEditingASlot.start,
                        oldTimeWhileEditingASlot.end,
                        time.hrs,
                        time.min,
                        time.merd,
                        slots
                      );
                      handleCloseGenericSchedulePopup();
                      return;
                    }
                    handleCloseGenericSchedulePopup();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
        {/* </Draggable> */}
        <div className="flex justify-center mt-5 mb-1">
          {slotwiseGenericSchd.mon?.length !== 0 ||
            slotwiseGenericSchd.tue?.length !== 0 ||
            slotwiseGenericSchd.wed?.length !== 0 ||
            slotwiseGenericSchd.thu?.length !== 0 ||
            slotwiseGenericSchd.fri?.length !== 0 ||
            slotwiseGenericSchd.sat?.length !== 0 ||
            slotwiseGenericSchd.sun?.length !== 0 ? (
            <div className="flex gap-2 mt-2">
              <Button
                variant="contained"
                onClick={handleOpenGenericSchedulePopup}
              >
                + Add More Time Preferences
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={handleOpenGenericSchedulePopup}
            >
              Add Time Preferences
            </Button>
          )}
        </div>
      </div>

      {/* Date-wise Time Preference */}
      {slotwiseGenericSchd.mon?.length !== 0 ||
        slotwiseGenericSchd.tue?.length !== 0 ||
        slotwiseGenericSchd.wed?.length !== 0 ||
        slotwiseGenericSchd.thu?.length !== 0 ||
        slotwiseGenericSchd.fri?.length !== 0 ||
        slotwiseGenericSchd.sat?.length !== 0 ||
        slotwiseGenericSchd.sun?.length !== 0 ||
        excpDatesBoolArr?.length !== 0 ? (
        <div className="border border-gray-500 rounded-md p-2">
          <Typography
            variant="h6"
            sx={{
              color: "green",
            }}
            className="s-desktop-card"
          >
            View/Edit Time Preferences (Date-wise)
          </Typography>
          <Typography
            sx={{
              color: "green",
            }}
            className="s-mobile-card"
            style={{ fontSize: '18px' }}
          >
            View/Edit Time Preferences<br />(Date-wise)
          </Typography>
          {excpDatesBoolArr?.length !== 0 ? (
            <div className="mx-auto my-2 text-center flex justify-center max-[600px]:flex-col s-mobile-align-start">
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                Same as Day-wise Schedule
              </div>
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-yellow-500"></div>
                Different from Day-wise Schedule
              </div>
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                Marked as a Holiday
              </div>
            </div>
          ) : null}
          <div
            className="grid grid-cols-3 mt-3 mx-3 items-start max-[600px]:flex flex-col max-[600px]:items-stretch max-[600px]:mx-0"
          >
            {/* History */}
            <div
              className="col-span-1 p-1 border-2 rounded-md h-full custom-scroll s-desktop-card"
              style={{ height: "300px" }}
            >
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", textAlign: "center" }}
              >
                Revised Schedule
                <Tooltip
                  title={`Summary of the dates for which you have edited your time preferences`}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <div className="overflow-y-auto py-2" style={{ height: "250px" }}>
                {excpDatesSlotsArr?.length !== 0 ? (
                  excpDatesSlotsArr
                    .sort(function (a, b) {
                      var aa = a.date.split("/").reverse().join(),
                        bb = b.date.split("/").reverse().join();
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    })
                    .map((item) => (
                      <div className="w-60 rounded-md border my-1 flex gap-2 p-2 mx-auto">
                        <div className="h-6 w-6 rounded-full bg-gray-500"></div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              color: "#6844c9",
                            }}
                          >
                            {item.date}
                          </Typography>
                          {item.slots?.length !== 0 ? (
                            item.slots.map((slot) => (
                              <Typography variant="subtitle1">
                                {slot.final[0]} - {slot.final[1]}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="subtitle1">Holiday</Typography>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="h-full flex justify-center">
                    <p>No edits made so far</p>
                  </div>
                )}
              </div>
            </div>

            {/* Calender */}
            <div className="col-span-1 flex items-center justify-center s-desktop-card">
              <Calendar
                minDate={new Date().setDate(new Date().getDate())}
                zIndex={0}
                value={calenderDate}
                onMonthChange={(date) => {
                  // console.log(date.getMonth());
                  setCalenderDate(new Date(date));
                }}
                onChange={(e) => {
                  setCalenderDate(new Date(e));
                }}
                mapDays={({ date, today, selectedDate, isSameDate }) => {
                  let props = {};
                  props.style = {
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: 700,
                  };
                  if (isSameDate(date, today)) {
                    const currentSelectedDate = new Date();
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    return props;
                  }

                  if (isSameDate(date, selectedDate)) {
                    const currentSelectedDate = new Date(date);
                    props.style = {
                      backgroundColor: "#fff",
                      color: "#000",
                      border: "1px solid #000",
                    };
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    return props;
                  }
                  // Color of Passed out days
                  // if ([4, 5, 6, 7].includes(date.day)) {
                  //   return { className: "text-red-500" };
                  // }
                  // if ([10, 11, 12, 13].includes(date.day)) {
                  //   return { className: "text-yellow-500" };
                  // }
                  // if ([15, 16, 17].includes(date.day)) {
                  //   return { className: "text-green-500" };
                  // }
                  if (red.includes(date.day)) {
                    return { className: "bg-red-500" };
                  }
                  if (yellow.includes(date.day)) {
                    return { className: "bg-yellow-500" };
                  }
                  if (green.includes(date.day)) {
                    return { className: "bg-green-500" };
                  }
                }}
              />
            </div>

            {/* Mobile Calender */}
            <div className="s-mobile-card" style={{ flexDirection: 'column', position: 'relative', flex: 1 }}>
              <div className="flex flex-row whitespace-nowrap gap-[0.5rem]" style={{ padding: '10px 50px' }}>
                <span style={{ transform: 'translateY(-5px)' }}>Select date : </span>
                <div style={{ width: '100%', marginBottom: '0.5rem' }} class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button"><AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'inherit' }} /> {calenderDate !== ""
                  ? moment(calenderDate).format("DD MMM YYYY") + " "
                  : "Select Date"}</div>
              </div>

              <AnimatePresence>
                {
                  // datePickerMobile && <Calendar
                  //   className="s-mobile-calendar-calendar"
                  //   minDate={new Date().setDate(new Date().getDate())}
                  //   zIndex={1}
                  //   value={calenderDate}
                  //   onChange={(e) => { setCalenderDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                  //   mapDays={({ date, calenderDate, isSameDate }) => {
                  //     let props = {};
                  //     if (isSameDate(date, calenderDate)) {
                  //       props.style = {
                  //         color: "#fff",
                  //         backgroundColor: "#00a86b",
                  //         border: "1px solid #000"
                  //       };
                  //       return props;
                  //     }
                  //   }}
                  // />
                  datePickerMobile && <motion.div

                    initial={{ height: '0px', opacity: 0, marginBottom: '0rem', x: "-10vh" }}
                    animate={{ height: '250px', marginBottom: '0.5rem', opacity: 1, x: "0vw" }}
                    exit={{ height: '0px', opacity: 0, marginBottom: '0rem', x: "10vh" }}
                    transition={{
                      type: "spring",
                      damping: 10,
                      stiffness: 100
                    }}
                    style={{ position: 'relative', zIndex: 10, display: 'flex', justifyContent: 'center' }}
                  >

                    <Calendar
                      style={{ marginLeft: '25px' }}
                      minDate={new Date().setDate(new Date().getDate())}
                      zIndex={1}
                      className=""

                      value={calenderDate}
                      onMonthChange={(date) => {
                        // console.log(date.getMonth());
                        setCalenderDate(new Date(date));
                      }}
                      onChange={(e) => {
                        setCalenderDate(new Date(e));
                      }}
                      mapDays={({ date, today, selectedDate, isSameDate }) => {
                        let props = {};
                        props.style = {
                          backgroundColor: "#fff",
                          color: "#000",
                          fontWeight: 700,
                        };
                        if (isSameDate(date, today)) {
                          const currentSelectedDate = new Date();
                          green.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(72 187 120)",
                                color: "#fff",
                                fontWeight: 500,
                              };
                            }
                          });
                          yellow.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(236 201 75)",
                                color: "#fff",
                                fontWeight: 500,
                              };
                            }
                          });
                          red.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(245 101 101)",
                                color: "#fff",
                                fontWeight: 500,
                              };
                            }
                          });
                          return props;
                        }

                        if (isSameDate(date, selectedDate)) {
                          const currentSelectedDate = new Date(date);
                          props.style = {
                            backgroundColor: "#fff",
                            color: "#000",
                            border: "1px solid #000",
                          };
                          green.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(72 187 120)",
                                color: "#000",
                                border: "1px solid #000",
                              };
                            }
                          });
                          yellow.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(236 201 75)",
                                color: "#000",
                                border: "1px solid #000",
                              };
                            }
                          });
                          red.map((item) => {
                            if (currentSelectedDate.getDate() === item) {
                              props.style = {
                                backgroundColor: "rgb(245 101 101)",
                                color: "#000",
                                border: "1px solid #000",
                              };
                            }
                          });
                          return props;
                        }
                        // Color of Passed out days
                        // if ([4, 5, 6, 7].includes(date.day)) {
                        //   return { className: "text-red-500" };
                        // }
                        // if ([10, 11, 12, 13].includes(date.day)) {
                        //   return { className: "text-yellow-500" };
                        // }
                        // if ([15, 16, 17].includes(date.day)) {
                        //   return { className: "text-green-500" };
                        // }
                        if (red.includes(date.day)) {
                          return { className: "bg-red-500" };
                        }
                        if (yellow.includes(date.day)) {
                          return { className: "bg-yellow-500" };
                        }
                        if (green.includes(date.day)) {
                          return { className: "bg-green-500" };
                        }
                      }}
                    />
                    <AiOutlineCloseCircle onClick={() => { setDatePickerMobile(!datePickerMobile) }} style={{ fontSize: '1.5rem', color: 'red' }} />
                  </motion.div>
                }
              </AnimatePresence>
            </div>
            {/* {
                  calenderDate !== "" && <button style={{ width: 'auto' }} class="button-13" onClick={() => setCalenderDate("")} ><MdDeleteForever style={{ display: 'inline-block', fontSize: '1rem' }} /></button>
                } */}


            {/* <div className="col-span-1 flex items-center justify-center s-desktop-card">
              <Calendar
                minDate={new Date().setDate(new Date().getDate())}
                zIndex={0}
                value={calenderDate}
                onMonthChange={(date) => {
                  // console.log(date.getMonth());
                  setCalenderDate(new Date(date));
                }}
                onChange={(e) => {
                  setCalenderDate(new Date(e));
                }}
                mapDays={({ date, today, selectedDate, isSameDate }) => {
                  let props = {};
                  props.style = {
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: 700,
                  };
                  if (isSameDate(date, today)) {
                    const currentSelectedDate = new Date();
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    return props;
                  }

                  if (isSameDate(date, selectedDate)) {
                    const currentSelectedDate = new Date(date);
                    props.style = {
                      backgroundColor: "#fff",
                      color: "#000",
                      border: "1px solid #000",
                    };
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    return props;
                  }
                  // Color of Passed out days
                  // if ([4, 5, 6, 7].includes(date.day)) {
                  //   return { className: "text-red-500" };
                  // }
                  // if ([10, 11, 12, 13].includes(date.day)) {
                  //   return { className: "text-yellow-500" };
                  // }
                  // if ([15, 16, 17].includes(date.day)) {
                  //   return { className: "text-green-500" };
                  // }
                  if (red.includes(date.day)) {
                    return { className: "bg-red-500" };
                  }
                  if (yellow.includes(date.day)) {
                    return { className: "bg-yellow-500" };
                  }
                  if (green.includes(date.day)) {
                    return { className: "bg-green-500" };
                  }
                }}
              />
            </div> */}

            {/* Slots List of Selected Date */}
            <div
              className="col-span-1 h-full px-3 rounded-md border-2"
              style={{ paddingBottom: '0.5rem' }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 1,
                  mb: 1,
                }}
                className="s-mobile-main-h"
              >
                {moment(calenderDate).format("dddd, MMMM Do")}
              </Typography>
              {selectedDateSlotsArr?.length !== 0 ? (
                <div
                  className="relative p-1 my-1 mb-2 rounded overflow-y-auto box-shadow-1 flex justify-between max-[600px]:px-0 max-[600px]:p-[0.5rem]"
                  style={{ maxHeight: "155px", alignItems: "flex-start" }}
                >
                  <div className="mx-2 max-[600px]:flex max-[600px]:flex-col max-[600px]:gap-[0.5rem] max-[600px]:mr-0">
                    {selectedDateSlotsArr !== [] ? (
                      selectedDateSlotsArr.map((item, index) => {
                        return (
                          <div
                            className="flex justify-between text-md rounded-full border border-black bg-teal-100 p-2 mb-1 mx-auto text-black max-[600px]:mb-0 max-[600px]:rounded max-[600px]:gap-[0.3rem]"
                            style={{}}
                          >
                            <p className="mx-auto">
                              {item.final[0]} - {item.final[1]}
                            </p>
                            <div
                              className="mx-1 cursor-pointer text-black hover:text-blue-600"
                              onClick={() => {
                                setIsItEditing(true);
                                handleAddTimePopupInCustomSchdOpen();
                                setTime({
                                  hrs: item.final[0].slice(0, 2),
                                  min: item.final[0].slice(3, 5),
                                  merd: item.final[0].slice(6, 8),
                                });
                                setSlots(item.init[1] - item.init[0] + 1);
                                setOldTimeWhileEditingCustomSlot({
                                  array: item,
                                  hrs: item.final[0].slice(0, 2),
                                  min: item.final[0].slice(3, 5),
                                  merd: item.final[0].slice(6, 8),
                                  start: item.init[0],
                                  end: item.init[1],
                                  index: index,
                                });
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </div>
                            <div
                              className="mx-1 cursor-pointer text-black hover:text-blue-600"
                              onClick={() => {
                                deleteCustomSlot(
                                  calenderDate,
                                  item.init[0],
                                  item.init[1]
                                );
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="my-2 mx-auto text-center">
                        No Time Preferences Specified
                      </p>
                    )}
                  </div>
                  <div
                    className="mx-1 max-[600px]:mx-0 s-mobile-sticky-btn-i-profile"
                    onClick={() => {
                      excpDateForGnrcSchedule(calenderDate);
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      size="large"
                      sx={{ p: 0, minWidth: 0 }}
                      className='s-mobile-delete-btn-i-profile'
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              ) : (
                <p className="my-2 mx-auto text-center">
                  No Time Preferences Specified
                </p>
              )}
              <div className="flex flex-col justify-center items-center mt-3">
                {customSchdButtons ? (
                  <Button
                    sx={{ mx: "auto" }}
                    onClick={() => {
                      resetToGenrcSchd(calenderDate);
                    }}
                  >
                    Reset to Default
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  sx={{ mx: "auto" }}
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={handleAddTimePopupInCustomSchdOpen}
                >
                  {selectedDateSlotsArr?.length !== 0
                    ? "+ Add More Time Preferences"
                    : "Add Time Preferences"}
                </Button>
              </div>
            </div>
            <div
              className="col-span-1 p-1 border-2 rounded-md h-full custom-scroll s-mobile-card mt-[1rem] max-[600px]:flex-col"
              style={{ maxHeight: "300px" }}
            >
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", textAlign: "center", fontSize: '18px' }}
              >
                Revised Schedule
                {/* <Tooltip
                  title={`Summary of the dates for which you have edited your time preferences`}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip> */}
                <div style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                  <div className="tt ml-auto">
                    <InfoOutlined
                      sx={{ color: "rgb(83, 81, 81)", fontSize: "20px", marginLeft:'0.5rem' }}
                    />
                    <span style={{ background: 'black', color: 'white', padding: '3px 5px',top:'-38px' }} className="tttext">
                      {`Summary of the dates for which you have edited your time preferences`}
                    </span>
                  </div>
                </div>
              </Typography>
              <div className="overflow-y-auto py-2" style={{ maxHeight: "250px" }}>
                {excpDatesSlotsArr?.length !== 0 ? (
                  excpDatesSlotsArr
                    .sort(function (a, b) {
                      var aa = a.date.split("/").reverse().join(),
                        bb = b.date.split("/").reverse().join();
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    })
                    .map((item) => (
                      <div className="w-60 rounded-md border my-1 flex gap-2 p-2 mx-auto">
                        <div className="h-6 w-6 rounded-full bg-gray-500"></div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              color: "#6844c9",
                            }}
                          >
                            {item.date}
                          </Typography>
                          {item.slots?.length !== 0 ? (
                            item.slots.map((slot) => (
                              <Typography style={{ fontSize: '14px' }} variant="subtitle1">
                                {slot.final[0]} - {slot.final[1]}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="subtitle1">Holiday</Typography>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="h-full flex justify-center">
                    <p>No edits made so far</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Dialog
            open={openAddTimePopupInCustomSchd}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ backdropFilter: 'blur(5px)' }}
          >
            <Box
              sx={{
                maxWidth: 321,
                width: '100%',
                bgcolor: "background.paper",
                boxShadow: 24,
                overflow: "hidden",
                // backgroundColor: "red",

              }}
            >
              <DialogTitle
                style={{ cursor: "move", padding: "0", width: '100%' }}
                id="draggable-dialog-title"
              >
                <div className="p-2 bg-blue-500 flex justify-between">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      ml: 1.5,
                    }}
                    className="s-mobile-main-h"
                  >
                    {isItEditing
                      ? "Edit Time Preferences"
                      : "Add Time Preferences"}
                  </Typography>
                  <div
                    onClick={() => {
                      handleAddTimePopupInCustomSchdClose();
                      console.log('close')
                    }}
                  >
                    <IconButton aria-label="delete" size="small">
                      <CancelIcon
                        fontSize="medium"
                        sx={{ color: "white", "&:hover": { color: "red" } }}
                      />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <div className="p-2">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Select Time Preferences
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#F87474",
                    textAlign: "center",
                  }}
                >
                  {moment(calenderDate).format("Do MMM YYYY")}
                </Typography>

                <div className="flex gap-3 mt-[0.7rem]">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="s-mobile-profile-text"
                  >
                    Available From
                  </Typography>
                  <div className="flex" id="time-picker">
                    <select
                      className="text-center"
                      name="hours"
                      id="hours time-picker"
                      value={time.hrs}
                      onChange={(e) => {
                        setTime({ ...time, hrs: e.target.value });
                      }}
                    >
                      <option value="12">12</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                    <p className="mx-1">:</p>
                    <select
                      className="text-center"
                      name="min"
                      id="min time-picker"
                      value={time.min}
                      onChange={(e) => {
                        setTime({ ...time, min: e.target.value });
                      }}
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                    <p className="mx-1"> </p>
                    <select
                      className="text-center"
                      name="merd"
                      id="merd time-picker"
                      value={time.merd}
                      onChange={(e) => {
                        setTime({ ...time, merd: e.target.value });
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="flex gap-3 mt-[0.7rem] mr-[98px] max-[600px]:mr-[94px]" id="time-picker">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="s-mobile-profile-text"
                  >
                    No. of Mock Interviews
                  </Typography>
                  <select
                    name="slots"
                    id="slots-select"
                    className="text-center "
                    value={slots}
                    onChange={handleSlotsChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                  {/* <Tooltip
                    title={`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip> */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="tt ml-auto">
                      <InfoOutlined
                        sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                      />
                      <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-55px' ,right:`${window.innerWidth<600?'-80px':'0px'}`}} className="tttext">
                        {`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                      </span>
                    </div>
                  </div>

                  {/* <div style={{display:'flex', justifyContent:'flex-end'}}>
                <div className="tt ml-auto">
                <InfoOutlined sx={{color:'rgb(83, 81, 81)', fontSize:'20px'}}/>
                      <span className="tttext">By keeping an account at interwiu.com, you agree to our Terms and Conditions and Privacy Policy</span>
                  </div>
                </div> */}
                </div>
                <div className="border-2 border-blue-500 rounded-lg my-4">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      ml: 2,
                    }}
                    className="s-mobile-profile-text"
                  >
                    Selected Time Preferences
                  </Typography>
                  {slots !== 0 ? (
                    <div className="my-2 ml-4">
                      {getTimeSlots(
                        time.hrs + ":" + time.min + " " + time.merd,
                        slots
                      )}
                    </div>
                  ) : (
                    <p className="ml-4">No Time Preferences Selected</p>
                  )}
                </div>
                <Button
                  variant="contained"
                  sx={{ position: "relative", left: "35%" }}
                  disabled={slots === 0}
                  onClick={() => {
                    if (isItEditing !== true) {
                      addNewCustomSlot(
                        calenderDate,
                        time.hrs,
                        time.min,
                        time.merd,
                        slots
                      );
                    } else {
                      editCustomSlot(
                        calenderDate,
                        oldTimeWhileEditingCustomSlot.start,
                        oldTimeWhileEditingCustomSlot.end,
                        time.hrs,
                        time.min,
                        time.merd,
                        slots
                      );
                      handleAddTimePopupInCustomSchdClose();
                      return;
                    }
                    handleAddTimePopupInCustomSchdClose();
                  }}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Dialog>
        </div>
      ) : null}
    </div>
  );
};

export default TimePreference;
