import React, { useState, useEffect, useContext, useRef } from "react";
import "./InterviewerRequest.css";
import { Button } from "@mui/material";
import axios from "axios";
import { Helmet } from "react-helmet";
import Accordian from "./cards/Accordian";
import { BASE } from "../../constants/endpoints";
import { COMPANY_NAME } from "../../constants/variables";
import { Oval } from "react-loader-spinner";
import UserContext from "../../contexts/UserContext";
import { calculateCount, compare, t24HDecimalTo12HFormat, toastStyle } from "../../utility/helper";
import toast from "react-hot-toast";
import Pagination from "@mui/material/Pagination";
import moment from "moment-timezone";

const InterviewerRequest = () => {
  const { user } = useContext(UserContext);
  const [recievedRequest, setrecievedRequest] = useState([]);
  const [SentStudents, setSentStudents] = useState([]);
  const [cards, setCards] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const rendered = useRef(false);
  const rendered2 = useRef(false);
  const [stack, setStack] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const params = {
    reqStatus: "ReqSent",
    userRole: "Interviewer",
  };
  const params2 = {
    reqStatus: "ReqPaid",
    userRole: "Interviewer",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          if (rendered.current) return;
          console.log(response)
          setrecievedRequest(req => [...req, ...response.data.Requests]);
          setSentStudents(req => [...req, ...response.data.SentInterViewers]);
          rendered.current = true;
          // console.log(response.data.Requests)
          // setCards(cards=> [...cards, ...response.data.Requests]);

          // let ints = response.data.Requests;

          // ints?.map((int) => {
          //   axios.get(`${BASE}/send/interview/recievedInterviewRequestById/${int.reqId}`)
          //   .then((response) => {
          //     console.log(response.data);
          //   }).catch((err) => {
          //     console.log(err);
          //   })
          // })

        });
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params: params2 })
        .then((response) => {
          if (rendered2.current) return;
          for (let i = 0; i < response.data.Requests.length; i++) {
            console.log(response.data.Requests[i]);
            if (response.data.Requests[i].reschedule.isRescheduled === 'true' || response.data.Requests[i].reschedule.isRescheduled === true) {
              console.log('RESCHEDULED', response.data.Requests[i]);
              console.log('RESCHEDULED INT', response.data.SentInterViewers[i]);
              setrecievedRequest(req => [...req, response.data.Requests[i]]);
              setSentStudents(req => [...req, response.data.SentInterViewers[i]]);
              // setCards(cards => [...cards, response.data.Requests[i]]);
            }
          }
          rendered2.current = true;
          setLoaded(true);
        }).catch((e) => { console.log(e) })
    }
    fetchData();
  }, []);

  console.log('recievedRequesets', recievedRequest)
  console.log('cards', cards)

  useEffect(() => {

    setCards(recievedRequest)
  }, [recievedRequest]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);

  const stacking = () => {
    // setStack([])
    let localCards = [];
    console.log('😀cards')

    cards.map((item, index) => {
      let c1 = false;
      console.log('😀cardsnext', item.fromTime)
      if (localCards?.length === 0) {
        localCards.push({
          slot: item.fromTime,
          date: item.date,
          array: [{ requests: item, students: SentStudents[index] }],
        });
        c1 = true;
      }
      if (c1 === false) {
        let check = false;
        localCards.map((cItem, cindex) => {
          if ((cItem.slot === item.fromTime && cItem.date === item.date) || (item.reschedule.isRescheduled == 'true' && (cItem.slot === item.reschedule.details.reschFromTime && cItem.date === item.reschedule.details.reschDate))) {
            localCards[cindex].array.push({
              requests: item,
              students: SentStudents[index],
            });
            check = true;
          }
        });
        if (!check) {
          localCards.push({
            slot: item.fromTime,
            date: item.date,
            array: [{ requests: item, students: SentStudents[index] }],
          });
        }
      }
    });
    setStack(localCards);
    console.log('stackkk', stack)
  };
  // Removing the card on Accepting or Declining the request
  const remove = (first, second) => {
    let localCards = [...stack];
    localCards[first].array.splice(second, 1);
    setStack(localCards);
  };
  const removeEntire = (first) => {
    let localCards = [...stack];
    localCards.splice(first, 1);
    setStack(localCards);
  };
  useEffect(() => {
    stacking();
  }, [cards]);
  useEffect(() => {
    console.log('cards................', cards);
  }, [cards]);
  useEffect(() => {
    console.log(stack);
  }, [stack]);
  const variable = calculateCount(recievedRequest)
  // stacking()

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, recievedRequest]);
  console.log(stack)

  const acceptReq = async (requests) => {
    const obj = {
      status: "ReqAccepted",
    };
    const reqId = requests.reqId
    const isRescheduled = requests.reschedule.isRescheduled
    const reschedule = requests.reschedule
    try {
      if ((isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent") {
        const response = await axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
          status: "accepted",
          reschDetails: reschedule.details
        })
        const responseAutoReject = await axios.patch(
          `${BASE}/send/autorejectrequests/${reqId}`
        )
        return true;
      } else {
        const response = await axios.patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
        return true;
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }


  const acceptAllFunction = async () => {
    const obj = {
      requests: recievedRequest,
    };
    console.log('😴2', obj);
    for (let i = 0; i < recievedRequest.length; i++) {
      let re = await acceptReq(recievedRequest[i]);
      if (!re) {
        toast.success('Some Error Occurred', {
          ...toastStyle.error,
          position: "top-center",
          style: { border: '1px solid #713200', padding: '16px', color: '#fff' }
        });
        console.log('😴3', re);
        return;
      }
    }
    toast.success('All Requests Accepted Successfully for this Slot', {
      ...toastStyle.success,
      position: "top-center",
      style: {
        // marginTop:'30%',
        border: '1px solid #713200',
        padding: '16px',
        color: '#fff',
        backgroundColor: '#0e8f11'
      }
    });
    setTimeout(() => {
      window.location.reload(false);
    }, 3000);
  }


  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Recieved Request</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className="interviews min-h-screen">
        <div className="flex justify-center items-center mb-4" id="head">
          <h2 className="interview-head text-2xl font-medium">
            Received Requests (
            {recievedRequest
              ? page * 10 -
              9 +
              " - " +
              (variable > page * 10
                ? page * 10
                : variable)
              : null}
            ) out of ({recievedRequest ? variable : 0})
          </h2>
        </div>
        {loaded ? (<>
          <div className="flex justify-between mx-8 s-mobile-flex-dir-col" style={{ gap: '0.6rem' }}>
            <div>
              Showing the results for {date ? date.toDateString() : "all days"}
            </div>
            {recievedRequest?.length !== 0 && (
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: "10px" }}
                style={{ fontWeight: "800" }}
                onClick={() => { acceptAllFunction() }}
              //   const obj = {
              //     requests: recievedRequest,
              //   };
              //   console.log('😴',obj);
              //   axios
              //     .patch(`${BASE}/send/acceptAllRequests`, obj)
              //     .then((response) => {
              //       console.log(response);
              //       toast.success("All Requests Accepted Successfully", {
              //         ...toastStyle.success,
              //         position: "top-center",
              //         style: {
              //           // marginTop:'30%',
              //           border: "1px solid #713200",
              //           padding: "16px",
              //           color: "#fff",
              //           backgroundColor: "#0e8f11",
              //         },
              //       });
              //       setTimeout(() => {
              //         window.location.reload(false);
              //       }, 3000);
              //     });
              // }}
              >
                Accept All Requests
              </Button>
            )}
            {/*
              <div className="flex gap-2">
                <label htmlFor="date">Filter by Date: </label>
                <DatePicker
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  value={date}
                />
              </div>
            */}

          </div>
          <div className="mx-auto" style={{ maxWidth: '900px' }}>
            {stack?.slice(pageNo[0], pageNo[1]).map((item, index) => {
              console.log('stack...................', item);
              if (item?.length === 0) {
                return <></>;
              }
              return (
                <Accordian
                  array={item.array}
                  index={index}
                  stack={stack}
                  setStack={setStack}
                  remove={remove}
                  removeEntire={removeEntire}
                  isAccepted={false}

                />
              );
            })}

            {/* Pagination */}
            <div className="flex flex-row justify-center mt-4 my-2">
              <Pagination
                count={Math.ceil((variable) / 10)}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </div>
          </div>
        </>) : (<div className="fullPageLoader">
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>)}
      </div>
    </>
  );
};

export default InterviewerRequest;
