import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./index.css";
// import 'tailwindcss/dist/tailwind.css';
import VideoCall from "./components/VideoCall/Video";
import Validator from "./components/VideoCall/Validator/Validator";
import StudentEnd from "./components/VideoCall/CallEndPages/StudentEnd";
import InterviewerEnd from "./components/VideoCall/CallEndPages/InterviewerEnd";
import InterviewerMidEnd from "./components/VideoCall/MidEndPages/InterviewerMidEnd";
import StudentMidEnd from "./components/VideoCall/MidEndPages/StudentMidEnd";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ErrorBoundary } from "react-error-boundary";

function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  console.log(error)
  // setTimeout(() => {

  // }, 1000)
  useEffect(() => {
    window.location.reload()
  }, [])
  
  // resetErrorBoundary()
  return (
    <div role="alert" className="h-screen w-full flex justify-content justify">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StyledEngineProvider injectFirst>
  <ErrorBoundary fallback={Fallback}>
    <BrowserRouter>
        <Routes>
          {/* VIDEOCALL PATHS */}
          {/* <Route path="/valid/vc/:mock_id/:id/:name" element={<Validator />} />
          <Route path="/vc/:mock_id/:id/:name" element={<VideoCall />} />
          <Route path="/end/student/vc/:mock_id" element={<StudentEnd />} />
          <Route path="/end/interviewer/vc/:mock_id" element={<InterviewerEnd />} />
          <Route path="/midend/student/vc/:mock_id" element={<StudentMidEnd />} />
          <Route path="/midend/interviewer/vc/:mock_id" element={<InterviewerMidEnd />}/> */}
          <Route path="/*" element={<App />} />
        </Routes>
        {/* APP PATHS */}

        {/* <App /> */}
        <Toaster

          position="top-right"
          toastOptions={{ className: "react-hot-toast" }}
        />
      </BrowserRouter>
      </ErrorBoundary>
  // </StyledEngineProvider>
);
