import axios from "axios";
import moment from "moment/moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BASE } from "../../../constants/endpoints";
import UserContext from "../../../contexts/UserContext";
import {
  compare,
  getCompanyLogo,
  t24HDecimalTo12HFormat,
} from "../../../utility/helper";
import DeclinedRequest from "../cards/DeclinedRequest";
import Navbarlogedin from "../Navbarlogedin";

const DeclinedRequests = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [declinedRequest, setdeclinedRequest] = useState([]);
  const [SentInterViewers, setSentInterViewers] = useState([]);
  const rendered3 = useRef(false);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqDeclined",
    userRole: "Student",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response);
          setdeclinedRequest(response.data.Requests);
          setSentInterViewers(response.data.SentInterViewers);
          console.log(response.data.Requests);
          setLoaded(true);
        });
    }
    fetchData();
  }, []);
  return (
    <>
      <Navbarlogedin />
      <div className="interviews" id="declined-request">
        <div className="interviews-container">
          <div
            className="flex justify-between items-center mt-2 ml-1 mr-1"
            id="head"
          >
            <div
              className="text-blue-500 cursor-pointer font-medium hover:text-red-600"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="fa-solid fa-angle-left"></i> Back
            </div>
            <h2 className="interview-head text-2xl font-medium">
              Declined Requests ({declinedRequest?.length})
            </h2>
            <div> </div>
          </div>
          {loaded ? (
            <div className="interviews-cont">
              <div
                className="interviews-list"
                style={{  margin: "0 auto" }}
              >
                {declinedRequest?.map((result, index) => {
                  
                  console.log(result,"------------");
                  return (
                  <>
                    <DeclinedRequest
                      user={user}
                      name={
                        SentInterViewers[index]?.fname +
                        " " +
                        SentInterViewers[index]?.lname
                      }
                      desg={SentInterViewers[index]?.designation}
                      price="499"
                      profile={result.profile}
                      subprofile={result.subprofile}
                      ccompany={SentInterViewers[index]?.currCompany}
                      slotStart={declinedRequest[index]?.fromTime}
                      slotEnd={declinedRequest[index]?.toTime}
                      status={declinedRequest[index]?.reqStatus}
                      id={SentInterViewers[index]?.id}
                      date={declinedRequest[index]?.date}
                      image={SentInterViewers[index]?.photo}
                      logo={getCompanyLogo(
                        SentInterViewers[index]?.currCompany
                      )}
                      suggestedSlotStart={
                        declinedRequest[index]?.suggestedTime?.fromTime
                      }
                      suggestedSlotEnd={
                        declinedRequest[index]?.suggestedTime?.toTime
                      }
                      suggestedDate={declinedRequest[index]?.suggestedDate}
                      reqId={declinedRequest[index]?.reqId}
                      mockId={declinedRequest[index]?.mockId}
                      isRescheduled={
                        declinedRequest[index].reschedule?.reason ||
                        declinedRequest[index].reschedule?.desc ||
                        declinedRequest[index].reschedule?.count === 1
                      }
                      
                      
                    />
                  </>
                )
                    })}
              </div>
            </div>
          ) : (
            <div className="fullPageLoader">
              <Oval
                height={120}
                width={120}
                color="#306bc9"
                wrapperStyle={{ padding: "50%, 50%" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#90caf9"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeclinedRequests;
