import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";

import {
  BASE,
  VIDEO_CALL_UPLOAD_FILE,
  VIDEO_CALL_GET_TIMER,
  VIDEO_CALL_GET_CHATS,
  VIDEO_CALL_GET_FILE,
  GET_STUDENT_INTERVIEWS,
  GET_INTERVIEWER_INTERVIEWS,
  FEEDBACK,
} from "../../constants/endpoints";
import { socket, peer, giveTime, giveYears, getImages, getProgram } from "./utils";
import axios from "axios";
import {
  useParams,
  useSearchParams,
  Link,
  useNavigate,
} from "react-router-dom";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Box, IconButton, Typography } from "@mui/material";
import {
  StopScreenShareOutlined,
  PresentToAll,
  CallEnd,
  Logout,
  Notifications,
  Close,
} from "@mui/icons-material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  faBell,
  faClose,
  faComment,
  faFileArrowUp,
  faFileCircleXmark,
  faMicrophone,
  faMicrophoneAltSlash,
  faPaperclip,
  faPaperPlane,
  faPenToSquare,
  faShapes,
  faVideoCamera,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CreateIcon from "@mui/icons-material/Create";
import { OwnerMessages, PeerMessages } from "./Messages";
import RateAndReview from "./RateAndReview";
import "./Video.css";
import { IoCloseCircle } from "react-icons/io5";

import {
  GET_UPCOMING_MOCK_INTERVIEWS,
  GET_REQUEST_INTERVIEWS,
  GET_STUDENT_INFO,
  GET_INETRVIEWER_INFO,
} from "../../constants/endpoints";
import { downloadPDF, programValues, toastStyle } from "../../utility/helper";
import { toast } from "react-hot-toast";
import UserContext from "contexts/UserContext";

const FRONTEND_BASE = process.env.REACT_APP_FRONTEND_SERVER_URL;

const VideoCall = ({ visibility }) => {
  // const {user: currUser, setUser: setCurrUser} = useContext(UserContext)
  const [myVideoStream, setMyVideoStream] = useState(); //to capture the video stream of the user
  const [mute, setMute] = useState(false); //toggling the mic button
  const [video_div, setVideo] = useState(false); //

  const vid_other = useRef(false); //checking if other's video is on or off
  const selfPic = useRef("");
  const otherPic = useRef("");
  const notificationRef = useRef(false); //checking if notification is on or off

  const [messages, setMessages] = useState([]); //saving messages/chats/filel-links along with the names of people
  const [showMessage, setShowMessage] = useState(true); //toggler for chats button
  const [screenShare, setScreenShare] = useState(false); //toggler for the screen share button
  const [file, setFile] = useState(null); //to store file from chats to be uploaded
  const [screenMedia, setScreenMedia] = useState(); //to capture the screen-share video
  const [idOfUser, setidOfUser] = useState(); //to capture the peerID of the user
  const [seconds, setSeconds] = useState(0); //to store the seconds of interaction
  const [arr, setArr] = useState([0, 0, 0]); //[hours,minutes,seconds] for timer
  const [valid, setValid] = useState(false); //validity of the people attending the interview
  const [endTime, setEndTime] = useState(); //mock interview time limit
  const [searchParams, setSearchParams] = useSearchParams(); //to get the account_id and name of user
  // const user = searchParams.get("name");
  // const accountId = searchParams.get("id");

  const [videoMicTime, setVideoMicTime] = useState({
    mic: 0,
    video: 0,
    screen_share: 0,
  });

  let studentDetails;
  let interviewerDetails;

  const [interviewDetails, setInterviewDetails] = useState(); //to print the details on the top bar
  const [interviewInfo, setInterviewInfo] = useState(); //info about the interview itself
  const [student, setStudent] = useState(); //check whether the person is student or not
  const [notification, setNotification] = useState(false); //if notification has to be shown or not
  const [nextInterviewTime, setNextInterviewTime] = useState(); //time for next interview
  const [newChats, setNewChats] = useState(0)
  const [putting, setPutting] = useState(false)

  const timeout = useRef();

  const [feedback, setFeedback] = useState(false)
  const [rated, setRated] = useState(false)

  const [scrollView, setScrollView] = useState(false)
  const [details, setDetails] = useState({ 
    get: (key) => {
      return key in this ? this[key] : null;
    }
  })

  let images = {
    get: (key) => {
      return key in this ? this[key] : null
    }
  }

  

  const FEEDBACK_TIME = 5//300//1500 

  let getTransferRate; //getting transfer rate

  const navigate = useNavigate(); // navigating to end pages

  const { mock_id: ROOM_ID,id:accountId,name:user } = useParams(); //capturing mock_id of the room created

  // variables for html components
  // variables for html components
  let myVideo;
  let main_right = document.querySelector(".main__right");
  let main_left = document.querySelector(".main__left");
  let send;
  let textDiv;
  let sec;

  let userInterval; //used to set interval function for timer
  let peerId; //store peerId to be used in functions in useEffect

  //checking internet connection
  //checking internet connection
  if (!navigator.onLine) {
    alert("Connection to internet is required");
    // leaveHandler()
    let finalUrl;
    if (accountId.startsWith("S")) {
      // navigate("/midend/student/vc/" + ROOM_ID);
      finalUrl = `${FRONTEND_BASE}/midend/student/vc/${ROOM_ID}`
    } else {
      // navigate("/midend/interviewer/vc/" + ROOM_ID);
      finalUrl = `${FRONTEND_BASE}/midend/interviewer/vc/${ROOM_ID}`
    }
    window.open(finalUrl, "_self") 
  }

  const handleBackButton = (e) => {
    e.preventDefault()
    // Call your functions or perform desired actions here
    if (!showMessage) {
      setShowMessage(true)
      return null
    } else if (showDetails) {
      setShowDetails(false)
      return null
    } else if (!$(".notification").hasClass('hide')) {
      $(".notification").addClass("hide")
      return null
    }
    return (e.returnValue = 'Are you sure you want to leave the meeting?')

  };


  useEffect(() => {
    

    const terminationEvent = 'onpagehide' in window.self ? 'pagehide' : 'unload';

    window.addEventListener(terminationEvent, handleBackButton);

    window.addEventListener('beforeunload', handleBackButton);

    return () => {
      window.removeEventListener('beforeunload', handleBackButton);
      window.removeEventListener(terminationEvent, handleBackButton);
    };
  }, []);

  useEffect(() => {
    $(() => setInterval(() => {
      console.log("interval")
    if (!$(".text__pin").length || $(".text__pin").length < $(".pin__video").length 
  || $(".unpinned__video").children() && $(".unpinned__video").children().not("img").length
   > $(".unpinned__video").children().not("video").length
   || !images["S"] || !images["I"])
      putName(
        accountId,
        ROOM_ID,
        peerId,
        interviewDetails,
        studentDetails,
        interviewerDetails,
        idOfUser,
        user,
        vid_other.current
      )
    }, 2000))
  }, [])

  useEffect(() => {
    console.log("fetchData")
    const fetchData = async () => {
      try {
        const fetchedDetails = await getImages(ROOM_ID)
        // setDetails({
        //   ...details,
        //   [fetchedDetails.interviewer_details.id]: fetchedDetails.interviewer_details,
        //   [fetchedDetails.student_details.id]: fetchedDetails.student_details
        // })
        // interviewerDetails.fname = fetchedDetails.interviewer_details.fname
        // studentDetails.fname = fetchedDetails.student_details.fname
        images[fetchedDetails.interviewer_details.id[0]] = fetchedDetails.interviewer_details.image
        images[fetchedDetails.student_details.id[0]] = fetchedDetails.student_details.image
        interviewerDetails = fetchedDetails.interviewer_details
        studentDetails = fetchedDetails.student_details
      } catch(err) {
        console.log(err)
      }
    }
    if (!interviewerDetails?.fname || !studentDetails?.fname || !images["S"] || !images["I"])
      fetchData() 
    

}, [images["S"], images["I"], interviewerDetails?.fname, studentDetails?.fname])

  const putName = useCallback(async (
    accountId,
     mockId,
      peerId,
       interviewDetails, studentDetails, interviewerDetails, idOfUser, user, vid, effect=false) => {
      // console.log("putname", new Date())
      //name pinning and unpinning
      // if(!effect) return
      // setPutting(true)
      $(".text__name").remove();
      let videoArray = $("video");
      console.log("putname")
      // console.log("before",interviewer_details, student_details, interviewerDetails, studentDetails, interviewDetails)
      // setTimeout(() => {
        let interviewer_details, student_details;
      // if(!details[accountId]) {
      //   ({interviewer_details, student_details} = await getImages(mockId))
      //   setDetails({
      //     ...details,
      //     [interviewer_details.id]: interviewer_details,
      //     [student_details.id]: student_details
      //   })
      // } else {
        
      // }

      
      // },2000)
      console.log(interviewer_details, student_details)
      for (let i = 0; i < videoArray.length; i++) {
        // let userId = videoArray[i].getAttribute("name").split("/")[0]
        // if the video is a screen-share video
        if (videoArray[i].getAttribute("name")?.split("/")[1] === "screen-share") {
          // if ($(videoArray[i].parent().hasClass("unpinned__video"))) {
          //   if (userId === idOfUser) {
          //     $(
          //         `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
          //           i
          //         ].getAttribute("name")}' >${
          //           accountId.startsWith("S")
          //             ? student_details?.fname?.slice(0,6)+"..." || studentDetails?.fname?.slice(0,6)+"..."
          //             : interviewer_details?.fname?.slice(0,6)+"..." ||
          //               interviewerDetails?.fname?.slice(0,6)+"..."
          //         } Screen</p>`
          //       ).insertBefore(videoArray[i]);
          //   } else {
          //     $(
          //       `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
          //         i
          //       ].getAttribute("name")}' >${
          //         accountId.startsWith("I")
          //           ? student_details?.fname?.slice(0,6)+"..." || studentDetails?.fname?.slice(0,6)+"..."
          //           : interviewer_details?.fname?.slice(0,6)+"..." ||
          //             interviewerDetails?.fname?.slice(0,6)+"..."
          //       } Screen</p>`
          //     ).insertBefore(videoArray[i]);
          //   } 
          // }

          // // if the video div has class unpinned i.e. sidebar
          if ($(videoArray[i]).parent().hasClass("unpinned__video")) {  
            if (
              videoArray[i].getAttribute("name")?.split("/")[0] === idOfUser ||
              videoArray[i].getAttribute("name")?.split("/")[0] === peerId
            ) {
              $(
                `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
                  i
                ].getAttribute("name")}' >${
                  accountId.startsWith("S")
                    ? studentDetails?.fname.slice(0,6)+"..."
                    : interviewerDetails?.fname.slice(0,6)+"..."
                } Screen</p>`
              ).insertBefore(videoArray[i]);
            }
            else {
              $(
                `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
                  i
                ].getAttribute("name")}' >${
                  // interviewDetails?.student || studentDetails
                    accountId.startsWith("I")
                    ? studentDetails?.fname.slice(0,6)+"..."
                    : interviewerDetails?.fname.slice(0,6)+"..."
                } Screen</p>`
              ).insertBefore(videoArray[i]);
            }
          } 
          else if (videoArray[i].classList.contains("pin__video")) {
            $(videoArray[i]).prev().remove();
          }
          continue;
        }

        // if the video div is of user
        if (
          (videoArray[i].getAttribute("name") == peerId ||
            videoArray[i].getAttribute("name") == idOfUser) &&
          !videoArray[i].captureStream().getVideoTracks()[0].enabled
        ) {
          if (videoArray[i].classList.contains("pin__video")) {
            // if($(".pin__video").length < $(".text__pin").length)
            $(
              `<p class='text__name text__pin' style='color:white' name='${videoArray[
                i
              ].getAttribute("name")}' >${user}</p>`
            ).insertBefore(videoArray[i]);
          } else if ($(videoArray[i]).parent().hasClass("unpinned__video")) {
            $(
              // `<p class='text__name text__unpin' style='color:white' name='${videoArray[i].getAttribute("name")}' >${user}</p>`
              `<image class="text__name text__unpin" style='color:white' src='${
                accountId.startsWith("S")
                  ? student_details?.image || images["S"]
                  : interviewer_details?.image || images["I"]
              }' name='${videoArray[i].getAttribute("name")}' />`
            ).insertBefore(videoArray[i]);
          } else {
            if($("video").length > $(".text__centerpin").length)
            $(
              `<p class='text__name text__centerpin' style='color:white' name='${videoArray[
                i
              ].getAttribute("name")}' >${accountId.startsWith("S")?
              student_details?.fname : interviewer_details?.fname}</p>`
            ).insertBefore(videoArray[i]);
          }
        } 
        // if the video div is of the other user
        else if (
          videoArray[i].captureStream().getVideoTracks()[0].enabled &&
          videoArray[i].getAttribute("name") != peerId &&
          videoArray[i].getAttribute("name") != idOfUser &&
          !vid
        ) {
          if (videoArray[i].classList.contains("pin__video")) {
            // if($(".pin__video").length < $(".text__pin").length)
            $(
              `<p class='text__name text__pin' style='color:white' name='${videoArray[
                i
              ].getAttribute("name")}' >${
                // interviewDetails?.student || studentDetails
                accountId.startsWith("I")  
                ? student_details?.fname || studentDetails?.fname
                  : interviewer_details?.fname ||
                    interviewerDetails?.fname
              }</p>`
            ).insertBefore(videoArray[i]);
          } else if ($(videoArray[i]).parent().hasClass("unpinned__video")) {
            $(
              // `<p class='text__name text__unpin' style='color:white' name='${videoArray[i].getAttribute("name")}' >${
              //   interviewDetails?.student || studentDetails
              //     ? interviewDetails?.student?.fname || studentDetails?.fname
              //     : interviewDetails?.interviewer?.fname || interviewerDetails?.fname
              // }</p>`
              `<image class="text__name text__unpin" style='color:white' src='${
                accountId.startsWith("I")
                  ? student_details?.image || images["S"]
                  : interviewer_details?.image || images["I"]
              }' name='${videoArray[i].getAttribute("name")}' />`
            ).insertBefore(videoArray[i]);
          } else {
            if($("video").length > $(".text__centerpin").length)
            $(
              `<p class='text__name text__centerpin' style='color:white' name='${videoArray[
                i
              ].getAttribute("name")}' >${
                accountId.startsWith("I")
                // interviewDetails?.student || studentDetails
                  ? student_details?.fname || studentDetails?.fname
                  : interviewer_details?.fname ||
                    interviewerDetails?.fname
              }</p>`
            ).insertBefore(videoArray[i]);
          }
        }
      }
      // setPutting(false)
}, [accountId, images]);
  
  function emitWithPromise(socket, event, data) {
    return new Promise((resolve, reject) => {
      socket.emit(event, (response) => {
        socket.prependAnyOutgoing(async (evt) => {
          if (evt === event || response.data) {
            resolve({message: event + " emit success"})
          } else {
            console.log(response)
            try {
              await socket.timeout(3000).emitWithAck(event)
            } catch (err) {
              reject({...err, message: event + " emit error"})
            }
          }
        }) 
      });
    });
  }
  

  const redirectAfterLeave = () => {
    let finalUrl;
    if (seconds >= FEEDBACK_TIME) {
      if (accountId.startsWith("S")) {
        finalUrl = `/end/student/vc/${ROOM_ID}`
        // navigate("/end/student/vc/" + ROOM_ID)
      } else {
        // navigate("/end/interviewer/vc/" + ROOM_ID)
        finalUrl = `/end/interviewer/vc/${ROOM_ID}`
      }
    } else {
      if (accountId.startsWith("S")) {
        // navigate("/midend/student/vc/" + ROOM_ID);
        finalUrl = `/midend/student/vc/${ROOM_ID}`
      } else {
        // navigate("/midend/interviewer/vc/" + ROOM_ID);
        finalUrl = `/midend/interviewer/vc/${ROOM_ID}`
      }
    }
    // navigate(finalUrl)
    window.open(FRONTEND_BASE + finalUrl, "_blank")
    window.close()
  }

  //useEffect to check the validity of the link
  //useEffect to check the validity of the link
  useEffect(() => {
    const getInter = async () => {
     await axios
        .get(`${GET_UPCOMING_MOCK_INTERVIEWS + "/" + ROOM_ID}`)
        .then((res) => {
          setValid(true);
        })
        .catch((err) => {
          alert("Invalid Mock ID");
          setValid(false);
          leaveHandler();
        });

      // check if the user is valid or not
    await  axios
        .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
        .then((res) => {
          setInterviewInfo(res.data);

          let student_id = res.data.studId;
          let interviewer_id = res.data.interviewerId;

          let fromTime = res.data.fromTime;
          let toTime = res.data.toTime;
          let dateOfInterview = res.data.date;

          let dateArray = res.data.date.split("/");
          let date = new Date();
          let currentDateArray = [
            date.getDate(),
            date.getMonth() + 1,
            date.getFullYear(),
            date.getHours() + date.getMinutes() / 60,
          ];

          //timer function will be written here for highlighting the limit of vc
          //time validity of the link
          if (
            (currentDateArray[0] == dateArray[0] ||
              "0" + currentDateArray[0] == dateArray[0]) &&
            (currentDateArray[1] == dateArray[1] ||
              "0" + currentDateArray[1] == dateArray[1]) &&
            currentDateArray[2] == dateArray[2]
          ) {
            if (
              currentDateArray[3] >= fromTime - 0.2 &&
              currentDateArray[3] <= fromTime + 1.5 &&
              (currentDateArray[0] == dateArray[0] ||
                "0" + currentDateArray[0] == dateArray[0])
            ) {
            } else if (currentDateArray[3] > fromTime + 1.5) {
              alert(
                "THE MEET LINK HAS EXPIRED AS IT IS ALREADY MORE THAN AN HOUR AFTER THE MOCK INTERVIEW SLOT"
              );
              // setValid(false);
              // leaveHandler();
            } else {
              alert(
                "YOU CAN JOIN THE MEET ONLY 10 MINUTES BEFORE THE SCHEDULED TIME"
              );
            }
          } else {
            alert(
              "YOU CAN JOIN THE MEET ONLY 10 MINUTES BEFORE THE SCHEDULED TIME AND DATE"
            );
            // setValid(false);
            // leaveHandler();
          }

          // to check the 1.5 hours time limit
          setInterval(() => {
            let date = new Date();
            let currentDateArray = [
              date.getDate(),
              date.getMonth(),
              date.getFullYear(),
              date.getHours() + date.getMinutes() / 60,
            ];

            // 1.5 hours handler
            // if (currentDateArray[3] > fromTime + 1.5) {
            //   if (
            //     (currentDateArray[0] + 1 == dateArray[0] ||
            // "0" + currentDateArray[0] + 1 == dateArray[0]) ||
            //     ((currentDateArray[0] + 1 == dateArray[0] ||
            // "0" + currentDateArray[0] + 1 == dateArray[0]) &&
            //       24 + currentDateArray[3] > fromTime + 1.5)
            //   ) {
            //     axios.post(`${BASE}/vc/timer/${ROOM_ID}`, {
            //       seconds: Number(sec.attr("name")),
            //     });
            //     alert("Mock Interview time limit exceeded");
            //     // setValid(false);
            //     // leaveHandler();
            //   }
            // }

            // 75 minutes handler
            if (currentDateArray[3] > fromTime + 1.25) {
              if (
                (currentDateArray[0] + 1 == dateArray[0] ||
                  "0" + currentDateArray[0] + 1 == dateArray[0]) ||
                ((currentDateArray[0] + 1 == dateArray[0] ||
                  "0" + currentDateArray[0] + 1 == dateArray[0]) &&
                  24 + currentDateArray[3] > fromTime + 1.25)
              ) {
                setEndTime(fromTime + 1.5);
                $(".timer").css("font-weight", 600);
                $(".timer").css("color", "red");
                $(".header").addClass("redline");
              }
            }
          }, 1000);

          // if user in interviewer
          if (accountId === interviewer_id) {
            setStudent(false);
            setValid(true);

            // storing that interviewer joined the meet
            axios.post(`${BASE}/vc/timer/interviewer/${ROOM_ID}`, {
              interviewer: true,
            });

            // getting upcoming interviews
            axios
              .get(GET_INTERVIEWER_INTERVIEWS + "/" + accountId)
              .then((res) => {
                let time = 1000;

                res.data.map((ele) => {
                  if (
                    ele.date === dateOfInterview &&
                    ele.fromTime <= toTime + 0.5 &&
                    ele.fromTime > fromTime
                  ) {
                    // $(".bell__point").removeClass("hide")
                    time = time > ele.fromTime ? ele.fromTime : time;
                  }

                  if (time != 1000) {
                    setNextInterviewTime(time);
                    setNotification(true);
                  }
                });
              })
              .catch((err) => {
                console.log(err);
              });

            // getting info of student
            axios
              .get(`${GET_STUDENT_INFO}/${student_id}`)
              .then((res) => {
                studentDetails = res.data;
                console.log(studentDetails, "----s-t-u-d--")
                setInterviewDetails({ student: res.data });

                // mic and video timer for interviewer
                axios
                  .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                  .then((res) => {
                    console.log(res.data, "----o-0-9-0--");
                    setVideoMicTime({
                      mic: res.data?.interviewer_mic,
                      video: res.data?.interviewer_video,
                      screen_share: res.data?.interviewer_screen_share,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                alert("Invalid Student ID");
                setValid(false);
                leaveHandler();
              });
          }

          // if user is student
          else if (accountId === student_id) {
            setStudent(true);
            setValid(true);

            // storing that student joined the meet
            axios.post(`${BASE}/vc/timer/student/${ROOM_ID}`, {
              student: true,
            });

            // getting upcoming interviews
            axios
              .get(GET_STUDENT_INTERVIEWS + "/" + accountId)
              .then((res) => {
                let time = 1000;

                res.data.map((ele) => {
                  if (
                    dateOfInterview === ele.date &&
                    ele.fromTime <= toTime + 0.5 &&
                    ele.fromTime > fromTime
                  ) {
                    // $(".bell__point").removeClass("hide")
                    time = time > ele.fromTime ? ele.fromTime : time;
                  }

                  if (time != 1000) {
                    setNextInterviewTime(time);
                    setNotification(true);
                  }
                });
              })
              .catch((err) => {
                console.log(err);
              });

              // getting interviewer info
            axios
              .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
              .then((res) => {
                interviewerDetails = res.data;
                console.log(interviewerDetails, "----i-n-t-w--")

                setInterviewDetails({ interviewer: res.data });

                //mic and video timer for student
                axios
                  .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                  .then((res) => {
                    setVideoMicTime({
                      mic: res.data?.student_mic,
                      video: res.data?.student_video,
                      screen_share: res.data?.student_screen_share,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                alert("Invalid Interviewer ID");
                // setValid(false);
                // leaveHandler();
              });
          } else {
            alert("You aren't authorized to enter this meet");

            setValid(false);
            leaveHandler();
          }
        })
        .catch((err) => {
          alert("No such interview");

          setValid(false);
          leaveHandler();
        });
    };

    getInter(); // calling the function inside useEffect

    // setting name and thumbnails etc. after 5 seconds
    // if(!putting)
    setTimeout(async () => await putName(
      accountId,
      ROOM_ID,
      peerId,
      interviewDetails,
      studentDetails,
      interviewerDetails,
      idOfUser,
      user,
      vid_other.current
    ), 2000)
  }, []);

  // useEffect(() => {
  //   // let len = $("video").length
    
  //   $(async ()=>{
  //   // if (len>0)  
  //     await putName(
  //       accountId,
  //       ROOM_ID,
  //       peerId,
  //       interviewDetails,
  //       studentDetails,
  //       interviewerDetails,
  //       idOfUser,
  //       user,
  //       vid_other.current
  //     );})
  //     return () => {
  //       // len = 0
  //     }
  // }, [$("video").length])

  useEffect(() => {
    $(() => {
    const hiddenButton = document.createElement("button")
    hiddenButton.style = "position:absolute; top:3%; left: 3%; opacity: 0; height: 1px; width: 1px;"
    $("body").append(hiddenButton)
      
    setInterval(() => {
      hiddenButton.click()
      // console.log("clicked", new Date())
    }, 3000)
    })
  }, [])

  useEffect(() => {
    $(() => {
    myVideo = document.createElement("video");  // video element for user
    myVideo.addEventListener("click", videoPinHandler);
    sec = $(".timer");  // timer div
    textDiv = $("#chat_message-mobile")[0];   // text box div in chats
    send = $("#send")[0];

    window.addEventListener("beforeunload", tabCLoserHandler);  // before unloading the tab the function tabCloseHandler will be called
    // window.addEventListener("unload", tabCLoserHandler)
    // window.addEventListener("close", tabCLoserHandler)
    // getting permissions for audio and video
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        //Adding video and audio stream of the user themselves on the user side
        setMyVideoStream(stream);
        myVideo.classList.add("pin__video");
        myVideo.muted = true;
        addVideoStream(myVideo, stream);

       /* //switching off audio and video when just connected
        stream.getAudioTracks()[0].enabled =
         !stream.getAudioTracks()[0].enabled;
        setMute(!mute); */

        // audio enabled as soon as the user gets in
        stream.getVideoTracks()[0].enabled =
          !stream.getVideoTracks()[0].enabled;
        setVideo(!video_div);

        //Handling call from a user who is already in the room to get their video and audio stream
        peer.on("call", (call) => {
          console.log("someone called me");
          let id = call.options.metadata;
          // setDetails({
          //   ...details,
          //   [call.options.details.id]: call.options.details
          // })
          call.answer(stream);  // answering the call with sending stream information

          call.on("iceStateChanged", (iceState) => {
            console.log(iceState)
            if (iceState === "failed" || iceState === "disconnected") {
              // call.close()
              socket.listeners("disconnected")[0](id?.split("/")[0], "")
            }
          })

          const video = document.createElement("video");  // creating video element of the caller

          //setting timer
          if (call.metadata?.split("/")[1] !== "screen-share") {
            //not stopping timer when screen-share is disconnected
            if (!userInterval) {
              userInterval = setInterval(() => {
                setSeconds((prev) => {
                  //message to say one more interview
                  if (prev > 1500) {
                    setNotification((p) => {
                      if (p) {
                        $(".bell__point").removeClass("hide");
                        $(".bell__point").addClass("blink");
                        $(".notification-mobile").removeClass("hide");

                        // $(".bell__icon").addClass("blink");
                      }
                      return p;
                    });
                  }

                  let hours = Math.trunc(prev / 3600);
                  let minutes =  Math.trunc((prev -3600*hours)/60) ;
                  let seconds = prev - (hours * 3600 + minutes * 60);


                  setArr([hours, minutes, seconds]);

                  return prev + 1;
                });

                // setting video mic share-screen timer
                setVideoMicTime((prev1) => {
                  setMute((prev) => {
                    if (!prev) {
                      prev1.mic = prev1.mic + 1;
                    }
                    return prev;
                  });

                  setVideo((prev) => {
                    if (!prev) {
                      prev1.video = prev1.video + 1;
                    }

                    return prev;
                  });

                  setScreenShare((prev) => {
                    if (prev) {
                      prev1.screen_share = prev1.screen_share + 1;
                    }

                    return prev;
                  });

                  return prev1;
                });
              }, 1000);
            }
          }

          //adding videostream of the connecting person
          //adding videostream of the connecting person
          call.on("stream", async (userVideoStream) => {
            let videos = $("video");
            // if(videos.length>0) 
            //   resetView()
            console.log(userVideoStream);
            if (id?.split("/")[1] !== "screen-share") {
              // pinning the video to side if users not on screen otherwise put the coming one on the side
              for (let i = 0; i < videos.length; i++) {
                if (
                  videos[i].classList.contains("pin__video") &&
                  videos[i] === myVideo
                ) {
                  if ($(".unpinned__video")) {
                    $("video").not(".pin__video").unwrap();
                  }
                  videos[i].classList.remove("pin__video");
                  video.classList.add("pin__video");
                  if($("video")
                    .not(".pin__video"))
                    $("video").not(".pin__video")
                    .wrapAll("<div class='unpinned__video' ></div>");

                  //name pinning and unpinning
                  $(".text__name").remove();
                  $(
                    `<p class='text__name' style='color:white'>Name</p>`
                  ).insertBefore("video");

                  // //putting name in place
                  // putName(
                  // accountId
                  // ROOM_ID,
                  //   peerId,
                  //   interviewDetails,
                  //   studentDetails,
                  //   interviewerDetails,
                  //   idOfUser,
                  //   user,
                  //   vid_other.current
                  // );
                  //setting text
                  setVideo((prev) => {
                    socket.emit("close-video", peerId, !prev);
                    return prev;
                  });

                  //putting name in place after the changes in UI
                  if(!putting)
                 await putName(
                    accountId,
                    ROOM_ID,
                    peerId,
                    interviewDetails,
                    studentDetails,
                    interviewerDetails,
                    idOfUser,
                    user,
                    vid_other.current
                  );

                  return;
                }
              }
            }

            // adding eventlistener to video element and adding video to program
            video.addEventListener("click", videoPinHandler);
            video.setAttribute("name", id);
            addVideoStream(video, userVideoStream); // adding video to parameter video element

            setVideo((prev) => {
              socket.emit("close-video", peerId, !prev);
              return prev;
            });
          });
        });

        // when user connected checking if timer interval is set or not and if not, set it to run timer
        socket.on("user-connected", (recievingId, details) => {
          // setDetails({
          //   ...details,
          //   [details.id]: details
          // })
          if (!userInterval) {
            userInterval = setInterval(() => {
              setSeconds((prev) => {
                //message to say one more interview
                if (prev > 1500) {
                  setNotification((p) => {
                    if (p) {
                      $(".bell__point").removeClass("hide");
                      $(".bell__point").addClass("blink");
                      // $(".bell__icon").addClass("blink");
                    }
                    return p;
                  });
                }

                let hours = Math.trunc(prev / 3600);
                let minutes =  Math.trunc((prev -3600*hours)/60) ;
                let seconds = prev - (hours * 3600 + minutes * 60);
                setArr([hours, minutes, seconds]);

                return prev + 1;
              });

              // setting video mic screen-share timing
              setVideoMicTime((prev1) => {
                setMute((prev) => {
                  if (!prev) {
                    prev1.mic = prev1.mic + 1;
                  }

                  return prev;
                });

                setVideo((prev) => {
                  if (!prev) {
                    prev1.video = prev1.video + 1;
                  }

                  return prev;
                });

                setScreenShare((prev) => {
                  if (prev) {
                    prev1.screen_share = prev1.screen_share + 1;
                  }

                  return prev;
                });

                return prev1;
              });
            }, 1000);
          }
          connectToNewUser(recievingId, stream);  // connecting the the caller with the user
        });
      }).catch((err) => {
      
        // if the user only has audio and no video devices

        navigator.mediaDevices({
          video: false,
          audio: true,
        }).then((stream) => {
            //Adding video and audio stream of the user themselves on the user side
        setMyVideoStream(stream);
        myVideo.classList.add("pin__video");
        myVideo.muted = true;
        addVideoStream(myVideo, stream);

        //switching off audio and video when just connected
        // stream.getAudioTracks()[0].enabled =
        //   !stream.getAudioTracks()[0].enabled;
        // setMute(!mute);
        stream.getVideoTracks()[0].enabled =
          !stream.getVideoTracks()[0].enabled;
        setVideo(!video_div);

        //Handling call from a user who is already in the room to get their video and audio stream
        peer.on("call", (call) => {
          console.log("someone call me,------------------------------");
          let id = call.options.metadata;
          // setDetails({
          //   ...details,
          //   [call.options.details.id]: call.options.details
          // })
          call.answer(stream);

          call.on("iceStateChanged", (iceState) => {
            console.log(iceState)
            if (iceState === "failed" || iceState === "disconnected") {
              // call.close()
              socket.listeners("disconnected")[0](id.split("/")[0], "")
            }
          })

          const video = document.createElement("video");

          //setting timer
          if (call.metadata.split("/")[1] !== "screen-share") {
            //not stopping timer when screen-share is disconnected
            if (!userInterval) {
              userInterval = setInterval(() => {
                setSeconds((prev) => {
                  //message to say one more interview
                  if (prev > 1500) {
                    setNotification((p) => {
                      if (p) {
                        $(".bell__point").removeClass("hide");
                        $(".bell__point").addClass("blink");
                        $(".notification-mobile").removeClass("hide");

                        // $(".bell__icon").addClass("blink");
                      }
                      return p;
                    });
                  }

                  let hours = Math.trunc(prev / 3600);
                  let minutes =  Math.trunc((prev -3600*hours)/60) ;
                  let seconds = prev - (hours * 3600 + minutes * 60);


                  setArr([hours, minutes, seconds]);

                  return prev + 1;
                });

                // setting video mic share-screen timer
                setVideoMicTime((prev1) => {
                  setMute((prev) => {
                    if (!prev) {
                      prev1.mic = prev1.mic + 1;
                    }
                    return prev;
                  });

                  setVideo((prev) => {
                    if (!prev) {
                      prev1.video = prev1.video + 1;
                    }

                    return prev;
                  });

                  setScreenShare((prev) => {
                    if (prev) {
                      prev1.screen_share = prev1.screen_share + 1;
                    }

                    return prev;
                  });

                  return prev1;
                });
              }, 1000);
            }
          }

          //adding videostream of the connecting person
          //adding videostream of the connecting person
          call.on("stream", async (userVideoStream) => {
            let videos = $("video");
            console.log(userVideoStream,"------------------------");
            if (id.split("/")[1] !== "screen-share") {
              // pinning the video to side if users not on screen otherwise put the coming one on the side
              for (let i = 0; i < videos.length; i++) {
                if (
                  videos[i].classList.contains("pin__video") &&
                  videos[i] === myVideo
                ) {
                  if ($(".unpinned__video")) {
                    $("video").not(".pin__video").unwrap();
                  }
                  videos[i].classList.remove("pin__video");
                  video.classList.add("pin__video");
                  if($("video")
                    .not(".pin__video"))
                    $("video").not(".pin__video")
                    .wrapAll("<div class='unpinned__video' ></div>");

                  //name pinning and unpinning
                  $(".text__name").remove();
                  $(
                    `<p class='text__name' style='color:white'>Name</p>`
                  ).insertBefore("video");

                  // //putting name in place
                  // putName(
                  // accountId
                  // ROOM_ID,
                  //   peerId,
                  //   interviewDetails,
                  //   studentDetails,
                  //   interviewerDetails,
                  //   idOfUser,
                  //   user,
                  //   vid_other.current
                  // );
                  //setting text
                  setVideo((prev) => {
                    socket.emit("close-video", peerId, !prev);
                    return prev;
                  });

                  //putting name in place
                  if(!putting)
                 await putName(
                    accountId,
                    ROOM_ID,
                    peerId,
                    interviewDetails,
                    studentDetails,
                    interviewerDetails,
                    idOfUser,
                    user,
                    vid_other.current
                  );

                  return;
                }
              }
            }

            // adding eventlistener to video element and adding video to program
            video.addEventListener("click", videoPinHandler);
            video.setAttribute("name", id);
            addVideoStream(video, userVideoStream);

            //setting text
            setVideo((prev) => {
              socket.emit("close-video", peerId, !prev);
              return prev;
            });
            call.on('iceStateChanged', (state) => {
              console.log("ICE state changed",state)
            })
          });
        });

        // when user connected checking if timer interval is set or not and if not, set it to run timer
        socket.on("user-connected", (recievingId, details) => {
          // setDetails({
          //   ...details,
          //   [details.id]: details
          // })
          if (!userInterval) {
            userInterval = setInterval(() => {
              setSeconds((prev) => {
                //message to say one more interview
                if (prev > 1500) {
                  setNotification((p) => {
                    if (p) {
                      $(".bell__point").removeClass("hide");
                      $(".bell__point").addClass("blink");
                      // $(".bell__icon").addClass("blink");
                    }
                    return p;
                  });
                }

                let hours = Math.trunc(prev / 3600);
                let minutes =  Math.trunc((prev -3600*hours)/60) ;
                let seconds = prev - (hours * 3600 + minutes * 60);
                setArr([hours, minutes, seconds]);

                return prev + 1;
              });

              // setting video mic screen-share timing
              setVideoMicTime((prev1) => {
                setMute((prev) => {
                  if (!prev) {
                    prev1.mic = prev1.mic + 1;
                  }

                  return prev;
                });

                setVideo((prev) => {
                  if (!prev) {
                    prev1.video = prev1.video + 1;
                  }

                  return prev;
                });
                
                setScreenShare((prev) => {
                  if (prev) {
                    prev1.screen_share = prev1.screen_share + 1;
                  }

                  return prev;
                });

                return prev1;
              });
            }, 1000);
            // socket.on("feedback-sent", (data) => {
            //   setFeedback(true)
            //   console.log("socket:", data.msg, data)
            // })
          }
          connectToNewUser(recievingId, stream);
        });
        })
      });

      // the first function to be called - creates a peer id and creates room using the Mock ID and calling other users using that peer id
        peer.on("open", async (id) => {
          console.log("hello");
          
          try {
            await socket.timeout(5000).emitWithAck("join-room", ROOM_ID, id, user, accountId);
          } catch (err) {
            socket.listeners('socket-connect-error')[0](accountId, err)
            console.log(err)
          }
          // console.log(details)
          myVideo.setAttribute("name", id);
          // console.log(id);
          peerId = id;
          setidOfUser(id);
        });
       

        peer.socket.on("tab-close", (userId) => {
          if (userId === idOfUser) {
            redirectAfterLeave()
          } else {
            socket.listeners("disconnected")[0](userId, "")
          }
        })


        peer.on('error', (error, type) => {
          console.log("Peer Error",error, type)
          // peer.reconnect()
          socket.emit("join-room", ROOM_ID, idOfUser, user, accountId);
          // if(type === "disconnected")
            // peer.emit("open")
            // socket.emit("disconnected")
        })



    // when someone gets disconnected
    socket.on("disconnected", async (deleteId, name = "") => {
      console.log("disconnected socket event", deleteId)
      // if disconnected screen is shared screen then
      if(deleteId !== peerId) {
      if (name === "screen-share" ) {
        console.log("leave with screen here")
        $("video").not(".pin__video").unwrap();
        $(`video[name='${deleteId + "/screen-share"}']`).remove();
        if ($("video")?.length === 1) {
          $("video").addClass("pin__video");
        } else {
          $("video").first().addClass("pin__video");
          if($("video")
            .not(".pin__video"))
            $("video").not(".pin__video")
            .wrapAll("<div class='unpinned__video' ></div>");
        }

      } else {
        //removing video element when call is cut and that video is on focus OR not
        if ($(`video[name='${deleteId}']`).hasClass("pin__video")) {
          $(`video[name='${deleteId}']`).remove();
          $("video").unwrap();
          $(`video[name='${peerId}']`).addClass("pin__video");
          if($("video")
            .not(".pin__video"))
            $("video").not(".pin__video")
            .wrapAll("<div class='unpinned__video' ></div>");
        } else {
          $(`video[name='${deleteId}']`).remove();
        }

        // removing screen-sharing of the call that was cut with screenshare on
        if ($(`video[name='${deleteId + "/screen-share"}']`).length !== 0) {
          $(`video[name='${deleteId + "/screen-share"}']`).remove();
          $("video").not(".pin__video").unwrap();
          $(`video[name='${peerId}']`).addClass("pin__video");
          if($("video")
            .not(".pin__video"))
            $("video").not(".pin__video")
            .wrapAll("<div class='unpinned__video' ></div>");
            
        }
        }

        //saving timer values when disconnected video is the other user
        if(name!=="screen-share")
        if (
          $("video").length <= 1 ||
          ($("video").length == 2 &&
            $(`video[name='${idOfUser}/screen-share']`))
        ) {
          // $(".unpinned__video").remove();
          clearInterval(userInterval);
          userInterval = null;

          axios.post(`${BASE}/vc/timer/${ROOM_ID}`, {
            seconds: Number(sec.attr("name")),
          });
        }

        //if time limit exceeds by 28 minutes
        // setSeconds((prev) => {
        //   if (prev > 1680) {
        //     leaveHandler();
        //   }
        //   return prev;
        // });
      

      $(".unpinned__video").insertAfter($(".pin__video"));
      $(".videos__group").css("justify-content", "center")
      $(".videos__group").css("align-items", "center")
      
      //return {data: {message: "success"}}
      } else {
        if(name !== "screen-share")
        redirectAfterLeave()
        else {
          // if ($(`video[name='${deleteId + "/screen-share"}']`).length !== 0) {
          //   $(`video[name='${deleteId + "/screen-share"}']`).remove();
          //   $("video").unwrap();
          //   $(`video[name='${peerId}']`).addClass("pin__video");
          //   $("video")
          //     .not(".pin__video")
          //     .wrapAll("<div class='unpinned__video' ></div>");
          // }

          // $(".unpinned__video").insertAfter(".pin__video");
        }
        //return {error: {message: "error"}}
      }
      //putting name in place
      // if(!putting)
      await putName(
        accountId,
        ROOM_ID,
        peerId,
        interviewDetails,
        studentDetails,
        interviewerDetails,
        idOfUser,
        user,
        vid_other.current
      );
    });

    // call after leave handler is activated on the other side
    socket.on("end-call", () => {
      peer.destroy();
      window.open("about:blank", "_self");
      window.close();
    });

    //Event listeners
    //Event listeners
    send.addEventListener("click", messageSender);
    textDiv.addEventListener("keypress", (e)=>{
      if(e.key==="Enter" && !e.shiftKey){
        e.preventDefault();
        messageSender();
      }
    })
    window.addEventListener("resize", windowResizer);
    // $("window").addEventListener("beforeunload",windowClose);
    })
    return () => {
      clearInterval(getTransferRate);

      send.removeEventListener("click", messageSender);
      window.removeEventListener("resize", windowResizer);
      window.removeEventListener("beforeunload", tabCLoserHandler);
      myVideo.removeEventListener("click", videoPinHandler);
      // $("window").removeEventListener("beforeunload",windowClose)
      socket.removeListener("createMessage");
      socket.removeListener("disconnected");
      socket.removeListener("user-connected");
      socket.removeListener("end-call");
    };
  }, []);

  // useEffect(() => {
  //   $(() => {
  //   let videos = $("video")
  //   videos.map((i, vid) => {
  //     let vidName = vid.getAttribute("name")
  //     if (!video_div && vidName?.startsWith(idOfUser)) {
  //       if(vid.classList.contains("pin__video"))
  //         $(
  //           `<p class='text__name text__pin' style='color:white' name='${vidName}' >${user}</p>`
  //         ).insertBefore(vid);
  //       else if ($(vid).parent().hasClass("unpinned__video"))
  //         $(
  //           `<image class="text__name text__unpin" style='color:white' src='${
  //             studentDetails?.id == accountId
  //               ? studentDetails?.image
  //               : interviewerDetails?.image
  //           }' name='${vidName}' />`
  //         ).insertBefore(vid);
  //     }
  //   })
  //   })
  // }, [video_div, interviewerDetails, studentDetails, $("video")])

  useEffect(() => {
    console.log("Peer destroyed: ", peer.destroyed)
    console.log("Peer disconnected: ", peer.disconnected)
    console.log("Peer open: ", peer.open)
    // console.log("Peer options: ", peer.options)
    // console.log("Peer socket:", peer.socket)
  }, [peer.disconnected, peer.destroyed])

  useMemo(() => {
    console.log("Socket Connected: ", socket.connected)
    console.log("Socket Active", socket.active)
    console.log("Socket IO", socket.io)
    console.log("Socket Disconnected", socket.disconnected)
    console.log("Socket Recovered", socket.recovered)
    socket.onAny((name) => {
      console.log(name, "event received at "+ accountId)
    })
    socket.onAnyOutgoing((name) => {
      console.log(name, " emitted from "+ accountId)
    })
    // peer.socket.emit("hey", {msg: "peer socket message"})
    // peer.socket.on("hey", (data) => {
    //   console.log("peer socket event hey", data)
    // })
  }, [socket.connected, socket.disconnected, socket.active, socket.io._readyState, socket.recovered])
  // to get video chats and timer details and creating close-video event for detecting when to show names and when not
  useEffect(() => {
    const getChats = () => {
      axios
        .get(VIDEO_CALL_GET_CHATS + "/" + ROOM_ID)
        .then((res) => {
          let arr = [];
          if (res.data && res.data[0].text) {
            res.data[0].text.forEach((ele) => {
              if (ele.text.split("/")[0] === "http:" || ele.text.split("/")[0] === "https:") {
                arr.push({ name: ele.name, url: ele.text, accountId: ele.accountId });
              } else {
                arr.push({ name: ele.name, text: ele.text, accountId: ele.accountId });
              }
            });
            // console.log(messages.length, arr.length)
            setMessages(arr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getChats();

    const timer = () => {
      axios.get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID).then((res) => {
        const t = res.data.seconds;
        if (t) {
          setSeconds(res.data.seconds);
        }
      });
    };
    timer();

    // event listener for closing video, putting names if needed and checking if the video is on or off and whose
    socket.on("close-video", async (id, vid) => {
      let videoArray = $("video");

      for (let i = 0; i < videoArray.length; i++) {
        if (videoArray[i].getAttribute("name") === id) {
          if ($(videoArray[i]).prev().hasClass("text__name")) {
            $(videoArray[i]).prev().remove();
          } else {
            //putting name in place
            if(!putting)
            await putName(
              accountId,
              ROOM_ID,
              peerId,
              interviewDetails,
              studentDetails,
              interviewerDetails,
              idOfUser,
              user,
              vid_other.current
            );
          }
        } else if (
          id != peerId &&
          id != idOfUser &&
          videoArray[i].getAttribute("name")?.split("/")[1] != "screen-share"
        ) {
          vid_other.current = vid;

          if (!vid) {
            //putting name in place
            
          }
        }
        if(!putting)
          await putName(
            accountId,
            ROOM_ID,
            peerId,
            interviewDetails,
            studentDetails,
            interviewerDetails,
            idOfUser,
            user,
            vid_other.current
          );
      }
    });

    // to putname at start of video call
    // to putname at start of video call
    
      // if(!putting)
      setTimeout(async () => await putName(
        accountId,
        ROOM_ID,
        peerId,
        interviewDetails,
        studentDetails,
        interviewerDetails,
        idOfUser,
        user,
        vid_other.current
      ), 3000)
  }, []);

  // whenever a message is send, scroll to the height of div to show latest sent messages
  // whenever a message is send, scroll to the height of div to show latest sent messages
  useEffect(() => {
    let messageBox = $(".messages")[0];
    messageBox.scrollTop = messageBox.scrollHeight;
  }, [messages, showMessage]);

  useEffect(() => {
    socket.on("screen-connected", (recievingId, details) => {
      // setDetails({
      //   ...details,
      //   [details.id]: details
      // })
      if (screenMedia) {
        peer.call(recievingId, screenMedia, {
          metadata: idOfUser + "/screen-share",
          // details: {
          //   id: currUser.id,
          //   fname: user.fname,
          //   image: user.photo
          // }
        });
      }
    });
    return () => {
      socket.removeListener("screen-connected");
    };
  }, [screenMedia]);

  useEffect(() => {
    // console.log(document.visibilityState)
    // document.addEventListener("visibilitychange", handleVisibility)
    if (seconds > FEEDBACK_TIME || visibility) {
      if (accountId.startsWith("I") && !feedback) {
        axios.get(`${FEEDBACK}/${ROOM_ID}`)
        .then((res) => {
          if(res.data) setFeedback(true)
        })
        .catch((err) => {
          setFeedback(false)
        })
      } else if (accountId.startsWith("S") && !rated) {
        axios.get(`${BASE}/send/getRating/${ROOM_ID}`)
        .then((res) => {
          if(res.data) {
            setRated(true)
          }
        })
        .catch((err) => {
          setRated(false)
          // console.log(err)
        })
      } 
    }
    return;
  }, [seconds > FEEDBACK_TIME, visibility])

  // useEffect(() => {
  //   setInterval(() => {
  //     // let unpinnedDiv = $(".unpinned__video")
  //     // let pinnedVideo = $(".pin__video")
      
  //     // unpinnedDiv.map(() => {
  //     //   unpinnedDiv.childre
  //     // })
  //   }, 2000)
      
    // setInterval(async () => {
    //   let unpinnedDiv = $(".unpinned__video")
    //   let pinnedVideo = $(".pin__video")
    //   pinnedVideo.siblings().filter("img").detach()
    //   // if($("video").map((i, el) => {
    //   //   if(el.captureStream()?.getVideoTracks()[0].enabled)
    //   //     el.siblings().filter("p").remove()
    //   // }))

    //   if(pinnedVideo.length > 0 && pinnedVideo.siblings().length<=1 ||
    //    unpinnedDiv.length > 0 && unpinnedDiv.children().length<=1 
    //    )
    //   await putName(
    //     accountId,
    //     ROOM_ID,
    //     peerId,
    //     interviewDetails,
    //     studentDetails,
    //     interviewerDetails,
    //     idOfUser,
    //     user,
    //     vid_other.current,
    //     true
    //   );
    // }, 2000)
  // }, [$("video").length])

  //window resize listener
  //window resize listener
  const windowResizer = async () => {
    if (document.body.clientWidth <= "700px") {
      setShowMessage(false);
    }
    if(!putting)
    await putName(
      accountId,
      ROOM_ID,
      peerId,
      interviewDetails,
      studentDetails,
      interviewerDetails,
      idOfUser,
      user,
      vid_other.current
    );
  };

  const resetView = () => {
    $(".videos__group").css("justify-content", "center")
      $(".videos__group").css("align-items", "center")
    $("video").not(".pin__video").unwrap()
    $(".pin__video").removeClass('pin__video')
    $(`video[name='${idOfUser}']`).addClass("pin__video")
    if($("video").not(".pin__video"))
    $("video").not(".pin__video").wrapAll("<div class='unpinned__video' ></div>")
    $(".unpinned__video").insertAfter($(".pin__video"))
  }

  //window closer
  //window closer
  const tabCLoserHandler = (e) => {
    // e.stopImmediatePropagation()
    // e.preventDefault()
    // e.preventDefault()
    // window.open("about:blank", "_self")
    // redirectAfterLeave()
    // window.open("https://google.com", "_blank")
    // socket.emit("disconnected", "tab-close")
    // peer.destroy()
    // window.open("about:blank", "_self");
    // window.close();
    // const message = "Are you sure you want to leave? A new tab will open.";
    // e.returnValue = null;
    // e.returnValue = "Are you sure you want to leave the meeting ?"
    // Open a new tab if the user confirms leaving the page
  };


  //message sending function
  //message sending function
  const messageSender = () => {
    
    if (textDiv.value !== "") {
      socket.emit("message", textDiv.value);
      textDiv.setAttribute("value", "");
    }

    textDiv.value = "";
  };

  //creating chats array
  //creating chats array
  useEffect(() => {
    if(socket.connected)
    socket.on("createMessage", (message, userName, currUserId) => {
      if(showMessage)
        setNewChats(prevNewChats => prevNewChats = prevNewChats + 1)
      if (message.split("/")[0] === "http:") {
        setMessages([
          ...messages,
          { name: userName, text: message, url: message, accountId: currUserId },
        ]);
      } else {
        setMessages([...messages, { name: userName, text: message, accountId: currUserId }]);
      }
      socket.removeListener("createMessage");
    });
  }, [messages, socket.connected]);

  //toggle comment container
  //toggle comment container
  const commentHandler = (e) => {
    e.preventDefault();
    main_right.classList.toggle("showChat");
    main_left.classList.toggle("showMain");
    
    setNewChats(0)
    setShowMessage(!showMessage);
  };

  //Pin the video
  //Pin the video
  // ------------------------------------------------------
  const videoPinHandler = async (e) => {
    //when unpinned video is selected
    //unpinning video
    if (e.target.classList.contains("pin__video")) {
      return;
      setScrollView(true)
      $(".videos__group").css("justify-content", "flex-start")
      $(".videos__group").css("align-items", "normal")
      $("video").not(".pin__video").unwrap();
      e.target.classList.remove("pin__video");
      //putting name in place
      if(!putting)
      await putName(
        accountId,
        ROOM_ID,
        peerId,
        interviewDetails,
        studentDetails,
        interviewerDetails,
        idOfUser,
        user,
        vid_other.current,
        true
      );
    }

    //pinning video
    else if (!e.target.classList.contains("pin__video")) {
      let videos = $("video");
      
      for (let i = 0; i < videos.length; i++) {
        if (videos[i].classList.contains("pin__video")) {
          $("video").not(".pin__video").unwrap();
          $(".pin__video").siblings().filter("img").remove()
          videos[i].classList.remove("pin__video");
          e.target.classList.add("pin__video");
          if($("video").not(".pin__video"))
          $("video")
            .not(".pin__video")
            .wrapAll("<div class='unpinned__video' ></div>");
          $(".unpinned__video").insertAfter($(".pin__video"));

        }
      }

      // e.target.classList.add("pin__video");
      // if($("video").not(".pin__video"))
      // $("video")
      //   .not(".pin__video")
      //   .wrapAll("<div class='unpinned__video' ></div>");
      // $(".unpinned__video").insertAfter($(".pin__video"));

      //putting name in place
      // if(!putting)
      // putName(
      //   accountId,
      //   ROOM_ID,
      //   peerId,
      //   interviewDetails,
      //   studentDetails,
      //   interviewerDetails,
      //   idOfUser,
      //   user,
      //   vid_other.current
      // );
    }
    putName(
      accountId,
      ROOM_ID,
      peerId,
      interviewDetails,
      studentDetails,
      interviewerDetails,
      idOfUser,
      user,
      vid_other.current
    );
  };

  // ------------------------------------------------------

  const exitScrollView = () => {
    $(".videos__group").css("justify-content", "center")
      $(".videos__group").css("align-items", "center")
    if (scrollView) {
      $(`video[name='${idOfUser}']`).addClass("pin__video")
      if($("video").not(".pin__video"))
      $("video").not(".pin__video").wrapAll('<div class="unpinned__video"></div>')
      $(".unpinned__video").insertAfter($(".pin__video"))
      setScrollView(false)
    }
    
  }

  //addVideoStream to play video stream got from navigator
  //addVideoStream to play video stream got from navigator
  const addVideoStream = async (video, stream) => {
    exitScrollView()

    console.log(video,"addVideoStream 1");

    video.srcObject = stream;
    video.play();
    let videos = $("video");
    if (video.getAttribute("name")?.split("/")[1] === "screen-share") {
      // if(!$(".unpinned__video").length > 0) {
      //   $(`video[name='${idOfUser}']`).get().click()
      // }
      console.log(video,"addVideoStream 2"); 
      video.style.transform = "rotateY(0deg)";

      if ($(".unpinned__video")) {
        $("video").not(".pin__video").unwrap();
      }
      $(".pin__video").removeClass("pin__video");
      video.classList.add("pin__video");
      console.log(video,"addVideoStream 3");
      // $("#video-grid").append(video)
      if($("video").not(".pin__video"))
      $("video")
        .not(".pin__video")
        .wrapAll("<div class='unpinned__video' ></div>");
        
        
    } else {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i].classList.contains("pin__video")) {
          // console.log($(".unpinned__video"));
          $(".unpinned__video")[0].append(video);
          //putting name in place
          // if(!putting)
          await putName(
            accountId,
            ROOM_ID,
            peerId,
            interviewDetails,
            studentDetails,
            interviewerDetails,
            idOfUser,
            user,
            vid_other.current
          );

          return;
        }
      }
    }

    if (!$(`video[name='${video.getAttribute('name')}']`).hasClass('pin__video') && 
    !$(`video[name='${video.getAttribute('name')}']`).parent().hasClass('unpinned__video')){
    $("#video-grid").append(video);
    $(".unpinned__video").insertAfter($(".pin__video"));
    }
    // if(!putting)
        await putName(
          accountId,
          ROOM_ID,
          peerId,
          interviewDetails,
          studentDetails,
          interviewerDetails,
          idOfUser,
          user,
          vid_other.current
        );
  };

  let call;
  // connecting to new user on getting user-connected call
  const  connectToNewUser = async (recieverId, stream) => {
    console.log(recieverId,stream);
    call = peer.call(recieverId, stream, { 
      metadata: peerId,
      // details: {
      //   id: user.id,
      //   fname: user.fname,
      //   image: user.photo
      // }
    });
    const video = document.createElement("video");

    call.on("error", (err) => console.log(err))

    call.on("iceStateChanged", (iceState) => {
      console.log(iceState)
      if (iceState === "failed" || iceState === "disconnected") {
        // call.close()
        socket.listeners("disconnected")[0](recieverId, "")
      }
    })

    video.setAttribute("name", recieverId);

    //adding videostream of the user
    //adding videostream of the user

    call.on("stream", async (userVideoStream) => {
      let videos = $("video");
      for (let i = 0; i < videos.length; i++) {
        if (
          videos[i].classList.contains("pin__video") &&
          videos[i] === myVideo
        ) {
          $("video").not(".pin__video").unwrap();
          videos[i].classList.remove("pin__video");
          video.classList.add("pin__video");
          if($("video").not(".pin__video"))
          $("video")
            .not(".pin__video")
            .wrapAll("<div class='unpinned__video' ></div>");
          
          //setting text
          setVideo((prev) => {
            socket.emit("close-video", peerId, !prev);
            return prev;
          });

          //putting name in place
          if(!putting)
          await putName(
            accountId,
            ROOM_ID,
            peerId,
            interviewDetails,
            studentDetails,
            interviewerDetails,
            idOfUser,
            user,
            vid_other.current
          );

          return;
        }
      }
      video.addEventListener("click", videoPinHandler);
      addVideoStream(video, userVideoStream);
      $(".unpinned__video").insertAfter($(".pin__video"));

      //setting text
      setVideo((prev) => {
        socket.emit("close-video", peerId, !prev);
        return prev;
      });

      //putting name in place
      await putName(
        accountId,
        ROOM_ID,
        peerId,
        interviewDetails,
        studentDetails,
        interviewerDetails,
        idOfUser,
        user,
        vid_other.current
      );
    });
    if(!putting)
    await putName(
      accountId,
      ROOM_ID,
      peerId,
      interviewDetails,
      studentDetails,
      interviewerDetails,
      idOfUser,
      user,
      vid_other.current
    );
  };

  //Muting audio
  //Muting audio
  const muteHandler = () => {
    myVideoStream.getAudioTracks()[0].enabled =
      !myVideoStream.getAudioTracks()[0].enabled;
    setMute(!mute);
  };

  //Stopping video
  //Stopping video
  const stopVideo = async () => {
    exitScrollView()
    $(".pin__video").siblings().filter("p").remove()
    $(".unpinned__video").children().filter("img").remove()

    myVideoStream.getVideoTracks()[0].enabled =
      !myVideoStream.getVideoTracks()[0].enabled;

    socket.emit("close-video", idOfUser, video_div);

    // $(".text__name").remove()

    setVideo(!video_div);
    
  };

  //leaving the call
  //leaving the call
  const leaveHandler = async (customEvt) => {
    // window.removeEventListener("beforeunload", tabCLoserHandler)
    window.removeEventListener("pagehide", handleBackButton)
    window.removeEventListener("unload", handleBackButton)
    window.removeEventListener("beforeunload", handleBackButton)
    myVideoStream.getTracks().map((t) => t.stop())
    if (student) {
     axios
        .put(`${BASE}/vc/video-mic/student/timer/${ROOM_ID}`, videoMicTime)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
     axios
        .put(`${BASE}/vc/video-mic/interviewer/timer/${ROOM_ID}`, videoMicTime)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    try {
      await socket.timeout(3000).emitWithAck("disconnected")
    } catch (err) {
      console.log("Leave Error: ",err)
      redirectAfterLeave()
    }
    // if(customEvt === "tabClose") {
    //   if(call)
    //     call.close()
    //   peer.destroy()
    //   redirectAfterLeave()
    // } else {
    // await emitWithPromise(socket, "disconnected", {})
    // .then(() => {
    //   if (call) 
    //   call.close()
    //   peer.destroy()
    //   console.log("call closed, peer destroyed")
    //   // sending to rate and reiview or feedback form after leaving at 28 minutes
    //   // if(true){
    //   // if (seconds >= 1680) {
    //   //   if (student) {
    //   //     navigate("/end/student/vc/" + ROOM_ID)
    //   //     // window.open(`${FRONTEND_BASE}/end/student/vc/${ROOM_ID}`,"_blank");
    //   //     // window.close();
    //   //   } else {
    //   //     navigate("/end/interviewer/vc/" + ROOM_ID)
    //   //     // window.open(`${FRONTEND_BASE}/end/interviewer/vc/${ROOM_ID}`,"_blank");
    //   //     // window.close();
    //   //   }
    //   // } else {
    //   //   if (student) {
    //   //     navigate("/midend/student/vc/" + ROOM_ID);
    //   //     // window.open(`${FRONTEND_BASE}/midend/student/vc/${ROOM_ID}`,"_blank");
    //   //     // window.close();
    //   //   } else {
    //   //     navigate("/midend/interviewer/vc/" + ROOM_ID);
    //   //     // window.open(`${FRONTEND_BASE}/midend/interviewer/vc/${ROOM_ID}`,"_blank");
    //   //     // window.close();
    //   //   }
    //   // }
    // })
    // .catch((err) => console.log(err)) 
    // }
  };

  const endCallHandler = () => {
    socket.emit("end-call");
    socket.emit("disconnected");
    peer.destroy();
    window.open("about:blank", "_self");
    window.close();
  };

  const windowClose = () => {
    socket.emit("disconnected");
    peer.destroy();
    window.open("about:blank", "_self");
    window.close();
  };

  //on choosing file for chats
  //on choosing file for chats
  const onFileChange = (e) => {
    if (e.target.files[0].size < 4000000) {
      setFile(e.target.files[0]);
      e.target.files = null;
      // $(".main__chat_window").css("marginBottom", "50px");
    } else {
      alert("Size of file should be less than 4MB");
    }
  };

  //on clicking uplaod button
  //on clicking uplaod button
  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("filename", file.name);
    formData.append("file", file);
    alert("File will upload as soon as upload option disappears");
    let fileUrl = await axios
      .post(VIDEO_CALL_UPLOAD_FILE, formData)
      .then((res) => res.data)
      .catch((err) => err);
    setFile(null);
    $(".main__chat_window").css("marginBottom", "0px");
    socket.emit("message", fileUrl);
    $("#file__input").val("");
    // $("#file__input")[0].val(null);
  };

  const onFileCancelUpload = () => {
    $(".main__chat_window").css("marginBottom", "0px");
    $("#file__input").val("");
    setFile(null);
  };

  // function governing screen-share
  const startScreenSharing = () => {
    navigator.mediaDevices
      .getDisplayMedia({
        video: {
          cursor: "always",
        },
        audio: true,
      })
      .then((stream) => {
        const video = document.createElement("video");
        video.addEventListener("click", videoPinHandler);
        video.setAttribute("name", idOfUser + "/screen-share");

        setScreenMedia(stream);

        stream.getVideoTracks()[0].onended = () => {
          exitScrollView()
          $(`video[name='${idOfUser}/screen-share']`).remove()
          resetView()
          setScreenShare(false)
          setScreenMedia(null)
          socket.emit("disconnected", "screen-share");
          putName(
            accountId,
            ROOM_ID,
            peerId,
            interviewDetails,
            studentDetails,
            interviewerDetails,
            idOfUser,
            user,
            vid_other.current
          );
          // // resetView()
          // setScreenShare(false);
          // setScreenMedia(null);
        };

        // let call;
        let videos = document.querySelectorAll("video");
        for (let i = 0; i < videos.length; i++) {
          if (videos[i].getAttribute("name") != idOfUser) {
            peer.call(videos[i].getAttribute("name"), stream, {
              metadata: idOfUser + "/screen-share",
              // details: {
              //   id: user.id,
              //   fname: user.fname,
              //   image: user.photo
              // }
            });
          }
        }

        // if (scrollView) {
        //   $(`video[name='${idOfUser}']`).addClass("pin__video")
        //   $("video").not(".pin__video").wrapAll('<div class="unpinned__video"></div>')
        //   $(".unpinned__video").insertAfter($(".pin__video"))
        // }

        addVideoStream(video, stream);

        //putting name in place
        putName(
          accountId,
          ROOM_ID,
          peerId,
          interviewDetails,
          studentDetails,
          interviewerDetails,
          idOfUser,
          user,
          vid_other.current
        );

    setScreenShare(!screenShare);

      }).catch((err) => {
        setScreenShare(false);
        setScreenMedia(null);
        console.log("Screen share error: ",err)
        // setScreenShare(!screenShare);
      });


    
  };

  const stopScreenSharing = async () => {
    exitScrollView()
    $(".videos__group").css("justify-content", "center")
      $(".videos__group").css("align-items", "center")
    // if ($(`video[name='${idOfUser + "/screen-share"}']`).length !== 0) {
      $(`video[name='${idOfUser + "/screen-share"}']`).remove(); // remove the screen share video
      $("video").not(".pin__video").unwrap(); // all videos now free in the DOM
      $(".pin__video").removeClass("pin__video")
      $(`video[name='${idOfUser}']`).addClass("pin__video"); // pin the video of current user (apparently)
      if($("video") 
        .not(".pin__video"))
        $("video") 
        .not(".pin__video").wrapAll("<div class='unpinned__video' ></div>"); // wrap all remaining videos oin in unpinned__video
      
        $(".unpinned__video").insertAfter($(".pin__video"));
    if(screenMedia?.getVideoTracks){
    screenMedia.getVideoTracks()[0].stop(); //stoping screen share
    setScreenShare(false);
    setScreenMedia(null);
    // $(`video[name='${idOfUser}/screen-share']`).remove()
    // setScreenShare(!screenShare);
       // insert unpinned__video after pin__video
    // }
      
    socket.emit("disconnected", "screen-share");
    await putName(
      accountId,
      ROOM_ID,
      peerId,
      interviewDetails,
      studentDetails,
      interviewerDetails,
      idOfUser,
      user,
      vid_other.current
    );
    }


    // $("video").addClass("pin__video");
    // $("video").not(".pin__video").wrapAll("<div class='unpinned__video' ></div>")
  };

  //handling notification
  const notificationHandler = () => {
    if ($(".bell__point").hasClass("blink")) {
      $(".notification").toggleClass("hide");
    }
  };

  //Annotation handlers
  //Annotation handlers
  const annotationHandle = (e) => {
    if ($(".pin__video").attr("name")?.split("/")[1] === "screen-share") {
      $("#annotation").toggleClass("annotation__bg");

      $(".sketch").toggleClass("show__sketch");

      if ($(".unpinned__video").length !== 0) {
        $(".sketch").addClass("sketch__unpin");
      } else {
        $(".sketch").removeClass("sketch__unpin");
      }

      drawOnCanvas();
    }
  };

  socket.on("canvas-data", (id, data) => {
    console.log(id, data, "----asd-a-ds-sad-as-d-asd-a-sd-as-d");
  });

  // function to draw on canvas element
  const drawOnCanvas = () => {
    var canvas = document.querySelector("#board");
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // var div = document.getElementById('myDivElement')
    // var cs = getComputedStyle(div)

    var sketch = document.querySelector("#sketch");
    var sketch_style = getComputedStyle(sketch);
    canvas.width = parseInt(sketch_style.getPropertyValue("width"));
    canvas.height = parseInt(sketch_style.getPropertyValue("height"));

    var mouse = { x: 0, y: 0 };
    var last_mouse = { x: 0, y: 0 };

    /* Mouse Capturing Work */
    canvas.addEventListener(
      "mousemove",
      function (e) {
        last_mouse.x = mouse.x;
        last_mouse.y = mouse.y;

        mouse.x = e.pageX - this.offsetLeft;
        mouse.y = e.pageY - this.offsetTop;
      },
      false
    );

    // /* Drawing on Paint App */
    ctx.lineWidth = 2;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.strokeStyle = "#FF0000";

    canvas.addEventListener(
      "mousedown",
      function (e) {
        canvas.addEventListener("mousemove", onPaint, false);
      },
      false
    );

    canvas.addEventListener(
      "mouseup",
      function () {
        canvas.removeEventListener("mousemove", onPaint, false);
      },
      false
    );

    var onPaint = function () {
      ctx.beginPath();
      ctx.moveTo(last_mouse.x, last_mouse.y);
      ctx.lineTo(mouse.x, mouse.y);
      ctx.closePath();
      ctx.stroke();

      if (!timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        var base64ImageData = canvas.toDataURL("image/png");
        socket.emit("canvas-data", idOfUser, base64ImageData);
      }, 1000);
    };
  };

  // const stop = () => {
  //   peer.disconnect();
  //   peer.destroy();
  // }

  // ------------------------------------------------------------------------------------------------------------------------
  const [showDetails, setShowDetails] = useState(false)
  const [showModal, setShowModal] = useState(false)
  
  useEffect(() => {
    if (socket) {
      socket.onAny((res) => {
        console.log("Receive: ", res)
      })
      socket.onAnyOutgoing((res) => {
        console.log("Emit: ", res)
      })
      socket.on("socket-connect-error", async (userId, err) => {
        console.log("Socket Connect Error: ", userId, err)
        try {
          await socket.emitWithAck("join-room", ROOM_ID, idOfUser, user, accountId);
        } catch (err) {
          setTimeout(() => {
            window.location.reload()
          }, 2000)
          console.log(err)
        }
      })

      socket.on("connect_error", async (err) => {
        console.log("socket connect_error", err)
        try {
          await socket.emitWithAck("join-room", ROOM_ID, idOfUser, user, accountId);
        } catch (err) {
          console.log(err)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      })
      let events = [
        'connect', 
        'connecting',
        'disconnect',
        'reconnect',
        'reconnecting',
        'connect_failed',
        'reconnect_failed',
        'close'
      ]
      events.forEach((e) => {socket.on(e, (res) => {console.log("SOCKET: ", e, res)})})
    }
  }, [socket])

  return (
    <>
      <div
        className="videoApp"
        style={{ display: `${valid ? "block" : "none"}` }}
      >
        <div style={{ position: 'relative' }} className="header">

          {/* mobile bell */}
          <div ref={notificationRef} className="bell s-mobile-bell tt">
            <p className="bell__point hide"></p>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip className="tooltip">Notifications</Tooltip>
              }
            >
              <label>
                <Notifications
                  className="bell__icon"
                  onClick={notificationHandler}
                />
              </label>
            </OverlayTrigger>

            {interviewDetails?.student ? (
              <p className="notification-mobile hide tttext_vc">
                You have another Mock Interview at{" "}
                {giveTime(nextInterviewTime)}. Please join it after
                completing the ongoing mock interview and sending the
                feedback to the student
              </p>
            ) : (
              <p className="notification-mobile hide tttext_vc">
                You have another Mock Interview at{" "}
                {giveTime(nextInterviewTime)}. Please join it after
                completing and rating the ongoing mock interview
              </p>
            )}
          </div>


          <div className="logo-video">
            {/* desktop navbar */}
            <div className="left__header s-desktop-video-details">
              <Box sx={{width: "100%", display: "flex", justifyContent:"space-evenly"}}>
              <div>
                <Button variant="contained" onClick={() => {
                  setShowModal(true)
                }}>
                  Details
                </Button>
                <Modal sx={{}} open={showModal} onClose={() => setShowModal(false)} className="s-desktop-video-details">
                  <Box sx={{minWidth: "max-content", bgcolor:'#383838', borderRadius:"10px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, boxShadow: 24, p: 4 }}>
                    <IconButton aria-label="close" onClick={() => setShowModal(false)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                      <Close sx={{fill: "white", zoom: 1.2}}/>
                    </IconButton>
                    <Typography variant="h5" sx={{fontWeight: "bold"}} component="h2" gutterBottom>
                      Interview Details
                    </Typography>
                    {interviewDetails?.student ? (
                      <Box sx={{ p: 1,  maxWidth: "600px", }}>
                        <Typography variant="body1"><b>Mock Interview of:</b>&nbsp;{interviewDetails.student.fname}&nbsp;{interviewDetails.student.lname},&nbsp;
                        {programValues[interviewDetails.student.program]},&nbsp;{interviewDetails.student.year},&nbsp;{interviewDetails.student.branch},&nbsp;
                        {interviewDetails.student.institute}</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ p: 1,  maxWidth: "600px", }}>
                        <Typography variant="body1"><b>Mock Interview by:</b>&nbsp;{interviewDetails?.interviewer?.fname}&nbsp;{interviewDetails?.interviewer?.lname},&nbsp;
                        {interviewDetails?.interviewer?.designation},&nbsp;
                        {interviewDetails?.interviewer?.currCompany}</Typography>
                      </Box>
                    )}
                    {/* {interviewDetails?.interviewer ?  : null} */}
                    <Box sx={{ display: 'flex', px: 1, pt: 1, pb: 0 }}>
                      <Typography variant="body1"><b>Profile:</b></Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>{interviewInfo?.profile}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', px: 1, pt:0 }}>
                      <Typography variant="body1"><b>Slot:</b></Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>{giveTime(interviewInfo?.fromTime)} - {giveTime(interviewInfo?.toTime)}, {interviewInfo?.date}</Typography>
                    </Box>
                    <Typography variant="body1" sx={{ ml: 1 }}><b>Mock ID:</b> {ROOM_ID}</Typography>
                  </Box>
                </Modal>
              </div>
              {interviewDetails? (interviewDetails.student? (<Button
                      variant="outlined"
                      color="warning"
                      style={{ padding: "0px 5px", margin: "0 5px" }}
                      onClick={() => {
                        downloadPDF(interviewDetails?.student.resume);
                      }}
                    >
                      Student's Resume
              </Button>):(<Button
                      variant="outlined"
                      color="warning"
                      style={{ padding: "0px 5px", margin: "0 5px" }}
                      onClick={() => {
                        interviewDetails?.interviewer.isUnavailable
                          ? toast.loading(
                              `Sorry! the user has ${interviewDetails?.interviewer.isDisable ? "disabled" : ""} ${
                                interviewDetails?.interviewer.isDelete ? "deleted" : ""
                              } this account`,
                              {
                                ...toastStyle.loading,
                                position: "top-center",
                                duration: 2000,
                              }
                            )
                          : window.open(
                              `${FRONTEND_BASE}/s/interviewer/${interviewDetails?.interviewer.id}`,
                              "_blank"
                            );
                      }}
                    >
                      Interviewer's Profile
              </Button>)): null 
              }
              </Box>
              {/* <p>
                <strong>Mock Interview </strong>
                {interviewDetails?.student ? (
                  <>
                    of: {interviewDetails?.student.fname}  {interviewDetails?.student.lname},{" "}
                    {interviewDetails.student != - undefined ? programValues[interviewDetails.student.program] : null},{" "}
                    {interviewDetails?.student.year},{" "}
                    {interviewDetails?.student.branch},{" "}

                    {interviewDetails?.student.institute}{" "}
                    <Button
                      variant="outlined"
                      color="warning"
                      style={{ padding: "0px 5px", margin: "0 5px" }}
                      onClick={() => {
                        downloadPDF(interviewDetails?.student.resume);
                      }}
                    >
                      Resume
                    </Button>
                  </>
                ) : (
                  <>
                    by: {interviewDetails?.interviewer.fname} {interviewDetails?.interviewer.lname},{" "}
                    {interviewDetails?.interviewer.designation},{" "}
                    {interviewDetails?.interviewer.currCompany}
                  </>
                )}
              </p>
              <div style={{ display: "flex" }}>
                <p>
                  <strong>Profile: </strong>
                  {interviewInfo?.profile}
                </p>
                <p style={{ marginLeft: "20px" }}>
                  <strong>Slot: </strong>
                  {giveTime(interviewInfo?.fromTime)} -{" "}
                  {giveTime(interviewInfo?.toTime)}, {interviewInfo?.date}
                </p>
              </div>
              <p>
                <strong>Mock ID: </strong>
                {ROOM_ID}
              </p> */}
            </div>
            {/* mobile navbar */}
            <div className="left__header s-mobile-video-details">
              <button onClick={() => setShowDetails(!showDetails)} className="button-13">Details</button>
            </div>

            <div className="center__header">
              <h1 style={{ color: '#02A3E8', fontSize: 'clamp(1.4rem,3vw,2rem)' }}>interwiu.com</h1>
              <div className="center__text">
                <h1 style={{ color: "white", fontSize: 'clamp(0.8rem,3vw,2rem)' }}>MOCK INTERVIEW</h1>
                <div className="bell s-desktop-bell">
                  <p className="bell__point hide"></p>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip">Notifications</Tooltip>
                    }
                  >
                    <label>
                      <Notifications
                        className="bell__icon"
                        style={{ margin: "10px 0 0" }}
                        onClick={notificationHandler}
                      />
                    </label>
                  </OverlayTrigger>

                  {interviewDetails?.student ? (
                    <p className="notification hide">
                      You have another Mock Interview at{" "}
                      {giveTime(nextInterviewTime)}. Please join it after
                      completing the ongoing mock interview and sending the
                      feedback to the student
                    </p>
                  ) : (
                    <p className="notification hide">
                      You have another Mock Interview at{" "}
                      {giveTime(nextInterviewTime)}. Please join it after
                      completing and rating the ongoing mock interview
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="right__header">
              <p className="timer" style={{color:`${seconds > 1680?"red":"white"}`}} name={seconds} color="green">
                <span>{arr[0] < 10 ? "0" + arr[0] : arr[0]} :</span>
                <span>{arr[1] < 10 ? "0" + arr[1] : arr[1]} :</span>
                <span>{arr[2] < 10 ? "0" + arr[2] : arr[2]}</span>
              </p>
              
              {accountId.startsWith("I")
                ? seconds > FEEDBACK_TIME && (
                  <Link
                    to={{
                      pathname:"/feedback/interviewer/vc/" + ROOM_ID +"/vc",
                      
                    }}
                    target="_blank" 
                    className="feedback__button-mobile"
                    style={{
                      pointerEvents: feedback?"none":"auto",
                    }}
                    // state={{redirectedFrom: "vc"}}
                  >
                    <Button
                      className={"feedback__button"+(feedback?"": " blink")}
                      variant="outlined"
                      // disabled={feedback}
                      sx={{
                        padding: "0px 5px",
                        margin: "5px 5px",
                        color: "white",
                        border: "1px solid gray",
                      }}
                    >
                      {!feedback?"Send Feedback":"Feedback Sent"}
                    </Button>
                  </Link>
                )
                  : seconds>FEEDBACK_TIME && <RateAndReview mock_id={ROOM_ID} setRated={setRated} rated={rated}/>}
                  {/* : seconds > 0 && <RateAndReview className="blink" mock_id={ROOM_ID} />} */}

              {/* replacing bell to center from here
              <div className="bell">
                <p className="bell__point hide"></p>
                <Notifications
                  className="bell__icon"
                  style={{ margin: "10px 0 0" }}
                  onClick={notificationHandler}
                />
                {interviewDetails?.student ? (
                  <p className="notification hide">
                    You have another Mock Interview. Please join it after
                    completing the ongoing mock interview and sending the
                    feedback to the student
                  </p>
                ) : (
                  <p className="notification hide">
                    You have another Mock Interview. Please join it after
                    completing and rating the ongoing mock interview
                  </p>
                )}
              </div> 
              replacing bell to center from here*/}
            </div>
          </div>

        </div>
        <div className="main">

          

          {/* mobile headder detailss */}
          {showDetails && (
            <div className="s-mobile-video-details__title">
              <p>
                <strong>Mock Interview </strong>
                {interviewDetails?.student ? (
                  <>
                    of: {interviewDetails?.student.fname}  {interviewDetails?.student.lname},{" "}
                    {/* {interviewDetails.student != - undefined ? programValues[interviewDetails.student.program] : null},{" "} */}
                    {interviewDetails?.student.year},{" "}
                    {interviewDetails?.student.branch},{" "}
                    {getProgram(interviewDetails?.student.program)},{" "}
                    {interviewDetails?.student.institute}{" "}

                  </>
                ) : (
                  <>
                    by: {interviewDetails?.interviewer.fname} {interviewDetails?.interviewer.lname},{" "}
                    {interviewDetails?.interviewer.designation},{" "}
                    {interviewDetails?.interviewer.currCompany}
                  </>
                )}
              </p>
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <p>
                  <strong>Profile: </strong>
                  {interviewInfo?.profile}
                </p>
                <p >
                  <strong>Slot: </strong>
                  {giveTime(interviewInfo?.fromTime)} -{" "}
                  {giveTime(interviewInfo?.toTime)}, {interviewInfo?.date}
                </p>
              </div>
              <p>
                <strong>Mock ID: </strong>
                {ROOM_ID}
              </p>
              {interviewDetails?.student ? (
                <Button
                  variant="outlined"
                  color="warning"
                  style={{ margin: "10px 5px" }}
                  onClick={() => {
                    downloadPDF(interviewDetails?.student.resume);
                  }}
                >
                  View Student's Resume
                </Button>) : <Button
                  variant="outlined"
                  color="warning"
                  style={{ margin: "10px 5px" }}
                  onClick={() => {
                    interviewDetails?.interviewer.isUnavailable
                      ? toast.loading(
                          `Sorry! the user has ${interviewDetails?.interviewer.isDisable ? "disabled" : ""} ${
                            interviewDetails?.interviewer.isDelete ? "deleted" : ""
                          } this account`,
                          {
                            ...toastStyle.loading,
                            position: "top-center",
                            duration: 2000,
                          }
                        )
                      : window.open(
                        `${FRONTEND_BASE}/s/interviewer/${interviewDetails?.interviewer.id}`,
                        "_blank"
                      );
                  }}
                >
                View Interviewer's Profile
              </Button>}
              <div onClick={() => setShowDetails(!showDetails)} className="close_details_mobile_vc">
                <IoCloseCircle style={{ fontSize: '25px', color: 'white' }} />
              </div>
            </div>
          )}

          <div className="main__left showMain">
            <div className="videos__group">
              <div id="video-grid">
                {/* Trial for canvas */}
                {/* Trial for canvas */}
                <section className="sketch show__sketch" id="sketch">
                  <canvas className="board" id="board"></canvas>
                </section>
                {/* Trial for canvas */}
                {/* Trial for canvas */}
              </div>
            </div>
            <div className="options">
              <div className="options__left">
                {video_div ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip">TURN Video ON</Tooltip>
                    }
                  >
                    <div
                      id="stopVideo"
                      className="options__button"
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={stopVideo}

                    >
                      <FontAwesomeIcon
                        icon={faVideoSlash}
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip">TURN Video OFF</Tooltip>
                    }
                  >
                    <div id="stopVideo" className="options__button"
                        onClick={stopVideo}
                        >
                      <FontAwesomeIcon
                        icon={faVideoCamera}
                      />
                    </div>
                  </OverlayTrigger>
                )}
                {mute ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Unmute</Tooltip>}
                  >
                    <div
                      id="muteButton"
                      className="options__button"
                      onClick={muteHandler}
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      <FontAwesomeIcon icon={faMicrophoneAltSlash} />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Mute</Tooltip>}
                  >
                    <div
                      id="muteButton"
                      className="options__button"
                      onClick={muteHandler}
                    >
                      <FontAwesomeIcon icon={faMicrophone} />
                    </div>
                  </OverlayTrigger>
                )}
                {/* <div id="muteButton" className="options__button">
              <FontAwesomeIcon icon={faTabletScreenButton} />
            </div> */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="tooltip">Leave Call</Tooltip>}
                >
                  <div
                    id="exitButton"
                    className="options__button"
                    style={{ backgroundColor: "red" }}
                    onClick={leaveHandler}
                  >
                    <CallEnd style={{ color: "white" }} />
                  </div>
                </OverlayTrigger>

                {/* ------------------END THE CALL FOR ALL-------------------- */}
                {/* ------------------END THE CALL FOR ALL-------------------- */}
                {/* <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="tooltip">End Call</Tooltip>}
              >
                <div
                  id="inviteButton"
                  className="options__button screenSharing"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={endCallHandler}
                >
                  <Logout />
                </div>
              </OverlayTrigger> */}
                {/* ------------------END THE CALL FOR ALL-------------------- */}
                {/* ------------------END THE CALL FOR ALL-------------------- */}

                {screenShare ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip">Cancel Screen Share</Tooltip>
                    }
                  >
                    <div
                      id="inviteButton"
                      className="options__button"
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={stopScreenSharing}
                    >
                      <StopScreenShareOutlined
                        onClick={() => {
                          setScreenShare(!screenShare);
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip">Screen Share</Tooltip>
                    }
                  >
                    <div
                      id="inviteButton"
                      className="options__button screenSharing"
                      onClick={startScreenSharing}
                    >
                      <PresentToAll />
                    </div>
                  </OverlayTrigger>
                )}
                
                  {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Annotations</Tooltip>}
                  >
                    <div
                      id="annotation"
                      className="options__button"
                      onClick={annotationHandle}
                    >
                      <CreateIcon />
                    </div>
                  </OverlayTrigger> */}
                  {(showMessage || window.innerWidth<700) && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="tooltip">Chats</Tooltip>}
                    ><>
                      <div
                        
                        className="relative options__button"
                        onClick={commentHandler}
                      >
                        {newChats > 0 && <Box 
                          sx={{
                            textAlign: "center",
                            fontSize: "10px", 
                            color: "white",
                            position: 'absolute',
                            width:"15px",
                            height: "15px",
                            top: -8,
                            left: -8, 
                            pointerEvents: 'none', 
                            borderRadius: '50%',
                            background: "red" 
                          }}>{newChats}
                      </Box>}
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      </>
                    </OverlayTrigger>
                  )}
              </div>
              {/* <div className="options__right">
                <div className="" style={{ display: "flex" }}>
                  
                </div>
              </div> */}
            </div>
          </div>
          <div className="main__right showChat">
            {!showMessage && (
              <div
                id="comment__cancel"
                className=""
                onClick={commentHandler}
                style={{ cursor: "pointer", zIndex: 1 }}
              >
                <FontAwesomeIcon icon={faClose} color="white" size="1x" />
              </div>
            )}
            <div className="main__chat_window">
              <div className="messages" style={{ paddingTop: "20px" }}>
                {messages.map((message, index) => {
                  return (
                    message.accountId !== accountId ? (
                      <OwnerMessages
                        index={index}
                        key={index}
                        name={message.name}
                        text={message.text?.split('').map((text, index) => {
                          if(message.text.indexOf('\n',index-1) === index){
                            return <br key={index}/>
                          }
                          else{
                            return text;
                          }
                        })}
                        url={message.url}
                      />
                    ) : (
                      <PeerMessages
                        index={index}
                        key={index}
                        name={message.name}
                        text={message.text?.split('').map((text, index) => {
                          if(message.text.indexOf('\n',index-1) === index){
                            return <br key={index}/>
                          }
                          else{
                            return text;
                          }
                        })}
                        url={message.url}
                      />
                    ))
                })}
              </div>
            </div>
            <div className="main__message_container mobile-textarea-container">
              {file && (
                <div className="file__upload">
                  <p
                    className="message__text"
                    style={{
                      flex: "1",
                      display: "flex",
                      maxHeight: "100px",
                      backgroundColor: "lightpink",
                    }}
                  >
                    <span style={{maxWidth: "65%",}}>{file.name.substring(0, 30)}...</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="tooltip">Send File</Tooltip>}
                    >
                      <label
                        id="send"
                        className="options__button"
                        onClick={onFileUpload}
                        style={{ backgroundColor: "green", color: "white" }}
                      >
                        <FontAwesomeIcon icon={faFileArrowUp} />
                      </label>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="tooltip">Cancel File</Tooltip>
                      }
                    >
                      <label
                        id="send"
                        className="options__button"
                        onClick={onFileCancelUpload}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        <FontAwesomeIcon icon={faFileCircleXmark} />
                      </label>
                    </OverlayTrigger>
                  </p>
                </div>
              )}
              <textarea
                id="chat_message-mobile"
                type="text"
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && e.target.value !== "") {
                //     socket.emit("message", e.target.value);
                //     e.target.value = "";
                //   }
                // }}
                autoComplete="off"
                placeholder="Type message here..."
              />
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="tooltip">Send Message</Tooltip>}
              >
                <button id="send" className="options__button send-btn">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="tooltip">Choose File</Tooltip>}
              >
                <label
                  id="send"
                  htmlFor="file__input"
                  className="options__button"
                  style={{
                    width: "40px",
                    aspectRatio: 1
                  }}
                >
                  <FontAwesomeIcon icon={faPaperclip} />
                </label>
              </OverlayTrigger>

              <input
                type="file"
                id="file__input"
                style={{ display: "none" }}
                onChange={onFileChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCall;
