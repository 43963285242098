import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./FeedbackStudent.css";
import {
  GET_UPCOMING_MOCK_INTERVIEWS,
  GET_REQUEST_INTERVIEWS,
  FEEDBACK,
  GET_INETRVIEWER_INFO,
  GET_STUDENT_INFO,
} from "../../../constants/endpoints";
import { useParams } from "react-router-dom";
import {
  Typography,
  Radio,
  RadioGroup,
  Button,
  FormControl,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
import { giveTime, giveYears } from "../utils";
import UserContext from "../../../contexts/UserContext";


const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FeedbackStudent = () => {
  const { user } = useContext(UserContext);
  const { mock_id } = useParams();
  const [interviewInfo, setInterviewInfo] = useState();
  const [interviewDetails, setInterviewDetails] = useState()
  const [selectedValue, setSelectedValue] = useState(1);
  const [textValue, setTextValue] = useState();
  const [student, setStudent] = useState(null)
  const [details, setDetails] = useState(0)

  useEffect(() => {

    // getting upcoming mock interviewes to check if the form link is valid or not
    axios
      .get(`${GET_UPCOMING_MOCK_INTERVIEWS + "/" + mock_id}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        alert("Invalid Link");
        window.open("about:blank", "_self");
        window.close();
      });

    // getting interview details and from that getting interviewer details for header
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + mock_id}`)
      .then((res) => {
        setInterviewDetails(res.data)
        let interviewer_id = res.data.interviewerId;
        console.log(res.data);
        let student_id = res.data.studId;
        axios
          .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
          .then((res) => {
            setInterviewInfo(res.data);
          })
          .catch((err) => {
            console.log(err);
          });

        axios.get(`${GET_STUDENT_INFO}/${student_id}`).then(res => {
          setStudent(res.data)
          console.log(res.data);
        }).catch(err => {
          console.log(err);
        })
      })
      .catch((err) => {
        console.log(err);
      });


    axios.get(FEEDBACK + "/" + mock_id).then(res => {
      setSelectedValue(res.data.scores)
      setTextValue(res.data?.text);
    }).catch(err => {
      console.log(err);
    })

    console.log(student);

  }, []);


  const textChange = (e) => {
    if (e.target.name === "q1") {
      setTextValue({ ...textValue, q1: e.target.value });
    } else if (e.target.name === "q2") {
      setTextValue({ ...textValue, q2: e.target.value });
    } else if (e.target.name === "q3") {
      setTextValue({ ...textValue, q3: e.target.value });
    } else if (e.target.name === "q4") {
      setTextValue({ ...textValue, q4: e.target.value });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "q1") {
      setSelectedValue({ ...selectedValue, q1: e.target.value });
    } else if (e.target.name === "q2") {
      setSelectedValue({ ...selectedValue, q2: e.target.value });
    } else if (e.target.name === "q3") {
      setSelectedValue({ ...selectedValue, q3: e.target.value });
    } else if (e.target.name === "q4") {
      setSelectedValue({ ...selectedValue, q4: e.target.value });
    }
  };
  console.log(details)

  return (
    <div className="feedback">
      <div className="top__header">
        <Typography variant="h3" style={{ fontSize: 'clamp(2rem,5vw,3rem', padding: '10px' }}>FEEDBACK FROM INTERVIEWER</Typography>
        <div className="flex-col gap-3 flex s-desktop-card" style={{ alignItems: "flex-start", flexWrap: 'wrap', width: '100%', maxWidth: '900px', margin: 'auto', flexDirection: 'row' }}>
          <p style={{ margin: "0 8px", color: 'black', alignSelf: 'baseline' }}>
            {" "}
            <strong>Slot: </strong>
            {giveTime(interviewDetails?.fromTime)} -{" "}
            {giveTime(interviewDetails?.toTime)}, {interviewDetails?.date}
          </p>
          <p style={{ margin: "0 8px", color: 'black', alignSelf: 'baseline' }}>
            <strong>Profile: </strong>
            {interviewDetails?.profile}
          </p>
          <p style={{ margin: "0 10px", color: 'black', alignSelf: 'baseline' }}><strong>Mock ID: </strong>{mock_id}</p>
        </div>

        <div className="flex-col gap-3 s-mobile-card" style={{ alignItems: "flex-start", flexWrap: 'wrap', width: '100%', maxWidth: '900px', margin: 'auto' }}>
          <p style={{ margin: "0 8px", color: 'black', alignSelf: 'baseline' }}>
            {" "}
            <strong>Slot: </strong>
            {giveTime(interviewDetails?.fromTime)} -{" "}
            {giveTime(interviewDetails?.toTime)}, {interviewDetails?.date}
          </p>
          <p style={{ margin: "0 8px", color: 'black', alignSelf: 'baseline' }}>
            <strong>Profile: </strong>
            {interviewDetails?.profile}
          </p>
          <p style={{ margin: "0 10px", color: 'black', alignSelf: 'baseline' }}><strong>Mock ID: </strong>{mock_id}</p>
        </div>
        <div className="flex-col gap-3 s-desktop-card" style={{ alignItems: "flex-start", flexWrap: 'wrap', width: '100%' }}>

          <div style={{ display: "flex", marginTop: "10px", flexWrap: 'wrap' }}>
            <p style={{ margin: "0 10px" }}><strong>Name of Interviewer: </strong>{interviewInfo?.fname + " " + interviewInfo?.lname}</p>
            <p style={{ margin: "0 10px" }}><strong>Designation: </strong>{interviewInfo?.designation}</p>
            <p style={{ margin: "0 10px" }}><strong>Company: </strong>{interviewInfo?.currCompany}</p>


          </div>
          <div style={{ display: "flex", flexWrap: 'wrap' }}>
            <p style={{ margin: "0 10px" }}><strong>Name of Student: </strong> {student?.fname + " " + student?.lname}</p>
            <p style={{ margin: "0 10px" }}><strong>Program: </strong> {student &&
              student?.program === "btech"
              ? "B.Tech"
              : student?.program === "dual"
                ? "Dual Degree (B.Tech + M.Tech)"
                : student?.program === "dualbsms"
                  ? "Dual Degree (BS + MS)"
                  : student?.program === "mtech"
                    ? "M.Tech"
                    : student?.program === "ms"
                      ? "MS (by Research)"
                      : student?.program === "mba"
                        ? "MBA"
                        : student?.program === "msc"
                          ? "M.Sc"
                          : student?.program === "ima"
                            ? "Intergrated MA"
                            : "PhD"}</p>
            <p style={{ margin: "0 10px" }}><strong>Year: </strong>{student && student?.year}</p>
            <p style={{ margin: "0 10px" }}>
              <strong>Branch: </strong>
              {student?.branch}
            </p>
            <p style={{ margin: "0 10px" }}><strong>Institute: </strong>{student?.institute}</p>

          </div>
        </div>


        <div className="flex-col gap-3 s-mobile-card" style={{ alignItems: "flex-start", flexWrap: 'wrap', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            <button style={{ flex: '1', margin: '0.5rem' }} onClick={() => setDetails(details == 1 ? 0 : 1)} className={`button-13 ${details == 1 ? 'selected-details' : ''}`} role="button">Interviewer Details</button>
            <button style={{ flex: '1', margin: '0.5rem', marginLeft: '0rem' }} onClick={() => setDetails(details == 2 ? 0 : 2)} className={`button-13 ${details == 2 ? 'selected-details' : ''}`} role="button">Student Details</button>
          </div>
          {
            details == 1 ? (
              <div>
                <p style={{ margin: "0 10px", color: '#19579f' }}>{interviewInfo?.fname + " " + interviewInfo?.lname}, {interviewInfo?.designation}, {interviewInfo?.currCompany}</p>
              </div>
            ) : null
          }
          {
            details == 2 ? (
              <div>
                <p style={{ margin: "0 10px", color: '#19579f' }}>{student?.fname + " " + student?.lname}, {student &&
                  student?.program === "btech"
                  ? "B.Tech"
                  : student?.program === "dual"
                    ? "Dual Degree (B.Tech + M.Tech)"
                    : student?.program === "dualbsms"
                      ? "Dual Degree (BS + MS)"
                      : student?.program === "mtech"
                        ? "M.Tech"
                        : student?.program === "ms"
                          ? "MS (by Research)"
                          : student?.program === "mba"
                            ? "MBA"
                            : student?.program === "msc"
                              ? "M.Sc"
                              : student?.program === "ima"
                                ? "Intergrated MA"
                                : "PhD"}, {student && student?.year}, {student?.branch}, {student?.institute}</p>
              </div>
            ) : null
          }
        </div>

      </div>
      <div className="questions">
        <div className="q__1 question">
          <Typography variant="p">
            1. Your knowledge, skillset, and experience in the desired role
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}
                  control={
                    <Radio
                      disabled
                      className="radio"
                      checked={selectedValue?.q1 == ele}
                      value={ele}
                      name="q1"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <p style={{ marginTop: "10px", color: "gray" }}>Suggestions for Improvement </p>
            <TextareaAutosize
              className="text__box"
              minRows={4}
              name="q1"
              value={textValue?.q1}
              placeholder="..."
            />
          </div>
        </div>
        <div className="q__2 question">
          <Typography variant="p">
            2. Your approach and ability to solve a problem
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}
                  control={
                    <Radio
                      disabled

                      className="radio"
                      checked={selectedValue?.q2 == ele}
                      value={ele}
                      name="q2"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>

          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <p style={{ marginTop: "10px", color: "gray" }}>Suggestions for Improvement </p>
            <TextareaAutosize
              className="text__box"
              minRows={4}
              name="q2"
              value={textValue?.q2}
              placeholder="..."
            />
          </div>
        </div>
        <div className="q__3 question">
          <Typography variant="p">
            3. Your introduction
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}
                  control={
                    <Radio
                      disabled

                      className="radio"
                      checked={selectedValue?.q3 == ele}
                      value={ele}
                      name="q3"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <p style={{ marginTop: "10px", color: "gray" }}>Suggestions for Improvement </p>
            <TextareaAutosize
              className="text__box"
              minRows={4}
              name="q3"
              value={textValue?.q3}
              placeholder="..."
            />
          </div>
        </div>
        <div className="q__4 question">
          <Typography variant="p">
            4. Your body language and communication skills
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}
                  value="bottom"
                  control={
                    <Radio
                      disabled

                      className="radio"
                      checked={selectedValue?.q4 == ele}
                      value={ele}
                      name="q4"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <p style={{ marginTop: "10px", color: "gray" }}>Suggestions for Improvement </p>
            <TextareaAutosize
              className="text__box"
              minRows={4}
              name="q4"
              value={textValue?.q4}
              placeholder="..."
            />
          </div>
        </div>
        <div className="q__5 question">
          <Typography variant="p">
            5. Action Plan for you to improve, and thus ensure the success
          </Typography>
          {(textValue?.q5)?.length > 0 ? textValue?.q5.map((ele, index) => {
            return (<>
              <p style={{ marginTop: "10px", color: "gray" }}>Action Item {index + 1}</p>
              <TextareaAutosize
                key={index}
                className="text__box"
                minRows={4}
                name="q5"
                placeholder="Action Plan"
                value={ele}
              /></>)
          }) : <>
            <p style={{ marginTop: "10px", color: "gray" }}>Action Item 1</p>

            <TextareaAutosize
              className="text__box"
              minRows={4}

              name="q5"
              placeholder="..."
            /></>}

        </div>
      </div>
      <button style={{margin:'auto', borderColor:'darkred',color:'darkred'}} className="s-mobile-card button-13" onClick={() => {
        window.open("about:blank", "_self");
        window.close();
      }}>Close</button>
    </div>
  );
};

export default FeedbackStudent;
