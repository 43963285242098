import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import RateAndReview from "../RateAndReview";
import { FEEDBACK } from "../../../constants/endpoints";
import {
  GET_REQUEST_INTERVIEWS,
  GET_STUDENT_INFO,
  GET_INETRVIEWER_INFO,
} from "../../../constants/endpoints";
import { giveYears } from "../utils";
import "./InterviewerEnd.css";
import { programValues } from "utility/helper";
import TextLoading from "./TextLoading";

const InterviewerEnd = () => {
  const { mock_id: ROOM_ID } = useParams();
  const [interviewerDetails, setInterviewerDetails] = useState();
  const [studentDetails, setStudentDetails] = useState();
  const [interviewInfo, setInterviewInfo] = useState();
  const [feedback, setFeedback] = React.useState(false);

  

  const handleVisibility = () => {
    console.log(document.visibilityState)
    console.log(studentDetails)
  }
  useEffect(() => {
    // console.log(document.visibilityState)
    document.addEventListener("visibilitychange", handleVisibility)
        if(!feedback)
        axios.get(`${FEEDBACK}/${ROOM_ID}`)
        .then((res) => {
          if(res.data) setFeedback(true)
        })
        .catch((err) => {
          setFeedback(false)
        })
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility)
    }
  }, [document.visibilityState])


  useEffect(() => {

    // getting data about the interview and make link
    if(!feedback)
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
      .then((res) => {
        setInterviewInfo(res.data);
        let student_id = res.data.studId;
        let interviewer_id = res.data.interviewerId;

        axios
          .get(`${GET_STUDENT_INFO}/${student_id}`)
          .then((res) => {
            console.log('response',res.data);
            setStudentDetails(res.data);

          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
            console.log(err);
          });

        axios
          .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
          .then((res) => {
            setInterviewerDetails(res.data);
          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
          });
      })
      .catch((err) => {
        alert("Invalid Link");
      });
  }, []);

  return (
    <div className="interviewend">
      <p style={{ margin: "20px 0" }}>
        Hope you had a Great Experience with the Mock Interview!
      </p>
      {!feedback && 
          <p style={{ margin: "20px 0" }}>
            Please spare 2 minutes to provide feedback to the Student{" "}
            {studentDetails ?studentDetails?.fname +
              " " +
              studentDetails?.lname +
              ", " +
              programValues[studentDetails?.program] +
              ", " +
              studentDetails?.year +
              ", " +
              studentDetails?.branch +
              ", " +
              studentDetails?.institute:<TextLoading text="loading..."/>}
          </p>
      }
      <Link to={"/feedback/interviewer/vc/" + ROOM_ID + "/end"} target="_blank" style={{pointerEvents: feedback?"none":"auto"}}>
        <Button
          className=""
          variant={feedback?"outlined":"contained"}
          style={{
            padding: "0px 5px",
            margin: "5px 5px",
            border: "1px solid gray",
          }}
          color="primary"
          onClick={() => {
            window.close();
          }}
        >
          {!feedback?"Send Feedback":"Feedback Sent"}
        </Button>
      </Link>
        
      <p style={{ margin: "40px 0" }}>
        If this mock interview session is yet to be completed, you can&nbsp;
        {interviewerDetails?.id? <Link
          to={
            "/valid/vc/" +
            ROOM_ID +
            "/" +
            interviewerDetails?.id +
            "/" +
            interviewerDetails?.fname
          }
          // to={
          //   "/valid/vc/" +
          //   ROOM_ID +
          //   "?id=" +
          //   interviewerDetails?.id +
          //   "&name=" +
          //   interviewerDetails?.fname
          // }
          style={{ color: "blue", textDecoration: "underline" }}
        >
          
          Re-Join
        </Link>: <TextLoading text="Re-Join"/>}
        &nbsp;it
      </p>
    </div>
  );
};

export default InterviewerEnd;
