import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import ReactMarkdown from "markdown-to-jsx";

import Navbarlogedin from "./Navbarlogedin";
import {
  TextField,
  Grid,
  Box,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem, Select,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import "./CompletedInterviews.css";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import ErrorIcon from "@mui/icons-material/Error";
import UpcomingInterview from "./cards/UpcomingInterview2";
import {
  BASE,
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../constants/endpoints.js";
import {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
} from "./days";
import { COMPANY_NAME } from "../../constants/variables.js";
import {
  compareDate,
  compareSlots,
  getCompanyLogo,
  t24HDecimalTo12HFormat,
  toastStyle,
  compareTime,
} from "../../utility/helper.js";
import Modal from "@mui/material/Modal";
import { Calendar } from "react-multi-date-picker";
import moment from "moment-timezone";
import UserContext from "../../contexts/UserContext.js";
import toast from "react-hot-toast";
import SlotAlreadyBooked from "./popups/SlotAlreadyBooked.js";
import Draggable from "react-draggable";
import { Link, useNavigate } from "react-router-dom";
import content from "../Home/data/footerDialog.js";
import { AiTwotoneCalendar } from "react-icons/ai";
import { InfoOutlined } from "@mui/icons-material";
/*imports end */
window.scrollTo(0, 0);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "fit-content",
    },
  },
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function MarkdownListItem(props) {
  return <Box component="li" sx={{ mt: 1, typography: "body1" }} {...props} />;
}
const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h4",
        component: "h1",
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: "h6", component: "h2" },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: "subtitle1" },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "caption",
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem,
    },
  },
};
const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-8/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
/**=============================================================================== */
const Issue = () => {
  // window.scrollTo(0, 0);
  const { user } = useContext(UserContext);
  const [cards, setCards] = React.useState(null);
  const [interviewer, setInterViewer] = React.useState(null);
  const [slotsArray, setSlotsArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [verified, setVerified] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [requestData, setRequestData] = useState({
    studentId: cards?.studId,
    interviewerId: interviewer?.id,
    requestId: "#Yet_to_be_generated",
    slot: "",
    date: moment(selectedDate).format("DD/MM/YYYY"),
    profile: "",
    requestStatus: 0,
    paymentId: "nan",
    paymentStatus: "Not Done",
  });
  const [date, setDate] = React.useState(new Date());
  const [updated, setUpdated] = useState(false);
  const [status, setStatus] = useState();
  const [changingInput, setChangingInput] = useState(false)
  const navigate = useNavigate()
  const subheading = "",
    heading = (
      <>
        <span tw="text-changed-100">Reschedule your Mock Interview</span>
        <wbr />
      </>
    ),
    description =
      "Please Enter the Mock Interview ID and then proceed to reschedule your mock interview.",
    textOnLeft = true;
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [data, setData] = React.useState({
    textofArea: "",
  });

  const rescheduleErrorMessage = {
    accepted: "Sorry! You cannot request for rescheduling this mock interview again as this mock interview has been already rescheduled once successfully",
    declined: "Sorry! You cannot request for rescheduling this mock interview again as a reschedule request for this mock interview has been already declined by the interviewer",
    sent: "Sorry! You cannot request for rescheduling this mock interview again as a reschedule request for this mock interview is already in process",
    failed: "Sorry! You cannot request for rescheduling this mock interview again as you have already sent a reschedule request for this mock interview earlier"
  }

  const handleChangeofBox = (e) => {
    const value = e.target.value;

    setData({
      ...data,
      [e.target.name]: value,
    });
  };
  const [checked, setChecked] = React.useState("");
  const handleChangeRadio = (event) => {
    if (event.target.value !== 5 || event.target.value !== "5") {
      setError(false);
    }
    console.log(checked);
    console.log(data.textofArea);
    setChecked(event.target.value);
  };
  const [error, setError] = useState(false);
  const [inputs, setInputs] = React.useState({
    idVerify: "",
  });

  const handleChange = (event) => {
    setChangingInput(true)
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  async function fetchData() {
    setVerified(false)
    setChangingInput(false)
    console.log(inputs.idVerify);
    if (inputs.idVerify === "") {
      window.alert("Please enter a valid Mock Interview ID");
      return;
    }
    await axios
      .get(`${BASE}/send/getRequest/${inputs.idVerify}`)
      .then((response) => {
        console.log(response.data);
        setVerified(true)
        if (response.data.date) {
          setVerified(true);
        }
        setCards(response.data);
        axios
          .get(`${BASE}/int/interviewer/${response.data.interviewerId}`)
          .then((res) => {
            console.log(res.data);
            setInterViewer(res.data);
          });
      })
      .catch((error) => {
        window.alert("Please enter a valid Mock Interview ID");
        setStatus("invalid");
        setVerified(false)
        console.error(error);
      });
  }
  const [isSlotAlreadyBooked, setisSlotAlredyBooked] = useState(false);
  const slotBookedModalHandler = (data) => {
    setisSlotAlredyBooked(data);
  };
  let currDate = moment(new Date()).format("DD/MM/YYYY");
  currDate = currDate[0] === "0" ? currDate.slice(1) : null;
  useEffect(() => {
    const result = compareTime(cards?.fromTime, cards?.toTime, cards?.date);
    setStatus(result);
    console.log(status);
    console.log(currDate, " ", cards?.date);
  }, [cards]);
  const checkInterviewerAvailability = async (requestData) => {
    console.log("requestData :-", requestData);
    let searchStr = "";
    searchStr += "?id=" + requestData?.interviewerId;
    searchStr += "&profile=" + String(requestData?.profile);
    searchStr +=
      "&time=" +
      requestData?.slot.slice(1).split(":")[0] +
      "," +
      requestData?.slot.slice(1).split(":")[1];
    searchStr += "&date=" + requestData?.date;

    console.log("Rakesh Search STring");
    console.log(searchStr);
    let isInterviewerAvailable = false;
    await axios
      .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
      .then((res) => {
        console.log(
          "res.data[0].id :- ",
          res.data[0].id,
          " requestData?.interviewerId :- ",
          requestData?.interviewerId,
          " res.data[0].id === requestData?.interviewerId :- ",
          res.data.id === requestData?.interviewerId
        );
        console.log("Rakesh res.data?.length:");
        console.log(res.data?.length);
        console.log("Rakesh res Data:");
        console.log(res.data);
        if (res.data?.length === 0) {
          setisSlotAlredyBooked(true);
        }
        if (res.data?.length === 1) {
          isInterviewerAvailable = true;
        }
      })
      .catch((err) => console.log(err));
    console.log("isInterviewerAvailable", isInterviewerAvailable);
    return isInterviewerAvailable;
  };

  const addEntryToInterviewReqTable = (requestData) => {
    console.log("Yes");
    var newReq = {
      //reqId: generateId(),
      studId: studentId,
      interviewerId: requestData?.interviewerId,
      profile: requestData?.profile,
      subprofile: requestData?.subprofile,
      date: requestData?.date, // [DD/MM/YYYY]
      fromTime: requestData?.slot.slice(1).split(":")[0],
      toTime: requestData?.slot.slice(1).split(":")[1],
      reqStatus: "ReqSent",
      mockId: "",
      reqStatusTime: [new Date(), null, null, null, null],
    };
    axios.post(`${BASE}/send/sendInterviewReq`, newReq).then((response) => {
      console.log(response.status);
      toast.success("Request Sent Successfully", {
        ...toastStyle.success,
        position: "top-center",
      });
    });
  };
  const rescheduleRequest = (requestData) => {
    axios
      .patch(`${BASE}/send/rescheduleInterview/${cards?.reqId}`, {
        value: checked,
        desc: data.textofArea,
        date: requestData?.date,
        fromTime: requestData?.slot.slice(1).split(":")[0],
        toTime: requestData?.slot.slice(1).split(":")[1],
      })
      .then((res) => {
        console.log(res);
        toast.success("Reschedule Request Sent Successfully", {
          ...toastStyle.success,
        });
        setUpdated(true);
        // window.location.reload(true);
        setTimeout(() => {
          // window.location.reload(true);
          navigate('/s/sent-requests')
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (requestData?.slot) setSendBtnDisabled(true);
    else setSendBtnDisabled(false);
  }, [requestData]);
  const currReq = requests.filter(
    (item) =>
      item.date?.indexOf(moment(selectedDate).format("DD/MM/YYYY")) !== -1
  );
  let classes = "rounded-full bg-green-400 my-1 px-2 py-1";
  const settingColor = (item) => {
    if (currReq?.length !== 0) {
      for (let i = 0; i < currReq?.length; i++) {
        if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqAccepted"
        ) {
          classes = "rounded-full bg-yellow-400 my-1 px-2 min-[600px]:py-1";
          return true;
        } else if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqPaid"
        ) {
          classes = "rounded-full bg-red-400 my-1 px-2 min-[600px]:py-1";
          return true;
        }
      }
    }
    classes = "rounded-full bg-green-400 my-1 px-2 min-[600px]:py-1";
    return true;
  };
  const id = interviewer?.id;
  const name =
    interviewer?.fname + (interviewer?.lname ? " " + interviewer?.lname : "");

  const designation = interviewer?.designation;
  const company = interviewer?.currCompany;
  const price = interviewer?.price || "INR 499";
  const profiles = interviewer?.profiles || [];
  const coreprofiles = interviewer?.coreprofiles || [];
  const specializations = interviewer?.specializations || [];
  const photo = interviewer?.photo;
  const logo = getCompanyLogo(interviewer?.currCompany);
  const gnrcSchd = interviewer?.GnrcSchedule ? interviewer?.GnrcSchedule : [];

  const custSchd = interviewer?.CustomSchedule
    ? interviewer?.CustomSchedule
    : [];

  const studentId = user.id;
  const displaySlots = (date) => {
    const currentDate = moment(date).format("DD/MM/YYYY");
    const currentDay = moment(date).format("ddd").toLowerCase();
    let check = false;
    if (custSchd !== 0) {
      custSchd?.map((item) => {
        if (item.date === currentDate) {
          check = true;
          let slotArray = [];
          item.sessions?.map((sess) => {
            let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
          });

          setSlotsArray([...slotArray]);
        }
      });
    }
    if (!check) {
      if (gnrcSchd?.length !== 0) {
        gnrcSchd?.map((item) => {
          if (item.day === currentDay) {
            let slotArray = [];
            item.sessions?.map((sess) => {
              let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
            });
            setSlotsArray([...slotArray]);
          }
        });
      }
    }
  };
  useEffect(() => {
    displaySlots(selectedDate);
    setRequestData({
      ...requestData,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      slot: "",
    }); // When date changes then date will be updated and slot will reset
  }, [selectedDate]);

  const handleOpen = () => {
    setOpen(true);
    console.log(interviewer?.id);
    async function fetchData() {
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${interviewer?.id}`)
        .then((response) => {
          console.log(response.data);
          setRequests(response.data);
          displaySlots(selectedDate);
        });
    }
    setOpen(true);
    console.log(open);
    fetchData();
  };

  const handleClose = () => {
    setOpen(false);
    //setRequestData({});
    setSelectedDate(new Date());
  };
  const handleRequestData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // Append the property if doesn't exist and if does then update
    if(value==="none"){
      setRequestData((requestData) => ({
        ...requestData,
        [name]: "",
        studentId: studentId,
        interviewerId: id,
      }));
    }    
    else{
    setRequestData((requestData) => ({
      ...requestData,
      [name]: value,
      studentId: studentId,
      interviewerId: id,
      [name==="profile"?"subprofile":null]:null
    }));
  }
  };
  const newDate = cards?.date[0] === "0" ? cards?.date.slice(1) : null;

  const [toolTip, setToolTip] = useState(false);
  const [datePickerMobile, setDatePickerMobile] = useState(false)

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Reschedule your Interwiu</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Container style={{ minHeight: '88vh' }}>
        <Dialog
          className="footer__head"
          open={open2}
          onClose={() => setOpen2(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <>
            <DialogTitle
              style={{ cursor: "move", fontWeight: "600", color: "#666666" }}
              id="draggable-dialog-title"
            >
              Cancellation Policy
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <ReactMarkdown options={options} className="markdown">
                  {content["Cancellation Policy"]}
                </ReactMarkdown>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                autoFocus
                style={{ textTransform: "none" }}
                onClick={() => setOpen2(false)}
              >
                Got It
              </Button>
            </DialogActions>
          </>
        </Dialog>
        <TwoColumn style={{ maxWidth: '1000px', margin: "10px auto" }}>
          <TextColumn textOnLeft={textOnLeft} style={{ padding: "0px 10px", marginTop: '1vw' }}>
            <TextContent>
              {subheading && (
                <Subheading style={{ marginBottom: "20px" }}>
                  {subheading}
                </Subheading>
              )}
              <Heading style={{ textAlign: 'left', textShadow: '1px 1px 3px #b7b7b7' }}>{heading}</Heading>
              <div className="flex" style={{ alignItems: 'stretch', marginTop: "20px" }}>
                <div className="flex items-start h-full" style={{ color: 'red', fontWeight: '500' }}>
                  *
                </div>
                <div className="text-left" style={{ color: "rgb(124, 139, 161" }}>
                  A mock interview can be rescheduled only if the time left for it
                  is more than 2 hours.
                </div>
              </div>

              {description && (
                <Description style={{ color: "black", textAlign: 'left' }}>
                  {description}
                </Description>
              )}
            </TextContent>
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                />
                <Button onClick={fetchData} variant={!changingInput && verified?"outlined":"contained"} 
                color={!changingInput && verified?"success":"primary"}>
                  {!changingInput && verified?"Verified":"Verify"}</Button>
              </div>
            </div>
            {verified && cards?.date && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <h5>Details of the Mock Interview</h5>
                  {console.log('------------->', interviewer)}
                  {cards && (
                    <UpcomingInterview
                      name={interviewer?.fname}
                      desg={interviewer?.designation}
                      company={interviewer?.currCompany}
                      price={cards?.price}
                      profile={cards?.profile}
                      subprofile={cards?.subprofile}
                      slotStart={cards?.fromTime}
                      slotEnd={cards?.toTime}
                      status={cards?.reqStatus}
                      id={interviewer?.id}
                      date={cards?.date}
                      image={interviewer?.photo}
                      mockId={cards?.mockId}
                      logo={getCompanyLogo(interviewer?.currCompany)}
                      req_id={cards?.reqId}
                      payment={cards?.payment}
                      rescheduled="true"
                      isCancelled={cards?.cancelled?.isCancelled}
                      isUnavailable={
                        cards?.isDisabled === true || cards?.isDelete === true
                      }
                      isDisable={cards?.isDisabled === true}
                      isDelete={cards?.isDelete === true}
                      isRescheduled={cards?.reschedule?.isRescheduled}
                      reschedule={cards?.reschedule}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {moment()
              .tz("Asia/Kolkata")
              .diff(moment(cards?.date, "DD/MM/YYYY"), "days") > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <p
                    className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                    style={{ color: "#d32f2f" }}
                  >
                    Sorry! This mock interview cannot be rescheduled because its
                    slot has already passed.
                  </p>
                </div>
              )}
            { cards?.reschedule.count === 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <p
                    className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                    style={{ color: "#d32f2f" }}
                  >
                    {rescheduleErrorMessage[cards?.reschedule?.status] || "Sorry! You cannot reschedule this mock interview because it has already been rescheduled once earlier"}
                  </p>
                </div>
              )}
            {currDate === newDate &&
              verified &&
              status === "passed" &&
              moment()
                .tz("Asia/Kolkata")
                .diff(moment(cards?.date, "DD/MM/YYYY"), "days") <= 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <p
                    className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                    style={{ color: "#d32f2f" }}
                  >
                    Sorry! This mock interview cannot be rescheduled because its
                    slot has already passed.
                  </p>
                </div>
              )}
            {currDate === newDate && verified && status === "ongoing" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  Sorry! This mock interview cannot be rescheduled because its
                  slot has already started.
                </p>
              </div>
            )}
            {currDate === newDate && verified && status === "invalid" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <div className="mb-4">
                    <p
                      className="mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                      style={{ color: "#d32f2f" }}
                    >
                      Sorry! This mock interview cannot be rescheduled because
                      the time left for it is less than 2 hours. If you cannot
                      attend it, you can{" "}
                      <a href="/s/cancel" style={{ color: "blue" }}>
                        cancel
                      </a>{" "}
                      it.
                    </p>
                    <span>Read</span>
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      className="privacypolicy"
                      style={{ color: "blue" }}
                    >
                      {" "}
                      Cancellation Policy
                    </a>
                  </div>
                </div>
              </>
            )}
            {cards?.cancelled?.isCancelled === "true" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <div className="mb-4">
                    <p
                      className="mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                      style={{ color: "#d32f2f" }}
                    >
                      Sorry! This mock interview cannot be rescheduled because
                      it has already been Cancelled.
                    </p>
                    <span>Read</span>
                    <a
                      href="#"
                      onClick={() => setOpen2(true)}
                      className="privacypolicy"
                      style={{ color: "blue" }}
                    >
                      {" "}
                      Cancellation Policy
                    </a>
                  </div>
                </div>
              </>
            )}
            {/* {cards.reschedule.status?.length>0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  {rescheduleErrorMessage[cards.reschedule.status]}
                </p>
              </div>
            )} */}
            {verified &&
              status === "valid" &&
              cards?.cancelled?.isCancelled !== "true" &&
              cards?.reschedule.count !== 1 && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Why do you want to reschedule this mock interview?
                    </FormLabel>
                    <FormGroup className="removeWordbreak">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          onChange={handleChangeRadio}
                          label="I got something else to do at that time"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          onChange={handleChangeRadio}
                          label="I want to postpone it as I need more time to prepare for it"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio />}
                          onChange={handleChangeRadio}
                          label="I want to prepone it as I will be prepared for it sooner"
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio />}
                          onChange={handleChangeRadio}
                          label="I want to prepone it because I want to appear for more mock interviews later"
                        />
                        <FormControlLabel
                          value={5}
                          control={<Radio />}
                          onChange={handleChangeRadio}
                          label="Other Reason"
                        />
                        <TextField
                          id="outlined-multiline-static"
                          name="textofArea"
                          label="Describe your issue(s) in detail"
                          multiline
                          rows={4}
                          sx={{ mt: 2 }}
                          value={data.textofArea}
                          onChange={handleChangeofBox}
                          error={error}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            <div>




            <Modal class open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  maxWidth: "750px",
                  width: "95%",
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                    className="slot-headding"
                  >
                    Request a Mock Interview (with Feedback Session)
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                {/* {coreprofiles.length>0 && specializations && specializations?.map(s => s.subDomains.length===0).includes(false) ? <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "start",
                        pointerEvents: "none"
                      }}
                    >
                      <div className="tt ml-auto mr-2">
                        <InfoOutlined
                          sx={{ color: "rgb(83, 81, 81)", fontSize: "20px", pointerEvents:"auto" }}
                        />
                        <span style={{ background: 'black', color: 'white', padding: '3px 5px' }} className="tttext">
                        Choosing a specialization for a Core Profile is not mandatory
                        </span>
                      </div>
                  </div>:null} */}
                <div className="grid grid-cols-2 max-[600px]:grid-cols-1 gap-3">
                  <div className="col-span-1 m-2 flex flex-row justify-center">
                    <div>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          my: 1,
                        }}
                        style={{ marginBottom: "20px" }}
                        className="slot-name"
                      >
                        To: {name}
                      </Typography>
                      <div className="w-full flex flex-row justify-center my-1 hide-picker">
                        <Calendar
                          minDate={new Date().setDate(new Date().getDate())}
                          zIndex={0}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(new Date(e))}
                          mapDays={({ date, selectedDate, isSameDate }) => {
                            let props = {};
                            if (isSameDate(date, selectedDate)) {
                              props.style = {
                                color: "#fff",
                                backgroundColor: "#00a86b",
                                border: "1px solid #000",
                              };
                              return props;
                            }
                          }}
                        />
                      </div>
                      {/* <MuiPickersUtilsProvider className="s-mobile-card" utils={LocalizedUtils}>
                        <DatePicker

                          style={{ flex: 1, }}
                          disablePast
                          placeholder="Select Date"
                          zIndex={0}
                          value={selectedDate}
                          showTabs={false}
                          onChange={(e) => { setSelectedDate(new Date(e)) }}
                        // onChange={(e) => setSelectedDate(new Date(e))}
                        />
                      </MuiPickersUtilsProvider> */}
                      {/* <div className="s-mobile-card" style={{ position: 'relative' }}>
                        <span style={{ fontWeight: '500', lineHeight: '30px', marginRight: '10px' }}>Select Date: </span><button class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button">{selectedDate !== ""
                          ? moment(selectedDate).format("DD MMM YYYY") + " "
                          : ""} <AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'text-top' }} /></button>
                        {
                          datePickerMobile && <Calendar
                            className="s-mobile-calendar-calendar"
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={1}
                            value={selectedDate}
                            onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000"
                                };
                                return props;
                              }
                            }}
                          />
                        }
                      </div> */}
                      <div
                        className="s-mobile-card"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div className="tt ml-auto">
                          <span
                            style={{
                              fontWeight: "500",
                              lineHeight: "30px",
                              marginRight: "10px",
                            }}
                          >
                            Select Date:{" "}
                          </span>
                          <button
                            class="button-13"
                            onClick={() =>
                              setDatePickerMobile(!datePickerMobile)
                            }
                            role="button"
                          >
                            {selectedDate !== ""
                              ? moment(selectedDate).format("DD MMM YYYY") + " "
                              : ""}{" "}
                            <AiTwotoneCalendar
                              style={{
                                display: "inline-block",
                                verticalAlign: "text-top",
                              }}
                            />
                          </button>
                          <span
                            style={{
                              background: "black",
                              color: "white",
                              padding: "3px 5px",
                              top: "-146px",
                              textAlign: "left",
                            }}
                            className="calendar-mobile-tooltip tttext"
                          >
                            <Calendar
                              className="s-mobile-calendar-calendar"
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={1}
                              value={selectedDate}
                              onChange={(e) => {
                                setSelectedDate(new Date(e));
                                setDatePickerMobile(!datePickerMobile);
                              }}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                    <div>
                    <Typography
                        
                        variant="p"
                        sx={{ mb: 2, fontSize: "15px" }}
                        // className="heading1"
                      >
                        <b>Profile:</b> {cards?.subprofile? `${cards?.subprofile} (${cards?.profile})`: cards?.profile}
                      </Typography>
                      <hr className="mx-4 my-3 border-black" />
                      <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                          Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                          Conditionally Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                          Occupied
                        </div>
                        <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                          {/* <Tooltip
                            open={toolTip}

                            disableFocusListener
                            title={
                              <>
                                <p className="my-1">
                                  Green: The slot is completely available to you
                                </p>
                                <p className="my-1">
                                  Orange: Another student has already chosen the
                                  slot, but is yet to pay and confirm. You can
                                  still request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  Red: The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </>
                            }
                          >
                            <img
                              onClick={() => setToolTip(!toolTip)}
                              src={require("../../../images/exclamation.png")}
                              alt="Description"
                              style={{ transform: "rotateZ(180deg)" }}
                            />
                          </Tooltip> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="tt ml-auto">
                              <InfoOutlined
                                sx={{
                                  color: "rgb(83, 81, 81)",
                                  fontSize: "20px",
                                }}
                              />
                              <span
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "3px 5px",
                                  top: "-146px",
                                  textAlign: "left",
                                }}
                                className="tttext"
                              >
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "lime",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Green:{" "}
                                  </span>
                                  The slot is completely available to you
                                </p>
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "orange",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orange:{" "}
                                  </span>
                                  Another student has already chosen the slot,
                                  but is yet to pay and confirm. You can still
                                  request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Red:{" "}
                                  </span>
                                  The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Typography
                        variant="h5"
                        component="h5"
                        className="heading1"
                      >
                        Choose a Slot
                      </Typography>
                      <RadioGroup
                        row
                        name="slot"
                        id="slot"
                        value={requestData.slot || ""}
                        onChange={handleRequestData}
                        sx={{ maxHeight: "150px", overflowX: "auto" }}
                      >
                        {slotsArray?.length !== 0 ? (
                          slotsArray.map(
                            (item) =>
                              (compareSlots(item[0]) ||
                                !compareDate(selectedDate, new Date())) &&
                              settingColor(item[0]) === true && (
                                <div className={classes}>
                                  <FormControlLabel
                                    value={"#" + item[0] + ":" + item[1]}
                                    key={"#" + item[0] + ":" + item[1]}
                                    control={<Radio sx={{ py: 0 }} />}
                                    label={
                                      t24HDecimalTo12HFormat(item[0]) +
                                      " - " +
                                      t24HDecimalTo12HFormat(item[1])
                                    }
                                    disabled={classes?.includes("red")}
                                  />
                                </div>
                              )
                          )
                        ) : (
                          <p className="text-center">
                            No slots available on{" "}
                            <span className="font-bold">
                              {moment(selectedDate).format("Do MMM, YYYY")}
                            </span>
                          </p>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full flex flex-row justify-center my-2"
                  style={{
                    background: " #ffdede",
                    margin: "0px",
                    padding: "8px",
                  }}
                >
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!sendBtnDisabled}
                    onClick={() => {
                      console.log(
                        "date: ",
                        moment(selectedDate).format("DD/MM/YYYY")
                      );
                      console.log(
                        "requestData Before sending to checkInterviewerAvailability(): ",
                        requestData
                      );
                      console.log("Sending Request...");
                      checkInterviewerAvailability(requestData).then(
                        (isInterviewerAvailable) => {
                          if (isInterviewerAvailable)
                            rescheduleRequest(requestData);
                        }
                      );
                      handleClose();
                    }}
                  >
                    Send Request
                  </Button>
                </div>
              </Box>
            </Modal>
              {isSlotAlreadyBooked ? (
                <SlotAlreadyBooked
                  isSlotAlreadyBooked={isSlotAlreadyBooked}
                  slotBookedModalHandler={slotBookedModalHandler}
                />
              ) : null}
              {verified &&
                status === "valid" &&
                cards?.cancelled?.isCancelled !== "true" &&
                cards?.reschedule.count === 0 && (
                  <Button
                    sx={{ mt: 2, mb: 2 }}
                    variant="contained"
                    onClick={() => {
                      console.log(checked);
                      if (checked === "") {
                        window.alert("Please select a reason for rescheduling");
                        window.scrollTo(0, 0);
                        return;
                      }
                      if (checked === 5 || checked === "5") {
                        console.log("null1");
                        if (
                          data.textofArea === "" ||
                          !data.textofArea ||
                          data.textofArea === null
                        ) {
                          console.log("null");
                          setError(true);
                          return;
                        } else {
                          setError(false);
                        }
                      }
                      handleOpen();
                    }}
                    disabled={!verified}
                  >
                    Choose a new Slot
                  </Button>
                )}
            </div>
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
export default Issue;
