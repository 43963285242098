import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-date-picker";
import axios from "axios";
import "./AcceptedRequests.css";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";

import Navbarlogedin from "./Navbarlogedin";
import AcceptedRequest from "./cards/AcceptedRequest";
import UserContext from "../../contexts/UserContext";
import {
  calculateCount,
  compare,
  compareSlots,
  getCompanyLogo,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import Pagination from "@mui/material/Pagination";
import moment from "moment-timezone";
import { Oval } from "react-loader-spinner";
const changeLink = () => {
  const links = document.querySelectorAll(".nav-item a p");
  links.forEach((link) => {
    if (link.classList.contains("active")) {
      link.classList.remove("active");
    }
    if (link.innerHTML === 'Accepted Requests <div className="notify"></div>') {
      link.classList.add("active");
    }
  });
};

const AcceptedRequests = (userDetails) => {
  const { user } = useContext(UserContext);
  const [acceptedRequest, setacceptedRequest] = useState([]);
  const [SentInterViewers, setSentInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqAccepted",
    userRole: "Student",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response);
          setacceptedRequest(response.data.Requests);
          setSentInterViewers(response.data.SentInterViewers);
          console.log(response.data.Requests);
          setLoaded(true);

          let ints = response.data.Requests;

          ints?.map((int) => {
            axios.get(`${BASE}/send/student/acceptedInterviewRequestById/${int.reqId}`)
            .then((response) => {
              console.log(response.data,"-----------");
            }).catch((err) => {
              console.log(err);
            })
          })

        });
    }
    fetchData();
  }, []);
  useEffect(() => {
    changeLink();
  }, []);
  const [date, setDate] = React.useState(null);
  const variable = calculateCount(acceptedRequest);

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
    console.log(acceptedRequest.slice(pageNo[0], pageNo[1]));
  }, [page, acceptedRequest]);
  return (
    <>
      <Helmet>
        <title>interwiu | Accepted Request</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <div className="accepted-request interviews">
        <div className="interviews-container">
          <div className="flex justify-center items-center mb-4" id="head">
            <h2 className="interview-head text-2xl font-medium heading1">
              Accepted Requests (
              {acceptedRequest.length
                ? page * 10 -
                  9 +
                  " - " +
                  (acceptedRequest.length > page * 10
                    ? page * 10
                    : acceptedRequest.length)
                : null}
              ) out of ({acceptedRequest.length ? variable : 0})
            </h2>
          </div>
          <div className="interviews-cont">
            <div className="flex justify-between mx-8">
              <div>
                Showing the results for{" "}
                {date ? date.toDateString() : "all days"}
              </div>
              {/**
                <div className="flex gap-2">
                  <label htmlFor="date">Filter by Date: </label>
                  <DatePicker
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    value={date}
                  />
                </div>
               */}
            </div>
            {loaded ? (
              <div className="interviews-list">
                {acceptedRequest?.slice(pageNo[0], pageNo[1]).map(
                  (result, index) =>
                    moment()
                      .tz("Asia/Kolkata")
                      .diff(
                        moment(
                          result.date +
                            " " +
                            t24HDecimalTo12HFormat(result.toTime),
                          "DD/MM/YYYY hh:mm a"
                        ),
                        "seconds"
                      ) < 0 && (
                      <AcceptedRequest
                        user={user}
                        id={SentInterViewers[pageNo[0] + index].id}
                        name={
                          SentInterViewers[pageNo[0] + index].fname +
                          " " +
                          SentInterViewers[pageNo[0] + index].lname
                        }
                        desg={SentInterViewers[pageNo[0] + index].designation}
                        email={user.email}
                        price="499"
                        profile={result.profile}
                        subprofile={result.subprofile}
                        company={
                          SentInterViewers[pageNo[0] + index].currCompany
                        }
                        slotStart={acceptedRequest[pageNo[0] + index].fromTime}
                        slotEnd={acceptedRequest[pageNo[0] + index].toTime}
                        status={acceptedRequest[pageNo[0] + index].reqStatus}
                        req_id={acceptedRequest[pageNo[0] + index].reqId}
                        date={acceptedRequest[pageNo[0] + index].date}
                        image={
                          SentInterViewers[pageNo[0] + index].isDisabled ===
                            true || SentInterViewers.isDelete === true
                            ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                            : SentInterViewers[pageNo[0] + index].photo
                        }
                        logo={getCompanyLogo(
                          SentInterViewers[pageNo[0] + index].currCompany
                        )}
                        isUnavailable={
                          SentInterViewers[pageNo[0] + index].isDisabled ===
                            true || SentInterViewers.isDelete === true
                        }
                        isDisable={
                          SentInterViewers[pageNo[0] + index].isDisabled ===
                          true
                        }
                        isDelete={
                          SentInterViewers[pageNo[0] + index].isDelete === true
                        }
                      />
                    )
                )}

                {/* Pagination */}
                <div className="flex flex-row justify-center mt-4 my-2">
                  <Pagination
                    count={Math.ceil(acceptedRequest.length / 10)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </div>
              </div>
            ) : (
              <div className="fullPageLoader">
                <Oval
                  height={120}
                  width={120}
                  color="#306bc9"
                  wrapperStyle={{ padding: "50%, 50%" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#90caf9"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptedRequests;
