import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import RateAndReview from "../RateAndReview";
import {
  GET_REQUEST_INTERVIEWS,
  GET_STUDENT_INFO,
  GET_INETRVIEWER_INFO,
} from "../../../constants/endpoints";
import {giveYears} from "../utils"
import "./InterviewerMidEnd.css";
import TextLoading from "../CallEndPages/TextLoading";

const InterviewerMidEnd = () => {
  const { mock_id: ROOM_ID } = useParams();
  const [interviewerDetails, setInterviewerDetails] = useState();

  useEffect(() => {

    // getting data about the interview and make link
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
      .then((res) => {
        let interviewer_id = res.data.interviewerId;

        axios
          .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
          .then((res) => {
            setInterviewerDetails(res.data);
          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
          });
      })
      .catch((err) => {
        alert("Invalid Link");
      });
  }, []);

  return (
    <div className="interviewend">
      <p style={{ margin: "20px 0" }}>
      It seems like there is some interruption
      </p>
      <p style={{ margin: "40px 0" }}>
        If this mock interview session is yet to be completed, you can&nbsp;
        {/* <Link
          to={
            "/i/upcoming-interviews"
          }
          style={{ color: "blue", textDecoration: "underline" }}
        >
          {" "}
          Re-Join{" "}
        </Link> */}
        {interviewerDetails?.id?<Link
          to={
            "/valid/vc/" +
            ROOM_ID +
            "/" +
            interviewerDetails?.id +
            "/" +
            interviewerDetails?.fname
          }
          // to={
          //   "/valid/vc/" +
          //   ROOM_ID +
          //   "?id=" +
          //   interviewerDetails?.id +
          //   "&name=" +
          //   interviewerDetails?.fname
          // }
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Re-Join
        </Link>:<TextLoading text="Re-Join"/>}
        &nbsp;it
      </p>
    </div>
  );
};

export default InterviewerMidEnd;
