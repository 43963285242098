const testimonials = [
  {
    name: "Mansi Hada",
    image: "Mansi Hada.jpg",
    degree: "MBA (Analytics and Marketing),",
    placedIn: "McKinsey & Company",
    position: "Analyst",
    comment:
      "I appeared for a mock interview in the Consulting profile. It was quite insightful, informative, and thought provoking. The interviewer provided me with a detailed feedback, which helped me indispensably during my actual placement interviews.",
  },
  {
    name: "Krishna Narwani",
    degree: "M.Tech, Computer Science and Engineering,",
    placedIn: "Microsoft India (R&D) Pvt Ltd",
    position: "Software Development Engineer (SDE-1)",
    comment:
      "I took the Data Science mock interview session. In the end, the HR-round based questions were also asked. I got some new pieces of information on how to tackle those profile based (data science) and HR questions using predefined templates. The interviewer was quite good at all the fronts.",
  },
  {
    name: "B Mohan Sudesh",
    degree: "B.Tech, Civil Engineering,",
    placedIn: "Amazon",
    position: "Software Development Engineer (SDE-1)",
    comment:
      "The mock interview in Software profile was really great. The interviewer, who was an alumnus of IIT Madras, was quite amazing. Through that mock interview, I got to know about my major mistakes and highly promising ways to correct the same via the feedback and suggestions provided by the interviewer. The session largely boosted-up my self-confidence.",
  },
  {
    name: "M G Raja Sree",
    degree: "Dual Degree (B.Tech + M.Tech), Electrical Engineering,",
    placedIn: "Goldman Sachs",
    position: "Analyst",
    comment:
      "I appeared for mock interviews in Data Science and Analytics profiles. These mock interviews gave me a rough idea of how I should be preparing for actual interviews. I realized that the tests and interviews require completely different kinds of preparation. One needs a structure to plan what to say and how exactly certain things should be said during an interview. I realized no amount of technical knowledge is helpful in this aspect. Considering the areas of improvement that I needed (concerned with the way I presented and explained myself), the mock interviews were really of great help.",
  },
  {
    name: "Manideep Ladi",
    degree: "M.Tech, Computer Science and Engineering,",
    placedIn: "Microsoft India Private Limited",
    position: "Software Development Engineer (SDE-1)",
    comment:
      "The mock interview was very helpful. It provided me with a highly relevant set of technical and generic questions to prepare for, some of which were actually asked in my final interview of Microsoft.",
  },
  {
    name: "Palvai Ravi Ram Reddy",
    degree: "MS (by Research), Management Studies,",
    placedIn: "Amazon",
    position: "Program Manager",
    comment:
      "Via this mock interview in Analytics profile, I've learnt how to answer a question precisely unless a detailed explanation is asked. The interviewer was so patient with me. By providing a detailed feedback and rigorous suggestions intermittently during the mock interview, he helped me understand how to answer questions precisely.",
  },
  {
    name: "Shubham Tibrewal",
    degree: "Dual Degree (B.Tech + M.Tech), Biotechnology,",
    placedIn: "Kotak Mahindra Bank",
    position: "Product Manager",
    comment:
      "I appeared for two mock interviews, one each in Consulting and Data Science profiles. The mock interviews were great and very helpful. The interviewers provided me with a crystal clear feedback. The entire experience was extremely helpful to understand the flow of how interviews are conducted as well as crisp pointers to improve for the next interviews. Great experience!",
  },
  {
    name: "K Dhanusha",
    degree: "B.Tech, Aerospace Engineering,",
    placedIn: "Accenture",
    position: "Management Consulting Delivery Associate",
    comment:
      "I took two mock interviews in the Consulting profile alone. The mock interviews were highly professional and some of the best interview experiences so far. The questions asked from me were substantially same as the ones that I faced in my actual interview later on, including the challenging case study problems, questions based on the application of courses mentioned in my resume, and typical HR questions. The best part was the feedback session with each mock interview, where they pointed out all the key points which helped me a lot to prepare better.",
  },
  {
    name: "Sai Charan Nalla",
    degree: "Dual Degree (B.Tech + M.Tech), Civil Engineering,",
    placedIn: "Standard Chartered",
    position: "Risk Analyst",
    comment:
      "I appeared for the Mock Interviews in Data Science and Software profiles, which have really helped me a lot to structure my answers and to present my answers clearly and confidently. Without that, it would have been a great trouble for me to ensure the required performance during real placement interviews. The kind of questions that were covered in the mock interview was really important which provided me with the overall picture of how interviews happen. The HR questions asked in the mock interview have also helped me a lot. Further, the feedback provided by the interviewers was very enlightening. I took the feedback points very seriously and worked on them. I realized that no matter how talented you are, that won't add value if things are not explained in a proper way.",
  },
  {
    name: "Manoj Sundararajan",
    degree:
      "Dual Degree (B.Tech + M.Tech), Mechanical and Computational Engineering,",
    placedIn: "Wells Fargo",
    position: "Program Associate (Corporate Model Risk)",
    comment:
      "I appeared for the Mock Interview in Analytics profile and it gave me a reality check about how well I was prepared with my Resume. From then, I only focused on revising my resume points. I felt very inadequate after the mock interview and feedback session. Without that, I wouldn't have given importance to my resume. The interviewer was brutally honest about how I fell short on words and provided me with suggestions to get over that issue.",
  },
  {
    name: "Dasi Manoj Kumar",
    degree: "Dual Degree (B.Tech + M.Tech), Civil Engineering,",
    placedIn: "Publicis Sapient",
    position: "Senior Associate (Data Scientist)",
    comment:
      "Overall, it was good. The mock interviewer went through the projects mentioned in my resume which turned out to be very helpful for me in my final interview. After the mock interview, I went through its video clip multiple times to recognize my mistakes, listen to interviewer’s feedback, and improve accordingly. I learnt that it’s not only about telling the right answers, but it's also about presenting ourselves in a right way. This realization helped me a lot during all of my placement interviews.",
  },
  {
    name: "Ritesh Bennabhaktula",
    degree: "Dual Degree (B.Tech + M.Tech), Civil Engineering,",
    placedIn: "FinMechanics",
    position: "Associate Consultant",
    comment:
      "The mock interviews helped me to understand the areas of improvement and settle down the initial jitters. I got a fair idea of what all might go wrong in an actual placement interview, if the answers to most potential questions are not prepared in advance.",
  },
  {
    name: "Chaithanya Krishna Moorthy",
    degree: "Dual Degree (BS + MS), Physics,",
    placedIn: "Wells Fargo",
    position: "Program Associate (Corporate Model Risk)",
    comment:
      "My experience of mock interview in Data Science profile was quite good and encouraging. I realized that there were many areas where I needed substantial improvements, without which I might not have cracked my actual interview of Wells Fargo.",
  },
  {
    name: "Surender Naik Kethavath",
    degree: "B.Tech, Metallurgical and Materials Engineering,",
    placedIn: "Bigbasket",
    position: " Software Engineer-1",
    comment:
      "It was really a great learning experience. I understood where I needed to improve and how I can stay confident during an interview. The interviewer asked pretty good questions, and provided the feedback during/after the mock interview really well. The mock interview experience turned out to be immensely helpful for me while I was facing my actual placement interviews. I would like to recommend these mock interviews to my juniors also.",
  },
  {
    name: "Lokesh Lakhani",
    degree: "B.Tech, Ocean Engineering,",
    placedIn: "Groww",
    position: "Business Associate",
    comment:
      "I took a mock interview session in Banking and Finance profile for my general interview practice and to get tips, so as to learn new skills and improve on my existing ones. It was a good experience overall. The interviewer also helped me in getting my basics right for the HR questions, and thus I learnt how to tackle HR questions better. The feedback session after mock interview also covered the DO's and DONT's which should be taken into account while appearing for the actual placement interviews.",
  },
  {
    name: "Manankumar Patel",
    degree: "Dual Degree (B.Tech + M.Tech), Engineering Design,",
    placedIn: "Zee Entertainment",
    position: "Associate Software Development Engineer",
    comment:
      "Mock interview definitely helped me in resume-based discussion rounds. It provided me with a very clear idea on what I needed to improve, which certainly helped. The interviewer was an alumnus of IIT Madras, and he was pretty good. He had a lot of industry experience. He got me real good. Though he was a little harsh, but totally realistic which I absolutely liked.",
  },
  {
    name: "Adarsh Kumar Tiwari",
    degree: "MS (by Research), Civil Engineering,",
    placedIn: "Tiger Analytics",
    position: "Data Science – Analyst",
    comment:
      "These mock interview services are excellent. My mock interview made me feel quite confident before facing the actual interview during campus placements. The interviewer told me about my mistakes and the areas that I needed to work upon. The overall experience was really wonderful.",
  },
  {
    name: "Ishan Natu",
    degree: "MS (by Research), Management Studies,",
    placedIn: "Genpact",
    position: "Consultant",
    comment:
      "I took mock interview sessions in three different profiles, namely Banking and Finance, Consulting, and Analytics. Indeed the mock interviews were very helpful. They gave me the confidence required to face real placement interviews. The real time feedback provided with the mock interviews was great. It was completely on the point in each session.",
  },
  {
    name: "Abhishek Gupta",
    degree: "MS (by Research), Management Studies,",
    placedIn: "Tiger Analytics",
    position: "Data Scientist",
    comment:
      "Having taken the mock interview sessions in Consulting and Analytics profiles, I was quite confident in actual placement interviews. Both the sessions were pretty good.",
  },
  {
    name: "G Suryanarayanan",
    degree: "Dual Degree (B.Tech + M.Tech), Ocean Engineering,",
    placedIn: "HiLabs Inc",
    position: "Data Scientist",
    comment:
      "I appeared for Mock Interviews in two profiles, viz., Software and Data Science. Appearing for the mocks was a really good experience. The environment created during the mocks was very real. It enabled me to prepare well as I got to learn certain things from the mocks. Overall, the mocks were very useful before my real placement interviews. Both of my interviewers were very professional and they provided me with a detailed feedback on every aspect of whatever I did, which was really helpful.",
  },
  {
    name: "Subodh Joshi",
    degree: "B.Tech, Civil Engineering,",
    placedIn: "Arga Investment Management",
    position: "Research Associate (Carbon Transition)",
    comment:
      "I had no idea about the interviews earlier. So the mock interviews gave me a clear idea of interviews, after which only I really started preparing for the interview part of my placements. In fact, the mentorship process should have been started earlier, before we started preparing our resume for the placements. A continuous guidance including resume evaluation, advice on target companies, etc can be furthermore impactful.",
  },
  {
    name: "Battu Lochana Janaki",
    degree: "B.Tech, Civil Engineering,",
    placedIn: "Accenture",
    position: "Management Consulting Delivery Associate",
    comment:
      "Firstly, I would like to sincerely appreciate your efforts for enabling us to provide our preferences (profile, company, availability, etc), arranging the mock interviews accordingly with alumni of IIT Madras as interviewers who were in the same industry/company/profile, and guiding us with the quality feedback. All the interviews were well conducted. It was really nice for me to obtain my recorded interview-video, based on which I was able to review myself, my facial expressions and tone, etc. The interviewer was very friendly, and he made me feel comfortable during the mock interview. He helped me identify my weak points with his feedback. All the points were really valid. I should definitely say that it was really helpful as after this mock interview, I went through my resume thoroughly and wrote down all the points. I prepared for all the suggested HR questions and especially my own Introduction in a structured format. I was happy after knowing that my interviewer has been working not only in the same company, but also in the same profile/role which I was aspiring for. The interviewer covered most of the questions related to the profile, and he was very friendly and helpful while discussing the better answers to the questions that he asked. He asked questions related to my internships, which I wasn't prepared for before the mock interview. The mock interview made me realize that I had to go through my resume thoroughly (especially my Internships & Projects).",
  },
  {
    name: "Amit Pratap",
    degree: "Dual Degree (B.Tech + M.Tech), Biotechnology,",
    placedIn: "Detect Technologies",
    position: "Machine Learning Engineer-1",
    comment:
      "I appeared for the mock interview in Data Science profile. It was a nice experience which led to increase in my confidence about my resume and myself as well. Apart from the profile-based questions, the questions based on my internship projects and other points mentioned in my resume were also asked, which helped me a lot while preparing for my actual placement interviews.",
  },
];

export default testimonials;
