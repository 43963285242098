import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, CircularProgress, IconButton, Typography, Divider, Button } from '@mui/material';
import './PdfViewer.css'
// import axios from 'axios';
// import { GET_INTERVIEW_DETAILS } from 'constants/endpoints';
import { ZoomIn, ZoomOut, NavigateNext, NavigateBefore, ExitToApp, CloseTwoTone } from '@mui/icons-material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import "react-pdf/dist/esm/Page/TextLayer.css";
import { useRef } from 'react';
import $ from 'jquery'
import { downloadPDF } from 'utility/helper';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ iDetails, mockId, setShowPdf, showPdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(0.7);
  const containerRef = useRef(null)

  useEffect(() => {
    console.log("pin video change")
    if(!$('.pin__video'))
      return
    if ($('.pin__video').attr('name')?.endsWith('screen-share')) {
      // if ($('.unpinned__video'))
      //   $('.unpinned__video').slideUp(500)
      $("#video-grid").css("justify-content", "flex-end")
      
      if (showPdf) {
        $('.pdf-container').addClass('lift-container')
        $('.pin__video').css('width', 'fit-content')
      } else {
        // $('.pdf-container').removeClass('lift-container')
        // $("#video-grid").css("justify-content", "center")
        // $('.pin__video').css('width', "80%")
      }
    } else {
      $("#video-grid").css("justify-content", "center")
      $('.pdf-container').removeClass('lift-container')
      $('.pin__video').css('width', "70%")
    }
  }, [$('.pin__video').attr('name'), showPdf])

  const PageLoading = () => {
    return (
      <div className={`flex justify-center items-center w-[540px] h-[500px] bg-white`}>
        <CircularProgress />
      </div>
    )
  }

  const PageError = () => {
    return (
      <div className={`flex justify-center items-center w-[540px] h-[500px] bg-white`}>
        There was an error while opening the resume using the inbuilt reader, <br/> 
        please try opening the PDF file in a new tab.
        <div className='flex w-full justify-center mt-2'><Button onClick={() => downloadPDF(iDetails?.student.resume)}>Open in new tab</Button></div>
      </div>
    )
  }
  const handleDocumentError = (err) => {
    console.log(err)
  }

  

  const MyDocument = React.memo(({ resume,  scale, currentPage}) => {
    useEffect(() => {
      console.log('render')
    }, [])
    return (
      <Document
      className="pdf-document"
      file={resume}
      onLoadSuccess={handleDocumentLoadSuccess}
      loading={<PageLoading />}
      error={<PageError/>}
      onDoubleClick={handleDoubleClick}
      onLoadError={handleDocumentError}
      onSourceError={handleDocumentError}
      externalLinkTarget='_blank'
    >
      <Page
        size="A4"
        renderTextLayer={false}
        width={830}
        pageNumber={currentPage}
        scale={scale}
      />
    </Document>
    
    )
  })


  // const MyDocument = ({ file }) => (
  //   <Document
  //     file={file}
  //     onLoadSuccess={handleDocumentLoadSuccess}
  //     loading={<PageLoading />}
  //     onDoubleClick={handleDoubleClick}
  //     externalLinkTarget='_blank'
  //   >
  //     <Page
  //       size="A4"
  //       renderTextLayer={false}
  //       width={830}
  //       pageNumber={currentPage}
  //       scale={scale}
  //     />
  //   </Document>
  //   // <iframe
  //   //     className="pdf-iframe"
  //   //     src={file}
  //   //     title="PDF Viewer"
  //   //   />
  // );

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseDownHandler = function (e) {
    // console.log("mouse", e)
    containerRef.current.style.userSelect = "none"
    if(e.which === 3 || e.button === 2) {
      handleRightClick(e)
    }
    pos = {
      // The current scroll
      left: containerRef.current.scrollLeft,
      top: containerRef.current.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
    e.target.addEventListener('mousemove', mouseMoveHandler)
    e.target.addEventListener('mouseup', mouseUpHandler)
  }

    const mouseMoveHandler = function (e) {
      
      // console.log('move')
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // console.log(pos, dx, dy)

      // Scroll the element
      // e.scrollTop = pos.top - e.movementY;
      // e.scrollLeft = pos.left - e.movementY;
      containerRef.current.scrollTo({left: pos.left - dx*1.5,top: pos.top - dy*1.5, behavior: 'smooth'})
    };
    

    const mouseUpHandler = function (e) {
      e.target.removeEventListener('mousemove', mouseMoveHandler)
      containerRef.current.style.removeProperty('user-select');
    };

    
      

  // useEffect(() => {
  //   console.log(mockId)
  //   if (mockId)
  //   if(!pdf)
  //     axios.get(`${GET_INTERVIEW_DETAILS}/${mockId}?page=pdfv`)
  //       .then((res) => {
  //         pdf = res.data.studentResume
  //       })
  //       .catch((err) => console.log(err))
  // }, [mockId, pdf])

  const handleDoubleClick = (e) => {
    e.target.removeEventListener('mousemove', mouseMoveHandler)
    if (scale <= 1)
      setScale(scale + 0.1);
  }

  const handleRightClick = (e) => {
    e.target.removeEventListener('mousemove', mouseMoveHandler)
    e.preventDefault()
    setScale(0.7)
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleZoomIn = () => {
    if (scale <= 1)
      setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.7) {
      setScale(scale - 0.1);
    }
  };

  const handleExit = () => {
    $('.pdf-container').removeClass('lift-container')
    $("#video-grid").css("justify-content", "center")
    if($('.pin__video').attr('name')?.endsWith("screen-share"))
      $('.pin__video').css('width', "fit-content")
    else 
      $('.pin__video').css('width', "70%")
    setShowPdf(false)
  }

  return (
    <div ref={containerRef} id="pdf-container" className="pdf-container" 
    onMouseDown={mouseDownHandler}
    // onMouseMove={mouseMoveHandler}
    // onMouseUp={mouseUpHandler}
    >
      <div className='pdf-controls'>
        <span className='zoom-pdf-controls pdf-control'>
          <IconButton color="warning" sx={{ bgcolor: "white", '&:hover': {bgcolor: "white"}}} aria-label="Zoom Out" onClick={handleZoomOut} title='Zoom Out'>
            <ZoomOut />
          </IconButton>
          <IconButton color="primary" sx={{ bgcolor: "white", '&:hover': {bgcolor: "white"}}} aria-label="Zoom In" onClick={handleZoomIn} title='Zoom In'>
            <ZoomIn />
          </IconButton>
        </span>
        <span className="page-pdf-controls pdf-control">
          <IconButton sx={{
            zoom: 0.9,
            mr: 1,
            borderRadius: "50%",
            bgcolor: "white",
            '&:hover': {
              bgcolor: "white"
            }
          }} color="info" aria-label="Previous Page" onClick={handlePrevPage} title='Previous Page'>
            <NavigateBefore />
          </IconButton>
          <Box sx={{ 
            display: "flex",
            justifyContent: "space-evenly",
            userSelect: "none", 
            fontSize: "12px",
            bgcolor: "white",
            borderRadius: "25px",
            p: 1.25,
            fontWeight: "900",
            color: "text.secondary"
          }}>
            Page&nbsp;<span>{currentPage}&nbsp;of&nbsp;</span><span>{numPages}</span>
          </Box>
          <IconButton sx={{
            zoom: 0.9,
            ml: 1,
            borderRadius: "50%",
            bgcolor: "white",
            '&:hover': {
              bgcolor: "white"
            }
          }}
           color="info" aria-label="Next Page" onClick={handleNextPage} title='Next Page'>
            <NavigateNext />
          </IconButton>
        </span>
        <span className='exit-pdf-controls pdf-control'>
          <IconButton sx={{ bgcolor: "white", '&:hover': {bgcolor: "white"}}} color="error" aria-label="Exit" onClick={handleExit} title='Close Resume'>
            <CloseTwoTone />
          </IconButton>
        </span>
      </div>
      {iDetails?.student ? 
      // <MyDocument resume={iDetails?.student?.resume} currentPage={currentPage} scale={scale} />
      <Document
      className="pdf-document"
      file={iDetails?.student.resume}
      onLoadSuccess={handleDocumentLoadSuccess}
      loading={<PageLoading />}
      onDoubleClick={handleDoubleClick}
      externalLinkTarget='_blank'
    >
      <Page
        size="A4"
        renderTextLayer={false}
        width={830}
        pageNumber={currentPage}
        scale={scale}
      />
    </Document>
      :

        <PageLoading />}
    </div>
  );
};

export default PdfViewer;
