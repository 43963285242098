import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
// import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import { Typography } from "@mui/material";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const AdminsFAQS = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question: "What is Interwiu.com?",
      answer:
        "interwiu.com is an online mock interview platform which connects the students (as interviewees) with the professionals from top MNCs (as interviewers) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews with Live as well as Documented Feedback. The interviewers and mentors serving at the platform are experienced professionals from various big MNCs like Microsoft, Amazon, Google, Goldman Sachs, McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture, Deloitte, etc.",
    },
    {
      question: "How is a Mock Interview Scheduled?",
      answer:
        "A student browses the interviewers, apply filters (profile, suitable date, etc.) to refine his/her search, and send a Mock Interview Request to each of the best suiting interviewers by picking a date and time-slot as per the respective interviewer’s and his/her own availability",
    },
    {
      question: "How do I check the available Interviewers on the platform?",
      answer:
        "Visit the page of [Interviewers] to see all the interviewers registered at interwiu.com along with their available mock interview slots (through the button of [View Slots] on the respective cards). You can apply the filters of date, profile, company (organization) of the interviewer, etc to refine your search and check the interviewers as per your preferences.",
    },
    {
      question: "How do I check the performance of the students of my institute in mock interviews?",
      answer:
        "Visit the page of [Feedback and Comments], and then go to the subsequent page of [By Interviewers] to check the Feedback (along with the Action Items for Improvement) provided by the Interviewers for each mock interview. By visiting the page of [By Students] there, you can also check the ratings/review-comments provided by the students on their mock interview experience.",
    },
    {
      question:
        "How is the structure of a Mock Interview with Feedback Session?",
      answer:
        "The mock interviews are structured exactly like real placement/internship interviews in every context such as kind/type, level, and number of questions asked; inclusion of stress rounds; environment; etc. The feedback is provided by your interviewer during/after the mock interview on every possible aspect like your incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. Interviewers also suggest better ways of answering certain questions",
    },
    {
      question:
        "What is the duration of a Mock Interview with Feedback Session?",
      answer:
      "The standard duration of a mock interview (with feedback session) is 45 minutes. Nevertheless, the duration can be extended based on your requirements and interviewer’s availability",    
    },
    {
      question: "How do I add other admins to the account of my institute?",
      answer:
        "While creating your account or verifying your account (if you have been added as a co-admin by another admin) at interwiu.com, or while visiting your profile page (if your account is already created/verified), click on the button of [Add Another Admin], add the basic details of another admin, and save the same. The respective “to be admin” will be notified and he/she will become an admin after verifying his/her profile at the institute account. This way, you can add any number of admins",
    },
    {
      question:
        "What are the roles/responsibilities/rights of different admins of an institute account?",
      answer:
        "Each admin can add the students to the Institute Account of a given institute, and all the added students lie in one place of the institute account. Every admin can view all the students, and can make the payment and generate the coupon codes for the students added by himself/herself as well as any other admin(s) of the respective institute account. The coupon codes will be automatically sent to the respective students via Gmail and WhatsApp",
    },
    {
      question: "How do I add the students of my institute to the institute account?",
      answer:
        "On the [Add Students] page, upload the data of the students of your institute (Name, Gmail address, and WhatsApp number) as a [.csv] file, choose the number of mock interviews to be booked per student, select your payment preference, and save the details. Then pay the required amount (in case it’s there, depending on your payment preference) and generate the coupon codes for all the added students. The added students will automatically receive their respective coupon codes via Gmail and WhatsApp.",
    },
    {
      question: "How do I check which all students of my institute (whom I had added) have actually created their personal account at interwiu.com?",
      answer:
        "On the page of [Current Students], visit the page of [Registered Students]. It will show all the registered students of your institute (who have their personal accounts created at interwiu.com) irrespective of the admins who have added them.",
    },
    {
      question: "If I pick a payment preference involving less than 100% payment from the institute's end, how and when the students make the remaining payment to book the mock interviews?",
      answer:
        "When you add the students and generate the coupon codes for them, they receive a coupon code using which they get a flat discount on the respective bookings. So, a student pays the remaining amount individually for each and every mock interview to confirm it after his/her respective mock interview request has been accepted by the interviewer.",
    },
    {
      question:
        "How do I check the mock interviews booked by the students of my institute?",
      answer:
        "Visit the page of [Mock Interviews], and then go to the subsequent pages of [Upcoming Interviews] and [Completed Interviews] to see the respective mock interviews. Each card displays the details of a mock interview along with the details of the respective student of your institute and the interviewer",
    },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <Typography sx={{}} variant="h3">
            FAQs for Institute Admins
          </Typography>

          <Typography>Got a question? We're here to answer!</Typography>

          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
export default AdminsFAQS;
