import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import Navbarlogedin from "./Navbarlogedin";
import "./UpcomingInterviews.css";
import DatePicker from "react-date-picker";
import UpcomingInterview from "./cards/UpcomingInterview";
import axios from "axios";
import { BASE } from "../../constants/endpoints";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import {
  compare,
  compareSlots,
  getCompanyLogo,
  getCountForUpcoming,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import moment from "moment-timezone";
import { Oval } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";

const changeLink = () => {
  const links = document.querySelectorAll(".nav-item a p");
  links.forEach((link) => {
    if (link.classList.contains("active")) {
      link.classList.remove("active");
    }
    if (link.innerHTML === "Upcoming Interviews") {
      link.classList.add("active");
    }
  });
};

const UpcomingInterviews = () => {
  useEffect(() => {
    changeLink();
  }, []);
  var prevScrollpos = window.pageYOffset;
  const { user } = useContext(UserContext);
  // console.log(user);
  const [upcomingRequest, setupcomingRequest] = useState([]);
  const [UpcomingInterViewers, setUpcomingInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqPaid",
    userRole: "Student",
    reqSite: "Upcoming"
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response.data);
          setupcomingRequest(response.data.Requests);
          setUpcomingInterViewers(response.data.SentInterViewers);
          setLoaded(true);
        });
    }
    fetchData();
  }, []);
  // window.onscroll = function () {
  //   var currentScrollPos = window.pageYOffset;
  //   if (currentScrollPos >= 104) {
  //     if (document.querySelector(".interview-head").style) {
  //       document.querySelector(".interview-head").style.position = "sticky";
  //       document.querySelector(".interview-head").style.top = "0";
  //     }
  //   } else {
  //     if (document.querySelector(".interview-head").style) {
  //       document.querySelector(".interview-head").style.position = "unset";
  //       document.querySelector(".interview-head").style.top = "0";
  //     }
  //   }
  //   prevScrollpos = currentScrollPos;
  // };
  const [count, setCount] = React.useState(-2);
  const [cards, setCards] = React.useState();
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get(`${BASE}/api/get`);
      setCards(req.data);
      for (let i = 0; i < req.data?.length; i++) {
        if (req.data[i].payment === true) {
          console.log(count);
        }
      }
    }

    fetchData();
  }, []);
  // const resultReq = [];
  // const resultInt = [];
  // for(let i=0;i<upcomingRequest.length;i++){
  //   if(upcomingRequest[i].reschedule.isRescheduled !== 'true'){
  //     resultReq.push(upcomingRequest[i]);
  //     resultInt.push(UpcomingInterViewers[i]);
  //   }
  // }
  // // Pagination
  // useEffect(() => {
  //   upcomingRequest.sort(compare)
  //   if (rendered3.current) return;
  //   if(resultReq.length!==0){
  //     for(let i=0;i<resultReq.length;i++){
  //       if((moment().tz("Asia/Kolkata").diff( moment( resultReq[i].date + " " + t24HDecimalTo12HFormat(resultReq[i].toTime) , 'DD/MM/YYYY hh:mm a'), 'seconds')) < 0){
  //         upcomingRequest.push(upcomingRequest[i])
  //         UpcomingInterViewers.push(UpcomingInterViewers[i]);
  //         resultReq.sort(compare)
  //       }
  //     }
  //     upcomingRequest.sort(compare)
  //     rendered3.current = true;
  //   }
  //   console.log(UpcomingInterViewers);
  //   console.log(upcomingRequest)
  // }, [upcomingRequest, UpcomingInterViewers,resultReq,resultInt]);
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, upcomingRequest]);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Upcoming</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <div className="upcoming-interview interviews">
        {/* <div className="interviews-container"></div> */}
        <div
          className="flex justify-center items-center mb-4 w-auto"
          id="head"
        >
          <h2 className="interview-head text-2xl font-medium heading1">
            Upcoming Interviews (
            {upcomingRequest.length
              ? page * 10 -
                9 +
                " - " +
                (upcomingRequest.length > page * 10
                  ? page * 10
                  : upcomingRequest.length)
              : null}
            ) out of ({upcomingRequest.length ? upcomingRequest.length : 0})
          </h2>
        </div>
        <div className="interview-cont">
          <div className="flex justify-between mx-8">
            <div>
              Showing the results for {date ? date.toDateString() : "all days"}
            </div>
            {/*
            <div className="flex gap-2">
              <label htmlFor="date">Filter by Date: </label>
              <DatePicker
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                value={date}
              />
            </div>
            */}
          </div>
          {loaded ? (
            <div className="interviews-list">
              {upcomingRequest
                ?.slice(pageNo[0], pageNo[1])
                .map((result, index) => (
                  <UpcomingInterview
                    user={user}
                    name={
                      UpcomingInterViewers[pageNo[0] + index]?.fname +
                      " " +
                      UpcomingInterViewers[pageNo[0] + index]?.lname
                    }
                    desg={UpcomingInterViewers[pageNo[0] + index]?.designation}
                    price={upcomingRequest[pageNo[0] + index].price}
                    profile={upcomingRequest[pageNo[0] + index].profile}
                    subprofile={upcomingRequest[pageNo[0] + index].subprofile}
                    slotStart={upcomingRequest[pageNo[0] + index].fromTime}
                    slotEnd={upcomingRequest[pageNo[0] + index].toTime}
                    status={upcomingRequest[pageNo[0] + index].reqStatus}
                    id={UpcomingInterViewers[pageNo[0] + index]?.id}
                    date={upcomingRequest[pageNo[0] + index].date}
                    company={
                      UpcomingInterViewers[pageNo[0] + index]?.currCompany
                    }
                    image={
                      UpcomingInterViewers[pageNo[0] + index]?.isDisabled ===
                        true || UpcomingInterViewers?.isDelete === true
                        ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        : UpcomingInterViewers[pageNo[0] + index]?.photo
                    }
                    mockId={upcomingRequest[pageNo[0] + index].mockId}
                    logo={getCompanyLogo(
                      UpcomingInterViewers[pageNo[0] + index]?.currCompany
                    )}
                    req_id={upcomingRequest[pageNo[0] + index].reqId}
                    isRescheduled={
                      // upcomingRequest[pageNo[0] + index].reschedule?.isRescheduled ||
                      upcomingRequest[pageNo[0] + index].reschedule?.isRescheduled ||
                      upcomingRequest[pageNo[0] + index].reschedule?.reason ||
                      upcomingRequest[pageNo[0] + index].reschedule?.desc ||
                      upcomingRequest[pageNo[0] + index].reschedule?.count === 1
                    }
                    isUnavailable={
                      UpcomingInterViewers[pageNo[0] + index]?.isDisabled ===
                        true || UpcomingInterViewers?.isDelete === true
                    }
                    isDisable={
                      UpcomingInterViewers[pageNo[0] + index]?.isDisabled ===
                      true
                    }
                    isDelete={
                      UpcomingInterViewers[pageNo[0] + index]?.isDelete === true
                    }
                    reschedule={
                      upcomingRequest[pageNo[0] + index].reschedule
                    }
                  />
                ))}
              {/* Pagination */}
              <div className="flex flex-row justify-center mt-4 my-2">
                <Pagination
                  count={Math.ceil(upcomingRequest?.length / 10)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </div>
          ) : (
            <div className="fullPageLoader">
              <Oval
                height={120}
                width={120}
                color="#306bc9"
                wrapperStyle={{ padding: "50%, 50%" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#90caf9"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UpcomingInterviews;
