export const special = {
  'Aerospace Engineering': [
    'Structures',
    'Aerodynamics',
    'Propulsion',
    'Flight Mechanics',

  ],
  'Biomedical Engineering': [
    'Bioinstrumentation',
    'Biomaterials',
    'Biomechanics',
    'Clinical Engineering',
    'Cellular, Tissue, and Genetic Engineering',
    'Medical Imaging',
    'Orthopedic Bio-engineering'
  ],
  'Bioscience and Bioengineering': [
    'Microbial Systems',
    'Cell Biology',
    'Biomaterials, Tissue Engineering & Drug Delivery',
    'Biophysics And Computational Biology',
    'Immunobiology',
    'Omics & Systems Biology',
    'Sensors, Instrumentation & Modelling'
  ],
  "Biotechnology": [
    'Biological Sciences',
    'Biological Engineering',
    'Computational Biology',
    'Chemical Biology',

  ],
  'Chemical Engineering': [
    'Energy and Environment',
    'Unit Operation and Process',
    'Simulations',
    'Reaction Engineering',

  ],
  'Civil Engineering': [
    'Building Tech & Construction Mgmt',
    'Environmental & Water Resources Engg',
    'Geotechnical Engineering',
    'Structural Engineering',
    'Transportation Engineering',

  ],
  'Computer Science and Engineering': [
    'Data Mining, ML, DL, AI, Speech Processing, & Computer Vision',
    'Systems Engg (VLSI, OS, Comp Arch, HPC, Comp Networks, Compiler Design, etc)',
    'Theoretical Computer Sc. (Data Struct. and Algo, Cryptography, Graph/Computational Complexity Theory, etc)',
    'Cloud Computing, and DevOps',
    'Cryptocurrency and Blockchain',

  ],
  'Electrical Engineering': [
    'Power (Systems, Electronics, and Elec Machines)',
    'Communication',
    'Electronics (VLSI Design, MEMs, etc.)',
    'Control Systems',
    'Instrumentation',

  ],
  'Electrical and Electronics Engineering': [
    'Electrical Power',
    'Power Electronics and Controls',
    'Data processing and computing',
    'Electromagnetics',

  ],
  'Electronics & Communication Engineering': [
    'VLSI and Embedded System',
    'Digital Signal and/or Image Processing',
    'Communication',
    'Robotics and Automation',
    'Signal and Image Processing',

  ],
  'Electric Vehicles (EV)': [
    'EV Design',
    'Motors, Converter, Inverter, Controls and Controllers',
    'Battery Pack and Battery Management System',
    'EV Charging and Charging Infrastructure'
  ],
  'Mechanical Engineering': [ 'Design', 'Thermal and Fluids', 'Manufacturing' ],
  'Metallurgical and Materials Engineering': [
    'Mechanical Metallurgy and Joining',
    "Materials' Processing and Characterization",
    'Computational Materials Engineering',
    'Iron and Steel Technology',
    'Ceramics & Biomedical Materials',

  ],
  'Mining Engineering': [
    'Rock Mechanics',
    'Mine Environment and Ventilation',
    'Mine Safety and Risk Analysis',
    'Mine Planning and Designs',
    'Coal Beneficiation',

  ],
  'Ocean Engineering': [
    'Structural Analysis, Design & Testing',
    'Wave Hydrodynamics & Coastal Processes',
    'Ships, Floating Platforms & Underwater Vehicles',
    'Marine Geotechniques',
    'Materials in Marine Environment',
    'Ocean Energy',

  ]
};

export const branchSpecilization = {
  
    'Aerospace Engineering': [
      'Structures',
      'Aerodynamics',
      'Propulsion',
      'Flight Mechanics',
  
    ]
  ,
  
    'Applied Mechanics': [
      'Solid Mechanics',
      'Fluid Mechanics',
      'Biomedical Engg',
  
    ]
  ,
  
    'Biomedical Engineering': [
      'Bioinstrumentation',
      'Biomaterials',
      'Biomechanics',
      'Clinical Engineering',
      'Cellular, Tissue, and Genetic Engineering',
      'Medical Imaging',
      'Orthopedic Bio-engineering',
      'Rehabilitation Engineering',
  
    ]
  ,
  
    'Bioscience and Bioengineering': [
      'Microbial Systems',
      'Cell Biology',
      'Biomaterials, Tissue Engineering & Drug Delivery',
      'Biophysics And Computational Biology',
      'Immunobiology',
      'Omics & Systems Biology',
      'Sensors, Instrumentation & Modelling',
      'Computational Neurophysiology',
  
    ]
,
  
    "Biotechnology": [
      'Biological Sciences',
      'Biological Engineering',
      'Computational Biology',
      'Chemical Biology',
  
    ]
  ,
  
    'Chemical Engineering': [
      'Energy and Environment',
      'Unit Operation and Process',
      'Simulations',
      'Reaction Engineering',
  
    ]
  ,
  
    "Chemistry": [
      'Physical Chemistry',
      'Organic Chemistry',
      'Inorganic Chemistry',
      'Biological/Biochemistry',
  
    ]
  ,
  
    'Civil Engineering': [
      'Building Tech & Construction Mgmt',
      'Environmental & Water Resources Engg',
      'Geotechnical Engineering',
      'Structural Engineering',
      'Transportation Engineering',
  
    ]
  ,
  
    'Computer Science and Engineering': [
      'Data Mining, ML, DL, AI, Speech Processing, & Computer Vision',
      'Systems Engg (VLSI, OS, Comp Arch, HPC, Comp Networks, Compiler Design, etc)',
      'Theoretical Computer Sc. (Data Struct. and Algo, Cryptography, Graph/Computational Complexity Theory, etc)',
      'Cloud Computing, and DevOps',
      'Cryptocurrency and Blockchain',
  
    ]
  ,
  
    "Economics": [
      'Macroeconomics',
      'Microeconomic',
      'Econometrics',
      'Labour Economics',
      'Development Economics',
  
    ]
  ,
  
    'Electrical Engineering': [
      'Power (Systems, Electronics, and Elec Machines)',
      'Communication',
      'Electronics (VLSI Design, MEMs, etc.)',
      'Control Systems',
      'Instrumentation',
  
    ]
  ,
  
    'Electrical and Electronics Engineering': [
      'Electrical Power',
      'Power Electronics and Controls',
      'Data processing and computing',
      'Electromagnetics',
  
    ]
  ,
  
    'Electronics & Communication Engineering': [
      'VLSI and Embedded System',
      'Digital Signal and/or Image Processing',
      'Communication',
      'Robotics and Automation',
      'Signal and Image Processing',
  
    ]
  ,
  
    'Engineering Design': [
      'Automotive Systems & Design',
      'Materials, Design & Manufacturing',
      'Robotics & Mechatronics',
      'Biomedical Design',
  
    ]
  ,
  
    'Humanities and Social Sciences': [ 'Development Studies', 'English Studies' ]
  ,
  
    'Management/Business Studies': [
      'Operations',
      'Analytics',
      'Finance',
      'Marketing',
      'HR',
      'Information Systems',
  
    ]
  ,
  
    'Mathematics/Mathematics and Computing': [
      'Applied Mathematics',
      'Pure Mathematics',
      'Computational Mathematics',
      'Discrete Mathematics',
      'Financial Mathematics',
  
    ]
  ,
  
    'Mechanical Engineering': [ 'Design', 'Thermal and Fluids', 'Manufacturing' ]
  ,
  
    'Metallurgical and Materials Engineering': [
      'Mechanical Metallurgy and Joining',
      "Materials' Processing and Characterization",
      'Computational Materials Engineering',
      'Iron and Steel Technology',
      'Ceramics & Biomedical Materials',
  
    ]
  ,
  
    'Mining Engineering': [
      'Rock Mechanics',
      'Mine Environment and Ventilation',
      'Mine Safety and Risk Analysis',
      'Mine Planning and Designs',
      'Coal Beneficiation',
  
    ]
  ,
  
    'Ocean Engineering': [
      'Structural Analysis, Design & Testing',
      'Wave Hydrodynamics & Coastal Processes',
      'Ships, Floating Platforms & Underwater Vehicles',
      'Marine Geotechniques',
      'Materials in Marine Environment',
      'Ocean Energy',
  
    ]
  ,
  
    'Physics/Engineering Physics': [
      'Condensed Matter',
      'Computational and Statistical Physics',
      'High Energy Physics',
      'Physics of Quantum Matter and Information Systems',
      'Plasma Physics',
      'Optics and Photonics',
      'Nuclear Engineering and Energy Systems',
  
    ]
  
}
