import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Typography from "@mui/material/Typography";
import './Pricing.css';

const Pricing = () => {

  const Card = styled.div((props) => [
    tw` md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row",
  ]);
  const Image = styled.div((props) => [
    `background: url("${props.imageSrc}") no-repeat center center / contain;`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
  ]);
  const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
  const Title = tw.h4`text-3xl font-bold text-gray-900`;
  const Description = tw.p`mt-2 text-sm leading-loose`;

  return (
    <div style={{maxWidth: "1024px", margin: "auto", padding: "0px 24px 24px 30px"}}>
      <Card  style={{  alignItems: "center", justifyContent: "space-between" }}>
      
        <div style={{flex:"1"}}>

        <img className="pricing-img" src="https://img.freepik.com/free-vector/e-wallet-concept-illustration_114360-7561.jpg?w=740&t=st=1666075685~exp=1666076285~hmac=5d3e5c15103c970e61514a5a93a292c71967671ab8a1d360dcae7d6fa8fd5892" alt="" />
        </div>
        {/* <Image style={{margin: "auto"}} imageSrc={"https://img.freepik.com/free-vector/e-wallet-concept-illustration_114360-7561.jpg?w=740&t=st=1666075685~exp=1666076285~hmac=5d3e5c15103c970e61514a5a93a292c71967671ab8a1d360dcae7d6fa8fd5892"} /> */}
        <Details style={{margin: "0",  flex:"1"}}>
        <Typography variant="h3" sx={{
            fontWeight:"bold",
            mt:4
        }}>Pricing</Typography>
          <Title>Payment is made or a Coupon Code is applied after acceptance of a <span style={{color: "#6415FF"}}>Mock Interview Request</span></Title>
          <Description className="normalText">As a Student, once your Mock Interview Request is accepted by an Interviewer, either <span style={{whiteSpace: "nowrap"}}><span style={{fontWeight: "bold", fontSize: "20px"}}>pay</span> <span style={{fontSize: "20px", fontWeight: "600", margin: "0 5px", color: "#6415FF", backgroundColor: "#E2E2E2", padding: "5px 10px", borderRadius: "50px"}}>INR 499</span></span> or <span style={{whiteSpace: "nowrap"}}><span style={{fontWeight: "bold", fontSize: "20px"}}>apply</span> <span style={{fontSize: "20px", fontWeight: "600", margin: "0 5px", color: "#6415FF", backgroundColor: "#E2E2E2", padding: "5px 10px", borderRadius: "50px"}}>Coupon Code</span></span> and pay the discounted price to confirm that Mock Interview. Then, join that Mock Interview Session as per the schedule.</Description>
        </Details>
      </Card>
    </div>
    // <div className="p-5" style={{ backgroundColor: "#f6e6d9" }}>
    //   <Typography
    //     variant="h2"
    //     component="h2"
    //     sx={{
    //       textAlign: "center",
    //       color: "#676df5",
    //       fontSize: "bold",
    //       marginTop: "50px",
    //     }}
    //     gutterBottom
    //   >
    //     Pricing
    //   </Typography>
    //   <div className="flex flex-wrap gap-5 justify-center">
    //     <div
    //       className="rounded-md relative bg-white overflow-hidden"
    //       style={{ width: "300px" }}
    //     >
    //       <div
    //         className="text-center text-white"
    //         style={{ backgroundColor: "#6184d8" }}
    //       >
    //         <Typography variant="h4" gutterBottom>
    //           Uno
    //         </Typography>
    //       </div>
    //       <div className="p-3 text-center my-auto">
    //         <Typography variant="h6" gutterBottom>
    //           1 Mock Interview <br /> (with Live Feedback)
    //         </Typography>
    //       </div>
    //       <div className="text-center flex justify-center mb-4">
    //         <div className="rounded-full border-2 border-blue-600 text-blue-600 px-3 py-2 text-lg font-bold">
    //           INR 499
    //         </div>
    //         {/* <Chip label="INR 499" variant="outlined" color="primary" /> */}
    //       </div>
    //     </div>
    //     <div
    //       className="rounded-md relative bg-white overflow-hidden"
    //       style={{ width: "300px" }}
    //     >
    //       <div
    //         className="text-center text-white"
    //         style={{ backgroundColor: "#89c152" }}
    //       >
    //         <Typography variant="h4" gutterBottom>
    //           Duo
    //         </Typography>
    //       </div>
    //       <div className="p-3 text-center my-auto">
    //         <Typography variant="h6" gutterBottom>
    //           2 Mock Interviews <br /> (with Live Feedback)
    //         </Typography>
    //       </div>
    //       <div className="text-center flex justify-center mb-4">
    //         <div className="rounded-full border-2 border-green-500 text-green-500 px-3 py-2 text-lg font-bold">
    //           INR 549
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       className="rounded-md bg-white overflow-hidden"
    //       style={{ width: "300px" }}
    //     >
    //       <div
    //         className="text-center text-white"
    //         style={{ backgroundColor: "#50c4b7" }}
    //       >
    //         <Typography variant="h4" gutterBottom>
    //           Trio
    //         </Typography>
    //       </div>
    //       <div className="p-3 text-center my-auto">
    //         <Typography variant="h6" gutterBottom>
    //           3 Mock Interviews <br /> (with Live Feedback)
    //         </Typography>
    //       </div>
    //       <div className="text-center flex justify-center mb-4">
    //         <div className="rounded-full border-2 border-emerald-600 text-emerald-600 px-3 py-2 text-lg font-bold">
    //           INR 799
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* Need Varification */}
    //   <div className="text-center text-lg mx-auto mt-4">
    //     <p>Payment can be made after acceptance of a Mock Interview Request</p>
    //   </div>
    // </div>
  );
};

export default Pricing;