import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";
import {
  downloadPDF,
  programValues,
  t24HDecimalTo12HFormat,
  convertDateFormat,
  toastStyle,
  isIIT,
} from "../../../utility/helper";
import LZString from "lz-string";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";

const UpcomingInterview = ({
  user,
  name,
  desg,
  slotStart,
  subprofile,
  image,
  slotEnd,
  branch,
  college,
  program,
  year,
  id,
  date,
  profile,
  cardId,
  resume,
  mockId,
  isRescheduled,
  isDisable,
  isDelete,
  isUnavailable,
  reschedule,
  price,
}) => {
  const REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET =
    process.env.REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET;

  const cancelling =
    window.location.href.includes("/i/cancel") ||
    window.location.href.includes("/i/report");

  const [meetUrl, setMeeturl] = useState();
  useEffect(() => {
    axios
      .get(`${BASE}/send/getReqByMockid/${mockId}`)
      .then((res) => {
        console.log(res.data.meetUrl);
        if (REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET === "ON") {
          if (res.data?.meetUrl?.split("/")[1] === "vc") {
            // setMeeturl(`${res.data.meetUrl}?id=${user.id}&name=${user.fname}`);
            setMeeturl(`${res.data.meetUrl}/${user.id}/${user.fname}`);
          } else {
            // setMeeturl(`/vc/${mockId}?id=${user.id}&name=${user.fname}`);
            setMeeturl(`/vc/${mockId}/${user.id}/${user.fname}`);
          }
        } else {
          if (res.data?.meetUrl?.split("/")[1] !== "vc") {
            setMeeturl(res.data.meetUrl);
          }
        }
        // setMeeturl(
        //   REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET === "ON"
        //     ? `${res.data.meetUrl}?id=${user.id}&name=${user.fname}`
        //     : res.data.meetUrl
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.group("date", date);

  const rescheduleStatusText = {
    sent: "Requested for Rescheduling",
    declined: "Reschedule Request Declined",
    accepted: "Rescheduled Successfully",
    failed: "Reschedule Request Failed"
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  return (
    <>
      {(isRescheduled === "true" || isRescheduled === true) && (
        <div
          className={"box-shadow-request-card px-3 py-2 s-desktop-card"}
          style={{
            position: "absolute",
            right: "30px",
            zIndex: "2",
            backgroundColor: "#f5ed4e",
            borderRadius: "10px",
          }}
        >
          <p>{rescheduleStatusText[reschedule?.status] || "no status"}</p>
        </div>
      )}
      <div
        className={
          "relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card"
        }
        id={"stack-" + cardId}
      >
        {/* -------- */}
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                      isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                    "_blank"
                  );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3">
          <div className="flex py-1 justify-between w-full">
            <div className="flex gap-x-2">
              <div className="w-12 h-12 drop-shadow-2 mx-1 rounded-lg">
                {isIIT(college) ? (
                  <img
                    src={require("../../Dashboard/Logos_IITs/" +
                      college.split(" ").join("_") +
                      "_Logo.png")}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />
                ) : (
                  <img
                    src={require("../../Dashboard/Logos_IITs/interwiu_logo.png")}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />
                )}
              </div>
              <div>
                <h4 className="text-2xl text-left font-medium leading-6">
                  {name}
                </h4>
                <p>
                  {programValues[program]}, {year}, {branch}
                </p>
                <p>{college}</p>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center h-10 text-blue-500 text-2xl">
                <Button variant="contained" onClick={() => downloadPDF(resume)}>
                  <i className="fa-solid fa-link mr-1"></i> Resume
                </Button>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div className="col-span-2">
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR 299</p>
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div className="col-span-3" style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{convertDateFormat(date)}</p>
            </div>
          </div> */}
           <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={isRescheduled}
                reschedule={reschedule}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
              />
          <div className="sent-item-btns flex justify-center mt-3">
            {REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET === "ON"
              ? meetUrl &&
                !cancelling && (
                  <Link to={"/valid" + meetUrl} target="_blank">
                    <button
                      type="button"
                      className="cust-btn join-meeting-btn"
                      style={{
                        backgroundColor: "green",
                        margin: "0",
                        marginRight: "10px",
                      }}
                    >
                      Join Mock Interview
                    </button>
                  </Link>
                )
              : meetUrl && (
                  <button
                    onClick={() => {
                      meetUrl.includes("http")
                        ? window.open(meetUrl, "_blank")
                        : window.open(`https://${meetUrl}`, "_blank");
                    }}
                    type="button"
                    className="cust-btn join-meeting-btn"
                    style={{
                      backgroundColor: "green",
                      margin: "0",
                      marginRight: "10px",
                    }}
                  >
                    Join Mock Interview
                  </button>
                )}

            <div className="text-green-700 text-lg font-bold">
              Mock Interview ID: {mockId}
            </div>
          </div>
        </div>
      </div>

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
        id={"stack-" + cardId}
      >
         {(isRescheduled === "true" || isRescheduled === true)  && (
          <div
            className={"s-mobile-card"}
            style={{
              position: "absolute",
              left: "0px",
              zIndex: "2",
              backgroundColor: "#f5ed4e",
              borderBottomRightRadius: "10px",
              padding: '0.2rem 0.75rem 0.1rem 0.75rem',
            }}
          >
            <p style={{
              fontSize: '0.8rem',
              fontFamily: 'sans-serif',
              letterSpacing: '1.5px'
            }}>{rescheduleStatusText[reschedule.status] || "no status"}</p>
          </div>
        )}
        <Button
          className="box-shadow-request-card s-mobile-resume-btn"
          variant="contained"
          onClick={() => downloadPDF(resume)}
        >
          <i className="fa-solid fa-link mr-1"></i> Resume
        </Button>
        {/* <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                      isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                    "_blank"
                  );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div> */}

        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"
            
          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                  "_blank"
                );
              }}
              className="view-profile-card-btn  mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>
              {programValues[program]}, {year}, {branch}
            </p>
            <p>{college}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <div className="s-duration-price" style={{ marginBottom: "10px" }}>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Duration :{" "}
                </span>
                <span className="text-lg">45 Min</span>
              </div>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Price :{" "}
                </span>
                <span className="text-lg">INR 499</span>
              </div>
            </div>

            <div style={{justifyContent: 'flex-start'}} className="s-duration-price">
              <p
                className="text-base text-gray-400"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginRight: "5px",
                  transform: "translateY(2px)",
                }}
              >
                Interview Slot :{" "}
              </p>
              <div>
                <span className="text-lg">
                  {" "}
                  {t24HDecimalTo12HFormat(slotStart)}
                </span>
                <span> - </span>
                <span className="text-lg">
                  {t24HDecimalTo12HFormat(slotEnd)}
                </span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p
                    className="text-base text-gray-400"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Profile :{" "}
                  </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})`: profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-green-700 text-lg font-bold pt-1"
          style={{
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "1.4rem",
            textAlign: "center",
          }}
        >
          Mock Interview ID: {mockId} <FaCopy style={{display: 'inline', fontSize: '1rem',transform: 'translate(6px,-3px)'}} onClick={handleCopy} />
        </div>
        {/* {!int?.iComment && (
          <div className="sent-item-btns flex justify-center w-full">
            <div className="rate-btn flex w-full">
              {feedback ? (
                <Button
                  variant="contained"
                  sx={{
                    borderColor: "gray",
                    color: '#15803d',
                    borderRadius: '0px 0px 10px 10px'
                  }}
                  className="s-mobile-dis-btn w-full"
                >
                  Feedback Sent
                </Button>
              ) : (
                <Link
                  to={"/feedback/interviewer/vc/" + mockId}
                  target="_blank"
                  className="w-full"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      borderRadius: '0px 0px 10px 10px'

                    }}
                    className="blink s-mobile-btn w-full"
                  >
                    Send Feedback
                  </Button>
                </Link>
              )}
            </div>
          </div>
        )} */}

        {REACT_APP_USE_INHOUSE_VC_APP_OVER_GMEET === "ON"
          ? meetUrl && (
              <Link to={"/valid" + meetUrl} target="_blank" className="w-full">
                <button
                  type="button"
                  className="cust-btn join-meeting-btn s-mobile-btn  w-full"
                  style={{
                    backgroundColor: "green",
                    borderRadius: "0px 0px 10px 10px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    textTransform: "uppercase",
                  }}
                >
                  Join Mock Interview
                </button>
              </Link>
            )
          : meetUrl && (
              <button
                onClick={() => {
                  meetUrl.includes("http")
                    ? window.open(meetUrl, "_blank")
                    : window.open(`https://${meetUrl}`, "_blank");
                }}
                type="button"
                className="cust-btn join-meeting-btn s-mobile-btn w-full"
                style={{
                  backgroundColor: "green",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                Join Mock Interview
              </button>
            )}
      </div>
    </>
  );
};
export default UpcomingInterview;
