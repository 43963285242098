import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../misc/Headings.js";
// import NavbarInterviewer from "./NavbarInterviewer.js";
import {
  TextField,
  Button,
  Grid,
  RadioGroup,
  Radio,
  Divider,
  MenuItem,
  Select,
} from "@mui/material";

// import EmailIllustrationSrc from "../../images/email-illustration.svg";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import ErrorIcon from "@mui/icons-material/Error";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import "./CompletedInterviews.css";
import { COMPANY_NAME } from "../../../constants/variables.js";
import axios from "axios";
import { BASE } from "../../../constants/endpoints.js";
import UpcomingInterview from "../../Dashboard/cards/UpcomingInterview2.js";
import {
  compareTimeForReport,
  getCompanyLogo,
  toastStyle,
} from "../../../utility/helper.js";
import { toast } from "react-hot-toast";
import Navbarlogedin from "../../Dashboard/Navbarlogedin.js";
import UserContext from "../../../contexts/UserContext.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";


const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Report = () => {
  const [state, setState] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
  });
  const { one, two, three, four, five } = state;
  const subheading = "",
    heading = (
      <>
        <span tw="text-changed-100">Report an Issue</span>
        <wbr />
      </>
    ),
    description = "Choose the category of your issue.",
    textOnLeft = true;
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [inputs, setInputs] = React.useState({
    idVerify: "",
  });

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [cards, setCards] = React.useState(null);
  const [interviewer, setInterViewer] = React.useState(null);
  const [verified, setVerified] = useState(false);
  const [status, setStatus] = useState();
  const [error, setError] = useState(false);
  const [mockDetails, setMockDetails] = useState({});
  const [radio, setRadio] = useState(null);

  const [mtList, setMtList] = useState({ adminId: user.id });
  const [gList, setGList] = useState({ adminId: user.id });

  const handleChangeRadio = (e) => {
    setRadio(e.target.value);
    setGList({ adminId: user.id });
    setMtList({ adminId: user.id });
    console.log(radio);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setMtList({ ...mtList, mockId: "" });
    setInputs({ ...inputs, [name]: value, mockId: "" });
  };
  async function fetchData() {
    console.log(inputs.idVerify);
    if (inputs.idVerify === "") {
      window.alert("Please enter a valid Mock Interview ID");
      return;
    }
    await axios
      .get(`${BASE}/send/getRequest/${inputs.idVerify}`)
      .then((response) => {
        console.log(response.data);
        setCards(response.data);
        setVerified(true);
        axios
          .get(`${BASE}/int/interviewer/${response.data.interviewerId}`)
          .then((res) => {
            setInterViewer(res.data);
          });
      })
      .catch((error) => {
        window.alert("Please enter a valid Mock Interview ID");
        setStatus("invalid");
        setVerified(false);
        console.error(error);
      });
    await axios
      .get(`${BASE}/send/getMockInterview/${inputs.idVerify}`)
      .then((res) => {
        console.log(res.data);
        setMockDetails(res.data);
      });
  }
  useEffect(() => {
    // const result = compareTimeForReport(
    //   cards?.fromTime,
    //   cards?.toTime,
    //   cards?.date
    // );
    // if (
    //   status !== "invalid" &&
    //   status !== "completed" &&
    //   status !== "expired" &&
    //   status !== "cancelled" &&
    //   status !== "upcoming" &&
    //   status !== "6 hours"
    // ) {
    //   setMtList({ ...mtList, [inputs.idVerify]: inputs.idVerify });
    // }
    if (status !== "invalid") {
      setMtList({ ...mtList, mockId: inputs.idVerify });
    }

    // setStatus(result);
    console.log(status);
    console.log(cards);
  }, [cards]);

  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const [data, setData] = React.useState({
    textofArea: "",
  });
  const handleChangeofBox = (e) => {
    const value = e.target.value;
    setError(false);
    setData({
      ...data,
      [e.target.name]: value,
    });
  };
  const handleSubmit = () => {
    if (
      five === true &&
      (data.textofArea === null || data.textofArea === "" || !data.textofArea)
    ) {
      setError(true);
      return;
    }
    console.log(one, two, three, four, five);
    console.log(data.textofArea);
    axios
      .patch(`${BASE}/send/updateMockInterview/${inputs.idVerify}`, {
        state,
        data,
        role: "Stud",
      })
      .then((res) => {
        console.log(res);
        toast.success("Report Sent Successfully", {
          ...toastStyle.success,
          position: "top-center",
        });
        setTimeout(() => {
          ;
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMtChange = (event) => {
    let x = {};

    if (
      event.target.name !== "mt_1" &&
      event.target.name !== "mt_2" &&
      event.target.name !== "mt_3" &&
      event.target.name !== "mt_4" &&
      event.target.name !== "mt_5" &&
      event.target.name !== "mt_6" &&
      event.target.name !== "mt_7" &&
      event.target.name !== "mt_8" &&
      event.target.name !== "mt_9"
    ) {
      x[event.target.name] = event.target.value;
    } else {
      x[event.target.name] = event.target.checked;
    }

    setMtList({ ...mtList, ...x });

  };


  const handleMtIssue = async () => {

    if (radio === 1 || radio === "1") {
      if (!mtList.intcompany || !mtList.intpos || !mtList.intname) {
        toast.error("Please fill all the required fields(*)", {
          ...toastStyle.error,
          position: "top-center",
        });
        return;
      }

      axios.post(`${BASE}/admin/create-intissue`, mtList).then((res) => {
        toast.success("Issue Reported Successfully")
        setTimeout(() => {
          navigate("/admin/mock-interviews")
        }, 2000)
      }).catch(err => {
        toast.error("Error Occured")
      })

    }
    else if (radio === 2 || radio === "2") {
      axios.post(`${BASE}/admin/create-generalissue`, gList).then((res) => {
        toast.success("Issue Reported Successfully")
        setTimeout(() => {
          navigate("/admin/mock-interviews")
        }, 2000)
      }).catch(err => {
        toast.error("Error Occured")
      })
    }
  };

  const handleGChange = (event) => {

    if (event.target.name === "g_11" || event.target.name === "g_12") {
      setGList({ ...gList, [event.target.name]: event.target.value });
    }
    else {
      setGList({ ...gList, [event.target.name]: event.target.checked });
    }

  }

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Report an Issue</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Container style={{ marginLeft: '0px', marginRight: '0px', paddingLeft: '1rem', paddingRight: '1rem', minHeight: '90vh' }}>
        <TwoColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent className="profile-form-cont2">
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
            </TextContent>
            {/* <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                  sx={{ width: 1 }}
                />
                <Button onClick={fetchData}>Verify</Button>
              </Grid>
            </Grid> */}
            <FormGroup className="profile-form-cont2">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  className="update"
                  value={1}
                  control={<Radio />}
                  onChange={handleChangeRadio}
                  label="About a particular Mock Interviewer"
                // checked={payment === 1 || payment === "1"}
                // disabled={user.isPayment}
                />
                <FormControlLabel
                  className="update"
                  value={2}
                  control={<Radio />}
                  onChange={handleChangeRadio}
                  label="General"
                // checked={payment === 2 || payment === "2"}
                // disabled={user.isPayment}
                />
              </RadioGroup>
            </FormGroup>

            {(radio === 2 || radio === "2") && (
              <div className="profile-form-cont2 my-5">
                <div
                  className="flex flex-col "
                  style={{ alignItems: "flex-start" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_1"
                      label="Number of interviewers is too less "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_2"

                      label="Availability of interviewers is too less"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_3"

                      label="Number of interviewers from top companies is less "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_4"

                      label="Interviewers are often declining the mock interview requests"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_5"

                      label="Interviewers are often canceling the mock interviews "
                    />
                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_6"

                      label="Number of auto-rejected mock interviews is too large"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_7"

                      label="Quality of mock interviews is below our expectation"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_8"

                      label="Quality and/or volume of the feedback provided during mock interviews is below our expectation"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_9"

                      label="The structure of mock interviews is not at par with the real interviews  "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      onChange={handleGChange}
                      name="g_10"

                      label="Duration of mock interviews is less than what it should be   "
                    />
                    <p className="my-3">
                      Other Issue{" "}
                      <input
                        onChange={handleGChange}
                        name="g_11"
                        className=" w-[100%] border-black border-[2px] border-solid rounded-lg overflow-scroll " />
                    </p>
                    <textarea
                      rows={5}
                      cols={5}
                      onChange={handleGChange}
                      name="g_12"

                      className="border-solid border-[2px] border-black rounded-lg my-3 p-2"
                      placeholder="Describe the issue (if required)"
                    />
                  </FormGroup>
                  <Button className="" variant="contained" onClick={handleMtIssue} color="success">
                    Submit
                  </Button>
                </div>
              </div>
            )}

            {(radio === 1 || radio === "1") && (
              <div className="profile-form-cont2 my-5 ">
                <div className="mb-4">
                  <div className="flex gap-5 mb-5 max-[600px]:flex-col">
                    <div className="element ">
                      <label htmlFor="intname">Name of Interviewer</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        id="intname"
                        type="text"
                        name="intname"
                        className="border-2 rounded w-full h-[50px] max-[600px]:h-[40px]"
                        placeholder=""
                        onChange={handleMtChange}
                      />
                    </div>
                    <div className="element">
                      <label htmlFor="intpos">
                        Position/Designation of the Interviewer
                      </label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        id="intpos"
                        type="text"
                        name="intpos"
                        className="border-2 rounded w-full  h-[50px] max-[600px]:h-[40px]"
                        placeholder=""
                        onChange={handleMtChange}
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-5 max-[600px]:flex-col">
                    <div className="element">
                      <label htmlFor="intcompany">
                        Company of the Interviewer
                      </label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        id="intcompany"
                        type="text"
                        name="intcompany"
                        className="border-2 rounded w-full  h-[50px] max-[600px]:h-[40px]"
                        placeholder=""
                        onChange={handleMtChange}
                      />
                    </div>
                    <div className="element">
                      <label htmlFor="intcompany">Mock Interview ID <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="tooltip">
                            Mock Interview ID of any mock <br /> interview  belonging to the <br />concerned interviewer
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className="mr-[10px] rounded-full bg-black relative top-[5px] text-white p-1 w-[20px] scale-75"
                          icon={faInfo}
                        />
                      </OverlayTrigger></label>
                      <Grid style={{ flexWrap: `nowrap` }} className="max-[600px]:flex-row max-[600px]:flex-nowrap" container>
                        <Grid item xs={9}>
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            name="idVerify"
                            className="border-2 rounded w-full px-3  h-[50px] max-[600px]:h-[40px]"
                            value={inputs.idVerify}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Button
                          variant={`${mtList.mockId ? "outlined" : "contained"}`}
                          style={{ marginLeft: "20px", pointerEvents : (mtList.mockId && "none") }}
                          color={`${mtList.mockId ? "success" : "primary"}`}
                          onClick={!mtList.mockId ? fetchData : null}
                        >
                          {mtList.mockId ? "Verified" : "Verify"}
                        </Button>
                      </Grid>
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_1"
                      onChange={handleMtChange}
                      label="This interviewer often cancels the mock interviews "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_2"
                      onChange={handleMtChange}
                      label="This interviewer often declines the mock interview requests"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_3"
                      onChange={handleMtChange}
                      label="This interviewer has got poor rating and/or review comments "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_3"
                      onChange={handleMtChange}
                      label="This interviewer often joins late and/or leaves early the mock interviews"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_4"
                      onChange={handleMtChange}
                      label="This interviewer is not from a reputed company "
                    />
                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_5"
                      onChange={handleMtChange}
                      label="This interviewer has abused our student(s)"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_6"
                      onChange={handleMtChange}
                      label="This interviewer does not provide a sound feedback"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_7"
                      onChange={handleMtChange}
                      label="This interviewer is unprofessional"
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_8"
                      onChange={handleMtChange}

                      label="This interviewer does not keep the structure of mock interviews as per the real interviews  "
                    />

                    <FormControlLabel
                      className="update"
                      control={<Checkbox />}
                      name="mt_9"
                      onChange={handleMtChange}
                      label="This interviewer focuses more on mentoring and guidance, rather asking the interview-questions  "
                    />
                    <p className="my-3">
                      Other Issue{" "}
                      <input
                        name="mt_10"
                        onChange={handleMtChange}
                        className=" px-2 w-[100%] border-black border-[2px] border-solid rounded-lg overflow-scroll "
                      />
                    </p>
                    <textarea
                      rows={5}
                      cols={5}
                      name="mt_11"
                      onChange={handleMtChange}
                      className="border-solid border-[2px] border-black rounded-lg my-3 p-2"
                      placeholder="Describe the issue (if required)"
                    />
                  </FormGroup>
                  <Button
                    onClick={handleMtIssue}
                    className=""
                    variant="contained"
                    color="success"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {/* {verified && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <h5>Details of the Mock Interview </h5>
                  {cards && (
                    <UpcomingInterview
                      name={interviewer?.fname}
                      desg={interviewer?.desg}
                      price={cards?.price}
                      profile={cards?.profile}
                      slotStart={cards?.fromTime}
                      slotEnd={cards?.toTime}
                      status={cards?.reqStatus}
                      id={interviewer?.id}
                      date={cards?.date}
                      image={interviewer?.photo}
                      mockId={cards?.mockId}
                      logo={getCompanyLogo(interviewer?.currCompany)}
                      req_id={cards?.reqId}
                      payment={cards?.payment}
                      // rescheduled='true'
                      isCancelled={cards?.cancelled?.isCancelled}
                      isUnavailable={
                        cards?.isDisabled === true || cards?.isDelete === true
                      }
                      isDisable={cards?.isDisabled === true}
                      isDelete={cards?.isDelete === true}
                    />
                  )}
                </Grid>
              </Grid>
            )} */}
            {/* {verified && mockDetails?.StudentIssue?.issueReported === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f", fontSize: "19px" }}
                >
                  Sorry! You've already reported an issue for this Mock
                  Interview
                </p>
              </div>
            )} */}
            {/* {verified && status === "upcoming" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "115%",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f", fontSize: "19px" }}
                >
                  Sorry! You can report an issue about this Mock Interview only
                  after its slot is over
                </p>
              </div>
            )} */}
            {/* {verified && status === "invalid" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f", fontSize: "19px" }}
                >
                  Sorry! Time Limit of Reporting an Issue about this Mock
                  Interview has expired! You could report it only upto 6 hours
                  after this mock interview’s time-slot
                </p>
              </div>
            )} */}
            {/* {verified &&
              status === "6 hours" &&
              mockDetails?.StudentIssue?.issueReported !== true && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Please Check the issues you faced
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                      className="update"
                        control={
                          <Checkbox
                            checked={one}
                            onChange={handleChangeCheckBox}
                            name="one"
                          />
                        }
                        label="Faced network issues in between the Mock Interview"
                      />
                      <FormControlLabel
                      className="update"
                        control={
                          <Checkbox
                            checked={two}
                            onChange={handleChangeCheckBox}
                            name="two"
                          />
                        }
                        label="Interviewer joined late by more than 15 minutes for the mock interview but left on time"
                      />
                      <FormControlLabel
                      className="update"
                        control={
                          <Checkbox
                            checked={three}
                            onChange={handleChangeCheckBox}
                            name="three"
                          />
                        }
                        label="Structure of mock interview was not as per the real interviews   "
                      />
                      <FormControlLabel
                      className="update"
                        control={
                          <Checkbox
                            checked={four}
                            onChange={handleChangeCheckBox}
                            name="four"
                          />
                        }
                        label="Interviewer focused more on mentoring and guidance, rather asking the interview-questions"
                      />
                      <FormControlLabel
                      className="update"
                        control={
                          <Checkbox
                            checked={five}
                            onChange={handleChangeCheckBox}
                            name="five"
                          />
                        }
                        label="Other Issues"
                      />
                      <TextField
                        type="text"
                        name="textofArea"
                        label="Describe your issue(s) in detail"
                        multiline
                        rows={4}
                        sx={{ mt: 2 }}
                        value={data.textofArea}
                        onChange={handleChangeofBox}
                        error={error}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )} */}
            {/* {verified &&
              status === "6 hours" &&
              mockDetails?.StudentIssue?.issueReported !== true && (
                <Grid item sx={{ mt: 2, mb: 2 }}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Report the Issue
                  </Button>
                </Grid>
              )} */}
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
export default Report;
