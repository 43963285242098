import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-date-picker";

import Navbarlogedin from "./Navbarlogedin";
import CancelRequest from "./popups/CancelRequest";
import SentRequest from "./cards/SentRequest";
import axios from "axios";
import "./SentRequests.css";
import { BASE } from "../../constants/endpoints";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import {
  calculateCount,
  compareSlots,
  getCompanyLogo,
  t24HDecimalTo12HFormat,
  compare,
} from "../../utility/helper";
import moment from "moment-timezone";
import { Oval } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";

const changeLink = () => {
  const links = document.querySelectorAll(".nav-item a p");
  links.forEach((link) => {
    if (link.classList.contains("active")) {
      link.classList.remove("active");
    }
    if (link.innerHTML === "Sent Requests") {
      link.classList.add("active");
    }
  });
};

// objs.sort( compare );
const SentRequests = (userDetails) => {
  const { user } = useContext(UserContext);
  const [sentRequest, setSentRequest] = useState([]);
  const [SentInterViewers, setSentInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const rendered3 = useRef(false);
  const params = {
    reqStatus: "ReqSent",
    userRole: "Student",
  };
  const params2 = {
    reqStatus: "ReqPaid",
    userRole: "Student",
  };
  const rendered = useRef(false);
  const rendered2 = useRef(false);
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id.trim()}`, {
          params,
        })
        .then((response) => {
          if (rendered.current) return;
          setSentRequest((res) => [...res, ...response.data.Requests]);
          setSentInterViewers((res) => [
            ...res,
            ...response.data.SentInterViewers,
          ]);
          rendered.current = true;
        });
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
          params: params2,
        })
        .then((response) => {
          if (rendered2.current) return;
          for (let i = 0; i < response.data.Requests.length; i++) {
            if (
              response.data.Requests[i].reschedule.isRescheduled === "true" ||
              response.data.Requests[i].reschedule.isRescheduled === true
            ) {
              setSentRequest((res) => [...res, response.data.Requests[i]]);
              setSentInterViewers((res) => [
                ...res,
                response.data.SentInterViewers[i],
              ]);
            }
          }
          rendered2.current = true;
          setLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    fetchData();
  }, []);
  useEffect(() => {
    changeLink();
  }, []);
  const [date, setDate] = React.useState(null);
  const variable = calculateCount(sentRequest);

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
    console.log(sentRequest.slice(pageNo[0], pageNo[1]));
  }, [page, sentRequest]);
  console.log("new", sentRequest);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Requests</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <CancelRequest />
      <div className="sent-request interviews">
        <div className="interviews-container">
          <div className="flex justify-center items-center mb-4" id="head">
            <h2 className="interview-head text-2xl font-medium  heading1">

              Sent Requests (
              {sentRequest.length
                ? page * 10 -
                  9 +
                  " - " +
                  (sentRequest.length > page * 10
                    ? page * 10
                    : sentRequest.length)
                : null}
              ) out of ({sentRequest.length ? variable : 0})
            </h2>
          </div>
          <div className="interviews-cont">
            <div className="flex justify-between mx-8">
              <div>
                Showing the results for{" "}
                {date ? date.toDateString() : "all days"}
              </div>
              {/*
              <div className="flex gap-2">
                <label htmlFor="date">Filter by Date: </label>
                <DatePicker
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  value={date}
                />
              </div>
              */}
            </div>
            {loaded ? (
              <div className="interviews-list">
                {sentRequest?.slice(pageNo[0], pageNo[1]).map(
                  (result, index) => (
                    console.log(sentRequest),
                    moment()
                      .tz("Asia/Kolkata")
                      .diff(
                        moment(
                          result.date +
                            " " +
                            t24HDecimalTo12HFormat(result.toTime),
                          "DD/MM/YYYY hh:mm a"
                        ),
                        "seconds"
                      ) < 0 && (
                      <>
                        <SentRequest
                          user={user}
                          name={
                            SentInterViewers[pageNo[0] + index].fname +
                            " " +
                            SentInterViewers[pageNo[0] + index].lname
                          }
                          desg={SentInterViewers[pageNo[0] + index].designation}
                          price={sentRequest[pageNo[0] + index].price ?? 499}
                          profile={sentRequest[pageNo[0] + index].profile}
                          subprofile={sentRequest[pageNo[0] + index].subprofile}
                          company={
                            SentInterViewers[pageNo[0] + index].currCompany
                          }
                          slotStart={sentRequest[pageNo[0] + index].fromTime}
                          slotEnd={sentRequest[pageNo[0] + index].toTime}
                          status={sentRequest[pageNo[0] + index].reqStatus}
                          id={SentInterViewers[pageNo[0] + index].id}
                          date={sentRequest[pageNo[0] + index].date}
                          image={
                            SentInterViewers[pageNo[0] + index].isDisabled ===
                              true || SentInterViewers.isDelete === true
                              ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                              : SentInterViewers[pageNo[0] + index].photo
                          }
                          logo={getCompanyLogo(
                            SentInterViewers[pageNo[0] + index].currCompany
                          )}
                          isRescheduled={
                            sentRequest[pageNo[0] + index].reschedule
                              ?.isRescheduled
                          }
                          reschedule={sentRequest[pageNo[0] + index].reschedule}
                          mockId={sentRequest[pageNo[0] + index].mockId}
                          reqId={sentRequest[pageNo[0] + index].reqId}
                          isUnavailable={
                            SentInterViewers[pageNo[0] + index].isDisabled ===
                              true || SentInterViewers.isDelete === true
                          }
                          isDisable={
                            SentInterViewers[pageNo[0] + index].isDisabled ===
                            true
                          }
                          isDelete={
                            SentInterViewers[pageNo[0] + index].isDelete ===
                            true
                          }
                        />
                      </>
                    )
                  )
                )}
                <div className="flex flex-row justify-center mt-4 my-2">
                  <Pagination
                    count={Math.ceil(sentRequest.length / 10)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </div>
              </div>
            ) : (
              <div className="fullPageLoader">
                <Oval
                  height={120}
                  width={120}
                  color="#306bc9"
                  wrapperStyle={{ padding: "50%, 50%" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#90caf9"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SentRequests;
