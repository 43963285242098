import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, useParams, Outlet, useLocation, useNavigate } from "react-router-dom";
import Login from "./components/Home/Login";
import HomeRoutes from "./components/Home/HomeRoutes";
import StudentRoutes from "./components/Dashboard/StudentRoutes";
import InterviewerRoutes from "./components/Interviewer/InterviewerRoutes";
import UserContext from "./contexts/UserContext";
import TimePreference from "./components/Interviewer/TimePreference/TimePreference";
import { AUTH_TOKEN_ENDPOINT } from "./constants/endpoints";
import Navbar from "./components/Home/Navbar";
import NavbarStudent from "./components/Dashboard/Navbarlogedin";
import NavbarInterviewer from "./components/Interviewer/NavbarInterviewer";
import Footer from "./components/Home/Footer";
import NotAuthorised from "./components/Home/NotAuthorised";
import HowItWorksInterviewer from "./components/Home/HowitWorksProfessionals";
import ProfessionalsFAQS from "./components/Home/FAQProfessional";
import StudentsFAQS from "./components/Home/FAQstudent";
import AdminsFAQs from "./components/Home/FAQadmin";
import HowItWorksStudent from "./components/Home/HowitWorksStudents";
import HowItWorksAdmin from "./components/Home/HowitWorksAdmins";
import AdminRoutes from "./components/Admin/AdminRoutes";
import Navbarlogedin from "./components/Admin/Navbarlogedin";
import VideoCall from "./components/VideoCall/Video";
import FeedbackInterviewer from "./components/VideoCall/FeedbackPages/FeedbackInterviewer";
import FeedbackStudent from "./components/VideoCall/FeedbackPages/FeedbackStudent";
import StudentEnd from "./components/VideoCall/CallEndPages/StudentEnd";
import InterviewerEnd from "./components/VideoCall/CallEndPages/InterviewerEnd";
import PricingPage from './additionalPages/Pricing'
// import ReferPageNew from './additionalPages/ReferPageNew/ReferPageNew'
import StudentMidEnd from "./components/VideoCall/MidEndPages/StudentMidEnd";
import InterviewerMidEnd from "./components/VideoCall/MidEndPages/InterviewerMidEnd";
import Validator from "./components/VideoCall/Validator/Validator";
import VideoCallDesktop from "./components/VideoCall/VideoDesktop";
import LoginSuccessStory from "./components/Home/Login_successStory";

import { Oval } from "react-loader-spinner";
function App() {
  const [user, setUser] = useState({});
  const [appStyles, setAppStyles] = useState({
    minHeight: "100vh",
    paddingTop: "70px"
  })
  const [currentUrl, setCurrentUrl] = useState(window.location.href)
  const [successStoryPopup, setSuccessStoryPopup] = useState(false)
  // const [loading, setLoading] = useState(false)

  const location = useLocation() 
  const navigate = useNavigate()


  

  const [visibility, setVisibility] = useState(document.visibilityState)

  const handleVisibility = () => {
    console.log(document.visibilityState)
    setVisibility(document.visibilityState)
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibility)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility)
    }
  }, [])

  // // show Feedback/rated page instead of platform after interview
  // useEffect(() => {
  //   if(!user) return;
  //   if(location.pathname.endsWith("upcoming-interviews")) return;
  //   const locationCondition = location.pathname.startsWith('/s/') || 
  //   location.pathname.startsWith('/i/') || location.pathname === '/'
  //   if (!locationCondition) return;
  //   let interruptData = window.localStorage.getItem("vcInterrupt")
  //   // console.log(interruptData)
  //   if (interruptData === '[object Object]' || !interruptData)
  //     return;

  //   let { mockId, mockStatus } = JSON.parse(interruptData)

  //   let lastMock = mockId.pop()

  //   // console.log("rated", rated, "feedback", feedback, "mockEnded", mockEnded)
    
  //   if (!mockStatus[lastMock].mockEnded) return;
    
  //   if (user?.role === "Student" && !mockStatus[lastMock].rated) {
  //     navigate("/end/student/vc/" + lastMock)
  //   } else if (user?.role === "Interviewer" && !mockStatus[lastMock].feedback) {
  //     navigate("/end/interviewer/vc/" + lastMock)
  //   }

  //   return;
  // }, [window.localStorage.getItem("vcInterrupt"), visibility, location.pathname, user])

  

  // const ovalLoader = (
  //   <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
  //     <Oval
  //       height={120}
  //       width={120}
  //       color="#306bc9"
  //       wrapperStyle={{ padding: "50%, 50%" }}
  //       wrapperClass=""
  //       visible={true}
  //       ariaLabel="oval-loading"
  //       secondaryColor="#90caf9"
  //       strokeWidth={2}
  //       strokeWidthSecondary={2}
  //     />
  //   </div>
  // );

  useEffect(() => {
    if (localStorage.getItem("company"))
      setSuccessStoryPopup(true)
  },[localStorage.getItem("company")])

  useEffect(() => { 
    if(location.pathname.includes("vc")) {
      setAppStyles({
        minHeight: "100vh",
        paddingTop: "0px"
      })
    } else {
      setAppStyles({
        minHeight: "100vh",
        paddingTop: "70px"
      })
    }
  }, [location])

  console.log(user);
  useEffect(() => {
    const sToken =
      JSON.parse(localStorage.getItem("isAuthenticated"))?.token || "";
    const iToken =
      JSON.parse(localStorage.getItem("isIAuthenticated"))?.token || "";
    const aToken =
      JSON.parse(localStorage.getItem("isAAuthenticated"))?.token || "";
    axios
      .post(AUTH_TOKEN_ENDPOINT, { sToken, iToken, aToken })
      .then((res) =>{
        setUser({
          ...res.data,
          photo:
            // .REACT_APP_BACKEND_SERVER_URL +
            // "/uploads/" +
            res.data.photo,
        })
        // setLoading(true)
      }
      )
      .catch((err) => {});
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <div
        className="app"
        style={appStyles}
      >
        {currentUrl.split("/")[3] !== "vc" && currentUrl.split("/")[4] !== "vc" ? (
          <>
            {successStoryPopup?<LoginSuccessStory/>:<Login />}
            {user.isDisabled ? <Navbar isDisabled={true} /> : null}
            {!user?.role ? <Navbar /> : null}
            {user?.role === "Student" && !user.isDisabled ? (
              <NavbarStudent />
            ) : null}
            {user?.role === "Interviewer" && !user.isDisabled ? (
              <NavbarInterviewer />
            ) : null}
            {user?.role === "Admin" && !user.isDisabled ? (
              <Navbarlogedin />
            ) : null}
          </>
        ) : (
          <></>
        )}

        <Routes>
          {/* VIDEOCALL PATHS */}
          <Route path="/valid/vc/:mock_id/:id/:name" element={<Validator />} />
          <Route path="/vc/:mock_id/:id/:name" 
          element={window.innerWidth < 768?
            <VideoCall visibility={visibility}/>:
            <VideoCallDesktop 
            // key={location.key} 
            visibility={visibility} />
          } />
          <Route path="/end/student/vc/:mock_id" element={user?.id?.startsWith("S")? <StudentEnd />:<NotAuthorised/>} />
          <Route path="/end/interviewer/vc/:mock_id" element={user?.id?.startsWith("I")? <InterviewerEnd />:<NotAuthorised/>} />
          <Route path="/midend/student/vc/:mock_id" element={user?.id?.startsWith("S")? <StudentMidEnd />:<NotAuthorised/>} />
          <Route path="/midend/interviewer/vc/:mock_id" element={user?.id?.startsWith("I")?<InterviewerMidEnd />:<NotAuthorised/>} />
          {/* VIDEOCALL PATHS END */}
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path={"/testing"} element={<TimePreference />} />
          <Route exact path="/s/faqs" element={<StudentsFAQS />} />
          <Route exact path="/i/faqs" element={<ProfessionalsFAQS />} />
          <Route exact path="/admin/faqs" element={<AdminsFAQs />} />
          <Route exact path="/s/how-it-works" element={<HowItWorksStudent />} />
          <Route exact path="/admin/how-it-works" element={<HowItWorksAdmin />} />
          <Route exact path="/plans" element={user?.role === "Student" ?<PricingPage/>:<NotAuthorised/>} />
          {/* <Route exact path="/refer" element={user?.role === "Student" ?<ReferPageNew/>:<NotAuthorised/>} /> */}
          <Route
            exact
            path="/i/how-it-works"
            element={<HowItWorksInterviewer />}
          />
          <Route
            path="/admin/*"
            element={
              user?.role === "Admin" ? <AdminRoutes /> : <NotAuthorised/>
            }
          />
          <Route
            path="/s/*"
            element={
              user?.role === "Student" ? <StudentRoutes /> : <NotAuthorised />
            }
          />
          <Route
            path="/i/*"
            element={
              user?.role === "Interviewer" ? (
                <InterviewerRoutes />
              ) : (
                <NotAuthorised />
              )
            }
          />

          {/* feedback form and end pages for videocall */}
          {/* feedback form and end pages for videocall */}
          <Route
            path="/feedback/interviewer/vc/:mock_id/:from"
            element={ 
              user?.role === "Interviewer" ? <FeedbackInterviewer /> : <NotAuthorised />
            }
          />
          <Route
            path="/feedback/student/vc/:mock_id"
            element={
              user?.role === "Student" ||  user?.role === "Admin" ? <FeedbackStudent /> : <NotAuthorised />
            }
          />
          {/* feedback form and end pages for videocall */}
          {/* feedback form and end pages for videocall */}

          <Route path="/*" element={<HomeRoutes />} />

        </Routes>
      </div>
      {currentUrl.split("/")[3] !== "vc" && currentUrl.split("/")[4] !== "vc" ? <Footer /> : <></>}
    </UserContext.Provider>
  );
}

export default App;