import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import "./StudentsHIW.css";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const StudentsHIW = () => {
  const cards = [
    {
      imageSrc:
        "https://img.freepik.com/free-vector/man-having-online-job-interview_52683-43379.jpg?w=740&t=st=1657086745~exp=1657087345~hmac=73bc6c8a02d15595dc3e5fbd83d80b868c878e5ced6ea7f1d7752f9562a8598d",
      title: "Find Interviewers and Apply Filters",
      description:
        "Search interviewers from our premium database with a single click; and apply the filters of profile and suitable date; and refine your search accordingly.",
    },

    {
      imageSrc:
        "https://img.freepik.com/free-vector/people-talking-via-electronic-mail_52683-38063.jpg?w=1060&t=st=1657086856~exp=1657087456~hmac=bee2b5a87056b7294dcf6cb9d20424ab33f391293ce893d8d68a054e18ed90c9",
      title: "Send a Mock Interview Request to an Interviewer",
      description:
        "Pick the interviewers matching your preferences and send a Mock Interview Request to each of them with a single click.",
    },

    {
      imageSrc:
        "https://img.freepik.com/free-vector/online-job-interview_52683-43509.jpg?w=1060&t=st=1657086544~exp=1657087144~hmac=6e64075bd5735429756c4ebaf652e7dc4d2116feb80d6254e2c1c6daa6e1079a",
      title: "Pay for the Mock Interview to Confirm it! Go for it!",
      description:
        "Once your Mock Interview Request is confirmed by an interviewer, either pay INR 499 or apply a Coupon Code and pay the discounted price to confirm that Mock Interview. Then, join the mock interview link from the page of [Upcoming Interviews] as per the schedule to appear for the Mock Interview.",
    },
  ];

  return (
    <Container>
      <SingleColumn style={{ paddingTop: "0" }}>
        <HeadingInfoContainer>
          <HeadingTitle style={{fontSize:"2.3rem"}}>For Students</HeadingTitle>
        </HeadingInfoContainer>

        <Content style={{ marginTop: "0" }}>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1} style={{ marginTop: "0" }}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title className={`${i==2?"title-padding-mobile":""}`}>{card.title}</Title>
                <Description className="normalText">{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
export default StudentsHIW;
