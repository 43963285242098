import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Rating,
  TextField,
  Chip,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  t24HDecimalTo12HFormat,
  toastStyle,
  convertDateFormat,
} from "../../../utility/helper";
import axios from "axios";
import { BASE, FEEDBACK } from "../../../constants/endpoints";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";
import { PRICING_CHANGE_DATE } from "constants/variables";
import moment from "moment";

// import ReadMoreReact from "read-more-react";

// const ReadMore = ({ children }) => {
//   const text = children;
//   const [isReadMore, setIsReadMore] = React.useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <p className="text">
//       {isReadMore ? text.slice(0, 150) : text}
//       <span onClick={toggleReadMore} className="read-or-hide">
//         {isReadMore ? "...read more" : " show less"}
//       </span>
//     </p>
//   );
// };
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const CompletedInterview = ({
  user,
  name,
  desg,
  price,
  logo,
  profile,
  slotStart,
  subprofile,
  image,
  date,
  slotEnd,
  status,
  mockId,
  reqId,
  meetUrl,
  company,
  sId,
  isUnavailable,
  id,
  isDisable,
  isDelete,
}) => {
  const [int, setint] = React.useState();
  const [value, setValue] = React.useState(3.5);
  const [hover, setHover] = React.useState(-1);
  const [comment, setComment] = React.useState("");
  const [feedback, setFeedback] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    getRate();
  };
  useEffect(() => {
    axios.get(`${BASE}/send/getMockInterview/${mockId}`).then((res) => {
      setint(res.data);
      console.log(res.data);
      setValue(res.data?.sRating)
      setComment(res.data?.sComment)
    });

    axios
      .get(FEEDBACK + "/" + mockId)
      .then((res) => {
        setFeedback(true);
      })
      .catch((err) => {
        setFeedback(false);
      });
  }, [mockId]);

  const getRate = () => {
    axios.get(`${BASE}/send/getMockInterview/${mockId}`).then((res) => {
      setint(res.data);
    });
  };

  const handleSubmit = async (value) => {
    console.log(value);
    console.log(comment);
    await axios
      .post(`${BASE}/send/addRating/${sId}`, {
        mockId: mockId,
        reqId: reqId,
        meetUrl: meetUrl,
        sRating: value,
        sComment: comment,
      })
      .then((res) => {
        console.log(res);
        handleClose();
        toast.success("Thankyou for the review.", {
          ...toastStyle.success,
          position: "top-center",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      console.log("svg", url);
      var img = new Image();
      img.src = url;
      console.log(img.height);
      return img?.getBBox?.height !== 0;
    } else {
      console.log(".png", url);
      var img2 = new Image();
      img2.src = url;
      console.log(img2?.getBBox?.height);
      return img2?.height !== 0;
    }
  }
  let imgExist = false;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };
  return (
    <>
      <div className="relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card">
        {/* Updated */}
        <div className="absolute h-full w-full unshow" id="updated">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="px-4 py-2 bg-green-500 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Updated
              </Typography>
            </div>
          </div>
        </div>

        {/* Canceled */}
        <div className="absolute h-full w-full unshow" id="canceled">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="absolute text-2xl top-2 right-2 text-red-600 cursor-pointer hover:text-red-800">
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
            <div className="px-4 py-2 bg-red-600 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Canceled
              </Typography>
            </div>
          </div>
        </div>
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                      isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                    "_blank"
                  );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3">
          <div className="flex py-1 justify-between w-full">
            <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-medium leading-4">{name}</h4>
              <p>
                {" "}
                {desg}, {company}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%"
              
            }}>
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "20px",
              width: "50%",

            }}>
            <div>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR {price}</p>
            </div>
            <div>
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{convertDateFormat(date)}</p>
            </div>
            </div>
          </div> */}
          <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null
                }}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
              />
          <div
            className="relative flex justify-center"
            style={{ justifyContent: "center" }}
          >
            <div className="meeting-id">Mock Interview ID: {int?.mockId}</div>
            {/* <button onClick={()=>{
              int?.meetUrl.includes("http")? window.open(int?.meetUrl, "_blank"):window.open(`https://${int?.meetUrl}`, "_blank")
            }} type="button" className="cust-btn join-meeting-btn" style={{backgroundColor:'green', margin:'0'}}>
              Join Mock Interview
            </button> */}
          </div>
          {int?.sComment && (
            <>
              <br />
              <div
                className="ratedConfirm"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                Rated and Reviewed
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    setint(null);
                    setOpen(!open);
                  }}
                />
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {feedback && (
              <Link to={"/feedback/student/vc/" + mockId} target="_blank">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "blue",
                  }}
                  style={{ margin: "10px 10px" }}
                >
                  View Feedback
                </Button>
              </Link>
            )}
            {!int?.sComment && (
              <div
                className="rate-btn flex"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                  }}
                  onClick={handleOpen}
                >
                  Rate and Review
                </Button>

                {/* <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ textAlign: "center" }}
                    >
                      Rate and Review
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Please Rate and Review your Interview Experience.
                    </Typography>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box sx={{ ml: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label="Review"
                        multiline
                        rows={4}
                        sx={{
                          mb: 2,
                        }}
                        onChange={(event, newValue) => {
                          setComment(event.target.value);
                        }}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleSubmit(value);
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Modal> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400" style={{ fontSize: "0.65rem" }}>
            Sent 15 Min ago
          </p>
        </div>
        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"
            
          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                isUnavailable
                  ? toast.loading(
                      `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                        isDelete ? "deleted" : ""
                      } this account`,
                      {
                        ...toastStyle.loading,
                        position: "top-center",
                        duration: 2000,
                      }
                    )
                  : window.open(
                      `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                      "_blank"
                    );
              }}
              className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div className="s-duration-price" style={{ marginBottom: "10px" }}>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Duration :{" "}
                </span>
                <span className="text-lg">{new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > PRICING_CHANGE_DATE? "45 Min": "30 Min"}</span>
              </div>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Price :{" "}
                </span>
                <span className="text-lg">{new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > PRICING_CHANGE_DATE? price: 299}</span>
              </div>
            </div>
            <div style={{justifyContent: 'flex-start'}} className="s-duration-price">
              <p
                className="text-base text-gray-400"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginRight: "5px",
                  transform: "translateY(2px)",
                }}
              >
                Interview Slot :{" "}
              </p>
              <div>
                <span className="text-lg">
                  {" "}
                  {t24HDecimalTo12HFormat(slotStart)}
                </span>
                <span> - </span>
                <span className="text-lg">
                  {t24HDecimalTo12HFormat(slotEnd)}
                </span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p
                    className="text-base text-gray-400"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Profile :{" "}
                  </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})`: profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="relative flex justify-center"
          style={{ justifyContent: "center" }}
        >
          <div
            style={{ fontSize: "13px", textAlign: "center" }}
            className="meeting-id"
          >
            Mock Interview ID: {mockId} <FaCopy style={{display: 'inline', fontSize: '1rem',transform: 'translate(6px,-3px)'}} onClick={handleCopy} />
          </div>

          {/* <button onClick={()=>{
              int?.meetUrl.includes("http")? window.open(int?.meetUrl, "_blank"):window.open(`https://${int?.meetUrl}`, "_blank")
            }} type="button" className="cust-btn join-meeting-btn" style={{backgroundColor:'green', margin:'0'}}>
              Join Mock Interview
            </button> */}
        </div>
        {int?.sComment && (
          <>
            <div
              className="ratedConfirm"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 10px",
                width: "97%",
                margin: "5px",
              }}
            >
              Rated and Reviewed
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ margin: "0 10px" }}
                onClick={() => {
                  setint(null);
                  setOpen(!open);
                }}
              />
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: "5px",
          }}
        >
          {feedback && (
            <Link
              style={{ width: "100%" }}
              to={"/feedback/student/vc/" + mockId}
              target="_blank"
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "blue",
                }}
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 0px 0px 6px grey",
                }}
              >
                View Feedback
              </Button>
            </Link>
          )}
          {!int?.sComment && (
            <div
              className="rate-btn flex"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                }}
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 0px 0px 6px grey",
                }}
                onClick={handleOpen}
              >
                Rate & Review
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(10px)" }}
              >
                <Box sx={style} style={{ width: "95%", maxWidth: "400px" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  >
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center" }}
                  >
                    Rate and Review
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Please Rate and Review your Interview Experience.
                  </Typography>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={value}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    style={{ marginTop: "5px" }}
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Review"
                      multiline
                      rows={4}
                      sx={{
                        mb: 2,
                      }}
                      value={comment}
                      onChange={(event, newValue) => {
                        setComment(event.target.value);
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSubmit(value);
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompletedInterview;
