import { Box, Button, Chip, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { giveYears } from "../../../components/VideoCall/utils";
import StudCardDetails from "components/misc/StudCardDetails";
import {
  t24HDecimalTo12HFormat,
  convertDateFormat,
  toastStyle,
  programValues,
  downloadPDF,
} from "../../../utility/helper";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import toast from "react-hot-toast";
import { FaCopy } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const Cancelled = ({
  user,
  name,
  desg,
  company,
  price,
  logo,
  profile,
  subprofile,
  slotStart,
  image,
  date,
  slotEnd,
  status,
  id,
  payment,
  mockId,
  req_id,
  rescheduled,
  isRescheduled,
  isUnavailable,
  isCancelled,
  isDisable,
  isDelete,
  sname,
  sbranch,
  sprogram,
  syear,
  sresume,
  appearingFor,
  suggestedTime
}) => {
  console.log("isCancelled", isCancelled);
  console.log('->>>>>>>>>>>>>>>>>>>>>>', syear)

  function ImageExist(url) {
    console.log(url);
    if (url?.includes(".svg")) {
      // console.log('svg',url);
      var img = new Image();
      img.src = url;
      return img?.getBBox?.height !== 0;
    } else {
      // console.log('.png',url);
      var img2 = new Image();
      img2.src = url;
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  if (payment === false) {
    return;
  }
  //   const timeDuration=(slot)=>{
  //     let text = slot
  // const myArray = text.split(" ");
  // const arr1=myArray[0].split(":")
  // const arr2=myArray[3].split(":")
  // const h1=parseInt(arr1[0]);
  // const h2=parseInt(arr2[0]);
  // const m1=parseInt(arr1[1]);
  // const m2=parseInt(arr2[1]);
  // return (60*(h2-h1)+(m2-m1))
  //   }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };
  
  return (
    <>
      {(isRescheduled === "true" || isRescheduled === true) && (
        <div
          className={"box-shadow-request-card px-3 py-2"}
          style={{
            position: "absolute",
            right: "30px",
            zIndex: "2",
            backgroundColor: "#f5ed4e",
            borderRadius: "10px",
          }}
        >
          <p>Rescheduled</p>
        </div>
      )}
      <div className="relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card">
        {/* Updated */}
        <div className="absolute h-full w-full unshow" id="updated">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="px-4 py-2 bg-green-500 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Updated
              </Typography>
            </div>
          </div>
        </div>

        {/* Canceled */}
        <div className="absolute h-full w-full unshow" id="canceled">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="absolute text-2xl top-2 right-2 text-red-600 cursor-pointer hover:text-red-800">
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
            <div className="px-4 py-2 bg-red-600 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Canceled
              </Typography>
            </div>
          </div>
        </div>
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                  `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                  } this account`,
                  {
                    ...toastStyle.loading,
                    position: "top-center",
                    duration: 2000,
                  }
                )
                : window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/interviewer/${id}`,
                  "_blank"
                );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Interviewer's Profile
          </button>
        </div>
        <div
          className="px-3 col-span-3"
          style={{ textAlign: "center", alignItems: "center" }}
        >
          <div className="flex py-1 justify-between w-full">
            <div className="h-14 w-14 p-1 rounded-md" id="int-logo" onClick={()=>{console.log(logo)}}>
            <img src="/images/interwiu_logo.png" alt="logo" className="h-full w-full" />
              {/* {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )} */}
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-medium leading-4">{name}</h4>
              <p className="pt-1">
                {desg}, {company}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%",
              
              
            }}>
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "20px",
              minWidth: "50%"
            }}>
            <div>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR 499</p>
            </div>
            <div>
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div style={{minWidth: "185px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{convertDateFormat(date)}</p>
            </div>
            </div>
          </div> */}
           <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null
                }}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
              />
          <div style={{ fontWeight: "bold", textDecoration: "underline" }}>Details of Student</div>
          <p>
            {sname} ({programValues[sprogram]}, {syear}, {sbranch}){"  "}  <br /> <span>{(appearingFor?.one || appearingFor?.two) && ("Appearing for : " + (appearingFor?.one ? "Campus Placements" + (appearingFor?.two ? " and Internship Interviews" : "") : (appearingFor?.two ? "Internship Interviews" : "")))}</span>
            <p className="mt-[5px]">
              <Button variant="contained" onClick={() => downloadPDF(sresume)}>
                <i className="fa-solid fa-link mr-1"></i> Resume
              </Button>
            </p>

          </p>
          <div className="meeting-id">Mock Interview ID: {mockId}</div>
        </div>
      </div>

      {/* mobile card */}

      <div className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow" style={{ marginLeft: "auto", marginRight: "auto" }}
      >

        <div className="absolute h-full w-full unshow" id="updated">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="px-4 py-2 bg-green-500 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Updated
              </Typography>
            </div>
          </div>
        </div>


        {/* Canceled */}
        <div className="absolute h-full w-full unshow" id="canceled">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="absolute text-2xl top-2 right-2 text-red-600 cursor-pointer hover:text-red-800">
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
            <div className="px-4 py-2 bg-red-600 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Canceled
              </Typography>
            </div>
          </div>
        </div>

        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"
            
          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              onClick={() => {
                isUnavailable
                  ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                  : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/interviewer/${id}`,
                    "_blank"
                  );
              }}
              className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Interviewer's Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div>
            <div className="s-duration-price" style={{ marginBottom: '10px', maxWidth: '90%', margin: 'auto' }}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">45 Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">INR 499</span>
              </div>
            </div>

            <div style={{ maxWidth: '90%', margin: 'auto', justifyContent: 'flex-start' }} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px', transform: 'translateY(2px)' }}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat(slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div style={{ maxWidth: '90%', margin: 'auto' }} className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})` : profile}
                    color="info"
                    className="s-mobile-changing-chip-property"
                    sx={{ fontWeight: "medium" }}
                  />
                </div>
              </div>
            </div>
            <hr className="m-4 border-gray-300" />
            <div className="s-duration-price mt-2" style={{ fontWeight: "bold", textDecoration: "underline", maxWidth: '90%', margin: '0rem auto 0.5rem auto', justifyContent: 'center' }}>Details of Student</div>
            <p style={{ maxWidth: '90%', margin: 'auto' }}>
              {sname} ({programValues[sprogram]}, {syear}, {sbranch}){"  "}  <br /> <span style={{ color: 'maroon', fontSize: '14px' }}>{(appearingFor?.one || appearingFor?.two) && ("Appearing for : " + (appearingFor?.one ? "Campus Placements" + (appearingFor?.two ? " and Internship Interviews" : "") : (appearingFor?.two ? "Internship Interviews" : "")))}</span>
              <p className="mt-[5px]">
                <Button className="w-[50%]" variant="contained" onClick={() => downloadPDF(sresume)}>
                  <i className="fa-solid fa-link mr-1"></i> Resume
                </Button>
              </p>

            </p>
            {/* <div className="meeting-id">Mock Interview ID: {mockId}</div> */}
          </div>
        </div>
        <div style={{ fontSize: '13px', textAlign: 'center' }} className="meeting-id">Mock Interview ID: {mockId} <FaCopy style={{display: 'inline', fontSize: '1rem',transform: 'translate(6px,-3px)'}} onClick={handleCopy} /></div>

        <div
          className="relative flex justify-center"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: '100%'
          }}
        >
          {isCancelled === "true" && (
            <div
              className={"box-shadow-request-card px-3 py-2"}
              style={{
                backgroundColor: "red",
                color: "white",
                fontWeight: "500",
                width: '100%'
              }}
            >
              <p>Cancelled</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cancelled;