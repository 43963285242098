import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Typography from '@mui/material/Typography';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabOne from './StudentsHIW'
import TabTwo from './ProfessionalHIW'
import TabThree from './AdminsHIW'

const Working=()=> {
  const user = JSON.parse(localStorage.getItem("isAuthenticated")) || JSON.parse(localStorage.getItem("isIAuthenticated"));
  const [value, setValue] = React.useState(user?.role === "Interviewer" ? '2' : '1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ typography: 'body1', display:"flex", flexDirection: 'column' ,justifyContent: 'center',alignItems: 'center', backgroundColor:"white", paddingTop: "100px"}}>
    <Typography variant="h3" sx={{
        fontWeight:"bold",
        mt:4
    }}
    style={{fontFamily:"'Ubuntu', sans-serif"}}
    
    >How it works</Typography>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab className='headding1' label="For Students" value="1" />
            <Tab className='headding1' label="For Professionals" value="2" />
            <Tab className='headding1' label="For Institute Admins" value="3" />
          </TabList>
        </Box>
        <Box sx={{width:"100%"}}>
        <TabPanel value="1"><TabOne/></TabPanel>
        <TabPanel value="2"><TabTwo/></TabPanel>
        <TabPanel value="3"><TabThree/></TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
export default Working