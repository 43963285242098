import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BASE } from "../../../constants/endpoints";
import UserContext from "../../../contexts/UserContext";
import { getCompanyLogo } from "../../../utility/helper";

import CanceledInterview from "../cards/CanceledInterview";
import Navbarlogedin from "../Navbarlogedin";

const CanceledInterviews = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [canceledRequests, setcanceledRequests] = useState([]);
  const [SentInterViewers, setSentInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const rendered = useRef(false);
  const rendered2 = useRef(false);
  const params = {
    reqStatus: "ReqCancelled",
    userRole: "Student",
  };
  const params2 = {
    reqStatus: "ReqDeclined",
    userRole: "Student",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          if (rendered.current) return;
          console.log(response);
          setcanceledRequests((res) => [...res, ...response.data.Requests]);
          setSentInterViewers((res) => [
            ...res,
            ...response.data.SentInterViewers,
          ]);
          rendered.current = true;
          console.log(response.data.Requests);
          setLoaded(true);
        });
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
          params: params2,
        })
        .then((response) => {
          console.log(response);
          if (rendered2.current) return;
          for (let i = 0; i < response.data.Requests.length; i++) {
            console.log(response.data.Requests[i]);
            if (
              response.data.Requests[i].cancelled.isCancelled === "true" ||
              response.data.Requests[i].cancelled.isCancelled === true ||
              response.data.Requests[i].status === "ReqCancelled"
            ) {
              setcanceledRequests((res) => [...res, response.data.Requests[i]]);
              setSentInterViewers((res) => [
                ...res,
                response.data.SentInterViewers[i],
              ]);
            }
          }
          rendered2.current = true;
        });
    }
    fetchData();
  }, []);
  return (
    <>
      <Navbarlogedin />
      <div className="interviews" id="declined-request">
        <div className="interviews-container">
          <div className="flex justify-between items-center" id="head">
            <div
              className="text-blue-500 cursor-pointer font-medium hover:text-red-600"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="fa-solid fa-angle-left"></i> Back
            </div>
            <h2 className="interview-head text-2xl font-medium">
              Canceled Mock Interviews ({canceledRequests.length})
            </h2>
            <div> </div>
          </div>
          <div className="interviews-cont">
            {loaded ? (
              <div className="interviews-cont">
                <div
                  className="interviews-list"
                  style={{ margin: "0 auto" }}
                >
                  {canceledRequests?.map((result, index) => 
                  {

                    return (
                    <>
                      <CanceledInterview
                        user={user}
                        name={
                          SentInterViewers[index].fname +
                          " " +
                          SentInterViewers[index].lname
                        }
                        desg={SentInterViewers[index].designation}
                        price={canceledRequests[index].price}
                        profile={result.profile}
                        subprofile={result.subprofile}
                        slotStart={canceledRequests[index].fromTime}
                        slotEnd={canceledRequests[index].toTime}
                        status={canceledRequests[index].reqStatus}
                        company={SentInterViewers[index].currCompany}
                        id={SentInterViewers[index].id}
                        date={canceledRequests[index].date}
                        image={SentInterViewers[index].photo}
                        logo={getCompanyLogo(
                          SentInterViewers[index].currCompany
                        )}
                        suggestedSlotStart={
                          canceledRequests[index].suggestedTime?.fromTime
                        }
                        suggestedSlotEnd={
                          canceledRequests[index].suggestedTime?.toTime
                        }
                        suggestedDate={canceledRequests[index].suggestedDate}
                        reqId={canceledRequests[index].reqId}
                        mockId={canceledRequests[index].mockId}
                        cancelledBy={
                          canceledRequests[index].cancelled?.cancelledBy
                        }
                        cancelledDate={
                          canceledRequests[index].cancelled?.cancelledDate
                        }
                        cancelledTime={
                          canceledRequests[index].cancelled?.cancelledTime
                        }
                        isRescheduled={canceledRequests[index].reschedule.isRescheduled}
                      />
                    </>
                  )
                      }
                      )}
                </div>
              </div>
            ) : (
              <div className="fullPageLoader">
                <Oval
                  height={120}
                  width={120}
                  color="#306bc9"
                  wrapperStyle={{ padding: "50%, 50%" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#90caf9"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CanceledInterviews;
