import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import "./SearchInterviews.css";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Share from "./popups/Share";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Interviewer from "./cards/Interview";
import { BASE } from "../../constants/endpoints";
import { COMPANY_NAME } from "../../constants/variables";
import { AnimatePresence, motion } from "framer-motion";
import {
  INTERVIEWERS_GET_ENDPOINT,
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../constants/endpoints";
import moment from "moment"; // Getting day from date and modifying date format
import TimeRange from "react-time-range";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "material-ui-pickers";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import { Calendar } from "react-multi-date-picker";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Oval } from "react-loader-spinner";
import Card from "./cards/Card";
import UserContext from "../../contexts/UserContext";
import {
  getCompanyLogo,
  Specialization,
  t12HTo24HDecimalFormat,
} from "../../utility/helper";
import { PLAN_ROUTE, REFER_ROUTE } from "../../constants/routes";
import { useNavigate } from "react-router";
import { special } from "../../constants/branchSpecilization";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TextFields } from "@mui/icons-material";

import { MdDeleteForever } from "react-icons/md";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";

moment.locale();


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/*  
    To convert 12 Hour Time format to 24 Hours Decimal format(for ease of processing in backend)
    E.g. 12:15 AM -> 0.25, 01:45 PM -> 13.75
*/

// Style for Pagination
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

class LocalizedUtils extends MomentUtils {
  getYearRange(start, end) {
    const startDate = this.moment(end).startOf("year");
    const endDate = this.moment(start).endOf("year");
    const years = [];

    let current = startDate;
    while (current.isAfter(endDate)) {
      years.push(current);
      current = current.clone().subtract(1, "year");
    }

    return years;
  }
}


const SearchInterviews = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [interviewers, setInterviewers] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const rendered = useRef(false);

  const [branch, setBranch] = useState();
  const [specialization, setSpecialization] = useState();
  // setInterviewers(newInt)
  const [isHidden, setIsHidden] = useState(true);
  const [shareurl, setShareurl] = useState("www.youtube.co.in");
  const [filters, setFilters] = useState({
    search: "",
    companies: [],
  });

  const [profilePref, setProfilePref] = useState({
    core: false,
    nonCore: false,
  });

  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // console.log('window.scrollY',window.scrollY)
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setRadioBtn(false);
      } else {
        // if scroll up hide the navbar
        // Set(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);


  // const [search, setSearch] = useState()

  const setSearch = (search) =>
    setFilters((filters) => ({ ...filters, search }));

  const [radioBtn, setRadioBtn] = useState(false)
  const [nonCoreProfile, setNonCoreProfile] = useState(null)

  const addProfilePref = (e) => {
    const profile = e.target.value;
    setNonCoreProfile(profile)
    console.log('profile', profile, 'profile-end');
    // setFilters({ ...filters, profiles: [profile] });
    console.log('filters', filters, 'filters-end')

    // setFilters((filters) => {
    // if (filters.profiles?.indexOf(profile) === -1)
    // return { ...filters, profiles: [profile] };
    // else return filters;
    // });
    // console.log('filters-2', filters, 'filters-2-end')

  };

  const deleteProfilePref = (profile) => {
    setFilters((filters) => {
      if (filters.profiles?.indexOf(profile) !== -1)
        return {
          ...filters,
          profiles: filters.profiles.filter((prof) => prof != profile),
        };
      else return filters;
    });
  };

  const addCompanyPref = (e) => {
    const company = e.target.value;
    console.log(company);
    setFilters((filters) => {
      if (filters.companies?.indexOf(company) === -1)
        return { ...filters, companies: [company] };
      else return filters;
    });
  };

  const deleteCompanyPref = (company) => {
    setFilters((filters) => {
      if (filters.companies?.indexOf(company) !== -1)
        return {
          ...filters,
          companies: filters.companies.filter((comp) => comp !== company),
        };
      else return filters;
    });
  };
  // Stores the data of date, from time and to time from filter area
  const [selectedDate, setSelectedDate] = useState(""); // Date
  // useEffect(() => {
  //   console.log("date: ", moment(selectedDate).format("DD/MM/YYYY"));
  // }, [selectedDate]);
  const [from, setFrom] = useState({ hrs: "12", min: "00", merd: "AM" }); // From time
  const [to, setTo] = useState({ hrs: "11", min: "59", merd: "PM" }); // To time
  const [timeFilterCheck, setTimeFilterCheck] = useState(false); // When to display date and time preference above calender in filtered area
  useEffect(() => {
    selectedDate !== "" ||
      from.hrs !== "12" ||
      from.min !== "00" ||
      from.merd !== "AM" ||
      to.hrs !== "11" ||
      to.min !== "59" ||
      to.merd !== "PM"
      ? setTimeFilterCheck(true)
      : setTimeFilterCheck(false);
  }, [selectedDate, from, to]);
  const mnc1 = [
    "Google",
    "Microsoft",
    "Amazon",
    "Boston Consulting Group",
    "Bain & Company",
    "Goldman Sachs",
    "JPMorgan Chase & Co (JPMC)",
    "Oracle",
    "McKinsey",
    "Morgan Stanley",
    "American Express",
    "Ernst & Young (EY)",
    "Deloitte",
    "Nomura",
    "Barclays",
    "HSBC",
    "Directi",
    "Sprinklr",
    "Uber",
    "Adobe",
    "IBM",
    "Mastercard",
    "Deutsche Bank",
    "Credit Suisse",
    "Standard Chartered",
    "Publicis Sapient",
    "Citibank",
    "Auctus Advisors",
    "Samsung",
    "Flipkart",
    "Walmart",
    "Cisco",
    "Analog Devices",
    "Qualcomm",
    "IQVIA",
    "FinMechanics",
    "Siemens",
    "OpenAI",
    "Ola",
    "Hindustan Unilever",
    "Procter & Gamble",
    "Amul",
    "Wells Fargo",
    "Groww",
    "Swiggy",
    "Dunzo",
    "Myntra",
    "Meesho",
    "PayPal",
    "Kotak Mahindra Bank",
    "Kotak Investment Advisors",
    "ZS Associates",
    "Novartis Healthcare",
    "Accenture",
    "Fujitsu",
    "Jio",
    "Udaan",
    "Unacademy",
    "Nykaa"
  ]

  const mnc2 = [
    "Micron",
    "Enphase Energy",
    "Jaguar Land Rover",
    "Tata Motors",
    "Bajaj Auto",
    "Maruti Suzuki",
    "Texas Instruments",
    "Caterpillar",
    "John Deere",
    "MRF",
    "Qualcomm",
    "Dr. Reddy's Laboratories",
    "Ford"
  ]

  const mnc3 = [
    "Fractal",
    "BNY Mellon",
    "Tata Consultancy Services (TCS)",
    "Capgemini",
    "Fidelity",
    "Protiviti",
    "Concentrix",
    "Boeing",
    "Mphasis",
    "NASDAQ",
    "Tiger Analytics",
    "Houlihan Lokey",
    "Tiktok",
    "Linecraft AI",
    "TE Connectivity",
    "Signzy",
    "Sigmoid",
    "Intract",
    "Pilgrim",
    "Mindtree",
    "Vedanta",
    "Spyne.ai",
    "RxLogix",
    "SafexPay",
    "Kempen Capital Management",
    "Kalozal Consultants"
  ]

  // Filtering Interviewer on basis of filter option
  // useEffect(() => {
  //   try {
  //     axios
  //       .get(INTERVIEWERS_GET_ENDPOINT)
  //       .then((res) => {
  //         setInterviewers(res.data);
  //         console.log(res.data, "-----------");
  //         // setNewInt(res.data);
  //         // setIsLoaded(true);
  //       })
  //       .catch((err) => console.log(err));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  
  useEffect(() => {
    let isMounted = true;
    let searchStr = "";
    searchStr += "?bothNamesRegx=" + filters.search;
    if (filters.companies?.length !== 0)
      searchStr += "&companyRegex=" + String(filters.companies);
    if (selectedDate !== "")
      searchStr += "&date=" + moment(selectedDate).format("DD/MM/YYYY");

    if (
      from.hrs !== "12" ||
      from.min !== "00" ||
      from.merd !== "AM" ||
      to.hrs !== "11" ||
      to.min !== "59" ||
      to.merd !== "PM"
    )
      searchStr +=
        "&time=" +
        t12HTo24HDecimalFormat(from.hrs + ":" + from.min + " " + from.merd) +
        "," +
        t12HTo24HDecimalFormat(to.hrs + ":" + to.min + " " + to.merd);

    if (profilePref.core) {
      searchStr += "&isCore=" + 1
      if (branch)
        searchStr += "&branch=" + String(branch)
      if (specialization)
        searchStr += "&spec=" + encodeURIComponent(specialization)
    }
    else if (profilePref.nonCore) {
      searchStr += "&isCore=" + 0
      if (nonCoreProfile)
        searchStr += "&profile=" + String(nonCoreProfile)
    }

    // if (pageNo)
    //   searchStr += "&pageNo=" + pageNo
    try {
      setInterviewers([])
      setIsLoaded(false);
      // Code need to be implemented for photo check then setIsLoading => true
      axios
        .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
        .then((res) => {
          if (isMounted) {
            // console.log(res)
            let ints = res.data
            setInterviewers((prev) => {
              shuffleArray(ints)
              return ints
            });
            console.log(res.data)
          }
          // setNonCoreProfile(null)
          // if (branch) {

          //   if (specialization) {
          //     let r = [];
          //     for (let i = 0; i < res.data.length; i++) {
          //       let s = res.data[i].specializations;
          //       for (let j = 0; j < s?.length; j++) {
          //         if (s[j].profile === branch) {
          //           if (
          //              s[j].subDomains?.includes(specialization) 
          //           ) {
          //             r.push(res.data[i]);
          //           } else {
          //             return 
          //           }
          //         }
          //       }
          //     }
          //     setInterviewers(r);
          //   } else {
          //     let r = res.data.filter((r) => r.coreprofiles?.includes(branch));
          //     setInterviewers(r);
          //   }
          // } else {
          //   if (profilePref.core) {
          //     let r = res.data.filter((r) => r.coreprofiles?.length > 0);
          //     setInterviewers(r);
          //   }
          //   else if (profilePref.nonCore) {
          //     let r = res.data.filter((r) => r.profiles?.length > 0);
          //     setInterviewers(r);
          //   }
          // }
          setIsLoaded(true);
        })
        .catch((err) => console.log(err));
      setPage(1);
    } catch (err) {
      console.log(err);
    }
    return () => {
      isMounted = false
    }

  }, [filters, selectedDate, specialization, branch, nonCoreProfile, from, to, profilePref]);

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, interviewers]);

  // Filtering Search
  const getSearchStr = (obj) => {
    let srchStr = "";
    Object.values(obj).forEach((val) => (srchStr += val + " "));
    return srchStr;
  };


  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 100
  }
  const [datePickerMobile, setDatePickerMobile] = useState(false)

  const handleFilter = (interviewer) =>
    (!filters.search ||
      getSearchStr(interviewer)
        .toLowerCase()
        ?.includes(filters.search.toLowerCase())) &&
    (!filters.profiles?.length ||
      interviewer.profiles.some((profile) =>
        filters.profiles?.includes(profile)
      )) &&
    (!filters.companies?.length ||
      interviewer.companies.some((company) =>
        filters.companies?.includes(company)
      )) &&
    interviewer.profiles?.length !== 0 &&
    interviewer.designation !== "";

  // Filter Time Preference
  const [timePrefWarning, setTimePrefWarning] = useState(false);
  
  // already checked for designation in api
  // let length = 0;
  // for (let i = 0; i < interviewers.length; i++) {
  //   if (interviewers[i].designation) {
  //     length = length + 1;
  //   }
  // }

  const handleDateChange1 = (date) => {
    // setState({ date });
    console.log(date)
  };

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Home</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Share url={shareurl} />
      <div className="searching">
        <h2 className="heading1 ">
          Find an Interviewer of your choice for a Mock Interview with Live
          Feedback
        </h2>

        <h2 className="heading1 s-mobile-card" style={{ justifyContent: 'space-around', border: 'none', backgroundColor: 'transparent', boxShadow: 'none', padding: '0px', gap: '10px', marginTop: '10px' }}>
          <button class="button-85" onClick={() => { navigate(PLAN_ROUTE); }} role="button">Flat Discounts</button>
          {/* <button class="button-85" onClick={() => { navigate(REFER_ROUTE); }} role="button">Referral Discount</button> */}
        </h2>


        <div className="searching-cont">
          <div className="search-pref box-shadow-request-card">
            <div className="search-space">
              {/* Search Interviewer by Name */}
              <div
                className="w-auto text-lg px-2 py-1 border-2 rounded bg-white grid grid-cols-5"
                id="searchbar-input"
              >
                <input
                  type="text"
                  className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
                  value={filters.search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  name="search"
                  id="search"
                  placeholder="Search Interviewer by Name"
                  size='small'
                  style={{ padding: "0px" }}
                />
                <div
                  className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
                  id="search-bar"
                >
                  {filters.search ? (
                    <IconButton
                      sx={{ p: 0, minWidth: 0 }}
                      onClick={() => setSearch("")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <div className="h-6 w-6"></div>
                  )}
                  <IconButton sx={{ p: 0, minWidth: 0 }}>
                    <SearchIcon />
                  </IconButton>
                </div>
              </div>

              {/* Search Interviewer by their companies */}
              <div className="my-3">
              <p className="text-left line text-lg text-green-800 font-medium">
                Filter by Company of the Interviewer
              </p>
              <div className="">
                {/* <div className="relative flex justify-between text-sm rounded-full border border-black bg-teal-100 p-2 mb-2 mx-auto text-black font-bold">
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {filters.companies.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => {
                          deleteCompanyPref(value);
                        }}
                      />
                    ))}
                  </Box>
                </div> */}
                {filters.companies[0] && <div
                  className="relative flex justify-between text-sm rounded-full border border-black bg-teal-100 p-2 mb-2 mx-auto text-black font-bold"
                // style={{ width: "220px" }}
                >
                  <p className="mx-auto">
                    {filters.companies[0]}
                  </p>
                  {filters.companies[0] && (
                    <div
                      className="mx-1 cursor-pointer text-black hover:text-blue-600"
                      onClick={() => {
                        deleteCompanyPref(filters.companies[0]);
                      }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </div>
                  )}
                </div>}
                <Select
                  displayEmpty
                  required
                  value={filters.companies}
                  sx={{ width: 1 }}
                  renderValue={() => "Top Companies (Non-core)"}
                  onChange={addCompanyPref}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {mnc1.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, filters.profiles, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  displayEmpty
                  required
                  value={filters.companies}
                  sx={{ width: 1, marginTop : "10px" }}
                  renderValue={() => "Top Companies (Core)"}
                  onChange={addCompanyPref}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {mnc2.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, filters.profiles, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  displayEmpty
                  required
                  value={filters.companies}
                  sx={{ width: 1, marginTop : "10px" }}
                  renderValue={() => "Other Companies"}
                  onChange={addCompanyPref}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {mnc3.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, filters.profiles, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

              <h4 className="text-center mt-3 text-sky-600 text-xl s-desktop-card">
                Filters <i className="fa-solid fa-filter"></i>
              </h4>

              {/* Profile Preference */}
              <p className="text-left line text-lg text-green-800 font-medium s-desktop-card">
                Filter by Profile
              </p>
              <div className="flex-mobile-filter s-mobile-card">
                <button onClick={() => setRadioBtn(!radioBtn)} style={{ flex: 1, padding: '0px' }} class="button-13" role="button">Filter by Profile</button>
                {/* <div className="s-mobile-card" style={{ position: 'relative', flex: 1 }}>
                  <button style={{ width: '100%', padding: '0px', whiteSpace: 'nowrap' }} class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button"><AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'inherit' }} /> {selectedDate !== ""
                    ? moment(selectedDate).format("DD MMM YYYY") + " "
                    : "Select Date"} {
                      selectedDate !== "" && <button style={{ width: 'auto', float: 'right' }} class="button-13" onClick={(e) => {e.stopPropagation(); setSelectedDate("")}} ><MdDeleteForever style={{ display: 'inline-block', fontSize: '1rem' }} /></button>
                    }</button>

                {
                  datePickerMobile && <Calendar
                    className="s-mobile-calendar-calendar"
                    minDate={new Date().setDate(new Date().getDate())}
                    zIndex={1}
                    value={selectedDate}
                    onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                    mapDays={({ date, selectedDate, isSameDate }) => {
                      let props = {};
                      if (isSameDate(date, selectedDate)) {
                        props.style = {
                          color: "#fff",
                          backgroundColor: "#00a86b",
                          border: "1px solid #000"
                        };
                        return props;
                      }
                    }}
                  />
                }
                </div> */}

                <div className=" tt ml-auto s-mobile-card" style={{ position: 'relative', flex: 1 }}>
                  <button style={{ width: '100%', padding: '0px', whiteSpace: 'nowrap' }} class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button"><AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'inherit' }} /> {selectedDate !== ""
                    ? moment(selectedDate).format("DD MMM YYYY") + " "
                    : "Select Date"} {
                      selectedDate !== "" && <button style={{ width: 'auto', float: 'right', padding:'0px 7px' }} class="button-13"  onClick={(e) => { e.stopPropagation(); setSelectedDate("") }} ><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                    }</button>


                  <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-146px', textAlign: 'left' }} className="calendar-mobile-tooltip tttext">
                    <Calendar
                      className="s-mobile-calendar-calendar"
                      minDate={new Date().setDate(new Date().getDate())}
                      zIndex={1}
                      value={selectedDate}
                      onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                      mapDays={({ date, selectedDate, isSameDate }) => {
                        let props = {};
                        if (isSameDate(date, selectedDate)) {
                          props.style = {
                            color: "#fff",
                            backgroundColor: "#00a86b",
                            border: "1px solid #000"
                          };
                          return props;
                        }
                      }}
                    />
                  </span>
                </div>
              </div>


              {
                !radioBtn && (profilePref.nonCore || profilePref.core) && (
                  <div className="flex-mobile-filter s-mobile-card" style={{ fontSize: '13px', marginLeft: '10px', alignItems: 'center' }}>
                    {/* {
                      profilePref.core && branch && (<div>
                        {branch}
                      </div>)
                    }
                    {
                      profilePref.core && specialization && (<div>&gt; {specialization}
                      </div>)
                    } */}
                    {
                      (profilePref.core) && <div>
                        {
                          !branch && !specialization && 'Core'
                        }
                        {
                          branch && !specialization && `${branch}`
                        }
                        {
                          specialization && `${specialization} `
                        }
                        {
                          branch && specialization && <span style={{ fontWeight: '500' }}>{`(${branch})`}</span>
                        }
                      </div>
                    }
                    {
                      (profilePref.nonCore) && <div>
                        {
                          nonCoreProfile ? nonCoreProfile : 'Non-Core'
                        }
                      </div>
                    }
                    <button style={{ padding: '0px 7px', width: 'auto' }} class="button-13" onClick={() => { setSpecialization(null); setBranch(null); setProfilePref({ core: false, nonCore: false }); setNonCoreProfile(null) }} ><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                  </div>)
              }

              {
                radioBtn && <div className="s-mobile-card" style={{ flexDirection: 'column' }}><FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={profilePref.core ? 'core' : profilePref.nonCore ? 'nonCore' : ''}
                    name="radio-buttons-group"
                    className="s-mobile-search-3filter"
                    onChange={(e) => {
                      if (e.target.value === "core") {
                        setProfilePref({ core: true, nonCore: false });
                        setNonCoreProfile(null)
                      } else if (e.target.value === "nonCore") {
                        setBranch(null);
                        setSpecialization(null);
                        setProfilePref({ core: false, nonCore: true });
                      } else {
                        setBranch(null);
                        setSpecialization(null);
                        setNonCoreProfile(null)
                        setProfilePref({ core: false, nonCore: false });
                      }
                    }}
                  >
                    <FormControlLabel
                      value="core"
                      control={<Radio />}
                      label="Core"
                    />
                    <FormControlLabel
                      value="nonCore"
                      control={<Radio />}
                      label="Non Core"
                    />
                    <FormControlLabel value="" control={<Radio />} label="None" />
                  </RadioGroup>
                </FormControl>
                  {profilePref.nonCore && (
                    <div className="mb-3 relative">
                      <div className="flex w-full justify-end cursor-pointer s-desktop-card">
                        <p
                          className="underline text-blue-500 "
                          onClick={(e) => {
                            setNonCoreProfile(null);
                          }}
                        >
                          clear
                        </p>
                      </div>
                      <Select
                        // multiple
                        displayEmpty
                        required
                        value={nonCoreProfile || "Select Profile"}
                        sx={{ width: 1 }}
                        onChange={addProfilePref}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        
                      >
                        <MenuItem value="Select Profile" disabled>
                          Select Profile
                        </MenuItem>
                        <MenuItem value="Data Science">Data Science</MenuItem>
                        <MenuItem value="Software">Software</MenuItem>
                        <MenuItem value="Banking and Finance">
                          Banking and Finance
                        </MenuItem>
                        <MenuItem value="Consulting">Consulting</MenuItem>
                        <MenuItem value="Analytics">Analytics</MenuItem>
                        <MenuItem value="Product Management">
                          Product Management
                        </MenuItem>
                        <MenuItem value="Operations">Operations</MenuItem>
                        <MenuItem value="Supply Chain">Supply Chain</MenuItem>
                        <MenuItem value="FMCG">FMCG</MenuItem>
                        <MenuItem value="Operations Research">
                          Operations Research
                        </MenuItem>
                        <MenuItem value="Sales">Sales</MenuItem>
                        <MenuItem value="Inventory Management">
                          Inventory Management
                        </MenuItem>
                        <MenuItem value="Logistics">Logistics</MenuItem>
                      </Select>
                      {
                        (nonCoreProfile && window.innerWidth < 600) ? (
                          <button onClick={(e) => {
                            setNonCoreProfile(null);
                          }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                        ) : null
                      }
                    </div>
                  )}
                  {profilePref.core && (
                    <>
                      <div className="mb-3 relative">
                        <div className="flex w-full justify-end cursor-pointer s-desktop-card">
                          <p
                            className="underline text-blue-500"
                            onClick={(e) => {
                              setBranch(null);
                              setSpecialization(null)
                            }}
                          >
                            clear
                          </p>
                        </div>
                        <Select
                          className="s-mobile-card relative"
                          // multiple
                          displayEmpty
                          required
                          value={branch}
                          sx={{ width: 1 }}
                          renderValue={() => branch || "Select Profile"}
                          onChange={(e) => {
                            setBranch(e.target.value);
                            setSpecialization(null);
                            setNonCoreProfile(null)
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          
                        >
                          {/* <MenuItem hidden disabled value="">
                          Select Branch/Department
                        </MenuItem>
                        <MenuItem value="Aerospace Engineering">
                          Aerospace Engineering
                        </MenuItem>
                        <MenuItem value="Applied Mechanics">
                          Applied Mechanics
                        </MenuItem>
                        <MenuItem value="Biomedical Engineering ">
                          Biomedical Engineering
                        </MenuItem>
                        <MenuItem value="Bioscience and Bioengineering">
                          Bioscience and Bioengineering
                        </MenuItem>
                        <MenuItem value="Biotechnology">Biotechnology</MenuItem>
                        <MenuItem value="Chemical Engineering">
                          Chemical Engineering
                        </MenuItem>
                        <MenuItem value="Chemistry">Chemistry</MenuItem>
                        <MenuItem value="Civil Engineering">
                          Civil Engineering
                        </MenuItem>
                        <MenuItem value="Computer Science and Engineering">
                          Computer Science and Engineering
                        </MenuItem>
                        <MenuItem value="Economics">Economics</MenuItem>
                        <MenuItem value="Electrical Engineering">
                          Electrical Engineering
                        </MenuItem>
                        <MenuItem value="Electrical and Electronics Engineering">
                          Electrical and Electronics Engineering
                        </MenuItem>
                        <MenuItem value="Electronics & Communication Engineering">
                          Electronics & Communication Engineering
                        </MenuItem>
                        <MenuItem value="Engineering Design">
                          Engineering Design
                        </MenuItem>
                        <MenuItem value="Humanities and Social Sciences">
                          Humanities and Social Sciences
                        </MenuItem>
                        <MenuItem value="Management/Business Studies">
                          Management/Business Studies
                        </MenuItem>
                        <MenuItem value="Mathematics/Mathematics and Computing">
                          Mathematics/Mathematics and Computing
                        </MenuItem>
                        <MenuItem value="Mechanical Engineering">
                          Mechanical Engineering
                        </MenuItem>
                        <MenuItem value="Metallurgical and Materials Engineering">
                          Metallurgical and Materials Engineering
                        </MenuItem>
                        <MenuItem value="Mining Engineering">
                          Mining Engineering
                        </MenuItem>
                        <MenuItem value="Ocean Engineering">
                          Ocean Engineering
                        </MenuItem>
                        <MenuItem value="Physics/Engineering Physics">
                          Physics/Engineering Physics
                        </MenuItem> */}
                          {
                            Object.keys(special).map((val, i) => (
                              <MenuItem key={i + val} value={val}>{val}</MenuItem>
                            ))
                          }
                        </Select>
                        {
                          (branch && window.innerWidth < 600) ? (
                            <button onClick={(e) => {
                              setBranch(null);
                              setSpecialization(null)
                            }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                          ) : null
                        }
                      </div>
                      {branch && branch !== "" && (
                        <div className="mb-3 relative">
                          <div className="">
                            <>
                              <div className="flex w-full justify-end cursor-pointer s-desktop-card">
                                <p
                                  className="underline text-blue-500 "
                                  onClick={(e) => {
                                    setSpecialization(null);
                                  }}
                                >
                                  clear
                                </p>
                              </div>
                              <Select
                                displayEmpty
                                required
                                value={specialization}
                                sx={{ width: 1 }}
                                renderValue={() =>
                                  specialization || "Select Specialization"
                                }
                                onChange={(e) => {
                                  setSpecialization(e.target.value);
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                                
                              >
                                {special[branch]?.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                  // style={getStyles(name, filters.profiles, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {
                                (specialization && window.innerWidth < 600) ? (
                                  <button onClick={(e) => {
                                    setSpecialization(null);
                                  }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                                ) : null
                              }
                            </>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              }


              {/* <FormControl className="s-mobile-card"><RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  className="s-mobile-search-3filter"
                  onChange={(e) => {
                    if (e.target.value === "core") {
                      setFilters({ ...filters, profiles: [] });

                      setProfilePref({ core: true, nonCore: false });
                    } else if (e.target.value === "nonCore") {
                      setBranch(null);
                      setSpecialization(null);
                      setProfilePref({ core: false, nonCore: true });
                    } else {
                      setFilters({ ...filters, profiles: [] });
                      setBranch(null);
                      setSpecialization(null);
                      setProfilePref({ core: false, nonCore: false });
                    }
                  }}
                >
                  <FormControlLabel
                    value="core"
                    control={<Radio />}
                    label="Core"
                  />
                  <FormControlLabel
                    value="nonCore"
                    control={<Radio />}
                    label="Non Core"
                  />
                  <FormControlLabel value="" control={<Radio />} label="None" />
                </RadioGroup>
              </FormControl> */}

              <FormControl className="s-desktop-card">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  className="s-mobile-search-3filter"
                  onChange={(e) => {
                    if (e.target.value === "core") {
                      setNonCoreProfile(null)
                      setProfilePref({ core: true, nonCore: false });
                    } else if (e.target.value === "nonCore") {
                      setBranch(null);
                      setSpecialization(null);
                      setProfilePref({ core: false, nonCore: true });
                    } else {
                      setBranch(null);
                      setSpecialization(null);
                      setProfilePref({ core: false, nonCore: false });
                      setNonCoreProfile(null)
                    }
                  }}
                >
                  <FormControlLabel
                    value="core"
                    control={<Radio />}
                    label="Core"
                  />
                  <FormControlLabel
                    value="nonCore"
                    control={<Radio />}
                    label="Non Core"
                  />
                  <FormControlLabel value="" control={<Radio />} label="None" />
                </RadioGroup>
              </FormControl>
              {profilePref.nonCore && (
                <div className="my-3 s-desktop-card">
                  {nonCoreProfile && <div className="flex w-full justify-end cursor-pointer">
                    <p
                      className="underline text-blue-500 "
                      onClick={(e) => {
                        setNonCoreProfile(null);
                      }}
                    >
                      clear
                    </p>
                  </div>}
                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={nonCoreProfile || "Select Profile"}
                    sx={{ width: 1 }}
                    onChange={addProfilePref}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    
                  >
                    <MenuItem value="Select Profile" disabled>
                      Select Profile
                    </MenuItem>
                    <MenuItem value="Data Science">Data Science</MenuItem>
                    <MenuItem value="Software">Software</MenuItem>
                    <MenuItem value="Banking and Finance">
                      Banking and Finance
                    </MenuItem>
                    <MenuItem value="Consulting">Consulting</MenuItem>
                    <MenuItem value="Analytics">Analytics</MenuItem>
                    <MenuItem value="Product Management">
                      Product Management
                    </MenuItem>
                    <MenuItem value="Operations">Operations</MenuItem>
                    <MenuItem value="Supply Chain">Supply Chain</MenuItem>
                    <MenuItem value="FMCG">FMCG</MenuItem>
                    <MenuItem value="Operations Research">
                      Operations Research
                    </MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Inventory Management">
                      Inventory Management
                    </MenuItem>
                    <MenuItem value="Logistics">Logistics</MenuItem>
                  </Select>
                  {/* {branch ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      width: 1,
                      p: 2,
                    }}
                  >
                    <Chip
                      key={branch}
                      label={branch}
                      onDelete={() => {
                        setBranch(null);
                      }}
                    />
                  </Box>
                ) : null} */}
                </div>
              )}
              {profilePref.core && (
                <div className="my-3 s-desktop-card">
                  {" "}
                  {branch && <div className="flex w-full justify-end cursor-pointer">
                    <p
                      className="underline text-blue-500 "
                      onClick={(e) => {
                        setBranch(null);
                        setSpecialization(null)
                      }}
                    >
                      clear
                    </p>
                  </div>
                  }
                  <Select
                    // className="s-desktop-card"
                    // multiple
                    displayEmpty
                    required
                    value={branch}
                    sx={{ width: 1 }}
                    renderValue={() => branch || "Select Profile"}
                    onChange={(e) => {
                      setBranch(e.target.value);
                      setSpecialization(null);
                      setNonCoreProfile(null)
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    
                  >
                    {
                      Object.keys(special).map((val, i) => (
                        <MenuItem value={val} key={val + i}>
                          {val}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <div className="my-3 s-desktop-card">
                    <div className="">

                      {branch && branch !== "" && (
                        <>
                          {specialization && <div className="flex w-full justify-end cursor-pointer ">
                            <p
                              className="underline text-blue-500 "
                              onClick={(e) => {
                                setSpecialization(null);
                              }}
                            >
                              clear
                            </p>
                          </div>}
                          <Select
                            displayEmpty
                            required
                            value={specialization}
                            sx={{ width: 1 }}
                            renderValue={() =>
                              specialization || "Select Specialization"
                            }
                            onChange={(e) => {
                              setSpecialization(e.target.value);
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                            
                          >
                            {special[branch]?.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                              // style={getStyles(name, filters.profiles, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Search Interviewer by Time Preferences */}
              <div className="my-3 s-desktop-card">
                <p className="text-justify line text-lg text-green-800 font-medium ">
                  Date Preference for Mock Interview (with Live Feedback)
                </p>
                <div className="w-full my-4 ">
                  <div className="border-2 rounded-md p-2 my-3 mx-auto ">
                    {timeFilterCheck ? (
                      <div
                        className="relative flex justify-between text-sm rounded-full border border-black bg-teal-100 p-2 mb-2 mx-auto text-black font-bold"
                      // style={{ width: "220px" }}
                      >
                        <p className="mx-auto">
                          {selectedDate !== ""
                            ? moment(selectedDate).format("DD MMM YYYY") + " "
                            : ""}
                          {/*[{from.hrs}:{from.min} {from.merd} - {to.hrs}:{to.min}{" "}
                        {to.merd}]*/}
                        </p>
                        {timeFilterCheck ? (
                          <div
                            className="mx-1 cursor-pointer text-black hover:text-blue-600"
                            onClick={() => {
                              setSelectedDate("");
                              //setFrom({
                              //  hrs: "12",
                              //  min: "00",
                              //  merd: "AM",
                              //});
                              //setTo({
                              //  hrs: "11",
                              //  min: "59",
                              //  merd: "PM",
                              //});
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <p className="text-center">No preference selected</p>
                    )}
                  </div>
                  <div className="my-3">
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                      }}
                      gutterBottom
                    >
                      Choose a Date
                    </Typography>
                    <div className="w-full flex flex-row justify-center">
                      <Calendar
                        minDate={new Date().setDate(new Date().getDate())}
                        zIndex={0}
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(new Date(e))}
                        mapDays={({ date, selectedDate, isSameDate }) => {
                          let props = {};
                          if (isSameDate(date, selectedDate)) {
                            props.style = {
                              color: "#fff",
                              backgroundColor: "#00a86b",
                              border: "1px solid #000",
                            };
                            return props;
                          }
                        }}
                      />

                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(e) => {
                            setSelectedDate(new Date(e));
                          }}
                          

                        />
                      </LocalizationProvider> */}
                    </div>
                  </div>
                  <div className="w-full my-3">
                    {/* <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    Choose Time
                  </Typography> */}
                    {/* <div className="grid grid-cols-4 gap-3 mx-2 my-2">
                    <p className="col-span-1 text-right">From: </p>
                    <div className="col-span-3 flex flex-row" id="time-picker">
                      <select
                        className="text-center"
                        name="hours"
                        id="hours time-picker"
                        value={from.hrs}
                        onChange={(e) => {
                          var now = moment(
                            e.target.value + ":" + from.min + " " + from.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            to.hrs + ":" + to.min + " " + to.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          if (now < end) {
                            setFrom({ ...from, hrs: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="12">12</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                      </select>
                      <p className="mx-1">:</p>
                      <select
                        className="text-center"
                        name="min"
                        id="min time-picker"
                        value={from.min}
                        onChange={(e) => {
                          var now = moment(
                            from.hrs + ":" + e.target.value + " " + from.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            to.hrs + ":" + to.min + " " + to.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          if (now < end) {
                            setFrom({ ...from, min: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="00">00</option>
                        <option value="30">30</option>
                      </select>
                      <p className="mx-1"> </p>
                      <select
                        className="text-center"
                        name="merd"
                        id="merd time-picker"
                        value={from.merd}
                        onChange={(e) => {
                          var now = moment(
                            from.hrs + ":" + from.min + " " + e.target.value,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            to.hrs + ":" + to.min + " " + to.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          if (now < end) {
                            setFrom({ ...from, merd: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div> */}
                    {/* <div className="grid grid-cols-4 gap-3 mx-2 my-2">
                    <p className="col-span-1 text-right">To: </p>
                    <div className="col-span-3 flex flex-row" id="time-picker">
                      <select
                        className="text-center"
                        name="hours"
                        id="hours time-picker"
                        value={to.hrs}
                        onChange={(e) => {
                          var now = moment(
                            from.hrs + ":" + from.min + " " + from.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            e.target.value + ":" + to.min + " " + to.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          if (now < end) {
                            if (
                              e.target.value !== "11" &&
                              to.min === "59" &&
                              to.merd === "PM"
                            )
                              setTo({
                                ...to,
                                hrs: e.target.value,
                                min: "00",
                              });
                            else setTo({ ...to, hrs: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="12">12</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                      </select>
                      <p className="mx-1">:</p>
                      <select
                        className="text-center"
                        name="min"
                        id="min time-picker"
                        value={to.min}
                        onChange={(e) => {
                          var now = moment(
                            from.hrs + ":" + from.min + " " + from.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            to.hrs + ":" + e.target.value + " " + to.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          if (now < end) {
                            setTo({ ...to, min: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="00">00</option>
                        <option value="30">30</option>
                        {to.hrs === "11" && to.merd === "PM" ? (
                          <option value="59">59</option>
                        ) : null}
                      </select>
                      <p className="mx-1"> </p>
                      <select
                        className="text-center"
                        name="merd"
                        id="merd time-picker"
                        value={to.merd}
                        onChange={(e) => {
                          // end = moment(end, "H:mm").format("hh:mm A");
                          var now = moment(
                            from.hrs + ":" + from.min + " " + from.merd,
                            "hh:mm A"
                          ).format("HH:mm");
                          var end = moment(
                            to.hrs + ":" + to.min + " " + e.target.value,
                            "hh:mm A"
                          ).format("HH:mm");

                          if (now < end) {
                            if (
                              e.target.value !== "PM" &&
                              to.hrs === "11" &&
                              to.min === "59"
                            )
                              setTo({
                                ...to,
                                min: "00",
                                merd: e.target.value,
                              });
                            else setTo({ ...to, merd: e.target.value });
                            setTimePrefWarning(false);
                          } else setTimePrefWarning(true);
                        }}
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div> */}
                    {/* {timePrefWarning ? (
                    <p className="text-red-600 text-center">
                      Please enter a valid time. To time cannot be before or
                      same as From time.
                    </p>
                  ) : null} */}
                  </div>
                </div>
                <div
                  className="date-time-filter"
                  style={{ display: isHidden ? "none" : "" }}
                >
                  {/* <DateTimePicker key={new Date().getTime()} /> */}
                </div>
              </div>
            </div>
            <div className="discounts-space s-desktop-card">
              <div>
                <h2>Flat Discounts</h2>
                <div class="coupon-container">
                  <div class="coupon-card">
                    <h3>
                      2 Mock Interviews: <span className="text-white line-through">
                        <span className="text-white">₹1499</span></span> <br/><span className="py-3">Earlybird Price: ₹899</span> <br />{" "}
                      <span style={{ fontWeight: "bold" }}>Save ₹600</span>
                    </h3>
                    <div class="coupon-row">
                      <span
                        id="cpnBtn"
                        onClick={() => {
                          navigate(PLAN_ROUTE);
                        }}
                      >
                        Get Coupon Codes
                      </span>
                    </div>
                    <h3>
                      3 Mock Interviews: <span className="text-white line-through">
                        <span className="text-white">₹2199</span></span> Earlybird Price: ₹1299<br />{" "}
                      <span style={{ fontWeight: "bold" }}>Save ₹900 </span>
                    </h3>
                    <div class="coupon-circle1"></div>
                    <div class="coupon-circle2"></div>
                  </div>
                </div>
              </div>
              {/* <div>
                <h2>Referral Discount</h2>
                <div class="coupon-container">
                  <div class="coupon-card">
                    <h3>
                      Refer a Friend and Get
                      <br />{" "}
                      <span style={{ fontWeight: "bold" }}>
                        10% Flat Discount
                      </span>
                    </h3>
                    <div class="coupon-row">
                      <span
                        id="cpnBtn"
                        onClick={() => {
                          navigate(REFER_ROUTE);
                        }}
                      >
                        Get Coupon Codes
                      </span>
                    </div>
                    <h3>
                      <span style={{ fontWeight: "bold" }}>Save ₹50</span>
                      <br />
                      <span>on a Mock Interview</span>
                    </h3>
                    <div class="coupon-circle1"></div>
                    <div class="coupon-circle2"></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* Search Interviewer result */}
          <div className="search-result min-h-screen">
            {isLoaded ? (
              <>
                <h3 className="text-xl font-[500]">
                  Search results (
                  {interviewers
                    ? page * 10 -
                    9 +
                    " - " +
                    (interviewers?.length > page * 10 ? page * 10 : interviewers?.length)
                    : null}
                  ) out of ({interviewers?.length})
                </h3>
                <div className="search-result-cont">
                  {interviewers?.length !== 0
                    ? interviewers
                      .slice(pageNo[0], pageNo[1])
                      .map((interviewer) => {
                        // console.log(interviewer, "-------------");
                        // console.log(interviewer.profiles, interviewer.coreprofiles ? "ud" : 'dd', "-------------><", interviewer.profiles?.length !== 0, interviewer.coreprofiles?.length !== 0)
                        return (
                          (interviewer.profiles?.length !== 0 || interviewer.coreprofiles?.length !== 0) &&
                          interviewer.designation && (
                            <Card
                              key={interviewer._id}
                              id={interviewer.id}
                              name={
                                interviewer.fname +
                                (interviewer.lname
                                  ? " " + interviewer.lname
                                  : "")
                              }
                              coreprofiles={interviewer.coreprofiles}
                              specializations={interviewer.specializations?.map(spec => ({
                                ...spec,
                                subDomains: spec?.subDomains?.filter(sD => sD !== "Other")
                              }))}
                              designation={interviewer.designation}
                              company={interviewer.currCompany}
                              price={interviewer.price || "INR 499"}
                              profiles={[...interviewer.profiles, ...(interviewer.coreprofiles ? interviewer.coreprofiles : [])] || []}
                              photo={interviewer.photo}
                              logo={getCompanyLogo(interviewer.currCompany)}
                              gnrcSchd={
                                interviewer.GnrcSchedule
                                  ? interviewer.GnrcSchedule
                                  : []
                              }
                              custSchd={
                                interviewer.CustomSchedule
                                  ? interviewer.CustomSchedule
                                  : []
                              }
                              studentId={user.id}
                              setShareUrl={setShareurl}
                            />
                          )
                        );
                      })
                    : "No Search Result Found"}
                </div>

                {/* Pagination */}
                <div className="flex flex-row justify-center mt-4 my-2">
                  <Pagination
                    count={Math.ceil(interviewers?.length / 10)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </div>
              </>
            ) : (
              <div
                className="w-full flex flex-row justify-center items-center"
                style={{ height: "600px" }}
              >
                <Oval
                  height={120}
                  width={120}
                  color="#306bc9"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#90caf9"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
};

export default SearchInterviews;
