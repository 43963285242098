import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { BASE } from "../../constants/endpoints";
import "./../Dashboard/Navbarlogedin.css";
import UserContext from "../../contexts/UserContext";
import {
  HOME_ROUTE,
  INTERVIEWER_ACCEPTED_REQUESTS_ROUTE,
  INTERVIEWER_COMPLETED_INTERVIEWS_ROUTE,
  INTERVIEWER_DASHBOARD_ROUTE,
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
  INTERVIEWER_UPCOMING_INTERVIEWS_ROUTE,
  INTERVIEWER_REPORT_ROUTE,
  INTERVIEWER_CANCEL_ROUTE,
  INTERVIEWER_ACCOUNT_SETTINGS_ROUTE,
  INTERVIEWER_CANCELED_INTERVIEWS_ROUTE,
} from "../../constants/routes";
// import Bookmark from "../Dashboard/popups/Bookmark";

const NavbarInterviewer = () => {
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // Profile Dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggingOut = () => {
    if (localStorage.getItem("isIAuthenticated"))
      localStorage.removeItem("isIAuthenticated");
    setUser({});
    navigate(HOME_ROUTE);
  };

  // useEffect(() => {
  //   // window.location.reload(false);
  //   console.log("reloading...");
  // }, [navigate]);

  // console.log("reloaded...");
  return (
    <>
      {/* <Bookmark /> */}
      <div className="navbarlogedin flex">
        <div className="logo">
          <Link to="/" style={{ textDecoration: "none" }}>
            <h1 className="text-2xl font-medium">interwiu.com</h1>
          </Link>
        </div>
        {location.pathname === "/profile-form" ? (
          <h1
            className="text-4xl font-medium text-center"
            style={{ marginRight: "450px" }}
          >
            Create an Account as a Student
          </h1>
        ) : (
          <>
            <ul className="nav-list flex">
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={INTERVIEWER_RECIEVED_REQUESTS_ROUTE}
                >
                  <div>Received Requests</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={INTERVIEWER_ACCEPTED_REQUESTS_ROUTE}
                >
                  <div className="flex">
                    Accepted Requests
                    {/* <div className="notify"></div> */}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={INTERVIEWER_UPCOMING_INTERVIEWS_ROUTE}
                >
                  <div>Upcoming Interviews</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={INTERVIEWER_COMPLETED_INTERVIEWS_ROUTE}
                >
                  <div className="flex">
                    Completed Interviews
                    {/* <div className="notify"></div> */}
                  </div>
                </NavLink>
              </li>
            </ul>
            <div className="sidebar flex">
              {/* Notification Bell */}
              {location.pathname === "/profile-form" ? null : null}
              {/* <div
                className="notifier rounded border px-3 py-2 m-1 hover:bg-slate-200"
                onClick={() => {
                  const dropdown = document.querySelector(
                    ".notification-dropdown"
                  );
                  dropdown.classList.toggle("hide");
                }}
              >
                <i className="fa-solid fa-bell text-2xl"></i>
                <div className="dot">76</div>
              </div> */}

              <div className="notification-dropdown hide">
                <h4>Notification</h4>
                <hr />
                <div className="notification-item">
                  <h6>Notification Head</h6>
                  <p>
                    Notification Desc such as Lorem ipsum dolor sit amet
                    consectetur adipisicing elit.
                  </p>
                </div>
                <hr />
                <div className="notification-item">
                  <h6>Notification Head</h6>
                  <p>
                    Notification Desc such as Lorem ipsum dolor sit amet
                    consectetur adipisicing elit.
                  </p>
                </div>
              </div>

              {/* Profile Dropdown */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
                
                >
                <Tooltip title="My Profile">
                  <Button
                    onClick={handleClick}
                    size="small"
                    sx={{ color: "black" }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    >
                    <div className="sidebar-profile px-2 rounded border cursor-pointer hover:bg-slate-200 flex">
                      <div className="sidebar-profile-img mx-1 flex">
                        <img
                          src={user.photo}
                          alt=""
                        />
                      </div>
                      <div
                        className="text-sm mx-1 flex"
                        style={{ flexDirection: "column" }}
                      >
                        <p>Me</p>
                        <i className="fa-solid fa-caret-down"></i>
                      </div>
                    </div>
                  </Button>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1,
                    px: 1,
                    width: 300,
                    borderRadius: 2,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Typography>
                  <div className=" profile-dropdown-head flex">
                    <div>
                      <img
                        src={user.photo}
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </div>
                    <div>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "bold" }}
                      >
                        {user.fname + (user.lname ? " " + user.lname : "")}
                      </Typography>
                      <p>{user.designation}</p>
                    </div>
                  </div>
                </Typography>
                <button
                  type="button"
                  className="cust-btn view-profile-btn"
                  onClick={() => {
                    navigate(INTERVIEWER_DASHBOARD_ROUTE);
                  }}
                >
                  View Profile
                </button>
                <Divider />
                {/* <MenuItem
                  className="dropdown-btn"
                  onClick={() => {
                    document.querySelector(".bookmark").style.visibility =
                      "visible";
                  }}
                >
                  <p>
                    <i className="fa-solid fa-bookmark"></i> Bookmarks
                  </p>
                </MenuItem> */}
                {/* <MenuItem
                  className="dropdown-btn"
                  onClick={() => {
                    navigate("/declined-requests");
                  }}
                >
                  <p>
                    <i className="fa-solid fa-circle-xmark"></i> Declined
                    Requests
                  </p>
                </MenuItem> */}
                {/* <MenuItem className="dropdown-btn">
                  <p>
                    <i className="fa-solid fa-ban"></i> Canceled Mock Interviews
                  </p>
                </MenuItem> */}
                {/* <MenuItem className="dropdown-btn">
                  <p>
                    <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                    Reschedule a Mock Interview
                  </p>
                </MenuItem> */}
                <MenuItem
                  id="dropdown-btn"
                  onClick={() => {
                    navigate(INTERVIEWER_CANCELED_INTERVIEWS_ROUTE);
                  }}
                  style={{ background: `${location.pathname.split("/").pop() === 'canceled-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'canceled-interviews' ? 'black' : ''}` }}
                >
                  <p>
                    <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                    Cancelled Mock Interviews
                  </p>
                </MenuItem>
                <MenuItem
                  id="dropdown-btn"
                  onClick={() => {
                    navigate(INTERVIEWER_CANCEL_ROUTE);
                  }}
                  style={{ background: `${location.pathname.split("/").pop() === 'cancel' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'cancel' ? 'black' : ''}` }}
                >
                  <p>
                    <i className="fa-solid fa-triangle-exclamation"></i> Cancel
                    a Mock Interview
                  </p>
                </MenuItem>
                <MenuItem
                  id="dropdown-btn"
                  onClick={() => {
                    navigate(INTERVIEWER_REPORT_ROUTE);
                  }}
                  style={{ background: `${location.pathname.split("/").pop() === 'report' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'report' ? 'black' : ''}` }}
                >
                  <p>
                    <i className="fa-solid fa-triangle-exclamation"></i> Report
                    an Interview Issue
                  </p>
                </MenuItem>
                {/* <MenuItem id="dropdown-btn"
                  onClick={() => {
                      navigate(INTERVIEWER_ACCOUNT_SETTINGS_ROUTE);
                  }}
                >
                  <p>
                    <i className="fa-solid fa-gear"></i> Account Settings
                  </p>
                </MenuItem> */}
                <hr />
                {console.log(`${BASE}`)}
                <form action={`${BASE}/auth/logout`}>
                  <button className="cust-btn logout-btn" onClick={loggingOut}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i> Log
                    Out
                  </button>
                </form>
              </Menu>
              <IconButton
                aria-label="more"
                className="navLoggedInDropdownIcon"
                id="long-button"
                aria-controls={Boolean(anchorEl2) ? "long-menu" : undefined}
                aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl2(e.currentTarget)}
              >
                <MenuRoundedIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={() => setAnchorEl2(null)}
              >
                <NavLink
                  // end
                  // className="nav-item hover-underline-animation"
                  // activeClassName="active"
                  to={INTERVIEWER_RECIEVED_REQUESTS_ROUTE}
                >
                  <MenuItem onClick={() => { setAnchorEl2(null) }} style={{ background: `${location.pathname.split("/").pop() === 'recieved-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'recieved-requests' ? 'black' : ''}` }}>
                    <div>Received Requests</div>
                  </MenuItem>
                </NavLink>
                <NavLink
                  // end
                  // className="nav-item hover-underline-animation"
                  // activeClassName="active"
                  to={INTERVIEWER_ACCEPTED_REQUESTS_ROUTE}
                >
                  <MenuItem onClick={() => { setAnchorEl2(null) }} style={{ background: `${location.pathname.split("/").pop() === 'accepted-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'accepted-requests' ? 'black' : ''}` }}>
                    <div className="flex">
                      Accepted Requests <div className="notify"></div>
                    </div>
                  </MenuItem>
                </NavLink>
                <NavLink
                  // end
                  // className="nav-item hover-underline-animation"
                  // activeClassName="active"
                  to={INTERVIEWER_UPCOMING_INTERVIEWS_ROUTE}
                >
                  <MenuItem onClick={() => { setAnchorEl2(null) }} style={{ background: `${location.pathname.split("/").pop() === 'upcoming-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'upcoming-interviews' ? 'black' : ''}` }}>
                    <div>Upcoming Interviews</div>
                  </MenuItem>
                </NavLink>
                <NavLink
                  // end
                  // className="nav-item hover-underline-animation"
                  // activeClassName="active"
                  to={INTERVIEWER_COMPLETED_INTERVIEWS_ROUTE}
                >
                  <MenuItem onClick={() => { setAnchorEl2(null) }} style={{ background: `${location.pathname.split("/").pop() === 'completed-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'completed-interviews' ? 'black' : ''}` }}>
                    <div className="flex">
                      Completed Interviews <div className="notify"></div>
                    </div>
                  </MenuItem>
                </NavLink>
              </Menu>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NavbarInterviewer;
