import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
} from ".././PopupDays.js";
import { STUDENT_PROFILE_FORM_ROUTE } from "../../../constants/routes.js";
import UserContext from "../../../contexts/UserContext.js";
import { InfoOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const display = (date, name, id, price, profile) => {
  const dt = date.toLocaleDateString();
  const day = date.getDay();
  if (day === 0)
    return (
      <Sunday id={id} date={dt} name={name} price={price} profile={profile} />
    );
  else if (day === 1)
    return (
      <Monday id={id} date={dt} name={name} price={price} profile={profile} />
    );
  else if (day === 2)
    return (
      <Tuesday id={id} date={dt} name={name} price={price} profile={profile} />
    );
  else if (day === 3)
    return (
      <Wednesday
        id={id}
        date={dt}
        name={name}
        price={price}
        profile={profile}
      />
    );
  else if (day === 4)
    return (
      <Thursday id={id} date={dt} name={name} price={price} profile={profile} />
    );
  else if (day === 5)
    return (
      <Friday id={id} date={dt} name={name} price={price} profile={profile} />
    );
  else if (day === 6)
    return (
      <Saturday id={id} date={dt} name={name} price={price} profile={profile} />
    );
};

const Interview = ({
  id,
  name,
  designation,
  price,
  logo,
  profiles,
  photo,
  company,
  setShareUrl,
}) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [profile, setProfile] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    // if (user.institute) setOpen(true);
    // else navigate(STUDENT_PROFILE_FORM_ROUTE);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Bookmarked?
  const Bookmark = () => {
    if (isBookmarked) {
      console.log("Bookmark Added!!!");
      console.log(`Name: ${name}, designation: ${designation}, Id: ${id}`);
    } else {
      console.log("Bookmark Removed");
    }
  };
  useEffect(() => {
    Bookmark();
  }, [isBookmarked]);

  const [openBookmark, setOpenBookmark] = useState(false);
  const [msg, setMsg] = useState("");

  const handleBookmarkClick = () => {
    setOpenBookmark(true);
  };

  const handleBookmarkClose = () => {
    setOpenBookmark(false);
  };

  const handleProfileChange = (event) => {
    setProfile(event.target.value);
  };

  // Check if image exist
  // function imageExists(image_url) {
  //   var http = new XMLHttpRequest();
  //   http.open("HEAD", image_url, false);
  //   http.send();
  //   return http.status != 404;
  // }
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      console.log("svg", url);
      var img = new Image();
      img.src = url;
      console.log(img.height);
      return img?.getBBox?.height !== 0;
    } else {
      console.log(".png", url);
      var img2 = new Image();
      img2.src = url;
      console.log(img2?.getBBox?.height);
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <>
      <div className="search-result-item">
        <div className="search-result-item-head flex">
          <div className="mx-2">
            <div className="flex w-full">
              <div className="interviewer-logo">
                {ImageExist(logo[0]) &&
                  imgExist === false &&
                  (imgExist = true) && (
                    <img src={logo[0]} alt="logo" className="h-full w-full" />
                  )}
                {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                  <img src={logo[1]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                  <img src={logo[2]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                  <img src={logo[3]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist &&
                  !ImageExist(logo[0]) &&
                  !ImageExist(logo[1]) &&
                  !ImageExist(logo[2]) &&
                  !ImageExist(logo[3]) &&
                  (imgExist = true) && (
                    <img src={logo[4]} alt="logo" className="h-full w-full" />
                  )}
              </div>
              <div className="interviewer-head">
                <div className="flex gap-2 justify-between">
                  <h4 className="text-2xl font-[500]">{name}</h4>
                  <div className="flex gap-2">
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{ padding: "6px", minWidth: "auto" }}
                      onClick={() => {
                        setIsBookmarked(!isBookmarked);
                        if (isBookmarked) {
                          console.log(isBookmarked);
                          setMsg("Bookmark Removed");
                        } else {
                          console.log(isBookmarked);
                          setMsg("Bookmark Added");
                        }
                        handleBookmarkClick();
                      }}
                    >
                      {isBookmarked ? (
                        <BookmarkAddedIcon size="large" />
                      ) : (
                        // <i className="fa-solid fa-trash-can"></i>
                        <BookmarkBorderIcon size="large" />
                        // <i className="fa-solid fa-bookmark"></i>
                      )}
                    </Button>
                    <Snackbar
                      open={openBookmark}
                      onClose={handleBookmarkClose}
                      message={msg}
                      key="bottom left"
                    />
                    <Button
                      size="large"
                      variant="outlined"
                      color="success"
                      sx={{
                        padding: "6px",
                        minWidth: "auto",
                        fontSize: "24px",
                      }}
                      onClick={() => {
                        document
                          .getElementById("share-cont")
                          .classList.remove("hide");
                        setShareUrl("www.twitter.co.in");
                      }}
                    >
                      <i className="fa-solid fa-share"></i>
                    </Button>
                  </div>
                </div>
                <p className="leading-6">
                  {designation}. {company}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 justify-between text-center my-1 gap-x-4">
              <div className="mx-2 col-span-2">
                <p className="text-base text-gray-400">Profile/Profiles</p>
                {/* <p className="detail-body">{profile}</p> */}
                <div className="text-lg flex flex-wrap justify-center gap-1">
                  {profiles.map((profile) => (
                    <Chip
                      label={profile}
                      color="info"
                      sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                    />
                  ))}
                  {/* <Chip label="Banking and Finance" variant="outlined" /> */}
                </div>
              </div>
              <div className="mx-2">
                <p className="text-base text-gray-400">Duration</p>
                <p className="text-lg">45 Min</p>
              </div>
              <div className="mx-2">
                <p className="text-base text-gray-400">Price</p>
                <p className="text-lg">{price}</p>
              </div>
            </div>
            <div className="search-result-item-btns flex">
              <Button
                style={{ marginTop: "10px", textTransform: "none" }}
                variant="contained"
                onClick={handleOpen}
              >
                Choose a Slot
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  >
                    <CloseIcon onClick={handleClose} />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    Request a Mock Interview (with Feedback Session) with {name}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <Box>
                        <Typography variant="h6" component="h6">
                          Choose a Profile
                        </Typography>
                        <RadioGroup
                          value={profile}
                          onChange={handleProfileChange}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <FormControlLabel
                            value="data"
                            control={<Radio />}
                            label="Data Science"
                          />
                          <FormControlLabel
                            value="ana"
                            control={<Radio />}
                            label="Analytics"
                          />
                          <FormControlLabel
                            value="fin"
                            control={<Radio />}
                            label="Finance"
                          />
                        </RadioGroup>
                      </Box>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CalendarPicker
                          date={date}
                          format="dd/MM/yyyy"
                          onChange={(newDate) => setDate(newDate)}
                          views={["day"]}
                          disablePast={true}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid
                      xs={6}
                      sx={{
                        mt: 2,
                      }}
                    >
                      <div className="relative border-2 border-gray-400 rounded p-1 m-1 flex flex-wrap">
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                          Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                          Conditionally Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                          Occupied
                        </div>
                        <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                          {/* <Tooltip
                            title={
                              <>
                                <p className="my-1">
                                  Green: The slot is completely available to you
                                </p>
                                <p className="my-1">
                                  Orange: Another student has already chosen the
                                  slot, but is yet to pay and confirm. You can
                                  still request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  Red: The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </>
                            }
                          >
                            <img
                              src={require("../../../images/exclamation.png")}
                              alt="Description"
                              style={{ transform: "rotateZ(180deg)" }}
                            />
                          </Tooltip> */}
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div className="tt ml-auto">
                                <InfoOutlined
                                  sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                                />
                                <span style={{ background: 'black', color: 'white', padding: '3px 5px',top:'-146px',textAlign:'left' }} className="tttext">
                                <p className="my-1 mb-[0.5rem]">
                                    <span style={{color:"lime",fontWeight:'bold'}}>Green: </span>The slot is completely available to you
                                  </p>
                                  <p className="my-1 mb-[0.5rem]">
                                    <span style={{color:"orange",fontWeight:'bold'}}>Orange: </span>Another student has already chosen the
                                    slot, but is yet to pay and confirm. You can
                                    still request that slot, and pay first after
                                    acceptance to confirm
                                  </p>
                                  <p className="my-1">
                                    <span  style={{color:"red",fontWeight:'bold'}}>Red: </span>The slot is not available to you, because
                                    another student has already confirmed the slot
                                  </p>
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                      <Typography variant="h5" component="h5">
                        Choose a Slot
                      </Typography>
                      {display(date, name, id, price, profile)}
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </div>
          </div>
          <div className="search-result-item-profile">
            <img
              // src={
              //   imageExists(photo)
              //     ? photo
              //     : require("../../../images/photo.png")
              // }

              src={
                photo.replace("http://localhost:8585/uploads/", "") ||
                require("../../../images/photo.png")
              }
              className="h-32 w-32"
              alt="Profile"
            />
            <Button
              color="secondary"
              sx={{
                fontSize: "12px",
                padding: "4px",
                borderRadius: "4px",
              }}
              onClick={() => {
                console.log(id);
                navigate(`/interviewer/profile/${id}`);
              }}
            >
              View Profile
            </Button>
          </div>
        </div>
        <div className="search-result-item-desc">
          {/* <div className="time-preference">
            <p>Slots Available </p>
            <ul className="slot-list">
              <li className="px-2 mx-2 my-1 text-center rounded bg-green-700 text-white">
                Sun (5-7 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-green-700 text-white">
                Mon (3-4 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-green-700 text-white">
                Tue (5-7 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-orange-500 text-white">
                Wed (3-4 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-green-700 text-white">
                Thu (5-7 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-red-700 text-white">
                Fri (3-4 pm)
              </li>
              <li className="px-2 mx-2 my-1 text-center rounded bg-green-700 text-white">
                Sat (5-7 pm)
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Interview;
