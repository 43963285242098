import { Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import VideoCall from "../Video";
import "./Validator.css";
import { BASE } from "../../../constants/endpoints";
import { Link, useParams, useSearchParams } from "react-router-dom";
import UserContext from "contexts/UserContext";
import TextLoading from "../CallEndPages/TextLoading";

const Validator = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const user = searchParams.get("name");
  // const accountId = searchParams.get("id");

  const { mock_id: ROOM_ID, id: accountId, name: user } = useParams(); //capturing mock_id of the room created

  const {user: currUser, setUser: setCurrUser} = useContext(UserContext)


  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: false,
      })
      .then((res) => {
        console.log(res);
        // res.getTracks().forEach((t) => t.stop())
      })
      .catch((err) => {
        console.log(err)
        navigator.mediaDevices
          .getUserMedia({
            video:false,
            audio: true,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, []);

  const [userAuthorised, setUserAuthorised] = useState(false)

  useEffect(() => {
    // setTimeout(() => {
      
    // }, 5000)
    
    setUserAuthorised(currUser?.id === accountId)
    console.log(currUser)
  }, [currUser])

  return (
    <>
      <div className="validator">
        <div className="validator__box">
          <p className="top__text">Join this Mock Interview ?</p>
          <div className="buttons">
            {/* <Link to={"/vc/"+ROOM_ID+"?id="+accountId+"&name="+user} target="_blank"> */}
            <Link
              to={userAuthorised?"/vc/" + ROOM_ID + "/" + accountId + "/" + user:"/"}
              style={{
                pointerEvents: userAuthorised?"auto":"none"
              }}
              target="_blank"
            >
              <Button
                variant={userAuthorised?"contained":"outlined"}
                size="large"
                onClick={() => {
                  window.close();
                }}
                sx={{
                  backgroundColor: userAuthorised? "success.main":"default.main"
                }}
              >
                {userAuthorised?"Join Now":<TextLoading text="Verifying..."/>}
              </Button>
            </Link>
            <Link to="/" target="_blank">
              <Button
                variant="outlined"
                style={{ backgroundColor: "white" }}
                size="large"
                onClick={() => {
                  window.close();
                }}
                color="error"
              >
                Cancel
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Validator;
