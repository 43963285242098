const moment = require("moment-timezone"); // Getting day from date and modifying date format
export const toastStyle = {
  success: {
    style: {
      minHeight: "70px",
      padding: "16px",
      color: "#FFFFFF",
      background: "#66BB6A",
    },
    iconTheme: {
      primary: "#66BB6A",
      secondary: "#FFFAEE",
    },
  },
  error: {
    style: {
      minHeight: "70px",
      padding: "16px",
      color: "#FFFFFF",
      background: "#E57373",
    },
    iconTheme: {
      primary: "#713200",
      secondary: "#FFFAEE",
    },
  },
  loading: {
    style: {
      minHeight: "70px",
      padding: "16px",
      color: "#FFFFFF",
      background: "#e0c812",
    },
    iconTheme: {
      primary: "#e0c812",
      secondary: "#FFFAEE",
    },
  },
};
/*  
    To convert 24 Hour Decimal Time format to 12 Hours format(for ease of processing in frontend)
    E.g. 12.25 -> 12:15 PM
  */
export const t24HDecimalTo12HFormat = (time24) => {
  let h = Math.floor(time24);
  let m = time24 - h;
  m = Math.round(m * 60);
  if (h === 24) return "11:59 PM";
  let tm = "AM";
  if (h >= 12) {
    h -= 12;
    tm = "PM";
  }
  if (h === 0) h = 12;
  return (
    (Math.floor(h / 10) === 0 ? "0" + h : h) +
    ":" +
    (Math.floor(m / 10) === 0 ? "0" + m : m) +
    " " +
    tm
  );
};
/*  
  To convert 12 Hour Time format to 24 Hours Decimal format(for ease of processing in backend)
  E.g. 12:15 AM -> 0.25, 01:45 PM -> 13.75
*/
export const t12HTo24HDecimalFormat = (time12) => {
  let time = moment(time12, "hh:mm A").format("HH:mm");
  if (time === "23:59") return 24;
  let h = Number(time.split(":")[0]);
  let m = Number(time.split(":")[1]) * (5 / 3);
  return Number(`${h}.${parseFloat(m)}`);
};
/* 
    Compares 2 dates given as parameters, return true if both of them represents the same date, else returns false.
*/
export const compareDate = (date1, date2) => {
  if (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getYear() === date2.getYear()
  ) {
    return true;
  } else {
    return false;
  }
};

/*  
    It converts the input date which is in format 'dd/mm/yyyy' to 'DD MON YYYY'.
    E.g. 12/12/2022 -> 12 NOV 2022
*/
export const convertDateFormat = (inputDate) => {
  const [tDay, tMonth, tYear] = inputDate.split("/");
  //console.log("tDay: ", tDay, " tMonth: ", tMonth, " tYear: ", tYear)
  const tDate = new Date(+tYear, +tMonth - 1, +tDay);
  return moment(tDate).format("DD-MMM-YYYY");
};

/* 
    Compares 2 slots with the current time, where item represents the slot provided, hour represents the present hour in 24 hours
    format and minute represents the current minutes
    item = [slotStart, slotEnd]
    example :- Item = [14, 14.5]
*/
export const compareSlots = (item) => {
  const d = new Date();
  const hour = d.getHours();
  const minutes = parseInt((d.getMinutes() * 100) / 60);
  if (
    (Math.floor(item) === hour && (item - Math.floor(item)) * 100 >= minutes) ||
    hour < Math.floor(item)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getCountForUpcoming = (data) => {
  let count = 0;
  data?.map(
    (result, index) =>
      (moment() < moment(result.date, "DD/MM/YYYY") ||
        (moment(new Date()).format("DD/MM/YYYY") === result.date &&
          compareSlots(result.toTime))) &&
      (count = count + 1)
  );
  //console.log(count);
  return count;
};

function debugBase64(base64URL) {
  var win = window.open();
  win.document.write(
    '<iframe src="' +
      base64URL +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
  );
}

export const downloadPDF = async (pdf) => {
  /* 
  // For Downloading the resume
  const linkSource = `${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "resume.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click(); 
  */
  debugBase64(pdf);
};
export const calculateCount = (data) => {
  let count = 0;
  for (let i = 0; i < data?.length; i++) {
    if (moment() < moment(data[i].date, "DD/MM/YYYY")) {
      count = count + 1;
    } else if (
      moment(new Date()).format("DD/MM/YYYY") === data[i].date &&
      compareSlots(data[i].toTime)
    ) {
      count = count + 1;
    }
  }
  return count;
};

export const reduce_image_file_size = async (
  base64Str,
  MAX_WIDTH = 100,
  MAX_HEIGHT = 100
) => {
  let resized_base64 = await new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
};

export const compareTime = (fromTime, toTime, date) => {
  console.log(fromTime, toTime, date);
  console.log(
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      )
  );
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) > 0 &&
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) < 0
  ) {
    console.log("ongoing");
    return "ongoing";
  }
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) > 0
  ) {
    console.log("passed");
    return "passed";
  }
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) < 0
  ) {
    if (
      moment()
        .tz("Asia/Kolkata")
        .diff(
          moment(
            date + " " + t24HDecimalTo12HFormat(fromTime),
            "DD/MM/YYYY hh:mm a"
          ),
          "seconds"
        ) >
      60 * -2 * 60
    ) {
      console.log("invalid");
      return "invalid";
    } else if (
      moment()
        .tz("Asia/Kolkata")
        .diff(
          moment(
            date + " " + t24HDecimalTo12HFormat(fromTime),
            "DD/MM/YYYY hh:mm a"
          ),
          "seconds"
        ) >
      60 * -6 * 60
    ) {
      console.log("partial");
      return "valid";
    } else {
      console.log("full");
      return "valid";
    }
  } else {
    console.log("invalid");
    return "invalid";
  }
};
export const compareTimeForCancel = (fromTime, toTime, date) => {
  console.log(fromTime, toTime, date);
  console.log(
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      )
  );
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) > 0 &&
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) < 0
  ) {
    console.log("ongoing");
    return "ongoing";
  }
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) > 0
  ) {
    console.log("passed");
    return "passed";
  }
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) < 0
  ) {
    if (
      moment()
        .tz("Asia/Kolkata")
        .diff(
          moment(
            date + " " + t24HDecimalTo12HFormat(fromTime),
            "DD/MM/YYYY hh:mm a"
          ),
          "seconds"
        ) >
      60 * -6 * 60
    ) {
      console.log("zero");
      return "zero";
    } else if (
      moment()
        .tz("Asia/Kolkata")
        .diff(
          moment(
            date + " " + t24HDecimalTo12HFormat(fromTime),
            "DD/MM/YYYY hh:mm a"
          ),
          "seconds"
        ) >
      60 * -12 * 60
    ) {
      console.log("partial");
      return "partial";
    } else {
      console.log("full");
      return "full";
    }
  } else {
    console.log("invalid");
    return "invalid";
  }
};
export const compareTimeForReport = (fromTime, toTime, date) => {
  console.log(fromTime, toTime, date);
  console.log(
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      )
  );
  if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) >
    60 * 6 * 60
  ) {
    console.log("invalid");
    return "invalid";
  } else if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) > 0 &&
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(toTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) <
      60 * 6 * 60
  ) {
    console.log("6 hours");
    return "6 hours";
  } else if (
    moment()
      .tz("Asia/Kolkata")
      .diff(
        moment(
          date + " " + t24HDecimalTo12HFormat(fromTime),
          "DD/MM/YYYY hh:mm a"
        ),
        "seconds"
      ) < 0
  ) {
    console.log("upcoming");
    return "upcoming";
  } else {
    console.log("invalid");
    return "invalid";
  }
};
export const programValues = {
  btech: "B.Tech",
  dual: "Dual Degree (B.Tech + M.Tech)",
  dualbsms: "Dual Degree (BS + MS)",
  mtech: "M.Tech",
  ms: "MS (by Research)",
  mba: "MBA",
  msc: "M.Sc",
  ima: "Integrated MA",
  phd: "PhD",
};
export const companiesData = {
  Apple: "apple",
  "Amazon India Development center": "amazon",
  "Tata consultancy services": "tataConsultancyServices",
  "Tata consultancy services ": "tataConsultancyServices",
  "Digital Outcomes Technologies Pvt Ltd": "digitalOutcomes",
  "LearnHill Technologies": "learnhill",
  "Bajaj Auto limited": "bajaj",
  "Bajaj Auto Limited": "bajaj",
  "Amazon ": "amazon",
  "Allen Career Institute": "allenCareerInstitute",
  "Kalozal Consultants Pvt. Ltd.": "kalozal",
  FinMechanics: "finMechanics",
  "Finmechanics": "finMechanics",
  Oracle: "oracle",
  "Oracle financial services software ": "oracle",
  "Deutsche Bank": "deutscheBank",
  TCS: "tcs",
  "Deloitte Pvt Ltd": "deloitte",
  HSBC: "hsbc",
  "HSBC Bank": "hsbc",
  "Mphasis Limited": "mphasis",
  "Uniphore Software Systems": "uniphore",
  "Publicis Sapient": "publicisSapient",
  "Kotak Mahindra Bank": "kotakMahindtabank",
  "Kotak Investment Advisors Ltd ": "kotakMahindtabank",
  "Kotak Bank":"kotak",
  Golconda: "golconda",
  "Boston Consulting Group": "bcg",
  IBM: "ibm",
  "Credit Suisse": "creditSuisse",
  "Goldman Sachs": "goldman_sachs1",
  NASDAQ: "nasdaq",
  "AMUL INDIA (GCMMF)": "amul",
  Protiviti: "protiviti",
  Dunzo: "dunzo",
  "Indian Oil Corporation Ltd (IOCL)": "iocl",
  Accenture: "accenture",
  "Accenture ": "accenture",
  Swiggy: "swiggy",
  Amazon: "amazon",
  "ZS ASSOCIATES": "zs",
  ManpowerGroup: "manpower",
  "Tiger Analytics": "tigerAnalytics",
  capgemini: "capgemini",
  Deloitte: "deloitte",
  "Self employed": "default",
  "Energy Exemplar Pvt. Ltd.": "energyExempler",
  "Houlihan Lokey": "houlihanLokey",
  "Detect Technologies Private Limited": "detectTecnologies",
  Sprinklr: "sprinklr",
  Microsoft: "microsoft",
  "Microsoft ": "microsoft",
  "Microsoft IDC": "microsoft",
  "Deloitte USI": "deloitte",
  "Deloitte India" : "deloitte",
  "Deloitte Touche Tohmatsu India, LLP" : "deloitte",
  Udaan: "udaan",
  "Deloitte US": "deloitte",
  "IIM Bangalore": "iimBanglore",
  Fractal: "fractal",
  "UPES": "upes",
  "Steel Authority of India Limited":"sail",
  Groww: "groww",
  "Siemens Ltd.": "siemens",
  "Siemens Healthineers":"siemens",
  "Samsung Research Institute Bangalore":"samsung",
  IQVIA: "iqvia",
  "Stony Brook University": "Stony_Brook_University_seal",
  "Auctus Advisors": "auctus",
  "IIM Ahmedabad": "iimAhmedabad",
  Concentrix: "concentrix",
  "Tiktok Pvt. Ltd.": "titktok",
  "Not Applicable": "default",
  "Linecraft AI": "linecraft",
  "TE Connectivity": "TeConnectivity",
  "BNY Mellon": "bnyMellon",
  "American Express": "americanExpress",
  "American express": "americanExpress",
  Signzy: "signzy",
  Intract: "interact",
  Pilgrim: "pilgrim",
  "Sigmoid- Astar Data LLP": "sigmoid",
  "Morgan Stanley": "morganStanley",
  "Analog Devices": "analogdevices",
  "Analog Devices ": "analogdevices",
  Flipkart: "flipkart",
  "SJ Engineers & V": "sjec",
  "Novartis Healthcare Pvt ltd": "novartis",
  "JP Morgan Chase & Co": "jpmc",
  Equifax: "equifax",
  Fidelity: "fidelityInvestments",
  "Fidelity Investments ":"fidelityInvestments",
  "Fidelity Investments Ltd":"fidelityInvestments",
  "Microsoft Pvt Ltd": "microsoft",
  Mindtree: "mindtree",
  "Siemens Technology and Services": "siemens",
  "ICFAI University": "icfaiUniversity",
  Qualcomm: "qualcomm",
  "Vedanta Limited": "vedanta",
  "Spyne.ai": "spyne",
  "Exawizards India LLP": "exawizards",
  "Centre of Science for Student Learning": "cssl",
  Google: "google",
  "Future Tech IT Global Services Pvt Ltd": "futureTech",
  "Materials Info consultancy private Limited": "materialsInfo",
  AdMAVIN: "admavin",
  "Dr MGR Educational and Research Institute( Deemed University)":
    "mgrInstitute",
  "Truechip Solutions Private Limited": "truechip",
  RxLogix: "rxLogicx",
  "American Express GBT": "american_express",
  "GMG, Dubai": "gmg",
  "SNC-LAVALIN": "sncLavalin",
  "Flipkart India Pvt. Ltd": "flipkart",
  "Fidelity International Limited": "fidelityInvestments",
  SAIL: "sail",
  NewDay: "newday",
  Wabtec: "wabtec",
  "ZS Associates": "zs",
  "ZS ASSOCIATES ": "zs",
  Intel: "intel",
  "Start Up": "default",
  Boeing: "boeingLogo",
  "Urban Research Council, New York": "urc",
  Cisco: "cisco",
  Meesho: "meesho",
  "Wells Fargo": "wellsFargo",
  "IIT Delhi": "iitd",
  "JPMorgan Chase & Co": "jpmc",
  "Infra Market": "inframarket",
  "JP Morgan Chase": "jpmc",
  Signdesk: "signdesk",
  "Microsoft Research": "microsoft",
  Fujitsu: "fujitsu",
  RupeeZen: "rupeezen",
  "Hindustan Unilever": "hul",
  Unacademy: "unacademy",
  SafexPay: "safexpay",
  Ford: "ford",
  "Protiviti ": "protiviti",
  "OpenAI": "openai",
  "Vedanta Limited ": "vedanta",
  "TE Connectivity ": "tec",
  "Mastercard Advisors":"mastercard",
  "Mastercard":"mastercard",
  "Jaguar Land Rover":"JLR",
  "IBM":"ibm",
  "IBM India Private Limited": "ibm",
  "Bain & Company":"bain",
  "McKinsey & Company" :"mckinsey",
  "Nomura":"nomura",
  "Barclays Bank" : "barclays",
  "Barclays Investment Bank" : "barclays",
  "Standard Chartered":"standard_chartered",
  "Standard Chartered Bank" : "standard_chartered", 
  "Directi":"directi", 
  "Ernst and Young LLP":"ey",
  "PayPal" : "paypal",
  "Paypal" : "paypal",
  "Adobe":"adobe",
  "Walmart":"walmart",
  "Ola":"ola",
  "OLA Cabs":"ola",
  "OLA ANI":"ola",
  "Ola Electric":"ola",
  "OLA":"ola",
  "Uber":"uber",
  "Myntra":"myntra",
  "Nykaa":"nykaa",
  "ShareChat":"sharechat",
  "Caterpillar":"caterpillar",
  "Caterpillar ":"caterpillar",
  "Caterpillar India":"caterpillar",
  "Coca Cola, Japan":"cola",
  "Tooliqa":"tooliqa",
  "Xpressbees logistic ltd":"xpress",
  "HCL Tech":"hcl",
  "Indian Oil Corporation Limited":"iocl",
  "Jio Platforms Ltd":"jio",
  "Jio Platforms Limited":"jio",
  "Jio AI CoE":"jio",
  "DePaul University":"depaul",
  "Helix Opportunities":"helix",
  "Amdocs":"amdocs",
  "Raj Associates ":"raj",
  "InnovationM Web & Mobile Technologies":"innovation",
  "Tavant Technologies":"tavant",
  "Techknomatic ":"Techknomatic",
  "CoinDCX ":"coindcx",
  "Zenon":"zenon",
  "ynos":"ynos",
  "YNOS Venture Engine":"ynos",
  "Valuelabs LLP":"valuelabs",
  "MRF Ltd":"mrf",
  "MRF Tyres":"mrf",
  "Xeality IT Technologies Pvt Ltd":"xeality",
  "IIM Ahmedabad ":"iimAhmedabad",
  "Robert Bosch" : "robert",
  "Hilabs":"hilabs",
  "L&T Construction":"l&t",
  "Danaher global":"danaher",
  "Target Corporation ":"target",
  "The University of Queensland, Australia-Indian Institute of Technology, Delhi":"uqiitd",
  "Sabre Airlines Solutions":"sabre",
  "Procter & Gamble":"proctor",
  "AltWorld":"altworld",
  "Kempen Capital Management":"kempen",
  "Quantum IT Innovation ":"quantum",
  "Synopsys":"synopsys",
  "Trika Technologies":"trika",
  "Dr. Reddy's Laboratories ":"reddy",
  "Tikona Capital":"tikona",
  "Getsetyo":"getsetyo",
  "Dechcept Private Limited":"dechcept",
  "AB InBev":"abinbev",
  "STL Technology":"stl",
  "Micron ":"micron",
  "Explorex":"explorex",
  "Ksolves India Limited ":"ksolves",
  "Dhurina Ventures Private Limited" :"dhurina",
  "Skit.ai":"skit",
  "Tata Motors Ltd":"tatamotors",
  "John Deere":"johndeere",
  "OkCredit":"okcredit",
  "Vyapar":"vyapar",
  "CNH industrial India Pvt Ltd":"cnh",
  "SNC-LAVALIN":"snc",
  "ORMAE":"ormae",
  "Indifi Technologies Pvt Ltd":"indifi",
  "Enphase Energy":"enphase",
  "Aspire Fintech":"aspire",
  "Maruti Suzuki India Ltd.":"maruti",
};
export const getCompanyLogo = (currCompany) => {
  // console.log('---------------------------')
  // console.log(currCompany, companiesData[currCompany])
  // console.log('---------------------------')
  if (!companiesData[currCompany]) {
    return [`/images/interwiu_logo.png`];
  }
  const arr = [`/images/company_logo/${companiesData[currCompany]}.png`,
  `/images/company_logo/${companiesData[currCompany]}.svg`,
  `/images/company_logo/${companiesData[currCompany]}.jfif`,
  `/images/company_logo/${companiesData[currCompany]}.jpeg`,
  `/images/company_logo/default.svg`]
  // const arr = [
  //   `/images/interwiu_logo.png`,
  //   `/images/interwiu_logo.png`,
  //   `/images/interwiu_logo.png`,
  //   `/images/interwiu_logo.png`,
  //   `/images/interwiu_logo.png`,
  // ];
  return arr;
};
export const compare = (a, b) => {
  if (
    moment(
      a.date + " " + t24HDecimalTo12HFormat(a.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.date + " " + t24HDecimalTo12HFormat(b.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) > 0
  ) {
    return 1;
  }
  if (
    moment(
      a.date + " " + t24HDecimalTo12HFormat(a.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.date + " " + t24HDecimalTo12HFormat(b.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) < 0
  ) {
    return -1;
  }
  return 0;
};
export const compareReverse = (a, b) => {
  if (
    moment(
      a.date + " " + t24HDecimalTo12HFormat(a.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.date + " " + t24HDecimalTo12HFormat(b.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) > 0
  ) {
    return 1;
  }
  if (
    moment(
      a.date + " " + t24HDecimalTo12HFormat(a.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.date + " " + t24HDecimalTo12HFormat(b.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) < 0
  ) {
    return -1;
  }
  return 0;
};
export const compareToTime = (a, b) => {
  if (
    moment(
      a.result.date + " " + t24HDecimalTo12HFormat(a.result.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.result.date + " " + t24HDecimalTo12HFormat(b.result.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) > 0
  ) {
    return 1;
  }
  if (
    moment(
      a.result.date + " " + t24HDecimalTo12HFormat(a.result.toTime),
      "DD/MM/YYYY hh:mm a"
    ).diff(
      moment(
        b.result.date + " " + t24HDecimalTo12HFormat(b.result.toTime),
        "DD/MM/YYYY hh:mm a"
      ),
      "seconds"
    ) < 0
  ) {
    return -1;
  }
  return 0;
};

export const isIIT = (IIT) => {
  if (
    IIT === "IIT (ISM) Dhanbad" ||
    IIT === "IIT Bhilai" ||
    IIT === "IIT BHU" ||
    IIT === "IIT Bhubaneswar" ||
    IIT === "IIT Bombay" ||
    IIT === "IIT Delhi" ||
    IIT === "IIT Dharwad" ||
    IIT === "IIT Gandhinagar" ||
    IIT === "IIT Goa" ||
    IIT === "IIT Guwahati" ||
    IIT === "IIT Hyderabad" ||
    IIT === "IIT Indore" ||
    IIT === "IIT Jammu" ||
    IIT === "IIT Jodhpur" ||
    IIT === "IIT Kanpur" ||
    IIT === "IIT Kharagpur" ||
    IIT === "IIT Madras" ||
    IIT === "IIT Mandi" ||
    IIT === "IIT Palakkad" ||
    IIT === "IIT Patna" ||
    IIT === "IIT Roorkee" ||
    IIT === "IIT Ropar" ||
    IIT === "IIT Tirupati"
  ) {
    return true;
  } else {
    return false;
  }
};
export const Specialization = {
  "Aerospace Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Applied Mechanics": ["Option 1", "Option 2", "Option 3"],
  Biotechnology: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
  "Chemical Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  Chemistry: ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Civil Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Computer Science and Engineering": [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ],
  "Electrical Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Engineering Design": ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Humanities and Social Sciences": [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ],
  "Management Studies": ["Option 1", "Option 2", "Option 3", "Option 4"],
  Mathematics: ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Mechanical Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  "Metallurgical and Materials Engineering": [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ],
  "Ocean Engineering": ["Option 1", "Option 2", "Option 3", "Option 4"],
  Physics: ["Option 1", "Option 2", "Option 3", "Option 4"],
};