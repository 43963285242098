import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import moment from "moment"; // Getting day from date and modifying date format
import { Calendar } from "react-multi-date-picker";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Draggable from "react-draggable";
import Paper, { PaperProps } from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { AiOutlineCloseCircle, AiTwotoneCalendar } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { InfoOutlined } from "@mui/icons-material";
import { t12HTo24HDecimalFormat, t24HDecimalTo12HFormat } from "utility/helper";
import { ar } from "date-fns/locale";
import { connectProps } from "@devexpress/dx-react-core";

const TimePreference = ({ timeRef, gnrcSchdule, cstmSchdule }) => {
  const [calenderDate, setCalenderDate] = useState(new Date());
  function PaperComponent(props) {
    return (
      <>
        {
          window.innerWidth >= 600 ? (
            <Draggable
              className="s-desktop-card"
              handle="#draggable-dialog-title"
              cancel={'[class*="MuiDialogContent-root"]'}
            >
              <Paper {...props} />
            </Draggable>
          ) : (
            <Paper {...props} />
          )
        }
      </>
    );
  }

  const FAQSContainer = tw.dl` max-w-4xl relative`;
  const FAQ = tw.div`cursor-pointer mt-5 px-8  sm: rounded-lg text-gray-800 hover:text-gray-900 bg-[#F8F8F8] transition duration-300`;
  const Question = tw.dt`flex justify-between items-center`;
  const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
  const QuestionToggleIcon = motion(styled.span`
    ${tw`ml-2 transition duration-300`}
    svg {
      ${tw`w-6 h-6`}
    }
  `);
  const Answer = motion(tw.dd`text-sm sm:text-base leading-relaxed`);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);

    // console.log(activeQuestionIndex);
  };
  // ADD TIME MODAL
  // ------- Edit Slot ---------------
  const [isItEditing, setIsItEditing] = useState(false); // "isItEditing" keeps track of whether we are editing the already entered schedule for both generic and exception schedule

  const [
    disableDaysWhileEditingGenericSlot,
    setDisableDaysWhileEditingGenericSlot,
  ] = useState({
    // To disable days that cannot be edit because they don't follow the edit rules
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  // ---- Model Settings for Generi Schedule Popup ----
  const [openGenericSchedulePopup, setOpenGenericSchedulePopup] =
    useState(false);
  const [checkedDaysGenericSchedule, setCheckedDaysGenericSchedule] = useState({
    // Store checked days while adding generic schedule
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  const [allDaysCheckedGenericSchedule, setAllDaysCheckedGenericSchedule] =
    useState(false); // To check and uncheck Select All
  useEffect(() => {
    // Handle Select All
    Object.values(checkedDaysGenericSchedule).filter(d => d).length === 7
      ? setAllDaysCheckedGenericSchedule(true)
      : setAllDaysCheckedGenericSchedule(false);
  }, [checkedDaysGenericSchedule]);
  const [time, setTime] = useState({
    // Stores the from time datas from popup
    hrs: "12",
    min: "00",
    merd: "AM",
  });
  const [slots, setSlots] = useState(0); // No of slot that are selected through popup
  const handleSlotsChange = (event) => {
    // Handling slot change event
    setSlots(parseInt(event.target.value));
  };
  const handleOpenGenericSchedulePopup = () => {
    setOpenGenericSchedulePopup(true);
  };
  const handleCloseGenericSchedulePopup = () => {
    setOpenGenericSchedulePopup(false);
    setIsItEditing(false);
    setDisableDaysWhileEditingGenericSlot({
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    });
    setCheckedDaysGenericSchedule({
      ...checkedDaysGenericSchedule,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    });
    setAllDaysCheckedGenericSchedule(false);
    setTime({
      hrs: "12",
      min: "00",
      merd: "AM",
    });
    setSlots(0);
  };

  // --- Handling Popup for "Clear All" Button while entering generic schedule ---
  const [openClearGenericSchedulePopup, setOpenClearGenericSchedulePopup] =
    useState(false);
  const handleClearGenericSchedulePopupOpen = () => {
    setOpenClearGenericSchedulePopup(true);
  };
  const handleClearGenericSchedulePopupClose = () => {
    setOpenClearGenericSchedulePopup(false);
  };

  const dayLookup = {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday"
  }
  const [localGeneric, setLocalGeneric] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: []
  })

  //# custom schedule states
  const [datePickerMobile, setDatePickerMobile] = useState(false)
  const [green, setGreen] = useState([]);
  const [yellow, setYellow] = useState([]);
  const [red, setRed] = useState([]);

  const [dateStatus, setDateStatus] = useState({})

  const [selectedDateBoolArr, setSelectedDateBoolArr] = useState([]);
  const [selectedDateSlotsArr, setSelectedDateSlotsArr] = useState([]);
  const [excpDatesBoolArr, setExcpDatesBoolArr] = useState([]);
  const [excpDatesSlotsArr, setExcpDatesSlotsArr] = useState([]);
  const [localCustom, setLocalCustom] = useState({})
  const [revised, setRevised] = useState([])

  //# custom schedule effects



  useEffect(() => {
    let revModified = []
    Object.entries(dateStatus)
      .forEach((e) => {
        if (["yellow", "red"].includes(e[1])) {
          revModified.push({
            date: e[0],
            sessions: localCustom[e[0]] || []
          })
        }
      })
    let uniqueSet = new Set(revModified.map(JSON.stringify))
    let finalRev = Array.from(uniqueSet).map(JSON.parse)
    setRevised(finalRev)
    // console.log(finalRev)
  }, [dateStatus, localCustom])

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("interwiu.com"));
    const modifiedGeneric = Object.entries(localGeneric).map((e) => {
      return {
        day: e[0],
        sessions: e[1]
      }
    })
    const newLocalData = {
      ...localData,
      gnrcSchdule: modifiedGeneric
    };
    localStorage.setItem("interwiu.com", JSON.stringify(newLocalData));
  }, [localGeneric]);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("interwiu.com"));
    // const modifiedCustom = Object.entries(localCustom).map((e) => {
    //   return {
    //     date: e[0],
    //     sessions: e[1]
    //   }
    // })
    const newLocalData = {
      ...localData,
      cstmSchdule: revised ?? []
    };
    // console.log(revised)
    localStorage.setItem("interwiu.com", JSON.stringify(newLocalData));
  }, [revised]);

  const [editGenData, setEditGenData] = useState(null)

  const timeStates = useMemo(() => {
    let weekOfSess = [].concat(...Object.values(localGeneric))
    let timeStates = []
    weekOfSess.forEach((sess, i) => {
      timeStates.push(sess.from)
      timeStates.push(sess.to)
    })
    return timeStates
  }, [...[...Object.values(localGeneric)].map((day) => day.length), editGenData])

  useEffect(() => {
    let modifiedCst = structuredClone(localCustom)
    let overlapDates = []
    Object.entries(modifiedCst)
      .forEach((dateEntry, i) => {
        if (dateEntry[1]?.length > 0) {
          let overlapMerged = mergeOverlapCust(dateEntry[1], dateEntry[0])
          // console.log(overlapMerged)
          if (overlapMerged) {
            modifiedCst[dateEntry[0]] = overlapMerged
            // updateDateStatus(dateEntry[0], overlapMerged)
            overlapDates.push(dateEntry[0])
          }
        }
      })

    Object.keys(modifiedCst)
      .forEach((dt, i) => {
        let prevDate = moment(dt, "DD/MM/YYYY").subtract(1, "day").format("DD/MM/YYYY")
        let nextDate = moment(dt, "DD/MM/YYYY").add(1, "day").format("DD/MM/YYYY")
        let prevDayLastSess = modifiedCst[prevDate]?.at(-1)
        let nextDayFirstSess = modifiedCst[nextDate]?.at(0)
        let sessDuration;
        let hasNext = false
        let hasPrev = false
        modifiedCst[dt] = modifiedCst[dt].map((sess) => {
          let oldSessDuration = sess.to - sess.from
          if (sess.from === 0 && prevDayLastSess && prevDayLastSess?.to === 24) {
            hasPrev = true
            sessDuration = 24 - prevDayLastSess.from + sess.to - 0
            console.log("hasPrev", sessDuration, dt)
          } else if (sess.to === 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
            hasNext = true
            sessDuration = 24 - sess.from + nextDayFirstSess.to - 0 
            console.log("hasNext", sessDuration, dt)
          } else {
            sessDuration = sess.to - sess.from
          }
          if (sessDuration % 0.75 !== 0) {
            console.log("bad ses")
            if (hasNext) {
              modifiedCst[nextDate].shift()
              modifiedCst[nextDate].unshift({
                from: 0,
                to: nextDayFirstSess.to + getSessIncrement(sessDuration)
              })
              updateDateStatus(nextDate, modifiedCst[nextDate])
            } else
            if (hasPrev) {
              sess.to += getSessIncrement(sessDuration)
            } else {
              sess.to += getSessIncrement(sessDuration)
              if (sess.to > 24) {
                if(!modifiedCst[nextDate])
                  modifiedCst[nextDate] = []
                modifiedCst[nextDate].unshift({from: 0, to: sess.to - 24})
                modifiedCst[nextDate] = mergeOverlapCust(modifiedCst[nextDate], nextDate)
                sess.to = 24
                updateDateStatus(nextDate, modifiedCst[nextDate])
              }
            }
          }
          let finalSessDuration = sess.to - sess.from
          if (oldSessDuration - finalSessDuration >= 0.75 && sess.to > 0.75)
            sess.to -= 0.75
          return sess
        })
      })

    // updateDateStatus(overlapDates, overlapDates.map((d) => modifiedCst[d]), "batch")
    setLocalCustom(modifiedCst)
    // Object.keys(localCustom).forEach((date, i) => {
    //   if (isDayEqual(localCustom[date], localGeneric[getDayFromDate(date)])) {
    //     setLocalCustom({
    //       [date]: []
    //     })
    //   }
    // })
  }, [calenderDate, ...Object.values(localCustom).map((arr => arr?.length)), editGenData])

  const [currDate, setCurrDate] = useState(moment(new Date()).format("DD/MM/YYYY"))




  useEffect(() => {
    let date = moment(new Date(calenderDate)).format("DD/MM/YYYY")
    // setLocalCustom((prev) => {
    //   if (!prev[date] || prev[date]?.length === 0)
    //     prev[date] = localGeneric[getDayFromDate(date)]
    //   return prev;
    // })
    if (calenderDate && date) {
      initCustom(date)
    }
    setCurrDate(date || moment(new Date()).format("DD/MM/YYYY"))
  }, [calenderDate, localGeneric])

  const currMonth = useMemo(() => {
    return moment(calenderDate).get('month')
  }, [calenderDate])

  // let count = 0
  // useEffect(() => {


  //   if (currDate)
  //     if (moment(currDate, "DD/MM/YYYY").get('month') === currMonth && count > 0) {
  //       return;
  //     }
  //   initCustom(currDate)
  //   // console.log("render")
  //   return () => {
  //     count += 1
  //   }
  // }, [currDate, ...timeStates])






  //# custom schedule functions
  function mergeOverlapCust(sessions, dt) {
    const sortedSessions = sessions?.sort((a, b) => a.from - b.from); // Sort sessions by the 'from' value

    let isOverlapping = false;
    let merged = sortedSessions?.reduce((prev, curr) => {
      // console.log("prev", prev, "curr", curr)
      let prevDate = moment(dt, "DD/MM/YYYY").subtract(1, "day").format("DD/MM/YYYY")
      let prevDayLastSess = localCustom[prevDate]?.at(-1)
      let prevSess = prev[prev.length - 1]
      let newSess, oldSess;

      if (prevSess) {
        // console.log(prevSess.to, curr.from)
        if (prevSess.to >= curr.from) {
          isOverlapping = true
          // oldSess which is overlapping with next sess is removed, curr is not pushed instead a 
          // merged newSess is pushed
          oldSess = prev.pop()

          newSess = {
            from: oldSess.from,
            to: curr.to
          }

          if (curr.from >= prevSess.from) {
            newSess.to = Math.max(curr.to, prevSess.to)
          }

          // let sessDuration;
          // // if prev session is part of last session of previous day, check if any overlap with next session and merge
          // // accordingly if new sess can be split into equal 0.75 slots or not (if not add 15 or 30 min to make it so)
          // if (prevSess.from === 0 && prevDayLastSess && prevDayLastSess.to === 24) {
          //   sessDuration = prevDayLastSess.to - prevDayLastSess.from + curr.to - 0
          //   // setLocalCustom((prev) => {
          //   //   if(!prev[dt]){
          //   //     prev[dt] = []
          //   //   }
          //   //   prev[dt].push(...localGeneric[getDayFromDate(dt)])
          //   // })
          // } else {
          //   sessDuration = (newSess.to - newSess.from)
          // }
          // if (((sessDuration) % 0.75 !== 0)) {
          //   newSess.to += (1 / 60) * Math.round(45 * (1 - (sessDuration) / 0.75 + Math.floor((sessDuration) / 0.75)))
          // }
        }
      }

      if (isOverlapping)
        prev.push(newSess)
      else
        prev.push(curr)
      isOverlapping = false;
      return prev
    }, [])
    // console.log(merged)
    // updateDateStatus(dt, merged)
    return merged
  }

  function getDayFromDate(dt) {

    return Object.keys(dayLookup).at(moment(dt, "DD/MM/YYYY").day() - 1)
  }


  const handleAddCust = (dt, t, nSlots, mode = "", toModify = null) => {
    // let dt = moment(date).format("DD/MM/YYYY")
    console.log("add", dt, t, nSlots)
    let custFrom = t12HTo24HDecimalFormat(`${t.hrs}:${t.min} ${t.merd}`)
    let custTo = custFrom + nSlots * 0.75
    let nextDayPart = null;
    if (custTo === 24) {
      custTo = t12HTo24HDecimalFormat(`23:59 PM`)
    } else if (custTo > 24) {
      nextDayPart = {
        from: 0,
        to: custTo - 24,
      }
      custTo = t12HTo24HDecimalFormat(`23:59 PM`)
    }
    let modified;
    if (!toModify && mode === "")
      modified = structuredClone(localCustom)
    else
      modified = toModify
    if (!modified[dt]) {
      modified[dt] = []
    }
    modified[dt] = [...modified[dt], { from: custFrom, to: custTo }]
    modified[dt] = sortDay(modified[dt])
    console.log(modified[dt])
    let nextDate = moment(dt, "DD/MM/YYYY")
      .add(1, "days")
      .format("DD/MM/YYYY");
    if (nextDayPart) {

      console.log("nextDate", nextDate, nextDayPart)
      if (!modified[nextDate])
        modified[nextDate] = []
      modified[nextDate] = [nextDayPart, ...modified[nextDate]]
      console.log(modified[nextDate])
      modified[nextDate] = sortDay(modified[nextDate])
    }

    if (mode !== "delete")
      if (nextDayPart)
        updateDateStatus([dt, nextDate], [modified[dt], modified[nextDate]], "batch")
      else {
        updateDateStatus(dt, modified[dt])
      }

    if (toModify && mode !== "")
      return modified
    if (mode === "")
      setLocalCustom(modified)
  }

  const isDayEqual = (d1, d2) => {
    if (!(d1 && d2))
      return false
    if (d1?.length !== d2?.length)
      return false
    return d2?.length === d1?.filter((sess, i) => {
      return sess.from === d2[i].from && sess.to === d2[i].to
    }).length
  }


  const updateDateStatus = (date, arr, option = "") => {
    let status = { ...dateStatus }
    let genArr = localGeneric[getDayFromDate(date)]
    let color;
    console.log("update", genArr)

    if (option === "batch") {
      arr.forEach((day) => {
        date.forEach((d) => {
          let mod = mergeOverlapCust(day, d)
          if (isDayEqual(genArr, mod)) {
            if (mod.length !== 0)
              color = "green"
            else
              color = "white"
          } else {
            if (mod.length === 0) {
              color = "red"
            } else {
              color = "yellow"
            }
          }
          status[d] = color;
          console.log(color)
          console.log(d, day)
        })
      })
    }
    // console.log(typeof arr, Array.isArray(date))
    if (option === "")
      if (typeof arr === 'string') {
        if (Array.isArray(date)) {
          status = date.reduce((prev, curr) => {
            prev[curr] = arr
            return prev
          }, { ...dateStatus })
        } else {
          status[date] = arr
        }
      } else {
        if (Array.isArray(date)) {
          date.forEach((d) => {
            let mod = mergeOverlapCust(arr)
            if (isDayEqual(genArr, mod)) {
              if (mod.length !== 0)
                color = "green"
              else
                color = "white"
            } else {
              if (mod.length === 0) {
                color = "red"
              } else {
                color = "yellow"
              }
            }
            status[d] = color
            console.log(color)
          })
        } else {
          let mod = mergeOverlapCust(arr)
          console.log(isDayEqual(genArr, mod), genArr, mod)
          if (isDayEqual(genArr, mod)) {
            if (mod.length !== 0)
              color = "green"
            else
              color = "white"
          } else {
            if (mod.length === 0) {
              color = "red"
            } else {
              color = "yellow"
            }
          }
          status[date] = color
          console.log(color)
        }
        // console.log(date, arr)
        console.log("update status")
      }
    // console.log("stus", status)
    setDateStatus(status)
  }

  

  const handleDeleteCust = (cust) => {
    let sessDuration = null;

    let modified = structuredClone(localCustom)
    // console.log(moment(cust.date, 'DD/MM/YYYY').subtract(1, "day").format("DD/MM/YYYY"))
    let prevDate = moment(cust.date, 'DD/MM/YYYY').subtract(1, "day").format("DD/MM/YYYY")
    let nextDate = moment(cust.date, 'DD/MM/YYYY').add(1, "day").format("DD/MM/YYYY")
    let prevDayLastSess = localCustom[prevDate]?.at(-1)
    let nextDayFirstSess = localCustom[nextDate]?.at(0)
    let hasPrev = false
    let hasNext = false
    // console.log("Here", prevDayLastSess, nextDayFirstSess, cust.sess)
    let filtered = modified[cust.date].filter((sess) => sess.to !== cust.sess.to && sess.from !== cust.sess.from)
    if (cust.sess.from === 0 && prevDayLastSess && prevDayLastSess?.to === 24) {
      modified[cust.date] = filtered
      // updateDateStatus(cust.date, filtered)
      sessDuration = 24 - prevDayLastSess.from
      console.log(cust.sess, sessDuration)
      if (sessDuration % 0.75 !== 0) {
        hasPrev = true
        modified[prevDate].pop()
        // updateDateStatus(prevDate, modified[prevDate])
        let newPrevDaySess = {
          from: prevDayLastSess.from,
          to: prevDayLastSess.to + getSessIncrement(sessDuration)
        }
        let { time: t, slots: s } = convertSess(newPrevDaySess)
        console.log(t, s)
        modified = handleAddCust(prevDate, t, s, "delete", modified)
        // modified[prevDate] = mergeOverlapCust([...modified[prevDate], newPrevDaySess], prevDate)
      }
    } else if (cust.sess.to === 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
      modified[cust.date] = filtered
      // updateDateStatus(cust.date, modified[cust.date])
      sessDuration = nextDayFirstSess.to - 0
      if (sessDuration % 0.75 !== 0) {
        hasNext = true
        modified[nextDate].shift()
        // updateDateStatus(nextDate, modified[nextDate])
        let newNextDaySess = {
          from: nextDayFirstSess.from,
          to: nextDayFirstSess.to + getSessIncrement(sessDuration)
        }
        let { time: t, slots: s } = convertSess(newNextDaySess)
        console.log(t, s)
        modified = handleAddCust(nextDate, t, s, "delete", modified)
        // modified[nextDate] = mergeOverlapCust([newNextDaySess, ...modified[nextDate]], nextDate)
      }
    } else {
      modified[cust.date] = filtered
    }
    
      if (hasPrev) {
        updateDateStatus(prevDate, modified[prevDate])
      }
      if (hasNext) {
        updateDateStatus(nextDate, modified[nextDate])
      }
      // else if (hasPrev && hasNext) {
      //   updateDateStatus([cust.date, nextDate, prevDate], [filtered, modified[nextDate], modified[prevDate]], "batch")
      // } 
    
    updateDateStatus(cust.date, filtered)

    setLocalCustom(modified)
  }

  const handleEditCust = (editData, date, t, nSlots) => {
    // console.log("here", t, nSlots)

    // setDisableDaysWhileEditingGenericSlot(falseWeek)
    let sessDuration = null;
    let cust = editData
    let modified = structuredClone(localCustom)
    // console.log(moment(cust.date, 'DD/MM/YYYY').subtract(1, "day").format("DD/MM/YYYY"))
    let prevDate = moment(cust.date, 'DD/MM/YYYY').subtract(1, "day").format("DD/MM/YYYY")
    let nextDate = moment(cust.date, 'DD/MM/YYYY').add(1, "day").format("DD/MM/YYYY")
    let prevDayLastSess = localCustom[prevDate]?.at(-1)
    let nextDayFirstSess = localCustom[nextDate]?.at(0)
    let hasPrev = false
    let hasNext = false
    // console.log("Here", prevDayLastSess, nextDayFirstSess, cust.sess)
    let filtered = modified[cust.date].filter((sess) => sess.to !== cust.sess.to && sess.from !== cust.sess.from)
    if (cust.sess.from === 0 && prevDayLastSess && prevDayLastSess?.to === 24) {
      modified[cust.date] = filtered
      // updateDateStatus(cust.date, filtered)
      sessDuration = 24 - prevDayLastSess.from
      console.log(cust.sess, sessDuration)
      if (sessDuration % 0.75 !== 0) {
        hasPrev = true
        modified[prevDate].pop()
        // updateDateStatus(prevDate, modified[prevDate])
        let { time: t, slots: s } = convertSess({
          from: prevDayLastSess.from,
          to: prevDayLastSess.to + getSessIncrement(sessDuration)
        })
        modified = handleAddCust(prevDate, t, s, "delete", modified)
      }
    } else if (cust.sess.to === 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
      modified[cust.date] = filtered
      // updateDateStatus(cust.date, modified[cust.date])
      sessDuration = nextDayFirstSess.to - 0
      if (sessDuration % 0.75 !== 0) {
        hasNext = true
        modified[nextDate].shift()
        // updateDateStatus(nextDate, modified[nextDate])
        let { time: t, slots: s } = convertSess({
          from: nextDayFirstSess.from,
          to: nextDayFirstSess.to + getSessIncrement(sessDuration)
        })
        modified = handleAddCust(nextDate, t, s, "delete", modified)
      }
    } else {
      modified[cust.date] = filtered
    }
    // if(hasPrev || hasNext) {
    //   if (hasPrev) {
    //     updateDateStatus(prevDate, modified[prevDate], "batch")
    //   }
    //   if (hasNext) {
    //     updateDateStatus(nextDate, modified[nextDate]) 
    //   }
    // } 
    // handleDeleteCust(editData)
    // handleAddCust(date, t, nSlots)
    //\\//\\//\\
    // console.log("add", dt, t, nSlots)
    let dt = date
    let custFrom = t12HTo24HDecimalFormat(`${t.hrs}:${t.min} ${t.merd}`)
    let custTo = custFrom + nSlots * 0.75
    let nextDayPart = null;
    if (custTo === 24) {
      custTo = t12HTo24HDecimalFormat(`23:59 PM`)
    } else if (custTo > 24) {
      nextDayPart = {
        from: 0,
        to: custTo - 24,
      }
      custTo = t12HTo24HDecimalFormat(`23:59 PM`)
    }
    // let modified = structuredClone(localCustom)
    if (!modified[dt]) {
      modified[dt] = []
    }
    modified[dt] = [...modified[dt], { from: custFrom, to: custTo }]
    modified[dt] = sortDay(modified[dt])
    console.log(modified[dt])
    let nextDate1 = moment(dt, "DD/MM/YYYY")
      .add(1, "days")
      .format("DD/MM/YYYY");
    if (nextDayPart) {

      // console.log("nextDate1", nextDate1, nextDayPart)
      if (!modified[nextDate1])
        modified[nextDate1] = []
      modified[nextDate1] = [nextDayPart, ...modified[nextDate1]]
      console.log(modified[nextDate1])
      modified[nextDate1] = sortDay(modified[nextDate1])
    }

    modified[dt] = mergeOverlapCust(modified[dt], dt)
    if (nextDayPart)
      updateDateStatus(nextDate1, modified[nextDate1])
    updateDateStatus(dt, modified[dt])
    // if (option === "edit") {
    //   
    // }
    setLocalCustom(modified)
  }

  const handleDeleteAllCust = (dt) => {
    let sessDuration = null;
    let hasPrev = false
    let hasNext = false
    let modified = structuredClone(localCustom)
    if (!modified[dt] || modified[dt]?.length === 0)
      return
    let sessionsOfDate = [...modified[dt]]
    let prevDate = moment(dt, 'DD/MM/YYYY').subtract(1, "day").format("DD/MM/YYYY")
    let nextDate = moment(dt, 'DD/MM/YYYY').add(1, "day").format("DD/MM/YYYY")
    sessionsOfDate.forEach((sess) => {

      let prevDayLastSess = modified[prevDate]?.at(-1)
      let nextDayFirstSess = modified[nextDate]?.at(0)
      let filtered = modified[dt].filter((s) => s.to !== sess.to && s.from !== sess.from)
      if (sess.from === 0 && prevDayLastSess && prevDayLastSess?.to === 24) {

        modified[dt] = filtered
        // updateDateStatus(dt, filtered)
        sessDuration = 24 - prevDayLastSess.from
        if (sessDuration % 0.75 !== 0) {
          modified[prevDate].pop()
          hasPrev = true
          // updateDateStatus(prevDate, modified[prevDate])
          let { time: t, slots: s } = convertSess({
            from: prevDayLastSess.from,
            to: prevDayLastSess.to + getSessIncrement(sessDuration)
          })
          modified = handleAddCust(prevDate, t, s, "deleteAll", modified)
        }
      } else if (sess.to === 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
        modified[dt] = filtered
        // updateDateStatus(dt, modified[dt])
        sessDuration = nextDayFirstSess.to - 0
        if (sessDuration % 0.75 !== 0) {
          modified[nextDate].shift()
          hasNext = true
          // updateDateStatus(nextDate, modified[nextDate])
          let { time: t, slots: s } = convertSess({
            from: nextDayFirstSess.from,
            to: nextDayFirstSess.to + getSessIncrement(sessDuration)
          })
          modified = handleAddCust(nextDate, t, s, "deleteAll", modified)
        }
      } else {
        modified[dt] = filtered
      }
    })
    if (hasPrev || hasNext) {
      if (hasPrev) {
        updateDateStatus(prevDate, modified[prevDate])
      }
      if (hasNext) {
        updateDateStatus(nextDate, modified[nextDate])
      }
      // else if (hasPrev && hasNext) {
      //   updateDateStatus([cust.date, nextDate, prevDate], [filtered, modified[nextDate], modified[prevDate]], "batch")
      // } 
    }
    updateDateStatus(dt, modified[dt])
    setLocalCustom(modified)
  }

  const initCustom = (date, option = "") => {
    // const currentDate = moment();
    const startDate = moment(date, "DD/MM/YYYY").startOf('month').subtract(2, 'days');
    const endDate = moment(date, "DD/MM/YYYY").endOf('month').add(2, 'days');

    let dateIterator = moment(startDate)
    let dateArr = []
    let whiteArr = []
    let modified = structuredClone(localCustom)

    // if (option === "reset") {
    //   updateDateStatus(currDate, "green")
    //   modified[currDate] = localGeneric[getDayFromDate(currDate)]
    // }

    let status = {}

    while (dateIterator.isSameOrBefore(endDate)) {
      let formattedDate = dateIterator.format("DD/MM/YYYY")
      // if (!red.includes(formattedDate) && !yellow.includes(formattedDate)) {
      // console.log("hehe")
      let genArr = localGeneric[getDayFromDate(formattedDate)]
      // if(option==="")
      if (!["red", "yellow"].includes(dateStatus[formattedDate])) {
        // console.log(genArr, modified[formattedDate])
        // if (genArr.length > 0)
        modified[formattedDate] = genArr
        // else if (genArr.length === 0) {

        // updateDateStatus(formattedDate, modified[formattedDate] ?? [])

        // }
        // setLocalCustom((prev) => {
        //   prev[formattedDate] =  localGeneric[getDayFromDate(formattedDate)]
        //   // console.log(prev)
        //   return prev
        // })
        if (genArr.length > 0)
          // dateArr.push(formattedDate)
          status[formattedDate] = "green"
        else
          // whiteArr.push(formattedDate)
          status[formattedDate] = "white"
      } else {

        // if (red.includes(formattedDate)) {
        //   modified[formattedDate] = []
        // } else {
        //   modified[formattedDate] = revised.find((r) => r.date === formattedDate)?.sessions
        // }
        // if (option === "reset")
        //   if(genArr.length > 0)
        //   dateArr.push(formattedDate)
      }
      if (option === "reset") {
        if (genArr.length > 0) {
          // dateArr.push(formattedDate)
          status[formattedDate] = "green"
        } else {
          // whiteArr.push(formattedDate)
          status[formattedDate] = "white"
        }
        modified[formattedDate] = genArr
      }
      dateIterator.add(1, 'day');
    }
    // updateDateStatus(dateArr, "green")
    // updateDateStatus(whiteArr, "white")
    if (option === "")
      setDateStatus({
        ...dateStatus,
        ...status
      })
    if (option === "reset") {
      // updateDateStatus(dateArr, "green")

      // setDateStatus((prev) => {
      //   Object.keys(prev).forEach((d) => {
      //     if (!["green", "white"].includes(prev[d]))
      //       delete prev[d]
      //   })
      //   return prev
      // })
      setDateStatus(status)
      setRevised((prev) => {
        return []
      })
    }
    // console.log(green)
    setLocalCustom(modified)
  }


  const sortDay = (daySess) => {
    return daySess.sort((a, b) => a.from - b.from)
  }

  const handleAddGen = (t, nSlots, checkedArr) => {
    // console.log("AddGen", checkedArr, t, nSlots)
    let days = Object.keys(dayLookup)
    let genFrom = t12HTo24HDecimalFormat(`${t.hrs}:${t.min} ${t.merd}`)
    let genTo = genFrom + nSlots * 0.75
    let nextDayPart = null;
    if (genTo === 24) {
      genTo = t12HTo24HDecimalFormat(`23:59 PM`)
    } else if (genTo > 24) {
      nextDayPart = {
        from: 0,
        to: genTo - 24,
      }
      genTo = t12HTo24HDecimalFormat(`23:59 PM`)
    }
    // console.log(localGeneric, genFrom, genTo, "before", days)
    let final = {}
    days.forEach((d, i) => {
      if (checkedArr[d]) {
        // console.log("here")
        setLocalGeneric((prev) => {
          // console.log(prev)
          // console.log(prev, genFrom, genTo)
          prev[d]
            .push({
              from: genFrom,
              to: genTo,
            })
          prev[d] = sortDay(prev[d])
          return prev;
        })
        if (nextDayPart) {
          
          let nextDay = days[(i % 7 + 1) % 7]
          let converted = convertSess(nextDayPart)
          handleAddGen(converted.time, converted.slots, {
            ...falseWeek,
            [nextDay]: true
          })
          //prev[days[(i % 7 + 1) % 7]] = sortDay(prev[days[(i % 7 + 1) % 7]])
          
        }
        // final[d] = mergeOverlappingSessions(localGeneric[d].slice())
      }
    })

    // setLocalGeneric((prev) => {
    //   console.log(prev)
    //     return {
    //       ...prev,
    //       ...final
    //     }
    // })


    // console.log("after", localGeneric, genFrom, genTo)
    // setEditGenData(null)
  }



  const convertSess = (sess) => {
    let time12From = t24HDecimalTo12HFormat(sess.from)
    let slots = (sess.to - sess.from) / 0.75
    console.log(slots, time12From)
    let time = {
      hrs: time12From.slice(0, 2),
      min: time12From.slice(3, 5),
      merd: time12From.slice(6, 8),
    }
    return { time, slots }
  }

  const getSessIncrement = (sessDuration) => {
    return (1 / 60) * Math.round(45 * (1 - (sessDuration) / 0.75 + Math.floor((sessDuration) / 0.75)))
  }



  const handleDeleteGen = (gen) => {
    // console.log(gen)
    let sessDuration = null;
    let sess1, sess2;
    let prevDay = Object.keys(dayLookup).at(Object.keys(dayLookup).indexOf(gen.day) - 1)
    let nextDay = Object.keys(dayLookup).at((Object.keys(dayLookup).indexOf(gen.day) + 1) % 7)
    console.log(prevDay, nextDay)
    let prevDayLastSess = localGeneric[prevDay]?.at(-1)
    let nextDayFirstSess = localGeneric[nextDay]?.at(0)
    console.log("Deleting", prevDayLastSess, nextDayFirstSess, gen.sess)
    if (gen.sess.from === 0 && prevDayLastSess && prevDayLastSess?.to >= 24) {

      setLocalGeneric({
        ...localGeneric,
        [gen.day]: localGeneric[gen.day].filter(sess => !(sess.to === gen.sess.to && sess.from === gen.sess.from))
      })
      sessDuration = 24 - prevDayLastSess.from
      console.log(gen.sess, sessDuration)
      if (sessDuration % 0.75 !== 0) {
        setLocalGeneric((prev) => {
          sess1 = prev[prevDay].pop()
          return prev
        })
        let { time: t, slots: s } = convertSess({
          from: prevDayLastSess.from,
          to: prevDayLastSess.to + getSessIncrement(sessDuration)
        })
        handleAddGen(t, s, {
          ...falseWeek,
          [prevDay]: true
        })
      }
    } else if (gen.sess.to >= 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
      setLocalGeneric({
        ...localGeneric,
        [gen.day]: localGeneric[gen.day].filter(sess => !(sess.to === gen.sess.to && sess.from === gen.sess.from))
      })
      sessDuration = nextDayFirstSess.to - 0
      if (sessDuration % 0.75 !== 0) {
        setLocalGeneric((prev) => {
          sess2 = prev[nextDay].shift()
          return prev
        })
        let { time: t, slots: s } = convertSess({
          from: nextDayFirstSess.from,
          to: nextDayFirstSess.to + getSessIncrement(sessDuration)
        })
        handleAddGen(t, s, {
          ...falseWeek,
          [nextDay]: true
        })
      }
    } else {
      setLocalGeneric({
        ...localGeneric,
        [gen.day]: localGeneric[gen.day].filter(sess => !(sess.to === gen.sess.to && sess.from === gen.sess.from))
      })
    }
    let dates = Object.keys(dateStatus).filter((dt) => getDayFromDate(dt) === gen.day && dateStatus[dt] !== "yellow")
    updateDateStatus(dates, "white")
    
  }

  let falseWeek = Object.keys(dayLookup).reduce((prev, curr) => {
    prev[curr] = false
    return prev
  }, {})

  const handleEditGen = (editGenData, t, nSlots) => {
    // console.log("here", t, nSlots)
    let gen = editGenData
    handleDeleteGen(editGenData)
    setDisableDaysWhileEditingGenericSlot(falseWeek)
    handleAddGen(t, nSlots, {
      ...falseWeek,
      [gen.day]: true
    })
    setEditGenData(null)
  }

  function mergeOverlap(sessions, d) {
    const sortedSessions = sessions?.sort((a, b) => a.from - b.from); // Sort sessions by the 'from' value

    let isOverlapping = false;
    let toNextDay;
    let merged = sortedSessions?.reduce((prev, curr) => {
      // console.log("prev", prev, "curr", curr)
      let prevDayLastSess = localGeneric[Object.keys(dayLookup).at(Object.keys(dayLookup).indexOf(d) - 1)].at(-1)
      let prevSess = prev[prev.length - 1]
      let newSess, oldSess;

      if (prevSess) {
        if (prevSess.to >= curr.from) {
          isOverlapping = true
          // oldSess which is overlapping with next sess is removed, curr is not pushed instead a 
          // merged newSess is pushed
          oldSess = prev.pop()
          newSess = {
            from: oldSess.from,
            to: curr.to
          }

          if (curr.from >= prevSess.from) {
            newSess.to = Math.max(curr.to, prevSess.to)
          }

          // let sessDuration;
          // // if prev session is part of last session of previous day, check if any overlap with next session and merge
          // // accordingly if new sess can be split into equal 0.75 slots or not (if not add 15 or 30 min to make it so)
          // // if (prevSess.from === 0 && prevDayLastSess && prevDayLastSess.to === 24) {
          // //   sessDuration = prevDayLastSess.to - prevDayLastSess.from + curr.to - 0
          // // } else {
          //   sessDuration = (newSess.to - newSess.from)
          // // }
          // if (((sessDuration) % 0.75 !== 0)) {
          //   newSess.to += getSessIncrement(sessDuration)
          // }
        }
      }

      let final
      if (isOverlapping)
        final = newSess
      else
        final = curr
      
      
      // if (final.to > 24) {
      //   toNextDay = {
      //     from: 0,
      //     to: final.to - 24
      //   }
      //   final.to = 24
      // }
      prev.push(final)
      
      
      isOverlapping = false;
      return prev
    }, [])

    // console.log(merged)
    return {merged, toNextDay}
  }

  // useEffect(() => {
  //   console.log(localGeneric[calenderDate])
  // }, localGeneric, calenderDate)
  // const localGenericMemo = useMemo(() => {
  //   return localGeneric
  // }, [isItEditing, openGenericSchedulePopup])






  useEffect(() => {
    // if (!editGenData)
    let days = Object.keys(dayLookup)
    if (Object.values(localGeneric).filter(dayArr => dayArr.length > 0).length > 0) {
      let modified = structuredClone(localGeneric)
      days.forEach((d, i) => {
        let {merged: overlapMerged, toNextDay} = mergeOverlap(modified[d], d)
        console.log(overlapMerged, toNextDay)
        if (overlapMerged)
          modified[d] = overlapMerged
        if (toNextDay) {
          let nextDay = days[(i+1)%7]
          modified[nextDay].unshift(toNextDay)
        }
      })
      
      days.forEach((d, i) => {
        let prevDay = days[i-1]
        let prevDayLastSess;
        if (prevDay)
          prevDayLastSess = modified[prevDay]?.at(-1)
        let nextDay = days[(i % 7 + 1) % 7]
        let nextDayFirstSess = modified[nextDay]?.at(0)
        let sessDuration
        let hasNext = false
        let hasPrev = false
        modified[d] = modified[d].map((sess) => {
          let oldSessDuration = sess.to - sess.from
          if (sess.from === 0 && prevDayLastSess && prevDayLastSess?.to === 24) {
            hasPrev = true
            sessDuration = 24 - prevDayLastSess.from + sess.to - 0
            console.log("hasPrev", sessDuration, d)
          } else if (sess.to === 24 && nextDayFirstSess && nextDayFirstSess?.from === 0) {
            hasNext = true
            sessDuration = 24 - sess.from + nextDayFirstSess.to - 0 
            console.log("hasNext", sessDuration, d)
          } else {
            sessDuration = sess.to - sess.from
          }
          if (sessDuration % 0.75 !== 0) {
            console.log("bad ses")
            if (hasNext) {
              modified[nextDay].shift()
              modified[nextDay].unshift({
                from: 0,
                to: nextDayFirstSess.to + getSessIncrement(sessDuration)
              })
            } else
            if (hasPrev) {
              sess.to += getSessIncrement(sessDuration)
            } else {
              sess.to += getSessIncrement(sessDuration)
              if (sess.to > 24) {
                if (!modified[nextDay]) {
                  modified[nextDay] = []
                }
                modified[nextDay].unshift({from: 0, to: sess.to - 24})
                modified[nextDay] = mergeOverlap(modified[nextDay], nextDay).merged
                sess.to = 24
              }
            }
          }
          let finalSessDuration = sess.to - sess.from
          if (oldSessDuration - finalSessDuration >= 0.75 && sess.to > 0.75)
            sess.to -= 0.75
          return sess
        })
      })

      
      setLocalGeneric((prev) => {
        return modified
      })
    }
  }, [...timeStates, editGenData])

  useEffect(() => {
    if (timeStates.length > 0 && currDate)
      initCustom(currDate)
  }, [timeStates.length])

  useEffect(() => {
    if (currDate) {

      initCustom(currDate)
    }
  }, [currDate, localGeneric, editGenData])

  // }, [...[...Object.values(localGeneric)].map((day) => day.length), editGenData])



  useEffect(() => {
    if (Object.keys(gnrcSchdule)?.length > 0) {
      // console.log(gnrcSchdule)

      let modifiedGnrc = {}
      Object.keys(gnrcSchdule).forEach((day, i) => {
        let daySess = gnrcSchdule[day].map((sess) => ({
          from: t12HTo24HDecimalFormat(sess.final[0]),
          to: t12HTo24HDecimalFormat(sess.final[1]),
        }))
        modifiedGnrc[day] = daySess
      })
      // console.log("modified", modifiedGnrc)

      setLocalGeneric(modifiedGnrc)
    }
  }, [gnrcSchdule]);



  useEffect(() => {
    if (cstmSchdule?.length > 0) {
      // console.log(gnrcSchdule)
      console.log(cstmSchdule)
      // let modifiedCstm = cstmSchdule.reduce((prev, curr) => {
      //   prev[curr.date] = curr.sessions.map((sess) => {
      //     delete sess._id
      //     return sess
      //   })
      //   updateDateStatus(curr.date, curr.sessions)
      //   return prev
      // }, {})
      // console.log(modifiedCstm)
      // setDateStatus({})
      // setRevised([])
      // updateDateStatus(Object.keys(modifiedCstm), Object.values(modifiedCstm), "batch")
      
      setLocalCustom((prev) => {
        
        Object.keys(prev).forEach((dt) => {
          let dateCustom = cstmSchdule.find(c => c.date === dt)
          if (dateCustom?.sessions?.length === 0 || !dateCustom)
            prev[dt] = localGeneric[getDayFromDate(dt)]
          else {
            prev[dt] = dateCustom?.sessions
          }
        })
        updateDateStatus(cstmSchdule.map((s) => s.date), cstmSchdule.map((s) => s.sessions), "batch")
        return prev
      })
    }
  }, [cstmSchdule]);






  // ---------------------------------------------------------------

  // Enable "SAVE" button if all the requirement has been satisfied
  const [isAddTimeBtnDisabled, setIsAddTimeBtnDisabled] = useState(true); // Disabling "SAVE" button in generic slot popup
  useEffect(() => {
    if (
      slots !== 0 &&
      (checkedDaysGenericSchedule.mon ||
        checkedDaysGenericSchedule.tue ||
        checkedDaysGenericSchedule.wed ||
        checkedDaysGenericSchedule.thu ||
        checkedDaysGenericSchedule.fri ||
        checkedDaysGenericSchedule.sat ||
        checkedDaysGenericSchedule.sun ||
        allDaysCheckedGenericSchedule)
    ) {
      setIsAddTimeBtnDisabled(false);
    } else {
      setIsAddTimeBtnDisabled(true);
    }
  }, [slots, checkedDaysGenericSchedule]);


  // Add Time Modal in Edit Availability in Custom Schedule
  const [openAddTimePopupInCustomSchd, setOpenAddTimePopupInCustomSchd] =
    useState(false);
  const handleAddTimePopupInCustomSchdOpen = () =>
    setOpenAddTimePopupInCustomSchd(true);
  const handleAddTimePopupInCustomSchdClose = () => {
    setOpenAddTimePopupInCustomSchd(false);
    setIsItEditing(false);
    setTime({
      hrs: "12",
      min: "00",
      merd: "AM",
    });
    setSlots(0);
  };
  const [oldTimeWhileEditingCustomSlot, setOldTimeWhileEditingCustomSlot] =
    useState({
      array: {},
      hrs: "12",
      min: "00",
      merd: "AM",
      start: 0,
      end: 0,
      index: 0,
    });

  /* 
    - Displaying slots on Popup in following format:
      (a) Selected Slots are
          Current Day: 11:00 PM - 11:59 PM
          Next Day: 12:00 AM - 01:00 AM
      (b) Selected Slots are
          11:00 PM - 11:30 PM
  */
  const getTimeSlots = (begin, number) => {
    let corr = moment(begin, "hh:mm a").format("hh:mm A");
    let end = moment(corr, "hh:mm A")
      .add(45 * number, "minutes")
      .format("hh:mm A");
    if (end.includes("12:00 AM")) {
      return (
        <>
          <div
            className="flex justify-center text-md rounded-full bg-green-500 p-2 text-white"
            style={{ width: "200px" }}
          >
            <p>{corr} - 11:59 PM</p>
          </div>
        </>
      );
    } else if (corr.includes("PM") && end.includes("AM")) {
      if (!openAddTimePopupInCustomSchd) {
        return (
          <>
            <div className="text-md">
              Given Day:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>{corr} - 11:59 PM</p>
              </div>
            </div>
            <div className="text-md">
              Next Day:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>12:00 AM - {end}</p>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="text-md">
              {moment(calenderDate, "DD/MM/YYYY").format("Do MMM YYYY")}:{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>{corr} - 11:59 PM</p>
              </div>
            </div>
            <div className="text-md">
              {moment(calenderDate, "DD/MM/YYYY")
                .add(1, "days")
                .format("Do MMM YYYY")}
              :{" "}
              <div
                className="inline-block rounded-full bg-green-500 p-2 mb-1 ml-3 px-5 text-white"
              // style={{ width: "300px" }}
              >
                <p>12:00 AM - {end}</p>
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <div
          className="flex justify-center text-md rounded-full bg-green-500 p-2 text-white"
          style={{ width: "200px" }}
        >
          <p>
            {corr} - {end}
          </p>
        </div>
      );
    }
  };
  // --------------------------
  return (
    <div className=" max-[600px]:m-[10px] m-5 p-2 rounded border-2 border-yellow-700">
      <div className="flex justify-between">
        <Typography
          variant=""
          component="h5"
          sx={{ fontWeight: "500", color: "darkgreen" }}
          className="text-[20px] max-[600px]:text-[18px]"
        >
          Time Preferences for Mock Interviews
          <span style={{ color: "red" }}>*</span>{" "}
          <span
            ref={timeRef}
            style={{ display: "none", fontSize: "15px", color: "red" }}
          >
            Please add your time preferences!
          </span>
        </Typography>
        {Object.values(localGeneric).filter(d => d.length > 0).length > 0 ? (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={handleClearGenericSchedulePopupOpen}
              style={{ whiteSpace: 'nowrap', minWidth: '125px' }}
            >
              <i className="fa-solid fa-trash-can mr-2"></i> Clear All
            </Button>
            <Dialog
              open={openClearGenericSchedulePopup}
              onClose={handleClearGenericSchedulePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure about removing all the entries?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={(e) => {
                    setLocalGeneric(Object.keys(dayLookup).reduce((prev, curr) => {
                      prev[curr] = []
                      return prev
                    }, {}))
                    setLocalCustom({})
                    // setGreen([])
                    // initCustom(currDate, "reset")
                    setRevised([])
                    setDateStatus({})
                    handleClearGenericSchedulePopupClose();
                  }}
                >
                  YES
                </Button>
                <Button onClick={handleClearGenericSchedulePopupClose}>
                  NO
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </div>

      <div className="relative element rounded-md border border-gray-500 p-2 my-2">
        <div className="flex justify-between w-100" >
        <Typography
          variant="h6"
          sx={{
            color: "green",
            paddingBottom: '10px',
            fontSize: '18px'
          }}
        >
          Day-wise Time Preferences
        </Typography>
        <div className="tt px-auto">
          <InfoOutlined sx={{ color: 'rgb(83, 81, 81)', fontSize: '20px', }} />
          <span className="tttext -translate-y-12">{`If you add multiple time-frames on any given day such that they overlap with each other 
          (partially or completely), a duration of 15 or 30 minutes might be added to your specified time-frame so that it can be divided among an 
          integral number of mock interview slots (45 minutes each)`}
          </span>
        </div>
          </div>
        <FAQSContainer className="mobile-hedder">
          {Object.keys(localGeneric).map((item, index) => (
            <FAQ
              key={index}
              onClick={() => {
                toggleQuestion(index);
              }}
              className="group"
              style={{
                backgroundColor: "hsl(225, 6%, 93%)",
                color: `${localGeneric[item]?.length === 0 ? "grey" : 'black'}`,
                fontWeight: "300",
                marginTop: `${index == 0 ? "0px" : "0.8rem"}`,
                border: `${localGeneric[item]?.length === 0 ? "" : '1px grey solid'}`,
                fontSize: '1rem'
              }}
            >
              <Question>
                <QuestionText>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    {dayLookup[item]}
                  </Typography>
                </QuestionText>
                <QuestionToggleIcon
                  variants={{
                    collapsed: { rotate: 0 },
                    open: { rotate: -180 },
                  }}
                  initial="collapsed"
                  animate={
                    activeQuestionIndex === index ? "open" : "collapsed"
                  }
                  transition={{
                    duration: 0.02,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <KeyboardArrowDownIcon />
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "0px" },
                  collapsed: {
                    opacity: 0,
                    height: 0,
                    marginTop: "0px",
                    display: "none",
                  },
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              // dangerouslySetInnerHTML={{ __html: faq.answer }}
              >
                <div className="text-center">
                  {localGeneric[item]?.length !== 0 ? (
                    localGeneric[item].map((arr) => (
                      <div
                        className="relative flex justify-between text-md rounded-full border bg-green-500 p-2 mb-2 mx-auto text-white"
                        style={{ width: "220px" }}
                      >
                        <p className="mx-auto">
                          {t24HDecimalTo12HFormat(arr.from)} - {t24HDecimalTo12HFormat(arr.to)}
                        </p>
                        <div
                          className="mx-1 cursor-pointer text-black hover:text-blue-600"
                          onClick={() => {
                            setIsItEditing(true);
                            setEditGenData({ sess: arr, day: item })
                            let time12From = t24HDecimalTo12HFormat(arr.from)
                            setTime({
                              hrs: time12From.slice(0, 2),
                              min: time12From.slice(3, 5),
                              merd: time12From.slice(6, 8),
                            })
                            setSlots(Number((arr.to - arr.from) / 0.75))
                            handleOpenGenericSchedulePopup();
                            setCheckedDaysGenericSchedule({
                              ...checkedDaysGenericSchedule,
                              [item]: true,
                            });
                            setDisableDaysWhileEditingGenericSlot(Object.keys(dayLookup).reduce((prev, curr) => {
                              prev[curr] = curr !== item
                              return prev
                            }, {}));
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </div>
                        <div
                          className="mx-1 cursor-pointer text-black hover:text-blue-600"
                          onClick={() => {
                            console.log("arr delete")
                            handleDeleteGen({ day: item, sess: arr });
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ paddingBottom: '10px', color: 'black', fontWeight: '400' }}>No Specific Preferences</p>
                  )}
                </div>
              </Answer>
            </FAQ>
          ))}
        </FAQSContainer>

        <div className="grid grid-cols-4 gap-2 max-[1000px]:flex flex-wrap s-desktop-card">
          {/* Days */}
          {Object.keys(dayLookup).map((item) => (
            <div>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  margin: "10px",
                  fontFamily: "'Ubuntu', sans-serif",
                  textAlign: "center",

                }}
              >
                {dayLookup[item]}
              </Typography>
              <div className="text-center">
                {localGeneric[item]?.length !== 0 ? (
                  localGeneric[item]?.map((sess) => (
                    <div
                      className="relative flex justify-between text-md rounded-full border bg-green-500 p-2 mb-2 mx-auto text-white"
                      style={{ width: "220px" }}
                    >
                      <p className="mx-auto">
                        {t24HDecimalTo12HFormat(sess?.from)} - {t24HDecimalTo12HFormat(sess?.to)}
                      </p>
                      <div
                        className="mx-1 cursor-pointer text-black hover:text-blue-600"
                        onClick={() => {
                          setIsItEditing(true);
                          setEditGenData({ sess, day: item })
                          let time12From = t24HDecimalTo12HFormat(sess.from)
                          setTime({
                            hrs: time12From.slice(0, 2),
                            min: time12From.slice(3, 5),
                            merd: time12From.slice(6, 8),
                          })
                          setSlots(Number((sess.to - sess.from) / 0.75))
                          handleOpenGenericSchedulePopup();
                          setCheckedDaysGenericSchedule({
                            ...checkedDaysGenericSchedule,
                            [item]: true,
                          });
                          setDisableDaysWhileEditingGenericSlot(Object.keys(dayLookup).reduce((prev, curr) => {
                            prev[curr] = curr !== item
                            return prev
                          }, {}));
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </div>
                      <div
                        className="mx-1 cursor-pointer text-black hover:text-blue-600"
                        onClick={() => {
                          handleDeleteGen({ day: item, sess });
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Specific Preferences</p>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Generic Schedule add and update popup */}
        {/* <Draggable> */}
        <Dialog
          open={openGenericSchedulePopup}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        // sx={{
        //   height: "200vh",
        //   width: "200vw",
        // }}
        // onClose={handleCloseGenericSchedulePopup}
        >
          <Box
            sx={{
              maxWidth: 560,
              bgcolor: "background.paper",
              overflow: "hidden",
              boxShadow: 24,
            }}
          >
            <DialogTitle
              style={{ cursor: "move", padding: "0" }}
              id="draggable-dialog-title"
            >
              <div className="p-2 bg-blue-500 flex justify-between cursor-move">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    ml: 1.5,
                  }}
                  className="s-mobile-main-h"
                >
                  {isItEditing
                    ? "Edit Time Preferences"
                    : "Add Time Preferences"}
                </Typography>
                <div
                  onClick={() => {
                    handleCloseGenericSchedulePopup();
                  }}
                >
                  <IconButton aria-label="delete" size="small">
                    <CancelIcon
                      fontSize="medium"
                      sx={{ color: "white", "&:hover": { color: "red" } }}
                    />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <div className="flex flex-row p-2 px-5 max-[600px]:flex-col">
              <div className="col-span-1" style={{ width: '100%' }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                >
                  Select Day(s)
                </Typography>
                <div style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateRows: `auto auto auto auto ${window.innerWidth > 600 ? 'auto auto auto  auto' : ''}` }}>
                  {Object.keys(dayLookup).map(
                    (item) => (
                      <div className="max-[600px]:h-[1.5rem]" style={{
                        whiteSpace: "nowrap"
                      }}>
                        <FormControlLabel
                          disabled={item !== editGenData?.day && isItEditing}
                          control={
                            <Checkbox
                              id={item}
                              name={item}
                              value={item}
                              sx={{
                                py: 0.5,
                                '& .MuiFormControlLabel-label': {
                                  whiteSpace: "nowrap",
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }
                              }}
                              checked={checkedDaysGenericSchedule[item]}
                              onChange={() => {
                                setCheckedDaysGenericSchedule({
                                  ...checkedDaysGenericSchedule,
                                  [item]: !checkedDaysGenericSchedule[item],
                                });
                              }}
                            />
                          }
                          label={dayLookup[item]}
                        />
                      </div>
                    )
                  )}

                  <div className="mt-1 text-red-800 italic max-[600px]:h-[1.5rem]" style={{
                    whiteSpace: "nowrap"
                  }}>
                    <FormControlLabel
                      disabled={isItEditing}
                      className="max-[600px]:h-[1.5rem]"
                      control={
                        <Checkbox
                          id="all"
                          name="all"
                          value="all"
                          sx={{ py: 0.5 }}
                          checked={allDaysCheckedGenericSchedule}
                          onChange={() => {
                            setCheckedDaysGenericSchedule({
                              ...checkedDaysGenericSchedule,
                              mon: !allDaysCheckedGenericSchedule,
                              tue: !allDaysCheckedGenericSchedule,
                              wed: !allDaysCheckedGenericSchedule,
                              thu: !allDaysCheckedGenericSchedule,
                              fri: !allDaysCheckedGenericSchedule,
                              sat: !allDaysCheckedGenericSchedule,
                              sun: !allDaysCheckedGenericSchedule,
                            });
                            setAllDaysCheckedGenericSchedule(
                              !allDaysCheckedGenericSchedule
                            );
                          }}
                        />
                      }
                      label={
                        allDaysCheckedGenericSchedule
                          ? "Unselect All"
                          : "Select All"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="my-4 max-[600px]:mb-0  col-span-2 s-mobile-text-14">
                
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                >
                  Select Time Preferences
                </Typography>
                <div className="my-4 max-[600px]:my-0 flex gap-3 s-mobile-text-14 s-margin-top-0-7">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="w-[114px] s-mobile-text-14"
                  >
                    Available From
                  </Typography>
                  <div className="flex" id="time-picker">
                    <select
                      className="text-center"
                      name="hours"
                      id="hours time-picker"
                      value={time.hrs}
                      onChange={(e) => {
                        setTime({ ...time, hrs: e.target.value });
                      }}
                    >
                      <option value="12">12</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                    <p className="mx-1">:</p>
                    <select
                      className="text-center"
                      name="min"
                      id="min time-picker"
                      value={time.min}
                      onChange={(e) => {
                        setTime({ ...time, min: e.target.value });
                      }}
                    >h
                      <option value="00">00</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                    </select>
                    <p className="mx-1"> </p>
                    <select
                      className="text-center"
                      name="merd"
                      id="merd time-picker"
                      value={time.merd}
                      onChange={(e) => {
                        setTime({ ...time, merd: e.target.value });
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="my-4 max-[600px]:my-0 flex gap-3 s-mobile-text-14  s-margin-top-0-7" id="time-picker">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="w-[114px] s-mobile-text-14"
                  >
                    No. of Mock Interviews
                  </Typography>
                  <select

                    name="slots"
                    id="slots-select"
                    className="text-center"

                    value={slots}
                    onChange={handleSlotsChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                  {/* <Tooltip
                    title={`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip> */}
                  <div className="s-mobile-transform-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="tt ml-auto">
                      <InfoOutlined
                        sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                      />
                      <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-55px', right: `${window.innerWidth < 600 ? '-80px' : '0px'}` }} className="tttext">
                        {`No. of Mock Interviews (each of 45 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                      </span>
                    </div>
                  </div>

                </div>
                <div className="border-2 border-blue-500 rounded-lg my-4">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      ml: 2,
                    }}

                  >
                    Selected Time Preferences
                  </Typography>
                  {!isAddTimeBtnDisabled ? (
                    <div className="my-2 ml-4">
                      {getTimeSlots(
                        time.hrs + ":" + time.min + " " + time.merd,
                        slots
                      )}
                    </div>
                  ) : (
                    <p className="ml-4">No Time Preferences Selected</p>
                  )}
                </div>
                <Button
                  variant="contained"
                  disabled={isAddTimeBtnDisabled}
                  sx={{ position: "relative", left: "35%" }}
                  onClick={() => {
                    if (!isItEditing) {
                      handleAddGen(time, slots, checkedDaysGenericSchedule)
                    } else {
                      handleEditGen(editGenData, time, slots)
                    }
                    handleCloseGenericSchedulePopup();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
        {/* </Draggable> */}
        <div className="flex justify-center mt-5 mb-1">
          {false ? (
            <div className="flex gap-2 mt-2">
              <Button
                variant="contained"
                onClick={handleOpenGenericSchedulePopup}
              >
                + Add More Time Preferences
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={handleOpenGenericSchedulePopup}
            >
              Add Time Preferences
            </Button>
          )}
        </div>
      </div>

      {/* Date-wise Time Preference */}
      {timeStates.length > 0 ? (
        <div className="border border-gray-500 rounded-md p-2">
          <Typography
            variant="h6"
            sx={{
              color: "green",
            }}
            className="s-desktop-card"
          >
            View/Edit Time Preferences (Date-wise)
          </Typography>
          <Typography
            sx={{
              color: "green",
            }}
            className="s-mobile-card"
            style={{ fontSize: '18px' }}
          >
            View/Edit Time Preferences<br />(Date-wise)
          </Typography>
          {timeStates.length !== 0 ? (
            <div className="mx-auto my-2 text-center flex justify-center max-[600px]:flex-col s-mobile-align-start">
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                Same as Day-wise Schedule
              </div>
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-yellow-500"></div>
                Different from Day-wise Schedule
              </div>
              <div className="flex mr-3">
                <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                Marked as a Holiday
              </div>
            </div>
          ) : null}
          <div
            className="grid grid-cols-3 mt-3 mx-3 items-start max-[600px]:flex flex-col max-[600px]:items-stretch max-[600px]:mx-0"
          >
            {/* History */}
            <div
              className="col-span-1 p-1 border-2 rounded-md h-full custom-scroll s-desktop-card"
              style={{ height: "300px" }}
            >
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", textAlign: "center" }}
              >
                Revised Schedule
                <Tooltip
                  title={`Summary of the dates for which you have edited your time preferences`}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <div className="overflow-y-auto py-2" style={{ height: "250px" }}>
                {revised?.length !== 0 ? (
                  revised
                    .sort(function (a, b) {
                      var aa = a.date.split("/").reverse().join(),
                        bb = b.date.split("/").reverse().join();
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    })
                    .map((item) => (
                      <div className="w-60 rounded-md border my-1 flex gap-2 p-2 mx-auto">
                        <div className="h-6 w-6 rounded-full bg-gray-500"></div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              color: "#6844c9",
                            }}
                          >
                            {item.date}
                          </Typography>
                          {item.sessions?.length !== 0 ? (
                            item.sessions.map((sess) => (
                              <Typography variant="subtitle1">
                                {t24HDecimalTo12HFormat(sess?.from)} - {t24HDecimalTo12HFormat(sess.to)}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="subtitle1">Holiday</Typography>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="h-full flex justify-center">
                    <p>No edits made so far</p>
                  </div>
                )}
              </div>
            </div>

            {/* Calender */}
            <div className="col-span-1 flex items-center justify-center s-desktop-card">
              <Calendar
                minDate={new Date().setDate(new Date().getDate())}
                zIndex={0}
                value={new Date(calenderDate)}
                onMonthChange={(date) => {
                  // console.log(date.getMonth());
                  initCustom(moment(new Date(date)).format("DD/MM/YYYY"));
                  if (date.month.index === new Date().getMonth())
                    setCalenderDate(new Date());
                  else
                    setCalenderDate(new Date(date))
                }}
                onChange={(e) => {
                  // let date = moment(new Date(e)).format("DD/MM/YYYY")
                  // console.log(getDayFromDate(new Date(e)))
                  // initCustom(new Date(e))
                  // if (new Date(e) === new Date())
                  //   initCustom(currDate)
                  initCustom(moment(new Date(e)).format("DD/MM/YYYY"));
                  setCalenderDate(new Date(e));
                }}
                mapDays={({ date, today, selectedDate, isSameDate }) => {
                  let props = {};

                  let styles = {
                    red: {
                      backgroundColor: "rgb(245 101 101)",
                      color: "#000",
                      border: "1px solid #000",
                    },
                    green: {
                      backgroundColor: "rgb(72 187 120)",
                      color: "#000",
                      border: "1px solid #000",
                    },
                    yellow: {
                      backgroundColor: "rgb(236 201 75)",
                      color: "#000",
                      border: "1px solid #000",
                    },
                    white: {
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: 700,
                    }
                  }
                  let mDate = moment(new Date(date))
                  let mToday = moment(new Date(today))
                  let fdt = mDate.format("DD/MM/YYYY")


                  if (mDate.isBefore(mToday)) {
                    props.style = {
                      background: "rgba(0,0,0,0.2)",
                    }
                  }


                  if (isSameDate(date, today)) {
                    props.style = {
                      ...styles[dateStatus[fdt]],
                      color: "#fff",
                      border: "none",
                    }
                  }
                  if (isSameDate(date, selectedDate)) {
                    props.style = styles[dateStatus[fdt]]
                    if (isSameDate(date, today)) {
                      props.style = { ...props?.style, color: "#fff" }
                    }
                  }


                  props.className = `bg-${dateStatus[fdt]}-500`

                  if (dateStatus[fdt] === "white") {
                    if (isSameDate(date, today)) {
                      props.style = {
                        ...props?.style,
                        color: "#000",
                        fontWeight: "bold"
                      }
                      // props.style.fontWeight = "bold"
                    }
                    else if (isSameDate(date, selectedDate)) {
                      props.style = {
                        ...props?.style,
                        fontWeight: "normal",
                        border: "1px solid black"
                      }
                    }
                  }

                  return props

                }}
              />
            </div>

            {/* Mobile Calender */}
            <div className="s-mobile-card" style={{ flexDirection: 'column', position: 'relative', flex: 1 }}>
              <div className="flex flex-row whitespace-nowrap gap-[0.5rem]" style={{ padding: '10px 50px' }}>
                <span style={{ transform: 'translateY(-5px)' }}>Select date : </span>
                <div style={{ width: '100%', marginBottom: '0.5rem' }} class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button"><AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'inherit' }} /> {calenderDate !== ""
                  ? moment(calenderDate).format("DD MMM YYYY") + " "
                  : "Select Date"}</div>
              </div>

              <AnimatePresence>
                {
                  // datePickerMobile && <Calendar
                  //   className="s-mobile-calendar-calendar"
                  //   minDate={new Date().setDate(new Date().getDate())}
                  //   zIndex={1}
                  //   value={calenderDate}
                  //   onChange={(e) => { setCalenderDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                  //   mapDays={({ date, calenderDate, isSameDate }) => {
                  //     let props = {};
                  //     if (isSameDate(date, calenderDate)) {
                  //       props.style = {
                  //         color: "#fff",
                  //         backgroundColor: "#00a86b",
                  //         border: "1px solid #000"
                  //       };
                  //       return props;
                  //     }
                  //   }}
                  // />
                  datePickerMobile && <motion.div

                    initial={{ height: '0px', opacity: 0, marginBottom: '0rem', x: "-10vh" }}
                    animate={{ height: '250px', marginBottom: '0.5rem', opacity: 1, x: "0vw" }}
                    exit={{ height: '0px', opacity: 0, marginBottom: '0rem', x: "10vh" }}
                    transition={{
                      type: "spring",
                      damping: 10,
                      stiffness: 100
                    }}
                    style={{ position: 'relative', zIndex: 10, display: 'flex', justifyContent: 'center' }}
                  >

                    <Calendar
                      style={{ marginLeft: '25px' }}
                      minDate={new Date().setDate(new Date().getDate())}
                      zIndex={1}
                      className=""

                      value={calenderDate}
                      onMonthChange={(date) => {
                        // console.log(date.getMonth());
                        initCustom(moment(new Date(date)).format("DD/MM/YYYY"));
                        if (date.month.index === new Date().getMonth())
                          setCalenderDate(new Date());
                        else
                          setCalenderDate(new Date(date))
                      }}
                      mapDays={({ date, today, selectedDate, isSameDate }) => {
                        let props = {};

                        let styles = {
                          red: {
                            backgroundColor: "rgb(245 101 101)",
                            color: "#000",
                            border: "1px solid #000",
                          },
                          green: {
                            backgroundColor: "rgb(72 187 120)",
                            color: "#000",
                            border: "1px solid #000",
                          },
                          yellow: {
                            backgroundColor: "rgb(236 201 75)",
                            color: "#000",
                            border: "1px solid #000",
                          },
                          white: {
                            backgroundColor: "#fff",
                            color: "#000",
                            fontWeight: 700,
                          }
                        }
                        let mDate = moment(new Date(date))
                        let mToday = moment(new Date(today))
                        let fdt = mDate.format("DD/MM/YYYY")


                        if (mDate.isBefore(mToday)) {
                          props.style = {
                            background: "rgba(0,0,0,0.2)",
                          }
                        }


                        if (isSameDate(date, today)) {
                          props.style = {
                            ...styles[dateStatus[fdt]],
                            color: "#fff",
                            border: "none",
                          }
                        }
                        if (isSameDate(date, selectedDate)) {
                          props.style = styles[dateStatus[fdt]]
                          if (isSameDate(date, today)) {
                            props.style = { ...props?.style, color: "#fff" }
                          }
                        }


                        props.className = `bg-${dateStatus[fdt]}-500`

                        if (dateStatus[fdt] === "white") {
                          if (isSameDate(date, today)) {
                            props.style = {
                              ...props?.style,
                              color: "#000",
                              fontWeight: "bold"
                            }
                            // props.style.fontWeight = "bold"
                          }
                          else if (isSameDate(date, selectedDate)) {
                            props.style = {
                              ...props?.style,
                              fontWeight: "normal",
                              border: "1px solid black"
                            }
                          }
                        }

                        return props
                      }}
                    />
                    <AiOutlineCloseCircle onClick={() => { setDatePickerMobile(!datePickerMobile) }} style={{ fontSize: '1.5rem', color: 'red' }} />
                  </motion.div>
                }
              </AnimatePresence>
            </div>
            {/* {
                  calenderDate !== "" && <button style={{ width: 'auto' }} class="button-13" onClick={() => setCalenderDate("")} ><MdDeleteForever style={{ display: 'inline-block', fontSize: '1rem' }} /></button>
                } */}


            {/* <div className="col-span-1 flex items-center justify-center s-desktop-card">
              <Calendar
                minDate={new Date().setDate(new Date().getDate())}
                zIndex={0}
                value={calenderDate}
                onMonthChange={(date) => {
                  // console.log(date.getMonth());
                  setCalenderDate(new Date(date));
                }}
                onChange={(e) => {
                  setCalenderDate(new Date(e));
                }}
                mapDays={({ date, today, selectedDate, isSameDate }) => {
                  let props = {};
                  props.style = {
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: 700,
                  };
                  if (isSameDate(date, today)) {
                    const currentSelectedDate = new Date();
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#fff",
                          fontWeight: 500,
                        };
                      }
                    });
                    return props;
                  }

                  if (isSameDate(date, selectedDate)) {
                    const currentSelectedDate = new Date(date);
                    props.style = {
                      backgroundColor: "#fff",
                      color: "#000",
                      border: "1px solid #000",
                    };
                    green.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(72 187 120)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    yellow.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(236 201 75)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    red.map((item) => {
                      if (currentSelectedDate.getDate() === item) {
                        props.style = {
                          backgroundColor: "rgb(245 101 101)",
                          color: "#000",
                          border: "1px solid #000",
                        };
                      }
                    });
                    return props;
                  }
                  // Color of Passed out days
                  // if ([4, 5, 6, 7].includes(date.day)) {
                  //   return { className: "text-red-500" };
                  // }
                  // if ([10, 11, 12, 13].includes(date.day)) {
                  //   return { className: "text-yellow-500" };
                  // }
                  // if ([15, 16, 17].includes(date.day)) {
                  //   return { className: "text-green-500" };
                  // }
                  if (red.includes(date.day)) {
                    return { className: "bg-red-500" };
                  }
                  if (yellow.includes(date.day)) {
                    return { className: "bg-yellow-500" };
                  }
                  if (green.includes(date.day)) {
                    return { className: "bg-green-500" };
                  }
                }}
              />
            </div> */}

            {/* Slots List of Selected Date */}
            <div
              className="col-span-1 h-full px-3 rounded-md border-2"
              style={{ paddingBottom: '0.5rem' }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 1,
                  mb: 1,
                }}
                className="s-mobile-main-h"
              >
                {moment(calenderDate).format("dddd, MMMM Do")}
              </Typography>
              {localCustom[currDate]?.length ? (
                <div
                  className="relative p-1 my-1 mb-2 rounded overflow-y-auto box-shadow-1 flex justify-between max-[600px]:px-0 max-[600px]:p-[0.5rem]"
                  style={{ maxHeight: "155px", alignItems: "flex-start" }}
                >
                  <div className="mx-2 max-[600px]:flex max-[600px]:flex-col max-[600px]:gap-[0.5rem] max-[600px]:mr-0">
                    {localCustom[currDate] ? (
                      (localCustom[currDate]).map((item, index) => {
                        return (
                          <div
                            className="flex justify-between text-md rounded-full border border-black bg-teal-100 p-2 mb-1 mx-auto text-black max-[600px]:mb-0 max-[600px]:rounded max-[600px]:gap-[0.3rem]"
                            style={{}}
                          >
                            <p className="mx-auto">
                              {t24HDecimalTo12HFormat(item.from)} - {t24HDecimalTo12HFormat(item.to)}
                            </p>
                            <div
                              className="mx-1 cursor-pointer text-black hover:text-blue-600"
                              onClick={() => {
                                setIsItEditing(true);
                                handleAddTimePopupInCustomSchdOpen();
                                setTime(convertSess(item).time);
                                setSlots(parseInt((item.to - item.from) / 0.75));
                                setEditGenData({ date: currDate, sess: item })
                                // setOldTimeWhileEditingCustomSlot({
                                //   array: item,
                                //   hrs: item.final[0].slice(0, 2),
                                //   min: item.final[0].slice(3, 5),
                                //   merd: item.final[0].slice(6, 8),
                                //   start: item.init[0],
                                //   end: item.init[1],
                                //   index: index,
                                // });
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </div>
                            <div
                              className="mx-1 cursor-pointer text-black hover:text-blue-600"
                              onClick={() => {
                                handleDeleteCust({ date: currDate, sess: item })
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="my-2 mx-auto text-center">
                        No Time Preferences Specified
                      </p>
                    )}
                  </div>
                  <div
                    className="mx-1 max-[600px]:mx-0 s-mobile-sticky-btn-i-profile"
                    onClick={() => {

                      // updateDateStatus(currDate, [])
                      handleDeleteAllCust(currDate)

                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      size="large"
                      sx={{ p: 0, minWidth: 0 }}
                      className='s-mobile-delete-btn-i-profile'
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              ) : (
                <p className="my-2 mx-auto text-center">
                  No Time Preferences Specified
                </p>
              )}
              <div className="flex flex-col justify-center items-center mt-3">
                {["yellow", "red"].includes(dateStatus[currDate]) ? (
                  <Button
                    sx={{ mx: "auto" }}
                    onClick={() => {
                      // resetToGenrcSchd(calenderDate);
                      // initCustom(currDate, "reset")


                      // setGreen([])

                      // initCustom(currDate, "reset")
                      handleDeleteAllCust(currDate)
                      setLocalCustom((prev) => {
                        prev[currDate] = localGeneric[getDayFromDate(currDate)]
                        updateDateStatus(currDate, prev[currDate])
                        return prev
                      })
                      // setDateStatus((prev) => {
                      //   Object.keys(prev).forEach((d) => {
                      //     if (prev[d] !== "green")
                      //       delete prev[d]
                      //   })
                      //   return prev
                      // })
                    }}
                  >
                    Reset to Default
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  sx={{ mx: "auto" }}
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => {
                    handleAddTimePopupInCustomSchdOpen()
                  }}
                >
                  {selectedDateSlotsArr?.length !== 0
                    ? "+ Add More Time Preferences"
                    : "Add Time Preferences"}
                </Button>
              </div>
            </div>
            {/* <div
              className="col-span-1 p-1 border-2 rounded-md h-full custom-scroll s-mobile-card mt-[1rem] max-[600px]:flex-col"
              style={{ maxHeight: "300px" }}
            >
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", textAlign: "center", fontSize: '18px' }}
              >
                Revised Schedule
                
                <div style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                  <div className="tt ml-auto">
                    <InfoOutlined
                      sx={{ color: "rgb(83, 81, 81)", fontSize: "20px", marginLeft: '0.5rem' }}
                    />
                    <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-38px' }} className="tttext">
                      {`Summary of the dates for which you have edited your time preferences`}
                    </span>
                  </div>
                </div>
              </Typography>
              <div className="overflow-y-auto py-2" style={{ maxHeight: "250px" }}>
                {excpDatesSlotsArr?.length !== 0 ? (
                  excpDatesSlotsArr
                    .sort(function (a, b) {
                      var aa = a.date.split("/").reverse().join(),
                        bb = b.date.split("/").reverse().join();
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    })
                    .map((item) => (
                      <div className="w-60 rounded-md border my-1 flex gap-2 p-2 mx-auto">
                        <div className="h-6 w-6 rounded-full bg-gray-500"></div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              color: "#6844c9",
                            }}
                          >
                            {item.date}
                          </Typography>
                          {item.slots?.length !== 0 ? (
                            item.slots.map((slot) => (
                              <Typography style={{ fontSize: '14px' }} variant="subtitle1">
                                {slot.final[0]} - {slot.final[1]}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="subtitle1">Holiday</Typography>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="h-full flex justify-center">
                    <p>No edits made so far</p>
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <Dialog
            open={openAddTimePopupInCustomSchd}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            style={{ backdropFilter: 'blur(5px)' }}
          >
            <Box
              sx={{
                maxWidth: 321,
                width: '100%',
                bgcolor: "background.paper",
                boxShadow: 24,
                overflow: "hidden",
                // backgroundColor: "red",

              }}
            >
              <DialogTitle
                style={{ cursor: "move", padding: "0", width: '100%' }}
                id="draggable-dialog-title"
              >
                <div className="p-2 bg-blue-500 flex justify-between">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      ml: 1.5,
                    }}
                    className="s-mobile-main-h"
                  >
                    {isItEditing
                      ? "Edit Time Preferences"
                      : "Add Time Preferences"}
                  </Typography>
                  <div
                    onClick={() => {
                      handleAddTimePopupInCustomSchdClose();
                      console.log('close')
                    }}
                  >
                    <IconButton aria-label="delete" size="small">
                      <CancelIcon
                        fontSize="medium"
                        sx={{ color: "white", "&:hover": { color: "red" } }}
                      />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <div className="p-2">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: "darkmagenta",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Select Time Preferences
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#F87474",
                    textAlign: "center",
                  }}
                >
                  {moment(calenderDate).format("Do MMM YYYY")}
                </Typography>

                <div className="flex gap-3 mt-[0.7rem]">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="s-mobile-profile-text"
                  >
                    Available From
                  </Typography>
                  <div className="flex" id="time-picker">
                    <select
                      className="text-center"
                      name="hours"
                      id="hours time-picker"
                      value={time.hrs}
                      onChange={(e) => {
                        setTime({ ...time, hrs: e.target.value });
                      }}
                    >
                      <option value="12">12</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                    <p className="mx-1">:</p>
                    <select
                      className="text-center"
                      name="min"
                      id="min time-picker"
                      value={time.min}
                      onChange={(e) => {
                        setTime({ ...time, min: e.target.value });
                      }}
                    >
                      <option value="00">00</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                    </select>
                    <p className="mx-1"> </p>
                    <select
                      className="text-center"
                      name="merd"
                      id="merd time-picker"
                      value={time.merd}
                      onChange={(e) => {
                        setTime({ ...time, merd: e.target.value });
                      }}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="flex gap-3 mt-[0.7rem] mr-[98px] max-[600px]:mr-[94px]" id="time-picker">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: '1.4'
                    }}
                    className="s-mobile-profile-text"
                  >
                    No. of Mock Interviews
                  </Typography>
                  <select
                    name="slots"
                    id="slots-select"
                    className="text-center "
                    value={slots}
                    onChange={handleSlotsChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                  {/* <Tooltip
                    title={`No. of Mock Interviews (each of 30 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip> */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="tt ml-auto">
                      <InfoOutlined
                        sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                      />
                      <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-55px', right: `${window.innerWidth < 600 ? '-80px' : '0px'}` }} className="tttext">
                        {`No. of Mock Interviews (each of 45 minutes) which you can take in one go, one after another, ${time.hrs}:${time.min} ${time.merd} onwards`}
                      </span>
                    </div>
                  </div>

                  {/* <div style={{display:'flex', justifyContent:'flex-end'}}>
                <div className="tt ml-auto">
                <InfoOutlined sx={{color:'rgb(83, 81, 81)', fontSize:'20px'}}/>
                      <span className="tttext">By keeping an account at interwiu.com, you agree to our Terms and Conditions and Privacy Policy</span>
                  </div>
                </div> */}
                </div>
                <div className="border-2 border-blue-500 rounded-lg my-4">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      ml: 2,
                    }}
                    className="s-mobile-profile-text"
                  >
                    Selected Time Preferences
                  </Typography>
                  {slots !== 0 ? (
                    <div className="my-2 ml-4">
                      {getTimeSlots(
                        time.hrs + ":" + time.min + " " + time.merd,
                        slots
                      )}
                    </div>
                  ) : (
                    <p className="ml-4">No Time Preferences Selected</p>
                  )}
                </div>
                <Button
                  variant="contained"
                  sx={{ position: "relative", left: "35%" }}
                  disabled={slots === 0}
                  onClick={() => {
                    if (isItEditing !== true) {
                      handleAddCust(currDate, time, slots)
                    } else {
                      handleEditCust(editGenData, currDate, time, slots)
                      handleAddTimePopupInCustomSchdClose();
                      return;
                    }
                    handleAddTimePopupInCustomSchdClose();
                  }}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Dialog>
        </div>
      ) : null}
    </div>
  );
};

export default TimePreference;
