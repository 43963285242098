import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import "./ProfileForm.css";
import {
  branchSpecilization,
  special,
} from "../../constants/branchSpecilization";
import toast from "react-hot-toast";
import {
  reduce_image_file_size,
  Specialization,
  toastStyle,
} from "../../utility/helper";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SouthIcon from "@mui/icons-material/South";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@mui/material/Chip";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Navbarlogedin from "./Navbarlogedin";
import data from "./data.js";
import NestedMenuItem from "material-ui-nested-menu-item";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Menu, MenuItem, Typography } from "@material-ui/core";

import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import FileBase from "react-file-base64";
import LZString from "lz-string";
import { downloadPDF } from "../../utility/helper";
import { Oval } from "react-loader-spinner";
import { STUDENT_FIND_AN_INTERVIEWER_ROUTE } from "../../constants/routes";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProfileForm = () => {
  let navigate = useNavigate();

  const { user } = useContext(UserContext);
  console.log(user);
  const location = useLocation();
  const [state, setState] = React.useState({
    one: false,
    two: false,
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [state2, setState2] = React.useState({
    core: user.coreprofiles.length > 0,
    nonCore: user.profiles.length > 0,
  });
  const [checked, setChecked] = useState();

  const { one, two } = state;
  const { core, nonCore } = state2;
  const handleChangeRadio = (event) => {
    console.log(checked);
    setChecked(event.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeCheckBox2 = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });
  };
  // User Data
  const [menuPosition, setMenuPosition] = useState(null);

  const handleRightClick = (event) => {
    if (menuPosition) {
      return;
    }
    // event.preventDefault();
    setMenuPosition(true);
  };

  const handleItemClick = (event) => {
    // setMenuPosition(null);
  };

  const [userData, setUserData] = useState({});
  const [photo, setPhoto] = useState(null);
  // Handling Change
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name, value);
    if (name === "branch") {
      setOtherSpecialization("");
      setUserData((userData) => ({ ...userData, [name]: value, specialization: "" }));
    }
    else
      setUserData((userData) => ({ ...userData, [name]: value }));

  };

  const [profiledata, setProfileData] = useState("");
  const [newImage, setnewImage] = useState("");
  const [newResume, setnewResume] = useState("");
  const [imageSize, setimageSize] = useState(false);
  const [resumeSize, setresumeSize] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [branches, setBranches] = useState([]);

  const [otherBranch, setOtherBranch] = useState("");
  const [showBranch, setShowBranch] = useState(false);

  const [otherSpecialization, setOtherSpecialization] = useState("");
  const [showSpecialization, setShowSpecialization] = useState(false);

  const [otherCore, setOtherCore] = useState("");
  const [showCore, setShowCore] = useState(false);
  const [validResume, setValidResume] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${BASE}/send/student/112964740830785165733`)
        .then((response) => {
          setProfileData(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE}/send/admin/getInstituteBranches/${userData.institute}`)
      .then((response) => {
        setBranches(response.data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));
        console.log(response.data, "--00-00-0-0-");
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
      });
  }, [userData.institute]);

  // Profile Preference
  // To delete selected profiles explicitly from the selection list
  const handleNonCorePrefChange = (e) => {
    const noncoreprofile = e.target.value.slice(-1).slice(-1)[0];
    if (noncoreprofile)
      setUserData((userData) =>
        userData.profiles.indexOf(noncoreprofile) === -1
          ? {
            ...userData,
            profiles: [...userData.profiles, noncoreprofile],
          }
          : userData
      );
  };
  const [coreProfileIndicator, setCoreProfileIndicator] = useState("");
  const handleNonCoreProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      profiles: userData.profiles.filter((item) => item !== label),
    }));
  const handleCorePrefChange = (e) => {
    const coreprofile = e.target.value;
    setCoreProfileIndicator(coreprofile);
    if (coreprofile === "Other") {
      return;
    }
    if (coreprofile)
      setUserData((userData) =>
        userData.coreprofiles.indexOf(coreprofile) === -1
          ? {
            ...userData,
            coreprofiles: [coreprofile],
            specializations: [],
          }
          : userData
      );
  };
  const handleCoreProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      coreprofiles: userData.coreprofiles.filter((item) => item !== label),
      specializations: [],
    }));
  const handleSpecializationPrefChange = (e) => {
    if (e.target.checked) {
      const specialization = e.target.name;
      if (specialization)
        setUserData((userData) =>
          userData.specializations.indexOf(specialization) === -1
            ? {
              ...userData,
              specializations: [...userData.specializations, specialization],
            }
            : userData
        );
    } else {
      const specialization = e.target.name;
      if (specialization)
        setUserData((userData) =>
          userData.specializations.indexOf(specialization) === -1
            ? userData
            : {
              ...userData,
              specializations: userData.specializations.filter(
                (item) => item !== specialization
              ),
            }
        );
    }
  };
  const handleSpecializationProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      specializations: userData.specializations.filter(
        (item) => item !== label
      ),
    }));
  const [errs, setErrs] = useState({});
  const [pfpImage, setpfpImage] = useState(null);

  useEffect(() => {
    setUserData(user);

    setOtherBranch(user.branch);
    if (document.querySelector('input[name="otherbranch"]')) {
      document.querySelector('input[name="otherbranch"]').disabled = true;
    }

    setOtherSpecialization(user.specialization);
    if (document.querySelector('input[name="otherspecialization"]')) {
      document.querySelector(
        'input[name="otherspecialization"]'
      ).disabled = true;
    }

    setOtherCore(user.coreprofiles[0]);
    if (document.querySelector('input[name="othercore"]')) {
      document.querySelector('input[name="othercore"]').disabled = true;
    }

    setState({ one: user.appearingFor.one, two: user.appearingFor.two });
  }, [user]);
  // console.log(userData);

  // Field Errors
  const [fieldError, setFieldError] = useState({
    institute: false,
    program: false,
    branch: false,
    resume: false,
    year: false,
  });

  // Fatching User's Profile Photo
  // const [image, setImage] = useState();
  // useEffect(() => {
  //   const imageUrl = user.thumbNail;

  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((imageBlob) => {
  //       setImage(URL.createObjectURL(imageBlob));
  //       console.log("image: ", image);
  //     });
  // }, []);

  // Prefilling Few Fields
  // const prefilled = () => {
  //   setInputs((prevState) => ({
  //     ...prevState,
  //     fname: localStorage.getItem("isAuthenticated").username.split(" ")[0],
  //     lname: localStorage.getItem("isAuthenticated").username.split(" ")[1],
  //     // dp: user.thumbNail,
  //   }));
  //   if (localStorage.getItem("isAuthenticated").email.includes("@gmail.com")) {
  //     setInputs((prevState) => ({
  //       ...prevState,
  //       email: localStorage.getItem("isAuthenticated").email,
  //     }));
  //   }
  // };
  // useEffect(() => {
  //   prefilled();
  // }, []);
  // useEffect(() => {
  //   console.log(inputs);
  // }, [inputs]);

  // Targeted Companies Object
  // E.g. [{company: "google", profile: "data science", fileJD: "", textJD: ""}]
  // 'fileJD: ""' will added if we use pdf in jd
  const [targetedCompanies, setTargetedCompanies] = useState([
    { company: "", profile: "", textJD: "" },
  ]);
  useEffect(() => {
    if (user.companies !== {}) {
      setTargetedCompanies(user.companies);
    }
    console.log(targetedCompanies);
  }, [user]);
  // Handle Change for Targeted Companies
  const handleChangeTargetedCompanies = (index, profile, value) => {
    let tarComObj = [...targetedCompanies];
    tarComObj[index][profile] = value;
    setTargetedCompanies(tarComObj);
  };
  const [updateDetails, setUpdateDetails] = useState(false);
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (!userData.title) {
      const box = document.getElementById("title");
      setErrs({ ...errs, title: true });
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else setErrs({ ...errs, title: false });

    if (!userData.fname) {
      const box = document.getElementById("fname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("fname").style.borderColor = "#E5E7EB";

    if (!userData.lname) {
      const box = document.getElementById("lname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("lname").style.borderColor = "#E5E7EB";

    // if (!userData.email.includes("@gmail")) {
    //   const box = document.getElementById("email");
    //   box.style.borderColor = "#FF0000";
    //   return window.scrollTo(0, box.offsetTop);
    // } else document.getElementById("email").style.borderColor = "#E5E7EB";

    // if (!userData.email.endsWith("@gmail.com")) {
    //   const box = document.getElementById("email");
    //   box.style.borderColor = "#FF0000";
    //   return window.scrollTo(0, box.offsetTop);
    // } else document.getElementById("email").style.borderColor = "#E5E7EB";

    if (
      !(userData.mobNumber && isValidPhoneNumber(String(userData.mobNumber)))
    ) {
      const box = document.getElementById("mobNum");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("mobNum").style.borderColor = "#e5e7eb";

    // Profile Preference
    // if (userData.profiles?.length === 0) {
    //   const box = document.getElementById("profiles");
    //   // box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("profile").style.borderColor = "#e5e7eb";

    if (!userData.institute) {
      setFieldError({ ...fieldError, institute: true });
      const box = document.getElementById("institute");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (!userData.program) {
      setFieldError({ ...fieldError, program: true });
      const box = document.getElementById("program");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (!userData.upiId) {
      setFieldError({ ...fieldError, upiId: true });
      const box = document.getElementById("upiId");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (!userData.branch) {
      setFieldError({ ...fieldError, branch: true });
      const box = document.getElementById("branch");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (!userData.year) {
      setFieldError({ ...fieldError, year: true });
      const box = document.getElementById("year");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    // if (userData.profiles?.length === 0) {
    //   setFieldError({ ...fieldError, profilesPref: true });
    //   const box = document.getElementById("profilesPref");
    //   // box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    if ((!user.resume && !newResume) || (newResume !== "" && newResume.split(";")[0] !== "data:application/pdf")) {
      setFieldError({ ...fieldError, resume: true });
      const box = document.getElementById("resume");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    setUpdateDetails(true);
    // console.log(userData);
    // console.log("targetedCompanies: ", targetedCompanies);
    // console.log("photo: ", photo);

    //api call of update student profile
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //  const resumeData = {
      //   myFile: userData.resume,
      //   email : userData.email,
      //   title : userData.title
      // };

      // console.log(resumeData);
      const fd = new FormData();
      fd.append("hi", "hello");
      fd.append("studentinfo", userData);
      fd.append("comp", targetedCompanies);
      // console.log(`${BASE}/profile/update/${user._id}`)
      // console.log(fd)
      // console.log(targetedCompanies);
      userData.companies = targetedCompanies;
      if (imageSize) {
        const box = document.getElementById("image");
        return window.scrollTo(0, box.offsetTop);
      }
      if (resumeSize) {
        const box = document.getElementById("resume");
        return window.scrollTo(0, box.offsetTop);
      }
      if (newImage) {
        userData.photo = newImage;
      }
      if (newResume) {
        userData.resume = newResume;
      }
      console.log("thi is user data", userData);
      console.log(newResume)
      try{
        const {data} = await axios.put(
          `${BASE}/profile/update/${user.id}`,
          { userData, state },
          config
        )
        toast.success(`${data.message}`, {
          ...toastStyle.success,
        });
        setUpdateDetails(false);
        window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/find-an-interviewer`;
      }
      catch(error) {
        setUpdateDetails(false);
        toast.error(`${error.response.data.message}`, {
          ...toastStyle.error,
        });
      };
    } catch (error) {
      toast.error(`${error.message}`, {
        ...toastStyle.error,
      });
    }
  };
  if (updateDetails) {
    return (
      <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
        <Oval
          height={120}
          width={120}
          color="#306bc9"
          wrapperStyle={{ padding: "50%, 50%" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#90caf9"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Edit Profile</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      {loaded ? (
        <div className="profile-form py-1">
          {/* <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                overflow: "hidden",
                minWidth: "750px",
              }}
            >
              <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                    mx: 2,
                  }}
                >
                  Choose Specialization
                </Typography>
                <IconButton onClick={handleClose}>
                  <CancelIcon sx={{ color: "white" }} />
                </IconButton>
              </div>
              <p className="pl-3 pt-2">
                {" "}
                If you do not have any specialization (for example, if you are a
                BE/B.Tech student), then you can just ignore and close this
                pop-up box
              </p>
              <FormGroup>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  className="pl-3"
                >
                  {Specialization[`${userData.coreprofiles}`]?.map(
                    (res, index) => {
                      return (
                        <p>
                          <FormControlLabel
                            value={res}
                            control={<Radio />}
                            onChange={handleChangeRadio}
                            label={res}
                          />
                        </p>
                      );
                    }
                  )}
                </RadioGroup>
              </FormGroup>
              <div style={{ textAlign: "center" }} className="pb-3">
                <Button
                  sx={{ textAlign: "center" }}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    let s = { target: { value: checked } };
                    handleSpecializationPrefChange(s);
                    handleClose();
                  }}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Modal> */}
          <h1 className="text-2xl font-medium text-center py-4 text-blue-500 pt-5 s-mobile-head-edit-profile">
            You're just a step away from scheduling your first Mock Interview!
          </h1>
          <h1
            className="text-2xl font-medium text-center py-4 leading-3 text-black-500 s-mobile-subhead-edit-profile"
            style={{ color: "rgb(9,90,84)" }}
          >
            Tell us about yourself!
          </h1>
          <div></div>
          <div></div>
          <form onSubmit={handleSubmit}>
            {/* Profile Form Container */}

            <div className="profile-form-cont">
              {/* Col-70 */}

              <div className="profile-form-text">
                {/* Profile mobile Photo */}

                <div
                  className="profile-photo border rounded-md p-2 flex s-resume-950-1"
                  style={{ flexDirection: "column" }}
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize: "16px",
                      color: "#095a54",
                      fontWeight: "500",
                    }}
                  >
                    Profile Photo
                  </Typography>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      marginTop: "0px",
                      borderRadius: "1000px",
                      margin: "10px",
                    }}
                    src={user.photo}
                    className="upload"
                    alt="Upload"
                  />
                  <div
                    className="filebase"
                    style={{
                      margin: "0px 10px",
                      transform: "scale(0.9) translateX(-10px)",
                    }}
                  >
                    <FileBase
                      type="file"
                      className="PhoneInputInput"
                      style={{ fontSize: "14px" }}
                      multiple={false}
                      onDone={async ({ base64, size }) => {
                        if (parseInt(size) < 500) {
                          setimageSize(false);
                          setnewImage(await reduce_image_file_size(base64));
                          console.log(await reduce_image_file_size(base64));
                        } else {
                          setimageSize(true);
                        }
                      }}
                    />
                  </div>
                  {imageSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 500 KB
                    </p>
                  )}
                  {/* <input
                  type="file"
                  name="photo"
                  id="photo"
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  value={""}
                  accept="image/png, image/jpg, image/jpeg, image/url"
                  onChange={(e) => {
                    const value = e.target.files[0];
                    // setUserData({ ...userData, photo: value });
                    setPhoto(value);
                    // console.log(value);
                    var image = document.querySelector(".upload");
                    image.src = URL.createObjectURL(value);
                    // document.getElementById("photo").value = value;
                  }}
                /> */}
                </div>

                {/* First Name and Last Name */}

                <div className="name cust-row flex max-[600px]:flex-wrap">
                  <div className="element" style={{ width: "auto" }}>
                    <label htmlFor="title">
                      Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      name="title"
                      id="title"
                      error={errs.title}
                      value={userData.title || ""}
                      onChange={handleChange}
                      autoWidth
                      className="s-card-height-56"
                      sx={{ padding: "0px", width: "100%" }}
                    >
                      <MenuItem value="mr">Mr.</MenuItem>
                      <MenuItem value="mrs">Ms.</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </div>
                  <div className="element">
                    <label htmlFor="firstname">
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      className="border-2 rounded s-card-height-56"
                      value={userData.fname}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="lastname">
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="lname"
                      type="text"
                      name="lname"
                      className="border-2 rounded s-card-height-56"
                      value={userData.lname}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                {/* Email and Mobile Number */}

                <div className="email cust-row flex max-[600px]:flex-wrap">
                  <div className="element">
                    <label htmlFor="email">
                      Gmail<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      disabled
                      className="border-2 rounded s-card-height-56"
                      value={userData.email}
                      placeholder="Email"
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="number">
                      WhatsApp Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="border-2 rounded px-3" id="mobNum">
                      <PhoneInput
                        className="max-[600px]:h-[40px]"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        name="mobNumber"
                        value={
                          userData.mobNumber
                            ? String(userData.mobNumber)
                            : "+91"
                        }
                        onChange={(number) =>
                          setUserData((userData) => ({
                            ...userData,
                            mobNumber: number,
                          }))
                        }
                        error={
                          userData.mobNumber
                            ? isValidPhoneNumber(String(userData.mobNumber))
                              ? undefined
                              : "Invalid phone number"
                            : "Ph"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Institution */}

                <div className="institution cust-row flex max-[600px]:flex-wrap">
                  <div className="branch cust-row flex">
                    <div className="element w-50">
                      <label htmlFor="institute">
                        Institute<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="institute"
                        className="border-2 rounded s-card-height-56"
                        placeholder="Institute"
                        displayEmpty
                        error={fieldError.institute}
                        value={userData.institute}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldError({ ...fieldError, institute: false });
                        }}
                        autoComplete="nope"
                        disabled={user.adminId}
                      />
                    </div>
                  </div>

                  <div className="element">
                    <label htmlFor="program">
                      Program<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      value={userData.program || ""}
                      name="program"
                      id="program"
                      error={fieldError.program}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError({ ...fieldError, program: false });
                      }}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: 1 }}
                      // label="age"
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue=""
                    >
                      <MenuItem hidden disabled value="">
                        Select Program
                      </MenuItem>
                      <MenuItem value="btech">B.Tech</MenuItem>
                      <MenuItem value="dual">
                        Dual Degree (B.Tech + M.Tech)
                      </MenuItem>
                      <MenuItem value="dualbsms">
                        Dual Degree (BS + MS)
                      </MenuItem>
                      <MenuItem value="mtech">M.Tech</MenuItem>
                      <MenuItem value="ms">MS (by Research)</MenuItem>
                      <MenuItem value="mba">MBA</MenuItem>
                      <MenuItem value="msc">M.Sc</MenuItem>
                      <MenuItem value="ima">Intergrated MA</MenuItem>
                      <MenuItem value="phd">PhD</MenuItem>
                    </Select>
                  </div>
                </div>

                {/* Branch and Specialization */}

                <div className="branch cust-row flex max-[600px]:flex-wrap">
                  <div className="element">
                    <label htmlFor="year">
                      Year<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      value={userData.year || ""}
                      name="year"
                      id="year"
                      error={fieldError.year}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError({ ...fieldError, year: false });
                      }}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: 1 }}
                      // label="age"
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue=""
                    >
                      <MenuItem hidden default disabled value="">
                        Year
                      </MenuItem>
                      <MenuItem value={"1st year"}>1st year</MenuItem>
                      <MenuItem value={"2nd year"}>2nd year</MenuItem>
                      <MenuItem value={"3rd year"}>3rd year</MenuItem>
                      <MenuItem value={"4th year"}>4th year</MenuItem>
                      <MenuItem value={"5th year"}>5th year</MenuItem>
                      <MenuItem value={"6th year"}>6th year</MenuItem>
                    </Select>
                  </div>
                  <div className="element">
                    <label htmlFor="branch">
                      Branch/Department<span style={{ color: "red" }}>*</span>
                    </label>
                    {!user.branches ||
                      user.branches.length === 0 ||
                      user.branches === [] ? (
                      <>
                        {" "}
                        {!showBranch && (
                          <Select
                            value={userData.branch || ""}
                            name="branch"
                            id="branch"
                            renderValue={() =>
                              userData.branch || "Select Branch/Department"
                            }
                            onChange={(e) => {
                              handleChange(e);
                              setFieldError({ ...fieldError, branch: false });
                              setOtherSpecialization("");
                              setUserData((current) => {
                                return { ...current, specialization: "" };
                              });
                            }}
                            error={fieldError.branch}
                            displayEmpty
                            MenuProps={MenuProps}
                            sx={{ width: 1 }}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ textOverflow: "ellipsis" }}
                            defaultValue=""
                          >
                            {Object.keys(branchSpecilization).map(
                              (item, index) => {
                                if (branches.includes(item)) return null;

                                return (
                                  <MenuItem
                                    value={item}
                                    key={index}
                                    style={{ textOverflow: "ellipsis" }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              }
                            )}

                            {/* {branches?.map((item, index) => {
                              console.log(branches);
                              return (
                                <MenuItem
                                  value={item}
                                  key={branches.indexOf(item)}
                                  style={{ textOverflow: "ellipsis" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })} */}

                            {branches?.map((item) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={branches.indexOf(item)}
                                  style={{ textOverflow: "ellipsis" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        )}
                        {showBranch && (
                          <div className="flex mt-2">
                            <input
                              type="text"
                              name="otherbranch"
                              className="border-2 rounded s-card-height-56"
                              placeholder="Other Branch/Department"
                              style={{ marginRight: "5px" }}
                              displayEmpty
                              value={otherBranch}
                              onChange={(e) => {
                                setOtherBranch(e.target.value);
                              }}
                              autoComplete="nope"
                            />
                            <Button
                              name="branch"
                              style={{ marginRight: "10px" }}
                              className="s-card-height-56"
                              variant="contained"
                              color="primary"
                              onClick={(e) => {
                                handleChange({
                                  target: {
                                    name: "branch",
                                    value: otherBranch,
                                  },
                                });
                                document.querySelector(
                                  "input[name=otherbranch]"
                                ).disabled = true;
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <Button
                              name="branch"
                              value=""
                              style={{ marginRight: "10px" }}
                              className="s-card-height-56"
                              variant="contained"
                              color="error"
                              onClick={(e) => {
                                setOtherBranch("");
                                handleChange({
                                  target: { name: "branch", value: "" },
                                });
                                setShowBranch(false);
                                document.querySelector(
                                  "input[name=otherbranch]"
                                ).disabled = false;
                                console.log("branch changed++++++++++");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {!showBranch && (
                          <Select
                            value={userData.branch}
                            name="branch"
                            id="branch"
                            renderValue={() =>
                              userData.branch || "Select Branch/Department"
                            }
                            onChange={(e) => {
                              handleChange(e);
                              setFieldError({ ...fieldError, branch: false });
                              if (e.target.value == "Other") {
                                setShowBranch(true);
                              } else {
                                setShowBranch(false);
                              }
                            }}
                            error={fieldError.branch}
                            displayEmpty
                            MenuProps={MenuProps}
                            sx={{ width: 1 }}
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ textOverflow: "ellipsis" }}
                            defaultValue=""
                          >
                            {/* {Object.keys(branchSpecilization).map(
                              (item, index) => {
                                if (branches.includes(item)) return null;

                                return (
                                  <MenuItem
                                    value={item}
                                    key={index}
                                    style={{ textOverflow: "ellipsis" }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              }
                            )} */}
                            {/* <MenuItem hidden disabled value="">
                              Select Branch/Department
                            </MenuItem>
                            <MenuItem hidden disabled value="">
                              Select Branch/Department
                            </MenuItem>
                            <MenuItem value="Aerospace Engineering">
                              Aerospace Engineering
                            </MenuItem>
                            <MenuItem value="Applied Mechanics">
                              Applied Mechanics
                            </MenuItem>
                            <MenuItem value="Biomedical Engineering ">
                              Biomedical Engineering
                            </MenuItem>
                            <MenuItem value="Bioscience and Bioengineering">
                              Bioscience and Bioengineering
                            </MenuItem>
                            <MenuItem value="Biotechnology">
                              Biotechnology
                            </MenuItem>
                            <MenuItem value="Chemical Engineering">
                              Chemical Engineering
                            </MenuItem>
                            <MenuItem value="Chemistry">Chemistry</MenuItem>
                            <MenuItem value="Civil Engineering">
                              Civil Engineering
                            </MenuItem>
                            <MenuItem value="Computer Science and Engineering">
                              Computer Science and Engineering
                            </MenuItem>
                            <MenuItem value="Economics">Economics</MenuItem>
                            <MenuItem value="Electrical Engineering">
                              Electrical Engineering
                            </MenuItem>
                            <MenuItem value="Electrical and Electronics Engineering">
                              Electrical and Electronics Engineering
                            </MenuItem>
                            <MenuItem value="Electronics & Communication Engineering">
                              Electronics & Communication Engineering
                            </MenuItem>
                            <MenuItem value="Engineering Design">
                              Engineering Design
                            </MenuItem>
                            <MenuItem value="Humanities and Social Sciences">
                              Humanities and Social Sciences
                            </MenuItem>
                            <MenuItem value="Management/Business Studies">
                              Management/Business Studies
                            </MenuItem>
                            <MenuItem value="Mathematics/Mathematics and Computing">
                              Mathematics/Mathematics and Computing
                            </MenuItem>
                            <MenuItem value="Mechanical Engineering">
                              Mechanical Engineering
                            </MenuItem>
                            <MenuItem value="Metallurgical and Materials Engineering">
                              Metallurgical and Materials Engineering
                            </MenuItem>
                            <MenuItem value="Mining Engineering">
                              Mining Engineering
                            </MenuItem>
                            <MenuItem value="Ocean Engineering">
                              Ocean Engineering
                            </MenuItem>
                            <MenuItem value="Physics/Engineering Physics">
                              Physics/Engineering Physics
                            </MenuItem> */}
                            {/* {userData.branches.map((item, index) => {
                              return <MenuItem value={item}>{item}</MenuItem>;
                            })} */}

                            {branches?.map((item) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={branches.indexOf(item)}
                                  style={{ textOverflow: "ellipsis" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                            {/* <MenuItem value="Other">Other</MenuItem> */}
                          </Select>
                        )}
                        {showBranch && (
                          <div className="flex mt-2">
                            <input
                              type="text"
                              name="otherbranch"
                              className="border-2 rounded s-card-height-56"
                              placeholder="Other Branch/Department"
                              style={{ marginRight: "5px" }}
                              displayEmpty
                              value={otherBranch}
                              onChange={(e) => {
                                setOtherBranch(e.target.value);
                              }}
                              autoComplete="nope"
                            />
                            <Button
                              name="branch"
                              style={{ marginRight: "10px" }}
                              variant="contained"
                              color="primary"
                              className="s-card-height-56"
                              onClick={(e) => {
                                handleChange({
                                  target: {
                                    name: "branch",
                                    value: otherBranch,
                                  },
                                });
                                document.querySelector(
                                  "input[name=otherbranch]"
                                ).disabled = true;
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <Button
                              name="branch"
                              value=""
                              className="s-card-height-56"
                              style={{ marginRight: "10px" }}
                              variant="contained"
                              color="error"
                              onClick={(e) => {
                                setOtherBranch("");
                                handleChange({
                                  target: { name: "branch", value: "" },
                                });
                                setShowBranch(false);
                                document.querySelector(
                                  "input[name=otherbranch]"
                                ).disabled = false;
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* Specialization */}

                <div className="branch cust-row flex">
                  <div className="element w-50">
                    <label htmlFor="specialization">
                      Specialization (if applicable)
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          setOtherSpecialization("");
                          setUserData({ ...userData, specialization: "" });
                        }}
                        className="cursor-pointer  ml-[10px]"
                      />
                    </label>
                    {!showSpecialization && (
                      <Select
                        value={userData.specialization}
                        name="specialization"
                        id="specialization"
                        renderValue={() =>
                          userData.specialization || "Select Specialization"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFieldError({
                            ...fieldError,
                            specialization: false,
                          });
                          if (e.target.value == "Other") {
                            setShowSpecialization(true);
                          } else {
                            setShowSpecialization(false);
                          }
                        }}
                        error={fieldError.specialization}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{ width: 1 }}
                        inputProps={{ "aria-label": "Without label" }}
                        style={{ textOverflow: "ellipsis" }}
                        defaultValue=""
                      >
                        <MenuItem hidden disabled value="">
                          Select Specialization
                        </MenuItem>
                        {branchSpecilization[userData.branch]?.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    )}
                    {showSpecialization && (
                      <div className="flex ">
                        <input
                          type="text"
                          name="otherspecialization"
                          className="border-2 rounded s-card-height-56"
                          placeholder="Specialization"
                          style={{ marginRight: "10px" }}
                          value={otherSpecialization}
                          onChange={(e) => {
                            setOtherSpecialization(e.target.value);
                          }}
                          autoComplete="nope"
                        />
                        <Button
                          name="specialization"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "10px" }}
                          className="s-card-height-56"
                          onClick={(e) => {
                            handleChange({
                              target: {
                                name: "specialization",
                                value: otherSpecialization,
                              },
                            });
                            document.querySelector(
                              "input[name=otherspecialization]"
                            ).disabled = true;
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                        <Button
                          name="specialization"
                          value=""
                          style={{ marginRight: "10px" }}
                          className="s-card-height-56"
                          variant="contained"
                          color="error"
                          onClick={(e) => {
                            setOtherSpecialization("");
                            handleChange({
                              target: { name: "specialization", value: "" },
                            });
                            setShowSpecialization(false);
                            document.querySelector(
                              "input[name=otherspecialization]"
                            ).disabled = false;
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                {/* mobile resume */}
                <div
                  className="profile-resume flex border rounded-md p-2 s-resume-950-1"
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                  id="resume"
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    id="resume"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize: "16px",
                      color: "#095a54",
                      fontWeight: "500",
                    }}
                  >
                    Resume<span style={{ color: "red" }}>*</span>
                  </Typography>
                  {userData.resume ? (
                    <button onClick={() => downloadPDF(userData.resume)}>
                      <i className="fa-solid fa-file"></i>Download
                    </button>
                  ) : null}
                  <div className="filebase">
                    <FileBase
                      type="application/pdf"
                      multiple={false}
                      onDone={({ base64, size, type }) => {
                        if(type !== "application/pdf"){
                          setValidResume(true)
                        }
                        else{
                          setValidResume(false)
                        }
                          if (parseInt(size) < 1024) {
                            setresumeSize(false);
                            // var compressedResume = LZString.compressToUTF16(base64);
                            setnewResume(base64);
                          } else {
                            console.log(parseInt(size));
                            setresumeSize(true);
                          }
                        // }
                      }}
                      onChange={() => {
                        setFieldError({ ...fieldError, resume: false });
                      }}
                    />
                  </div>
                  {resumeSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 1 MB
                    </p>
                  )}
                  {validResume === true && (
                    <p className="choosefile resumeValidation">
                      Please upload resume in pdf form only
                    </p>
                  )}
                  {/* <input
                  type="file"
                  name="resume"
                  id="resume"
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  value={""}
                  onChange={(e) => {
                    const value = e.target.files[0];
                    setUserData({ ...userData, resume: value });
                    console.log(value);
                    // setpfpImage({ pfpImage: value });
                    // console.log(pfpImage.pfpImage);
                    const resumeBtn = document.getElementById("resume-a");
                    resumeBtn.href = URL.createObjectURL(e.target.files[0]);
                  }}
                /> */}
                </div>

                {/* Project Title */}

                <div className="project cust-row flex">
                  <div className="element">
                    <label htmlFor="project">
                      B.Tech/Dual Degree/M.Tech/MS/PhD Project Title
                    </label>
                    <textarea
                      type="text"
                      className="border-2 rounded w-full"
                      name="project"
                      placeholder="Project Title"
                      value={userData.project || ""}
                      onChange={handleChange}
                      autoComplete="nope"
                      rows="3"
                    />
                  </div>
                </div>
                <div className="upiId cust-row flex">
                  <div className="element">
                    <label
                      htmlFor="upiId"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        UPI ID<span style={{ color: "red" }}>&nbsp;*</span>
                      </p>
                      {/* <div className="tooltip">
                        <InfoOutlined
                          sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                        />
                        <span className="tttext">
                          UPI ID is needed to refund your mock interview charges
                          in cases like cancelation of a mock interview, etc
                        </span>
                      </div> */}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="tt ml-auto">
                          <InfoOutlined
                            sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                          />
                          <span style={{ top: "-40px" }} className="tttext">
                            UPI ID is needed to refund your mock interview
                            charges in certain extreme cases
                          </span>
                        </div>
                      </div>
                    </label>
                    <input
                      type="text"
                      className="border-2 rounded upiIddd s-card-height-56"
                      name="upiId"
                      placeholder=" Your UPI Id"
                      value={userData.upiId || ""}
                      onChange={handleChange}
                      autoComplete="nope"
                      id="upiId"
                    />
                  </div>
                </div>
                {/* Profile Preference */}

                <div className="profilepref cust-row flex">
                  <div className="element">
                    <label htmlFor="profilepreference">
                      Profile Preferences
                    </label>
                    {/* <FormControl
                      sx={{
                        display: "flex",
                        justifyContent: "space",
                      }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          style={{ flex: '1' }}
                          control={
                            <Checkbox
                              checked={nonCore}
                              onChange={(e) => {
                                handleChangeCheckBox2(e);
                                // console.log(e)
                                if (!e.target.checked) {
                                  setUserData((userData) => ({
                                    ...userData,
                                    profiles: [],
                                  }))
                                }
                              }}
                              name="nonCore"
                            />
                          }
                          label="Non Core"
                        />
                        <FormControlLabel
                          style={{ flex: '1' }}
                          control={
                            <Checkbox
                              checked={core}
                              onChange={(e) => {
                                handleChangeCheckBox2(e);
                                if (!e.target.checked) {
                                  setUserData((userData) => ({
                                    ...userData,
                                    coreprofiles: [],
                                    specializations: [],
                                  }))
                                }
                              }}
                              name="core"
                            />
                          }
                          label="Core"
                        />
                      </FormGroup>
                    </FormControl> */}

                    <div
                      className="max-[600px]:bg-black"
                      style={{ display: "flex", gap: "6px" }}
                    >
                      <div
                        style={{
                          flex: "1",
                          width: "50%",
                          paddingRight: "1%",
                          background: "white",
                          boxShadow: "0px 0px 0px 2px white",
                        }}
                      >
                        <div
                          className="flex"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            style={{ flex: "1", alignSelf: "baseline" }}
                            control={
                              <Checkbox
                                className="profilePageDropdownCorrection"
                                checked={nonCore}
                                onChange={(e) => {
                                  handleChangeCheckBox2(e);
                                  // console.log(e)
                                  if (!e.target.checked) {
                                    setUserData((userData) => ({
                                      ...userData,
                                      profiles: [],
                                    }));
                                  }
                                }}
                                name="nonCore"
                              />
                            }
                            label="Non Core"
                          />
                          {nonCore && (
                            <>
                              <div className="flex w-full justify-end cursor-pointer">
                                <p
                                  className="underline text-blue-500 s-mobile-text-14"
                                  onClick={(e) => {
                                    setUserData((userData) => ({
                                      ...userData,
                                      profiles: [],
                                    }));
                                  }}
                                >
                                  clear
                                </p>
                              </div>
                              <Select
                                multiple
                                displayEmpty
                                id="profilesPref"
                                value={userData.profiles || []}
                                sx={{ width: 1 }}
                                renderValue={() => "Select Preferences"}
                                // error={fieldError.profilesPref}
                                onChange={(e) => {
                                  handleNonCorePrefChange(e);
                                  // setFieldError({ ...fieldError, profilesPref: false });
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="Data Science">
                                  Data Science
                                </MenuItem>
                                <MenuItem value="Software">Software</MenuItem>
                                <MenuItem value="Banking and Finance">
                                  Banking and Finance
                                </MenuItem>
                                <MenuItem value="Consulting">
                                  Consulting
                                </MenuItem>
                                <MenuItem value="Analytics">Analytics</MenuItem>
                                <MenuItem value="Product Management">
                                  Product Management
                                </MenuItem>
                                <MenuItem value="Operations">
                                  Operations
                                </MenuItem>
                                <MenuItem value="Supply Chain">
                                  Supply Chain
                                </MenuItem>
                                <MenuItem value="FMCG">FMCG</MenuItem>
                                <MenuItem value="Operations Research">
                                  Operations Research
                                </MenuItem>
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Inventory Management">
                                  Inventory Management
                                </MenuItem>
                                <MenuItem value="Logistics">Logistics</MenuItem>
                              </Select>
                              <div className="profile-pref-list rounded pt-2">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    minHeight: "0",
                                  }}
                                >
                                  {userData.profiles?.map((value) => (
                                    <div
                                      className="relative flex justify-between text-md rounded-full border p-1 mb-2 mx-auto text-white s-mobile-rounded-10 blue-mobile-color"
                                      style={{
                                        // width: "0px",
                                        backgroundColor: "#ebebeb",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <div className="mx-1 text-black">
                                        {value} &nbsp;
                                      </div>
                                      <div
                                        className="mx-1 cursor-pointer "
                                        onClick={() =>
                                          handleNonCoreProfilePrefDelete(value)
                                        }
                                      >
                                        <i
                                          className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                          id="iconCross"
                                        // style={{ color: "#aeaeae" }}
                                        ></i>
                                      </div>
                                    </div>
                                  ))}
                                </Box>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          width: "50%",
                          paddingLeft: "1%",
                          background: "white",
                          boxShadow: "0px 0px 0px 5px white",
                        }}
                      >
                        <div
                          className="flex"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            style={{ flex: "1", alignSelf: "baseline" }}
                            control={
                              <Checkbox
                                className="profilePageDropdownCorrection"
                                checked={core}
                                onChange={(e) => {
                                  handleChangeCheckBox2(e);
                                  if (!e.target.checked) {
                                    setUserData((userData) => ({
                                      ...userData,
                                      coreprofiles: [],
                                      specializations: [],
                                    }));
                                  }
                                }}
                                name="core"
                              />
                            }
                            label="Core"
                          />
                          {core && (
                            <>
                              <div className="flex w-full justify-end cursor-pointer s-mobile-text-14">
                                <p
                                  className="underline text-blue-500 "
                                  onClick={(e) => {
                                    setUserData((userData) => ({
                                      ...userData,
                                      coreprofiles: [],
                                      specializations: [],
                                    }));
                                  }}
                                >
                                  clear
                                </p>
                              </div>
                              {!showCore && (
                                <Select
                                  // multiple
                                  displayEmpty
                                  id="profilesPref"
                                  value={userData.coreprofiles[0] || []}
                                  sx={{ width: 1 }}
                                  renderValue={() =>
                                    userData.coreprofiles[0] ||
                                    "Select Profile Preference"
                                  }
                                  // error={fieldError.profilesPref}
                                  onChange={(e) => {
                                    console.log(
                                      "sdfniondsnvewvnewosdkk",
                                      userData.coreprofiles[0] || []
                                    );
                                    handleCorePrefChange(e);
                                    // setFieldError({ ...fieldError, profilesPref: false });
                                    if (e.target.value == "Other") {
                                      setShowCore(true);
                                    } else {
                                      setShowCore(false);
                                    }
                                  }}
                                  MenuProps={MenuProps}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  {Object.keys(special).map((key) => (
                                    <MenuItem value={key}>{key}</MenuItem>
                                  ))}
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                              )}
                              {userData.coreprofiles[0] || []?.length != 0 ? (
                                <Button
                                  className="relative flex justify-between text-md rounded-full border p-1 mx-auto text-white blue-mobile-color"
                                  style={{
                                    // width: "0px",
                                    backgroundColor: "#ebebeb",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "14px",
                                    marginBottom: "10px",
                                    marginTop: "8px",
                                    width: "100%",
                                  }}
                                >
                                  <div className="mx-1 text-black">
                                    {userData.coreprofiles[0] || []}
                                  </div>
                                  <div className="mx-1 cursor-pointer ">
                                    <i
                                      onClick={(e) => {
                                        setUserData((userData) => ({
                                          ...userData,
                                          coreprofiles: [],
                                          specializations: [],
                                        }));
                                      }}
                                      className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                      style={{ fontSize: "16px" }}
                                      id="iconCross"
                                    ></i>
                                  </div>
                                </Button>
                              ) : null}

                              {showCore && (
                                <div className="flex items-center">
                                  <input
                                    type="text"
                                    name="othercore"
                                    className="border-2 roundeds-card-height-56"
                                    placeholder="Other Core Specialization"
                                    style={{
                                      marginRight: "10px",
                                      width: "250px",
                                      overflow: "scroll",
                                    }}
                                    value={otherCore}
                                    onChange={(e) => {
                                      setOtherCore(e.target.value);
                                    }}
                                    autoComplete="nope"
                                  />
                                  <p
                                    name="othercore"
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      color: "skyblue",
                                    }}
                                    onClick={(e) => {
                                      handleCorePrefChange({
                                        target: {
                                          name: "coreprofiles",
                                          value: otherCore,
                                        },
                                      });
                                      document.querySelector(
                                        "input[name=othercore]"
                                      ).disabled = true;
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                  </p>
                                  <p
                                    name="othercore"
                                    value=""
                                    style={{
                                      marginRight: "10px",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setOtherCore("");
                                      handleCorePrefChange({
                                        target: {
                                          name: "coreprofiles",
                                          value: "",
                                        },
                                      });
                                      setShowCore(false);
                                      document.querySelector(
                                        "input[name=othercore]"
                                      ).disabled = false;
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </p>
                                </div>
                              )}
                              <FormControl>
                                <FormGroup>
                                  {special[userData.coreprofiles[0]]?.map(
                                    (value, index) => (
                                      <FormControlLabel
                                        className="odd-grey"
                                        style={{
                                          margin: "0px",
                                          fontSize: "14px",
                                        }}
                                        control={
                                          <Checkbox

                                            className="profilePageDropdownCorrection"
                                            key={index}
                                            checked={
                                              userData?.specializations?.indexOf(
                                                value
                                              ) !== -1
                                            }
                                            onChange={(e) => {
                                              handleSpecializationPrefChange(e);
                                            }}
                                            name={value}
                                          />
                                        }
                                        label={value}
                                      />
                                    )
                                  )}
                                </FormGroup>
                              </FormControl>
                              {/* <div className="profile-pref-list p-2 pt-2">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    // gap: 0.5,
                                    minHeight: "0",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >



                                  {userData.coreprofiles?.map((value) => (
                                    <div
                                      className="relative flex justify-between text-md rounded-full border p-1 mb-2 mx-auto text-white"
                                      style={{
                                        // width: "0px",
                                        backgroundColor: "#ebebeb",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <div className="mx-1 text-black">
                                        {value} &nbsp;
                                      </div>
                                      <div className="mx-1 cursor-pointer ">
                                        {Specialization[
                                          `${userData.coreprofiles}`
                                        ] && (
                                          <i
                                            className="fa-solid fa-pen-to-square pr-2 text-gray-400 hover:text-gray-600"
                                            onClick={handleOpen}
                                          ></i>
                                        )}
                                        <i
                                          onClick={() =>
                                            handleCoreProfilePrefDelete(value)
                                          }
                                          className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                          id="iconCross"
                                          // style={{ color: "#aeaeae" }}
                                        ></i>
                                      </div>
                                    </div>
                                  ))}

                                  {userData.specializations?.map((value) => (
                                    <>
                                      <Divider
                                        orientation="vertical"
                                        height="20px"
                                        sx={{
                                          height: "30px",
                                          textAlign: "center",
                                          color: "black",
                                          width: "2px",
                                        }}
                                        color="black"
                                      />
                                      <div
                                        className="relative flex justify-between text-md rounded-full border p-1 mt-2 mb-2 mx-auto text-white"
                                        style={{
                                          // width: "0px",
                                          backgroundColor: "#ebebeb",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <div className="mx-1 text-black">
                                          {value} &nbsp;
                                        </div>
                                        <div className="mx-1 cursor-pointer ">
                                          <i
                                            onClick={() =>
                                              handleSpecializationProfilePrefDelete(
                                                value
                                              )
                                            }
                                            className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                            id="iconCross"
                                          ></i>
                                        </div>
                                      </div>
                                    </>
                                  ))}



                                </Box>
                              </div> */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Appearing for */}
                <FormControl
                  sx={{
                    m: 3,
                    mt: 1,
                    ml: 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="s-mobile-appearing-for"
                  component="fieldset"
                  variant="standard"
                >
                  <label
                    className="profilepreference mr-3"
                    htmlFor="profilepreference"
                  >
                    Appearing for
                  </label>{" "}
                  <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                      control={
                        <Checkbox

                          className="profilePageDropdownCorrection"
                          checked={one}
                          onChange={handleChangeCheckBox}
                          name="one"
                        />
                      }
                      label="Campus Placements"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox

                          className="profilePageDropdownCorrection"
                          checked={two}
                          onChange={handleChangeCheckBox}
                          name="two"
                        />
                      }
                      label="Internship Interviews"
                    />
                  </FormGroup>
                </FormControl>
                {/* Targeted Companies */}

                <div className="w-full gap-2 text-lg mt-2 flex">
                  <div className="element">
                    <label htmlFor="companies">
                      Targeted Companies and Profiles
                    </label>
                    <div>
                      {targetedCompanies?.map((item, index) => (
                        <div className="relative rounded border p-2 my-2">
                          {targetedCompanies?.length !== 1 ? (
                            <div className="absolute top-1 right-1">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  setTargetedCompanies(
                                    targetedCompanies.filter(function (sItem) {
                                      return sItem !== item;
                                    })
                                  );
                                }}
                              >
                                <CancelIcon fontSize="medium" />
                              </IconButton>
                            </div>
                          ) : null}
                          <div className="relative flex gap-5 mt-4 mb-2 w-full max-[600px]:flex-wrap">
                            <div>
                              <label
                                htmlFor="company"
                                className="s-mobile-text-14 max-[600px]:font-medium"
                              >
                                Company
                              </label>
                              <input
                                type="text"
                                className="border-2 rounded w-full p-2 s-card-height-56 s-mobile-text-14"
                                name="targeted-company"
                                placeholder="Company"
                                value={item.company}
                                onChange={(e) => {
                                  handleChangeTargetedCompanies(
                                    index,
                                    "company",
                                    e.target.value
                                  );
                                }}
                                autoComplete="nope"
                              />
                            </div>
                            <div>
                              <label
                                className="s-mobile-text-14 max-[600px]:font-medium"
                                htmlFor="targeted-profile"
                              >
                                Profile
                              </label>
                              <input
                                type="text"
                                className="border-2 rounded w-full p-2 s-card-height-56 s-mobile-text-14"
                                name="targeted-profile"
                                placeholder="Profile"
                                value={item.profile}
                                onChange={(e) => {
                                  handleChangeTargetedCompanies(
                                    index,
                                    "profile",
                                    e.target.value
                                  );
                                }}
                                autoComplete="nope"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              className="s-mobile-text-14 max-[600px]:font-medium"
                              htmlFor="attachJD"
                            >
                              Add Job Description (JD)
                            </label>
                            <div className="flex gap-5">
                              <textarea
                                name="targeted-textJD"
                                id="targeted-textJD"
                                cols="30"
                                rows="3"
                                value={item.textJD}
                                onChange={(e) => {
                                  handleChangeTargetedCompanies(
                                    index,
                                    "textJD",
                                    e.target.value
                                  );
                                }}
                                placeholder="Enter your Job Description"
                                className="border p-1 focus:outline-none resize-none w-full s-mobile-text-14"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button
                        variant="contained"
                        onClick={() => {
                          setTargetedCompanies([
                            ...targetedCompanies,
                            {
                              company: "",
                              profile: "",
                              // fileJD: "",
                              textJD: "",
                            },
                          ]);
                        }}
                      >
                        {targetedCompanies.length === 0
                          ? `Add a company/profile`
                          : " Add another company/profile"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Col-30 */}

              <div className="profile-form-profile" id="image">
                {/* Profile Photo */}

                <div className="profile-photo border rounded-md p-2 s-resume-950">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Profile Photo
                  </Typography>
                  <img src={user.photo} className="upload" alt="Upload" />
                  <div className="filebase">
                    <FileBase
                      type="file"
                      multiple={false}
                      onDone={async ({ base64, size }) => {
                        if (parseInt(size) < 500) {
                          setimageSize(false);
                          setnewImage(await reduce_image_file_size(base64));
                          console.log(await reduce_image_file_size(base64));
                        } else {
                          setimageSize(true);
                        }
                      }}
                    />
                  </div>
                  {imageSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 500 KB
                    </p>
                  )}
                  {/* <input
                  type="file"
                  name="photo"
                  id="photo"
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  value={""}
                  accept="image/png, image/jpg, image/jpeg, image/url"
                  onChange={(e) => {
                    const value = e.target.files[0];
                    // setUserData({ ...userData, photo: value });
                    setPhoto(value);
                    // console.log(value);
                    var image = document.querySelector(".upload");
                    image.src = URL.createObjectURL(value);
                    // document.getElementById("photo").value = value;
                  }}
                /> */}
                </div>
                {/* Profile Resume */}

                <div
                  className="profile-resume border rounded-md p-2 s-resume-950"
                  id="resume"
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    id="resume"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Resume<span style={{ color: "red" }}>*</span>
                  </Typography>
                  {userData.resume ? (
                    <button onClick={() => downloadPDF(userData.resume)}>
                      <i className="fa-solid fa-file"></i>Download
                    </button>
                  ) : null}
                  <div className="filebase">
                    <FileBase
                      type="application/pdf"
                      multiple={false}
                      onDone={({ base64, size, type }) => {
                        if(type !== "application/pdf"){
                          setValidResume(true)
                        }
                        else{
                          setValidResume(false)
                        }
                          if (parseInt(size) < 1024) {
                            setresumeSize(false);
                            // var compressedResume = LZString.compressToUTF16(base64);
                            setnewResume(base64);
                          } else {
                            console.log(parseInt(size));
                            setresumeSize(true);
                          }
                        // }
                      }}
                      onChange={() => {
                        setFieldError({ ...fieldError, resume: false });
                      }}
                    />
                  </div>
                  {resumeSize === true && (
                    <p className="choosefile">
                      Choose a file smaller than 1 MB
                    </p>
                  )}
                  {validResume === true && (
                    <p className="choosefile resumeValidation">
                      Please upload resume in pdf form only
                    </p>
                  )}
                  {/* <input
                  type="file"
                  name="resume"
                  id="resume"
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  value={""}
                  onChange={(e) => {
                    const value = e.target.files[0];
                    setUserData({ ...userData, resume: value });
                    console.log(value);
                    // setpfpImage({ pfpImage: value });
                    // console.log(pfpImage.pfpImage);
                    const resumeBtn = document.getElementById("resume-a");
                    resumeBtn.href = URL.createObjectURL(e.target.files[0]);
                  }}
                /> */}
                </div>
                <div className="my-3 p-2 border rounded-md text-left">
                  <Typography
                    variant="h5"
                    component="h5"
                    className="s-mobile-main-h"
                    align="center"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Social Links
                  </Typography>
                  <div className="my-3">
                    <label htmlFor="linkedin">
                      <i className="fa-brands fa-linkedin"></i> LinkedIn
                    </label>
                    <input
                      type="text"
                      name="linkedInURL"
                      id="linkedin"
                      value={userData.linkedInURL}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="LinkedIn URL"
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="github">
                      <i className="fa-brands fa-github"></i> GitHub
                    </label>
                    <input
                      type="text"
                      name="githubURL"
                      id="github"
                      value={userData.githubURL}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="GitHub URL"
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="twitter">
                      <i className="fa-brands fa-twitter"></i> Twitter
                    </label>
                    <input
                      type="text"
                      name="twitterURL"
                      id="twitter"
                      value={userData.twitterURL}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                      placeholder="Twitter URL"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="px-2"
            >
              <div></div>
              <button
                type="submit"
                // onClick={(e) => handleSubmit(e)}
                className="cust-btn create-btn"
              >
                {!user?.mobNumber ? "Create an Account" : "Update Details"}
              </button>
              {/* <div className="tooltip">
                <InfoOutlined
                  sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                />
                <span className="tttext">
                  By clicking on{" "}
                  {user?.mobNumber
                    ? ["[Update Details]"]
                    : ["[Create Account]"]}{" "}
                  button, you agree to our Terms and Conditions and Privacy
                  Policy
                </span>
              </div> */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="tt ml-auto">
                  <InfoOutlined
                    sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                  />
                  <span className="tttext">
                    By clicking on{" "}
                    {user?.mobNumber
                      ? ["[Update Details]"]
                      : ["[Create Account]"]}{" "}
                    button, you agree to our Terms and Conditions and Privacy
                    Policy
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
};

export default ProfileForm;