import { useState } from 'react';
import { Box, Button, Modal, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import { InfoOutlined, WarningOutlined, Close, FileCopy, KeyboardArrowDown, OpenInNew, Splitscreen, ViewSidebar, OpenWith, OpenInBrowser } from '@mui/icons-material';
import { giveTime, } from '../utils';
import { downloadPDF, programValues, toastStyle } from 'utility/helper';
import { toast } from 'react-hot-toast';
import { MdOutlineMenuOpen, MdViewSidebar } from 'react-icons/md';
import TextLoading from '../CallEndPages/TextLoading';


const SidePanel = ({ accountId, iDetails, iInfo, mockId, setShowPdf }) => {
  const FRONTEND_BASE = process.env.REACT_APP_FRONTEND_SERVER_URL;
  const [showModal, setShowModal] = useState(false)
  const buttonStyles = {
    common: {
      color: "white",
      zoom: 0.8,
      width: "100%",
    },
    first: {
      backgroundColor: "primary.dark",
    },
    second: {
      backgroundColor: "secondary.dark"
    }
  }

  const openSResume = () => {
    setShowPdf(true)
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    // Handle button click logic here
    if(option === 'here') {
      openSResume()
    } else if(option === 'newtab') {
      downloadPDF(iDetails?.student.resume)
    }
    handleClose();
  };

  const openIProfile = () => {
    iDetails?.interviewer.isUnavailable
      ? toast.loading(
        `Sorry! the user has ${iDetails?.interviewer.isDisable ? "disabled" : ""} ${iDetails?.interviewer.isDelete ? "deleted" : ""
        } this account`,
        {
          ...toastStyle.loading,
          position: "top-center",
          duration: 2000,
        }
      )
      : window.open(
        `${FRONTEND_BASE}/s/interviewer/${iDetails?.interviewer.id}`,
        "_blank"
      );
  }

  return (
    <Box sx={{
      width: "fit-content",
      height: "100%",
      backgroundColor: "#343333",
      color: "white",
      maxWidth: "20%"
    }}>
      <List sx={{ mx: 1 }}>
        <ListItem divider disableGutters sx={{
          borderBottomColor: "#707070"
        }}>
          <Button variant="outlined" color="primary" sx={{
            ...buttonStyles.common,
            ...buttonStyles.first
          }}
            onClick={() => setShowModal(true)}
          // onMouseEnter={() => handleHover("first", "enter")} onMouseLeave={() => handleHover("first", "leave")}
          >
            <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
              <InfoOutlined sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText  sx={{ 
              mx: 0.5,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              } 
            }} primary="Interview Details" />
          </Button>
          <div>
            <Modal sx={{}} open={showModal} onClose={() => setShowModal(false)} className="s-desktop-video-details">
              <Box sx={{ minWidth: "max-content", bgcolor: '#383838', borderRadius: "10px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, boxShadow: 24, p: 4 }}>
                <IconButton aria-label="close" onClick={() => setShowModal(false)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Close sx={{ fill: "white", zoom: 1.2 }} />
                </IconButton>
                <Typography variant="h5" sx={{ fontWeight: "bold" }} component="h2" gutterBottom>
                  Interview Details
                </Typography>
                {iDetails?.student ? (
                  <Box sx={{ p: 1, maxWidth: "600px", }}>
                    <Typography variant="body1"><b>Mock Interview of:</b>&nbsp;{iDetails.student.fname}&nbsp;{iDetails.student.lname},&nbsp;
                      {programValues[iDetails.student.program]},&nbsp;{iDetails.student.year},&nbsp;{iDetails.student.branch},&nbsp;
                      {iDetails.student.institute}</Typography>
                  </Box>
                ) : (
                  <Box sx={{ p: 1, maxWidth: "600px", }}>
                    <Typography variant="body1"><b>Mock Interview by:</b>&nbsp;{iDetails?.interviewer?.fname}&nbsp;{iDetails?.interviewer?.lname},&nbsp;
                      {iDetails?.interviewer?.designation},&nbsp;
                      {iDetails?.interviewer?.currCompany}</Typography>
                  </Box>
                )}
                {/* {iDetails?.interviewer ?  : null} */}
                <Box sx={{ display: 'flex', px: 1, pt: 1, pb: 0 }}>
                  <Typography variant="body1"><b>Profile:</b></Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>{iInfo?.profile}</Typography>
                </Box>
                <Box sx={{ display: 'flex', px: 1, pt: 0 }}>
                  <Typography variant="body1"><b>Slot:</b></Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>{giveTime(iInfo?.fromTime)} - {giveTime(iInfo?.toTime)}, {iInfo?.date}</Typography>
                </Box>
                <Typography variant="body1" sx={{ ml: 1 }}><b>Mock ID:</b> {mockId}</Typography>
              </Box>
            </Modal>
          </div>
        </ListItem>
        <ListItem divider disableGutters sx={{
          borderBottomColor: "#707070"
        }}>
          <Button variant="outlined" color="secondary" sx={{
            ...buttonStyles.common,
            ...buttonStyles.second
          }}
            // onMouseEnter={() => handleHover("second", "enter")} onMouseLeave={() => handleHover("second", "leave")}
            onClick={accountId.startsWith("I") ? handleClick : openIProfile}
          >
            <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
              {accountId.startsWith("S") ?
                <WorkIcon sx={{ color: "white" }} /> :
                <FileCopy sx={{ color: "white" }} />
              }
            </ListItemIcon>
            <ListItemText sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              }
            }} primary={accountId.startsWith("S") ? "Interviewer's Profile" : "Student's Resume "} />
            {accountId.startsWith("S") ? null:<KeyboardArrowDown />}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                transform: "translate(-5px,-35px)",
                width: "18%",
              }
            }}
          >
            <MenuItem sx={{display: "flex", justifyContent: "space-between"}} onClick={() => handleOptionClick('here')}>Open here&nbsp;<OpenInBrowser/></MenuItem>
            <MenuItem sx={{display: "flex", justifyContent: "space-between"}}onClick={() => handleOptionClick('newtab')}>Open in New Tab&nbsp;<OpenInNew/></MenuItem>
          </Menu>
        </ListItem>
        <ListItem divider sx={{
          borderBottomColor: "#707070"
        }}>
          {/* <ListItemIcon>
            <WarningOutlined sx={{
              color: "warning.light"
            }} />
          </ListItemIcon> */}
          <ListItemText sx={{
            color: "error.main",
            px: 0,
            fontWeight: 900
          }} >
            <Typography>If you have not been connected with {iDetails?(iDetails?.student)?"the student":"your interviewer":<TextLoading text="..."/>}&nbsp;
            for sometime even after the starting time of the mock interview, 
            please <TextLoading interval={2} text="try disconnecting and connecting"/> again</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};

export default SidePanel;
