import { Box, Button, Chip, Modal, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  t24HDecimalTo12HFormat,
  convertDateFormat,
  toastStyle,
} from "../../../utility/helper";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import toast from "react-hot-toast";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  maxWidth: "750px",
  width: "95%",
};
const UpcomingInterview = ({
  user,
  name,
  desg,
  price,
  logo,
  profile,
  subprofile,
  slotStart,
  image,
  date,
  slotEnd,
  status,
  id,
  payment,
  mockId,
  req_id,
  rescheduled,
  isRescheduled,
  isUnavailable,
  isCancelled,
  isDisable,
  isDelete,
  company,
  reschedule
}) => {
  
  console.log("isCancelled", isCancelled,"--------------");

  const cancelling = window.location.href.includes("/s/cancel") || window.location.href.includes("/i/cancel") || window.location.href.includes("/s/report");

  const [meetUrl, setMeeturl] = useState();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState(true);
  const [text, setText] = useState("new");
  const handleChange = (e) => {
    setValue(e.target.value);
    console.log(value);
    if (!e.target.value) {
      setError(true);
    } else {
      setError(false);
    }
    console.log(error);
  };
  const handleSubmit = () => {
    console.log(value);
    if (!value) {
      setError(true);
      return;
    }
    const arr = value.split(" ");
    console.log(arr);
    let link = value;
    if (arr.length > 1) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes("meet.google.com")) {
          link = arr[i];
          console.log(arr[i]);
        }
      }
    }
    let ans = link.split("meet");
    console.log(ans);
    let res = link;
    if (ans.length >= 1) {
      for (let i = 0; i < ans.length; i++) {
        console.log(ans[i]);
        if (ans[i].includes("google.com")) {
          res = "https://meet" + ans[i];
          console.log(res);
        }
      }
    }
    let link2 = res.replaceAll("-", "");
    let finalAns = link2.slice(0, 34);
    axios
      .patch(`${BASE}/send/addMeetingLink/${req_id}`, { meetUrl: finalAns })
      .then((res) => {
        console.log(res);
        setMeeturl(value);
        setOpen(false);
        toast.success(`Meeting Link added Successfully`, {
          ...toastStyle.success,
        });
      })
      .catch(() => {
        setOpen(false);
      });
    setOpen(false);
    setMeeturl(value);
    // setTimeout(() => {
    //   setOpen(false)
    // }, 0);
  };
  const handlePopupClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const ribbon = document.querySelectorAll(".ribbon");
    ribbon.forEach((item) => {
      if (item.classList.contains("active")) {
        item.parentElement.style.paddingRight = "20px";
      } else {
        item.parentElement.style.paddingRight = "0px";
      }
    });
  });
  useEffect(() => {
    axios
      .get(`${BASE}/send/getReqByMockid/${mockId}`)
      .then((res) => {
        console.log(res.data);
        setMeeturl(res.data.meetUrl);
        setLoaded(true);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [meetUrl]);
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      // console.log('svg',url);
      var img = new Image();
      img.src = url;
      return img?.getBBox?.height !== 0;
    } else {
      // console.log('.png',url);
      var img2 = new Image();
      img2.src = url;
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  if (payment === false) {
    return;
  }
  //   const timeDuration=(slot)=>{
  //     let text = slot
  // const myArray = text.split(" ");
  // const arr1=myArray[0].split(":")
  // const arr2=myArray[3].split(":")
  // const h1=parseInt(arr1[0]);
  // const h2=parseInt(arr2[0]);
  // const m1=parseInt(arr1[1]);
  // const m2=parseInt(arr2[1]);
  // return (60*(h2-h1)+(m2-m1))
  //   }
  const rescheduleStatusText = {
    sent: "Requested for Rescheduling",
    declined: "Reschedule Request Declined",
    accepted: "Rescheduled Successfully",
    failed: "Reschedule Request Failed"
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };


  return (
    <>
       {(isRescheduled === "true" || isRescheduled === true) && (
        <div
          className={"box-shadow-request-card px-3 py-2 s-desktop-card"}
          style={{
            position: "absolute",
            right: "30px",
            zIndex: "2",
            backgroundColor: "#f5ed4e",
            borderRadius: "10px",
          }}
        >
          <p>{rescheduleStatusText[reschedule.status] || "no status"}</p>
        </div>
      )}

      {loaded && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={!meetUrl || open}
          onClose={handlePopupClose}
          closeAfterTransition
        >
          <Box sx={style}>
            <div className="p-2 bg-blue-500 flex justify-between cursor-move">
              <Typography
                id="modal-modal-description"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                {text === "new" &&
                  `Please create a Google Meet Link using your Gmail and enter it below for this Mock Interview (ID: ${mockId})`}
                {text === "edit" &&
                  `Please add a New Google Meet Link using your Gmail and enter it below for this Mock Interview (ID: ${mockId})`}
              </Typography>
            </div>
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "20px",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <TextField
                error={error}
                defaultValue={meetUrl}
                onChange={(e) => handleChange(e)}
                fullWidth
                id="outlined-basic"
                label="Google Meet Link"
                variant="outlined"
              />
              <br />
              <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="success"
                >
                  Save
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      <div className="relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card">
        {/* Updated */}
        <div className="absolute h-full w-full unshow" id="updated">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="px-4 py-2 bg-green-500 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Updated
              </Typography>
            </div>
          </div>
        </div>

        {/* Canceled */}
        <div className="absolute h-full w-full unshow" id="canceled">
          <div className="flex justify-center items-center h-full bg-gray-500/50">
            <div className="absolute text-2xl top-2 right-2 text-red-600 cursor-pointer hover:text-red-800">
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
            <div className="px-4 py-2 bg-red-600 rounded-md">
              <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                Canceled
              </Typography>
            </div>
          </div>
        </div>
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                      isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                    "_blank"
                  );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div
          className="px-3 col-span-3"
          style={{ textAlign: "center", alignItems: "center" }}
        >
          <div className="flex py-1 justify-between w-full">
            <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-medium leading-4">{name}</h4>
              <p>
                {" "}
                {desg}, {company}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div>
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "20px",
              width: "50%"
            }}>
            <div>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR {price}</p>
            </div>
            <div>
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{convertDateFormat(date)}</p>
            </div>
            </div>
          </div> */}
          <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={isRescheduled}
                reschedule={reschedule}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
              />
          <div
            className="relative flex justify-center"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {isCancelled === "true" && (
              <div
                className={"box-shadow-request-card px-3 py-2"}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "10px",

                }}
              >
                <p>Cancelled</p>
              </div>
            )}
            {rescheduled !== "true" &&
              isCancelled !== "true" && !cancelling &&
              (meetUrl ? (
                <div style={{ display: "flex" }}>
                  <button
                    onClick={() => {
                      meetUrl.includes("http")
                        ? window.open(meetUrl, "_blank")
                        : window.open(`https://${meetUrl}`, "_blank");
                    }}
                    type="button"
                    className="cust-btn join-meeting-btn"
                    style={{
                      marginRight: "5px",
                      border: "0",
                      backgroundColor: "green",
                      margin: "0",
                    }}
                  >
                    Join Mock Interview
                  </button>
                  <div
                    onClick={() => {
                      setOpen(true);
                      setText("edit");
                    }}
                    type="button"
                    className="cust-btn pt-0.5"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      margin: "0",
                      marginLeft: "8px",
                    }}
                  >
                    <p>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </p>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setOpen(true);
                    setText("new");
                  }}
                  type="button"
                  className="cust-btn join-meeting-btn"
                  style={{ margin: "0" }}
                >
                  Add Meeting Link
                </button>
              ))}
          </div>
          <div className="meeting-id">Mock Interview ID: {mockId}</div>
        </div>
      </div>

      {/* mobile card */}

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400" style={{ fontSize: "0.65rem" }}>
            Sent 15 Min ago
          </p>
        </div>
        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"
            
          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                isUnavailable
                  ? toast.loading(
                      `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                        isDelete ? "deleted" : ""
                      } this account`,
                      {
                        ...toastStyle.loading,
                        position: "top-center",
                        duration: 2000,
                      }
                    )
                  : window.open(
                      `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                      "_blank"
                    );
              }}
              className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div>
          <div className="s-duration-price" style={{marginBottom:'10px'}}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">45 Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">INR 499</span>
              </div>
            </div>

            <div style={{justifyContent: 'flex-start'}} className="s-duration-price">
                <p className="text-base text-gray-400" style={{color:'black', fontWeight:'bold', marginRight:'5px',transform:'translateY(2px)'}}>Interview Slot : </p>
              <div > 
                <span className="text-lg"> {t24HDecimalTo12HFormat(slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})`: profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{fontSize:'13px', textAlign:'center'}} className="meeting-id">Mock Interview ID: {mockId} <FaCopy style={{display: 'inline', fontSize: '1rem',transform: 'translate(6px,-3px)'}} onClick={handleCopy} /></div>

        <div
          className="relative flex justify-center"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width:'100%'
          }}
        >
          {isCancelled === "true" && (
            <div
              className={"box-shadow-request-card px-3 py-2"}
              style={{
                color: "red",
                fontWeight: "500",
                width:'100%',
                border:'2px solid red',
                borderRadius:'0px 0px 20px 20px'
              }}
            >
              <p style={{width:'100%',textAlign:'center'}}>Cancelled</p>
            </div>
          )}
          
        </div>
      </div>
    </>
  );
};

export default UpcomingInterview;